
import { Component, Prop, Vue } from 'vue-property-decorator'

import NeutralButton from './NeutralButton.vue'

import { web3Store } from '~/store'
import { Dex } from '~/types/web3'
import { isClient, isStaging } from '~/utils'

const logos: Record<Dex | 'PancakeSwap', string> = {
  [Dex.Apeswap]: '/assets/apeswap_logo.png',
  [Dex.Arbidex]: '/assets/arbidex_logo.png',
  [Dex.Camelot]: '/assets/camelot.webp',
  [Dex.Fusionx]: '/assets/fusionx_logo.png',
  [Dex.Pancakeswap]: '/assets/pancakeswap_logo.svg',
  [Dex.Ramses]: '/assets/ramses_logo.png',
  [Dex.Retro]: '/assets/retro_logo.png',
  [Dex.Stellaswap]: '/assets/stellaswap_logo.webp',
  [Dex.Sushiswap]: '/assets/sushiswap_logo.svg',
  [Dex.Thena]: '/assets/thena_logo.png',
  [Dex.Uniswap]: '/assets/uniswap_logo.svg',
  [Dex.Horiza]: '/assets/horiza_logo.png',
  [Dex.Pangolin]: '/assets/pangolin_logo.png',
  [Dex.Baseswap]: '/assets/baseswap_logo.jpeg',
  PancakeSwap: '/assets/pancakeswap_logo.svg',
}

export const dexes: (Dex | 'PancakeSwap')[] = isStaging
  ? [
      ...new Set([
        Dex.Uniswap,
        ...Object.values(Dex)
          .map((dex) => (dex === Dex.Pancakeswap ? 'PancakeSwap' : dex))
          .sort(),
      ]),
    ]
  : [
      Dex.Uniswap,
      Dex.Apeswap,
      Dex.Arbidex,
      Dex.Baseswap,
      Dex.Camelot,
      Dex.Horiza,
      'PancakeSwap',
      // Dex.Pancakeswap,
      Dex.Pangolin,
      Dex.Ramses,
      Dex.Retro,
      Dex.Sushiswap,
      Dex.Thena,
    ]

// eslint-disable-next-line no-use-before-define
@Component<NetworkSelectMenu>({
  components: { NeutralButton },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
  },
})
export default class NetworkSelectMenu extends Vue {
  @Prop({ type: String, required: false, default: '' }) dropdownClass!: boolean
  @Prop({ type: String, required: false, default: '' }) ulClass!: boolean

  open = false
  Dex = Dex
  dexes = dexes
  logos = logos
  ready = false

  get selectedDex() {
    const currentDex =
      web3Store.dex === Dex.Pancakeswap ? 'PancakeSwap' : web3Store.dex

    return this.dexes.find((e) => e === currentDex)
  }

  get isLocalChange() {
    // if (isStaging) return true
    if (!isClient) return false

    const hostname = window.location.hostname

    return ['localhost', 'staging', 'v1', 'herokuapp', 'cloudfrond'].some((e) =>
      hostname.includes(e)
    )
  }

  select(dex: Dex | 'PancakeSwap') {
    this.open = false

    if (dex === this.selectedDex) return

    if (this.isLocalChange)
      web3Store.setDex(dex === 'PancakeSwap' ? Dex.Pancakeswap : dex)
    else {
      const url =
        window.location.protocol +
        '//' +
        (dex === Dex.Uniswap
          ? 'app'
          : dex === 'PancakeSwap' || dex === Dex.Pancakeswap
          ? 'pancakeswap'
          : dex
        ).toLowerCase() +
        '.' +
        window.location.hostname.split('.').slice(1).join('.')

      window.open(url, '_blank')
    }
  }

  close() {
    this.open = false
  }
}
