
import Vue from 'vue'
export default Vue.extend({
  props: {
    maxWidthClass: {
      type: String,
      default: 'max-w-lg',
    },
    closeOnClick: Boolean,
    closeOnEsc: Boolean,
  },
  data() {
    return {
      ready: false,
      hasScrollbar: false,
    }
  },
  watch: {
    closeOnEsc() {
      document.body.removeEventListener('keyup', this.onKeyUpListener)

      if (this.closeOnEsc)
        document.body.addEventListener('keyup', this.onKeyUpListener)
    },
  },
  mounted() {
    this.hasScrollbar = document.body.scrollHeight > window.innerHeight
    const scrollbarWidth = window.innerWidth - document.body.offsetWidth

    if (this.hasScrollbar) {
      document.body.classList.add('overflow-hidden')
      document.body.style.paddingRight = `${scrollbarWidth + 1}px`
    }
    if (this.closeOnEsc)
      document.body.addEventListener('keyup', this.onKeyUpListener)
    setTimeout(() => {
      this.ready = true
    }, 200)
  },
  beforeDestroy() {
    if (this.hasScrollbar) {
      document.body.classList.remove('overflow-hidden')
      document.body.style.paddingRight = ''
    }

    if (this.closeOnEsc)
      document.body.removeEventListener('keyup', this.onKeyUpListener)
  },
  methods: {
    onKeyUpListener(ev: KeyboardEvent) {
      if (ev.keyCode === 27) this.$emit('close')
    },
  },
})
