// abstract connector types
export interface AbstractConnectorArguments {
  supportedChainIds?: number[]
}

export interface ConnectorUpdate<T = number | string> {
  provider?: any
  chainId?: T
  account?: null | string
}

export enum ConnectorEvent {
  Update = 'Web3Update',
  Error = 'Web3Error',
  Deactivate = 'Web3Deactivate',
}

// injected connector types
export type SendReturnResult = { result: any }
export type SendReturn = any

export type Send = (
  method: string,
  params?: any[]
) => Promise<SendReturnResult | SendReturn>
export type SendOld = ({
  method,
}: {
  method: string
}) => Promise<SendReturnResult | SendReturn>
