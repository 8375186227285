import { ChainId } from '~/types/web3'

export const AGGREGATOR_ADDRESS = '0xFF3E4153eC40fDF7A8D6cAbEa79093a04a0FfF8E'
export const FAIR_LAUNCH_ADDRESS = '' //
export const QUOTER_ADDRESS = '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6'

// export const WHITELISTED_ADDRESSES = [
//   '0x08dce649f86af45da8648fad31d1c33a617c52d1',
//   '0xc58f20d4cd28303a669826b7a03543aeac6626ba',
//   '0xc9e9ed5033faf883f173cbdfe1313faeef2cb4d7',
//   '0x22CB224F9FA487dCE907135B57C779F1f32251D4',
//   '0x3896217Ee5478884eB1768C9A692c7123cF6f886',
//   '0x3bD120c25360E645F55d104AF02AC8094D1C52c9',
//   '0x99872bc311048A9cCAe5B30A82C57e4b80DEA54c',
//   '0xa7499aa6464c078eeb940da2fc95c6acd010c3cc',
//   '0x6007d8256e0840A7dA2ef8dbFce06450B02043c9',
//   '0xf61c9bb2f9ddf9ea0bb193be29b4be33d3fe2ca7',
//   '0x287d6a461310eF675f0CFAb930e1a498beAc8A42',
//   '0x9e6f52B7835712686F870d5f8359c00537e276c6',
//   '0x2FCFa9087fCb9C25FE4A1268665059aB40112ac5',
//   '0x72d5dd65ab287C7A09c364B1ec63047A5FE239c8',
//   '0xC81635aBBF6EC73d0271F237a78b6456D6766132',
//   '0x865E996078F20Aa1AdF96B3AEF24Fe5BB290edcd',
//   '0x00000092efe40470C25228372310142836f1eDa6',
//   '0xF7f502609de883a240536832E7917db9eE802990',
//   '0x0DeB954555625c04CEBE64115efDE667fd8118b6',
//   '0x9dd5F8948615F966ca9819130115e3Fe1d012C2d',
//   '0x641E1eb091406EBe035562b10C7a4af367D5CAa1',
//   '0x815CE6D39D258dc55BB1058D62Eb2464e95377D1',
//   '0x14c712FA53588087193e43b8f3fA468ac6f6B0aE',
//   '0x59BD094663Dc4Ed75d19e2397AbdC22b9dcf68A2',
//   '0xF168Cf3328355a5E4eaa9dAEb0dAf9085d62269e',
//   '0xb7102517d071aD7c3a0F826524F5612B76A3E509',
//   '0x03CDC580e7dE9d805483773CD73F00e9c6b2D0a0',
//   '0xa344Ad63E7265BE83BB4aF5F41Ff1CDd943d9B29',
//   '0xad796B11d4046D2c52Af8FF34997f68A638a100A',
//   '0x4A3E017c87317DB1461D39D996Ca5523d4a2e22D',
//   '0x4497C293b025C2E2374f346C7D52dc2caa68edf8',
//   '0x98eDf1E01acF54D7663f7723D4d2F4b66d4e0958',
//   '0x0663F73E810C8088C7De36C9c3F91FACafA2eFb0',
//   '0xad796B11d4046D2c52Af8FF34997f68A638a100A',
//   '0xC9e9Ed5033FAF883f173cBdFe1313fAeeF2Cb4d7',
//   '0x523ADA4715754C7dB01DCE0Bc44e57405308E3d5',
//   '0x3f436e96F7fBBA9dbD764294C424b38e9Ff3Fff8',
//   '0x1d0f888AD1754Dc186Bb5bF71497F7922957e022',
//   '0x51E34B6B88F8d5934eE354B0aCA0fDA33A2b75f9',
// ].map((address) => address.toLowerCase())

export const BLACKLISTED_STRATEGIES = [
  '0x8e75374ff86d3dacaec17abc9ad432c513cb7d13',
  '0xe1ad0177f77c757d1012f69ad117ca7ea1cd978e',
  '0x2dfac4a204b2728c64a54b44bb65d338bcfd37cc',
  '0xe6cf4c7f9079d998f42a0390a133169c09387f0f',
  '0xb6cf3ad306db2a2b877296abc21dc5ed77411def',
  '0x36278ce7164cb7f2f6fe50154ad08247aa2735ea',
  '0x9d9b0ec179f4d7d4fbedcf5f0db8c4f71b1feadf',
  '0xd7896c59a6903c9d0384a3f28b3684c526517133',
  '0x68a6d8817fb941691a4c03c653ca4220c7af336e',
  '0xfea393b99dbf4b361ffef3cbddc105447b4d8638',
  '0x3592938a805f537c42a6f97864d8130d42348569',
  '0xd7896c59a6903c9d0384a3f28b3684c526517133',
  '0x94ff1db94c8a9cd2e6130d112652887c1119a746',
  '0x36278ce7164cb7f2f6fe50154ad08247aa2735ea',
  '0x3d6a43689ac9eec3a4cae3b0df8a5e0616bea57e',
  '0xb9023b97293796a0a9b6e6b18ecf00c1d9b28e56',
  '0x55f49ec21752c3d1bd283cc47300a6bb6d1d8aee',
  '0x5c13636a16858423017fb29239be35fbbf1bfdbb',
  '0x5cc4a668b4758a0db67cfa46d5130eb812de471f',
  '0x00cbd82490eea0723c3fc7f069868716bf78860d',
  '0x96e1c132465b944009613578c8a0d3ef6687506b',
  '0x43e74c028b1b6affb74edd8bc9fdcd42c27c404d',
  '0x6b7e0f21278ea5ef89553823abf6df3d7a207470',
  '0x346a8e2bc1c9246b1b515197305bb4680f64119c',
  '0xbcd1148b7be82a30d7f777241d018fd1ee6790f9',
  '0xb2e47abb86824a9db7f3b352228f5f36441680e9',
  '0xc1621a96363ffc30e261b4e8bd8b1509e5fa7d09',
  '0x7e6da5e549c9e426e6e76791753e94c4cf3a7a76',
  '0x48a90fc04a9f66f9690ebe8ce8a4d372569eea3b',
  '0x266fe1bd63364569cb6b5724af49b30d34f0e0be',
  '0x22cfdc5e8392baa23e1742a944ec5c53b75e0345',
  '0x527ae956eea43562ee2788f4ffd592a9c2a1d157',
  '0x46ecffdead97feaa493ef2a7c68d6f1608ad809d',
  '0x96e1c132465b944009613578c8a0d3ef6687506b',
  '0x00cbd82490eea0723c3fc7f069868716bf78860d',
  '0x47d402009099104b88ad2ebdfb3e10c1607ad53d',
  '0xe2aadea9d09d2fdc998f8ff91dc4c223aa0e827f',
].map((strategyAddress) => strategyAddress.toLowerCase())
// In general, not used
export const STABLE_COINS = ['DAI', 'USDT', 'USDC', 'FRAX', 'UST']

export const ITEMS_PER_TABLE = 10
export const ITEMS_PER_LEADERBOARD_TABLE = 5

// Outdated: For old rebalance, remove afterwards
export const STABLE_COIN_RANGE_DEFAULTS = {
  LOWER_TICK_DIFF: 2, // Will be used as (tick - LOWER_TICK_DIFF * ts) = tick - 2 * ts (ts = tickspacing)
  UPPER_TICK_DIFF: 1, // Will be used as (tick - UPPER_TICK_DIFF * ts) = tick - 1 * ts
  PERCENTAGE: 0.1, // Will be input as it is into the Percentage Input Field in Rebalance Modal
}

// For APY Calculation
export const YEAR_IN_SECONDS = 31536000

// Max ranges allowed for Rebalancing (Primary + Limit orders)
export const MAX_RANGES = 20

// Rebalance: Primary Range constants for "Token to Accept" values
export const PRIMARY_RANGE = {
  LOWER_TICK_DIFF: 2, // Will be used as (tick - LOWER_TICK_DIFF * ts) = tick - 2 * ts (ts = tickspacing)
  UPPER_TICK_DIFF: 1, // Will be used as (tick - UPPER_TICK_DIFF * ts) = tick - 1 * ts
  MULTIPLY_BY: 4,
  DIVIDE_BY: 4,
  PERCENTAGE: 0.01,
}

// For Rebalace History, show max ranges at a time (others hidden until "Show more" is clicked)
export const SHOW_MAX_RANGES = 3

// slippage
export const SLIPPAGE = 2

/**
 *  AWS API: Router for Swap
 *  **Usage**:
 *  https://y2lkyb8fj3.execute-api.us-east-1.amazonaws.com/prod/quote?protocols=v2,v3&tokenInAddress=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&tokenInChainId=137&tokenOutAddress=0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6&tokenOutChainId=137&amount=1000000000000&type=exactIn
 * */
export const ROUTER_API =
  'https://y2lkyb8fj3.execute-api.us-east-1.amazonaws.com/prod/quote'

export const ONEINCH_ROUTER_API = 'https://api-defiedge.1inch.io/v4.0'

export const ROUTER_FEE_PCT = {
  100: '0.01%',
  500: '0.05%',
  3000: '0.3%',
  10000: '1%',
}

export const BLOCK_PER_DAY_MAP: Record<ChainId, number> = {
  [ChainId.arbitrum]: 5760, // 15 sec
  [ChainId.base]: 6400, // 12-15 sec
  [ChainId.baseTestnet]: 6400, // 12-15 sec
  [ChainId.bsc]: 6400, // 12-15 sec
  [ChainId.goerli]: 6400, // 12-15 sec
  [ChainId.kovan]: 6400, // 12-15 sec
  [ChainId.mainnet]: 6400, // 12-15 sec
  [ChainId.mantle]: 43200, // 2 sec
  [ChainId.moonbeam]: 6400, // 12-15 sec
  [ChainId.avalanche]: 6400, // 12-15 sec
  [ChainId.optimism]: 6400, // 12-15 sec
  [ChainId.polygon]: 43200, // 2 sec
  [ChainId.zksyncEra]: 43200, // 2 sec
  [ChainId.rinkeby]: 6400, // 12-15 sec
}
