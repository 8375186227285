
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @Prop({ type: String, default: 'text-gray-500' }) readonly color:
    | string
    | undefined

  @Prop({ type: [String, Number], default: 20 }) size!: string | number

  get radius() {
    if (!this.size) return 20
    return this.size as number
  }

  get strokeWidth() {
    if (!this.size) return 2
    return (this.size as number) / 10
  }
}
