
import { BIconGiftFill } from 'bootstrap-vue'
import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapMutations } from 'vuex'

import { getMigratorAddress } from '~/constants/config.helper'
import { getStrategiesAddress } from '~/constants/liquidityMining'
import { web3Store } from '~/store'
import { RootState } from '~/store/app'
import { Dex } from '~/types/web3'
import { isClient } from '~/utils'

// eslint-disable-next-line no-use-before-define
@Component<AppLayout>({
  components: {
    BIconGiftFill,
  },
  computed: {
    ...mapState({
      secureBanner: (state: any) =>
        (state.app as RootState).warning.secureBanner,
      scrolled: (state: any) => (state.app as RootState).scrolled,
      collaspe: (state: any) => (state.app as RootState).navBarCollapse,
    }),
  },
  beforeMount() {
    if (window.innerWidth < 1024) {
      this.setNavbar(true)
    }
    this.initOvly()
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    ...mapMutations('app', ['setSecureBanner', 'setNavbar', 'setScrolled']),
  },
})
export default class AppLayout extends Vue {
  showButton = false
  open = false
  setScrolled!: (arg: boolean) => void
  setNavbar!: (arg: boolean) => void
  links = Object.freeze([
    {
      name: 'Dashboard',
      props: { to: '/' },
      icon: 'DashboardIcon',
    },
    {
      name: 'Leaderboard',
      props: { to: '/leaderboard' },
      icon: 'LeaderboardIcon',
    },
    {
      name: 'Farm',
      props: { to: '/farm' },
      show: () => !!this.hasLiquidMining && !!this.account,
      icon: 'FarmIcon',
    },
    // {
    //   name: 'Merkl Rewards',
    //   props: { to: '/merkl-rewards' },
    //   show: () => [Dex.Uniswap, Dex.Sushiswap].includes(web3Store.dex),
    //   logo: '/assets/merkl-logo-dark.webp',
    // },
    {
      name: 'Portfolio',
      props: { to: '/portfolio' },
      show: () => !!this.account,
      icon: 'PortfolioIcon',
    },
    {
      name: 'Manage Strategy',
      props: { to: '/profile#ManageStrategy' },
      activeKey: 'isActive',
      show: () => !!this.account,
      icon: 'ManageStrategyIcon',
    },
    {
      name: 'Defi Tokens',
      props: { to: '/tokens' },
      icon: 'TokenIcon', // BIconCoins,
    },
    {
      name: 'Import UNI position',
      props: { to: '/strategy/import' },
      show: () =>
        !!this.account &&
        web3Store.dex === Dex.Uniswap &&
        getMigratorAddress(false),
      icon: 'Unicorn',
    },
  ])

  get hasLiquidMining() {
    const chainId =
      web3Store.status.chainId ?? web3Store.chainConfigs[0].chainId
    return !!getStrategiesAddress(chainId).length
  }

  get chainName() {
    return web3Store.status.networkName
  }

  get chainId() {
    return web3Store.status.chainId
  }

  get account() {
    return web3Store.status.account
  }

  scrolled!: boolean

  onScroll() {
    const element = window.document.scrollingElement!
    const scrolled = element.scrollTop > 28
    if (scrolled !== this.scrolled) this.setScrolled(scrolled)
  }

  initOvly() {
    if (!isClient || !window.Olvy || window.olvyInstance) return

    window.olvyInstance = new window.Olvy('defiedge')
    this.showButton = true
  }
}
