var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],class:[
    'relative font-poppins text-xs transition-all',
    ...(_vm.iconOnly
      ? [_vm.open ? 'w-[210px]' : 'hover:w-[210px] w-[70px]']
      : 'w-[210px]'),
  ]},[_c('NeutralButton',{staticClass:"w-full py-2.5 pr-10 pl-3 relative",class:_vm.iconOnly
        ? ''
        : 'text-left !bg-transparent !border-grey-2 !dark:border-white/20',attrs:{"aria-expanded":"true","aria-haspopup":"listbox","aria-labelledby":"listbox-label"},on:{"click":function($event){_vm.open = !_vm.open}}},[(_vm.ready)?_c('div',{staticClass:"flex items-center gap-x-2"},[(_vm.CHAIN_ICONS[_vm.selected.chainId].startsWith('bg'))?_c('div',{staticClass:"rounded-full h-3 w-3",class:_vm.CHAIN_ICONS[_vm.selected.chainId]}):_c('img',{key:_vm.selected.chainId,staticClass:"h-4 w-4",attrs:{"alt":_vm.selected.chainName,"src":_vm.CHAIN_ICONS[_vm.selected.chainId]}}),_vm._v(" "),_c('span',{staticClass:"block truncate"},[_vm._v("\n        "+_vm._s(_vm.selected.chainName)+"\n      ")])]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"flex ml-3 pr-2 inset-y-0 right-0 absolute items-center pointer-events-none"},[_c('ChevronDownIcon',{class:['transform transition-transform' , {'rotate-180': _vm.open }]})],1)]),_vm._v(" "),_c('transition',{attrs:{"name":"page"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],staticClass:"bg-white rounded-lg shadow ring-black text-base text-xs p-3 ring-1 ring-opacity-5 z-20 absolute overflow-auto dark:bg-dark-400 focus:outline-none",class:[_vm.iconOnly ? 'right-0 min-w-[210px]' : 'inset-x-0'],staticStyle:{"top":"calc(100% + 0.5rem)"}},[_c('h2',{staticClass:"mt-2 text-xs mb-3 px-2 text-gray-3 dark:text-gray-7"},[_vm._v("\n        Select  network\n      ")]),_vm._v(" "),_c('ul',{staticClass:"space-y-1 px-2"},_vm._l((_vm.options),function(chainConfig){return _c('li',{key:chainConfig.network,staticClass:"rounded-lg cursor-pointer -mx-3 py-2 px-3 text-gray-900 select-none relative dark:text-white hover:text-black hover:bg-gray-200 hover:dark:bg-dark-100 hover:dark:text-white",attrs:{"title":`${chainConfig.chainName} (${chainConfig.network})`,"value":chainConfig.network},on:{"click":function($event){return _vm.select(chainConfig)}}},[_c('div',{staticClass:"flex items-center"},[_c('div',[(
                  _vm.CHAIN_ICONS[chainConfig.chainId] &&
                  !_vm.CHAIN_ICONS[chainConfig.chainId].startsWith('bg')
                )?_c('img',{staticClass:"object-contain h-4 w-4",attrs:{"alt":chainConfig.chainName,"src":_vm.CHAIN_ICONS[chainConfig.chainId]}}):_c('div',{staticClass:"rounded-full h-4 w-4",class:_vm.CHAIN_ICONS[chainConfig.chainId] || 'bg-gray-500'})]),_vm._v(" "),_c('span',{staticClass:"mt-0.5 text-sm ml-2 block truncate",class:_vm.selected.network === chainConfig.network
                  ? 'font-semibold'
                  : 'font-normal'},[_vm._v("\n              "+_vm._s(chainConfig.chainName)+"\n            ")])])])}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }