
import { Component, Vue, Prop } from 'vue-property-decorator'

import { getMigratorAddress } from '~/constants/config.helper'
import { web3Store } from '~/store'
import { Dex } from '~/types/web3'
import { getIconUrlOr404 } from '~/utils/tokenIcon'

@Component
export default class CreateStrategyButton extends Vue {
  @Prop({ type: Boolean, default: false }) hideMigrator: boolean | undefined

  menu = false
  getIconUrlOr404 = getIconUrlOr404
  getMigratorAddress = getMigratorAddress

  get hasMigrator() {
    return (
      !!web3Store.status.account &&
      web3Store.dex === Dex.Uniswap &&
      getMigratorAddress(false)
    )
  }
}
