import { Context } from '@nuxt/types'
import { Store } from 'vuex'

import { Dex } from '~/types/web3'
import { initializeStores, web3Store } from '~/utils/store'

const initializer = (store: Store<any>) => initializeStores(store)

export const plugins = [initializer]

const clean = (str: string | undefined) =>
  str?.replace('http://', '').replace('https://', '').split('/')[0]

const checkDex = (host: string | undefined, $cookies: Context['$cookies']) => {
  host = clean(host)

  let dex = host?.split('.')[0]?.replace('1', '') as Dex | undefined

  dex =
    host?.includes('staging') || host?.includes('localhost')
      ? $cookies.get<Dex | undefined>('defiedge.useDex')
      : (dex && ((dex[0].toUpperCase() + dex.substring(1)) as Dex)) ??
        $cookies.get<Dex | undefined>('defiedge.useDex')

  if (
    !dex ||
    !Dex[dex] ||
    (dex as string) === 'v1' ||
    (dex as string) === 'staging'
  )
    dex = Dex.Uniswap

  web3Store.setDex(dex)
}

export const actions = {
  nuxtServerInit(_: any, { req, $cookies }: Context) {
    checkDex(req.headers.host, $cookies)
  },
  nuxtClientInit(_: any, { $cookies }: Context) {
    checkDex(window.location.href, $cookies)
  },
}

export * from '~/utils/store'
