import { render, staticRenderFns } from "./TransactionStatusModal.vue?vue&type=template&id=ea7d24c8&"
import script from "./TransactionStatusModal.vue?vue&type=script&lang=ts&"
export * from "./TransactionStatusModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('/tmp/build_7e4316b3/components/Loader.vue').default,Modal: require('/tmp/build_7e4316b3/components/Modal/index.vue').default})
