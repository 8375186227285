
import { Component, Prop, Vue } from 'vue-property-decorator'

import NeutralButton from './NeutralButton.vue'

import { CHAIN_ICONS } from '~/constants/chainConfigs'
import { web3Store } from '~/store'
import { ChainConfig } from '~/store/web3'
import { switchNetwork } from '~/utils/wallet'

// eslint-disable-next-line no-use-before-define
@Component<NetworkSelectMenu>({
  components: { NeutralButton },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
  },
})
export default class NetworkSelectMenu extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) iconOnly!: boolean
  open = false
  ready = false
  CHAIN_ICONS = CHAIN_ICONS

  get options() {
    return web3Store.chainConfigs
  }

  async select(chainConfig: ChainConfig) {
    if (this.selected?.network === chainConfig.network) {
      this.close()
      return
    }

    if (!web3Store.status.account) {
      web3Store.setStatus({
        chainId: chainConfig.chainId,
        networkName: chainConfig.chainName,
      })
      this.close()
      return
    }

    try {
      await switchNetwork(chainConfig.chainId)
      this.$emit('switch')
    } catch (e: any) {
      console.warn(e, "Couldn't switch network")
      this.$toast.error(e.message)
    }
  }

  get selected() {
    const currentChainConfig = web3Store.currentChain

    return (
      currentChainConfig ?? {
        chainId: -1,
        network: '',
        chainName: 'Unknown Network',
      }
    )
  }

  close() {
    this.open = false
  }
}
