var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',_vm._g({class:[
    'disabled:cursor-default disabled:pointer-events-none',
    'px-8 font-medium text-white font-inter leading-[17px] px-8 rounded-lg transition focus:outline-none focus:ring dark:text-gray-100 dark:ring-dark-100',
    _vm.block ? 'w-full py-3' : 'py-2.5',
    _vm.disabled
      ? 'bg-gray-300 text-gray-500 cursor-not-allowed dark:bg-gray-600 dark:text-gray-400'
      : 'bg-primary-2 !text-primary-1 !text-opacity-80 hover:bg-primary-3 hover:bg-opacity-80 hover:!text-opacity-100 dark:bg-primary-3 dark:text-primary-1 dark:hover:bg-primary-2',
  ],attrs:{"disabled":_vm.disabled,"type":"button"}},_vm.disabled ? undefined : _vm.$listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }