// import axios from 'axios'

import api from '~/api'
import tokenAliases from '~/constants/tokenAliases'

const usdPriceCache: Record<string, number> = {}

const promises: Record<string, Promise<number>> = {}

export async function tokenUSDBySymbol(token: string): Promise<number> {
  promises[token] = promises[token] || _tokenUSDBySymbol(token)
  const price = await promises[token]
  delete promises[token]
  return price
}

async function _tokenUSDBySymbol(token: string): Promise<number> {
  if (
    token &&
    token.length > 0 &&
    Object.keys(tokenAliases).includes(token.toLowerCase())
  ) {
    // @ts-ignore
    token = tokenAliases[token.toLowerCase()]
  }

  const final_token = token.toUpperCase()
  if (usdPriceCache[final_token]) return usdPriceCache[final_token]
  try {
    const res = await api.tokenPrice(token)
    //   ;.catch(
    //   async () =>
    //     await axios.get(
    //       `https://min-api.cryptocompare.com/data/price?fsym=${final_token}&tsyms=USD`,
    //       {
    //         headers: {
    //           authorization: `Apikey ${process.env.CRYPTOCOMPARE_API_KEY}`,
    //         },
    //       }
    //     )
    // )

    const price = res.data.USD || usdPriceCache[final_token] || 1

    usdPriceCache[final_token] = price

    setTimeout(() => delete usdPriceCache[final_token], 5000)
    return price
  } catch (error) {
    return 0
  }
}

export async function amountsToUSD(
  token0Symbol: string,
  token1Symbol: string,
  amount0: string | number,
  amount1: string | number
) {
  const amount0USD = +amount0 * +(await tokenUSDBySymbol(token0Symbol))
  const amount1USD = +amount1 * +(await tokenUSDBySymbol(token1Symbol))

  const totalUSD = amount0USD + amount1USD

  return {
    amount0USD,
    amount1USD,
    totalUSD,
  }
}
