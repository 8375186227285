import { render, staticRenderFns } from "./default.vue?vue&type=template&id=a88b5a14&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLayout: require('/tmp/build_7e4316b3/components/AppLayout.vue').default,NetworkSelectMenu: require('/tmp/build_7e4316b3/components/NetworkSelectMenu.vue').default,Alert: require('/tmp/build_7e4316b3/components/Alert.vue').default,PrimaryButton: require('/tmp/build_7e4316b3/components/UI/PrimaryButton.vue').default,Wallet: require('/tmp/build_7e4316b3/components/Wallet.vue').default,InfoIcon: require('/tmp/build_7e4316b3/components/Icons/InfoIcon.vue').default,SecondaryButton: require('/tmp/build_7e4316b3/components/UI/SecondaryButton.vue').default,TransactionStatusModal: require('/tmp/build_7e4316b3/components/Modal/TransactionStatusModal.vue').default,SubscribeModel: require('/tmp/build_7e4316b3/components/SubscribeModel.vue').default})
