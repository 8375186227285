var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',_vm._g(_vm._b({class:[
    'font-medium py-2.5 text-white font-inter leading-[17px] px-8 rounded-lg transition focus:outline-none focus:ring',
    'bg-primary-1 hover:bg-opacity-[0.85]',
    'dark:bg-primary-1 dark:hover:bg-primary-700',
    {
      'px-8 sm:px-11 py-3 sm:!py-4 border-2 border-primary-light': _vm.large,
      'w-full !py-3': _vm.block,
      'cursor-not-allowed opacity-50 filter grayscale': _vm.disabled,
    },
  ]},'button',{ type: _vm.type, disabled: _vm.disabled },false),_vm.disabled ? undefined : _vm.$listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }