import { Token } from '@uniswap/sdk-core'
import { FeeAmount, POOL_INIT_CODE_HASH, tickToPrice } from '@uniswap/v3-sdk'
import { BigNumber } from 'ethers'

import { formatBigInt } from '..'
import { web3Store } from '../store'
import { getIconUrlOr404 } from '../tokenIcon'
import { tokenUSDBySymbol } from '../tokenUSD'
import {
  useNonfungiblePositionManagerContract,
  usePoolContract,
  useTokenContract,
} from '../useContract'

import { computePoolAddress } from './utils/computePoolAddress'

import { getAmountsForLiquidity } from '~/services/Pool'
import { ChainId, Dex } from '~/types/web3'

export enum Type {
  Token = 'token',
}

export interface T {
  key: string
  type: Type
  symbol: string
  name: string
  address: string
  decimals: string
  isStableCoin: boolean
  logoURI?: string
  price?: string
  coingeckoTokenId?: string
  isToken: boolean
}

// export interface Position {
//   tokenId: string
//   poolAddr: string
//   fee: string
//   liquidity: string
//   liquidityRaw: string
//   token0: T
//   token1: T
//   token0Amount: string
//   token1Amount: string
//   token0Collect: string
//   token1Collect: string
//   currentTick: string
//   lowerTick: string
//   upperTick: string
//   token1PerToken0PriceCurrent: string
//   token0PerToken1PriceCurrent: string
//   token1PerToken0PriceMin: string
//   token0PerToken1PriceMin: string
//   token1PerToken0PriceMax: string
//   token0PerToken1PriceMax: string
//   key: string
//   isStaked: boolean
//   isDeposited: boolean
//   isDepositedForInstIncentive: boolean
// }
type AddressMap = Partial<Record<ChainId, Partial<Record<Dex, string>>>>

class UniswapNFTService {
  static DEFAULT_MANAGER_ADDRESS = '0xc36442b4a4522e871399cd717abdd847ab11fe88'
  static DEFAULT_FACTORY_ADDRESS = '0x1f98431c8ad98523631ae4a59f267346ea31f984'
  static FACTORY_ADDRESS_MAP: AddressMap = {
    [ChainId.bsc]: {
      [Dex.Uniswap]: '0xdb1d10011ad0ff90774d0c6bb92e5c5c8b4461f7',
      [Dex.Apeswap]: '0x7bc382ddc5928964d7af60e7e2f6299a1ea6f48d',
      [Dex.Pancakeswap]: '0x0bfbcf9fa4f9c56b0f40a671ad40e0805a091865',
    },
  }

  static POOL_INIT_CODE_HASH_MAP: AddressMap = {
    [ChainId.bsc]: {
      [Dex.Apeswap]:
        '0x3d5dcdd0a5890dbad55ff9543ece732377aa023ae7180e3ffc94f63eaf1a4ad1',
      [Dex.Pancakeswap]:
        '0x6ce8eb472fa82df5469c6ab6d485f17c3ad13c8cd7af59b3d4a8026c5ce0f7e2',
    },
  }

  static MANAGER_ADDRESS_MAP: AddressMap = {
    [ChainId.bsc]: {
      [Dex.Uniswap]: '0x7b8a01b39d58278b5de7e48c8449c9f4f5170613',
      [Dex.Apeswap]: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45 ',
      [Dex.Pancakeswap]: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364 ',
      [Dex.Camelot]: '0x00c7f3082833e796A5b3e4Bd59f6642FF44DCD15',
    },
  }

  static get MANAGER_ADDRESS() {
    return (
      (web3Store.status.chainId &&
        UniswapNFTService.MANAGER_ADDRESS_MAP[web3Store.status.chainId]?.[
          web3Store.dex ?? Dex.Uniswap
        ]) ||
      UniswapNFTService.DEFAULT_MANAGER_ADDRESS
    )
  }

  static get POOL_INIT_CODE_HASH() {
    return (
      (web3Store.status.chainId &&
        UniswapNFTService.POOL_INIT_CODE_HASH_MAP[web3Store.status.chainId]?.[
          web3Store.dex
        ]) ||
      POOL_INIT_CODE_HASH
    )
  }

  static get FACTORY_ADDRESS() {
    return (
      (web3Store.status.chainId &&
        UniswapNFTService.FACTORY_ADDRESS_MAP[web3Store.status.chainId]?.[
          web3Store.dex ?? Dex.Uniswap
        ]) ||
      UniswapNFTService.DEFAULT_FACTORY_ADDRESS
    )
  }

  static async getPositions(account = web3Store.status.account) {
    const manager = useNonfungiblePositionManagerContract(this.MANAGER_ADDRESS)!
    const accountBalance = await manager
      .balanceOf(account)
      .then((e: BigNumber) => e.toNumber())
      .catch(() => 0)

    return await Promise.all(
      Array(accountBalance)
        .fill(null)
        .map(async (_, i) =>
          this.getNFtDetailsVerbose(
            (await manager.tokenOfOwnerByIndex(account, i)).toNumber()
          )
        )
    )
  }

  static getNFtDetails(nftId: number) {
    const nonfungiblePositionManagerContract =
      useNonfungiblePositionManagerContract(this.MANAGER_ADDRESS)

    if (!nonfungiblePositionManagerContract)
      throw new Error('NonfungiblePositionManager not initialized')

    return Promise.all([
      nonfungiblePositionManagerContract.positions(nftId),
      nonfungiblePositionManagerContract.ownerOf(nftId),
      nonfungiblePositionManagerContract.name(),
    ])
  }

  static async getPoolAddress(
    args: {
      token0: string
      token1: string
      fee: FeeAmount
      d0?: number
      d1?: number
    },
    chainId = web3Store.status.chainId!
  ): Promise<string> {
    const { token0, token1, fee, d0: _d0, d0: _d1 } = args

    const [d0, d1] = await Promise.all([
      _d0
        ? Promise.resolve(_d0)
        : useTokenContract(token0)
            ?.decimals()
            .catch(() => 18) ?? 18,
      _d1
        ? Promise.resolve(_d1)
        : useTokenContract(token1)
            ?.decimals()
            .catch(() => 18) ?? 18,
    ])

    return computePoolAddress({
      tokenA: new Token(chainId, token0.toLowerCase(), d0),
      tokenB: new Token(chainId, token1.toLowerCase(), d1),
      factoryAddress: UniswapNFTService.FACTORY_ADDRESS,
      initCodeHashManualOverride: UniswapNFTService.POOL_INIT_CODE_HASH,
      fee,
    })
  }

  static async getNFtDetailsVerbose(
    nftId: number,
    chainId = web3Store.status.chainId!,
    withFees = false
  ) {
    const [details, owner, name] = await this.getNFtDetails(nftId)
    const {
      fee,
      feeGrowthInside0LastX128,
      liquidity,
      feeGrowthInside1LastX128,
      nonce,
      operator,
      tickLower,
      tickUpper,
      token0,
      token1,
      tokensOwed0,
      tokensOwed1,
    } = details

    const cToken0 = useTokenContract(token0)!
    const cToken1 = useTokenContract(token1)!

    const [d0, d1, s0, s1] = await Promise.all([
      cToken0.decimals(),
      cToken1.decimals(),
      cToken0.symbol(),
      cToken1.symbol(),
    ])

    const tokenA = new Token(chainId, token0, d0, s0)
    const tokenB = new Token(chainId, token1, d1, s1)

    const poolAddress = computePoolAddress({
      tokenA,
      tokenB,
      factoryAddress: UniswapNFTService.FACTORY_ADDRESS,
      fee,
    })
    const pool = usePoolContract(poolAddress)!

    const [
      currentTick,
      { amount0, amount1 },
      p0,
      p1,
      feeGrowthGlobal0X128,
      feeGrowthGlobal1X128,
      tickL,
      tickU,
    ] = await Promise.all([
      this.currentTick(poolAddress),
      getAmountsForLiquidity(
        { type: 'Chainlink', poolAddress },
        tickLower,
        tickUpper,
        liquidity
      ),
      tokenUSDBySymbol(s0),
      tokenUSDBySymbol(s1),
      ...(withFees
        ? [
            pool.feeGrowthGlobal0X128(),
            pool.feeGrowthGlobal1X128(),
            pool.ticks(tickLower),
            pool.ticks(tickUpper),
          ]
        : []),
    ])

    const status: 'IN_RANGE' | 'OUT_OF_RANGE' =
      tickLower < currentTick && currentTick < tickUpper
        ? 'IN_RANGE'
        : 'OUT_OF_RANGE'
    let feeToken0 = 0
    let feeToken1 = 0

    if (withFees) {
      feeToken0 = this.computeFees(
        true,
        feeGrowthInside0LastX128,
        currentTick,
        liquidity,
        tokenA.decimals,
        {
          tickLower,
          tickUpper,
          feeGrowthGlobal0X128,
          feeGrowthGlobal1X128,
          tickL,
          tickU,
        }
      )

      feeToken1 = this.computeFees(
        false,
        feeGrowthInside1LastX128,
        currentTick,
        liquidity,
        tokenB.decimals,
        {
          tickLower,
          tickUpper,
          feeGrowthGlobal0X128,
          feeGrowthGlobal1X128,
          tickL,
          tickU,
        }
      )
    }

    const token0Amount = +formatBigInt(amount0, tokenA.decimals)
    const token1Amount = +formatBigInt(amount1, tokenB.decimals)

    const aum = token1Amount * p0 + token1Amount * p1

    return {
      tokenId: nftId,
      status,
      name,
      currentTick,
      fee,
      feeToken1,
      feeToken0,
      feeGrowthInside0LastX128,
      liquidity,
      feeGrowthInside1LastX128,
      nonce,
      operator,
      owner,
      tickLower,
      tickUpper,
      tokensOwed0,
      tokensOwed1,
      poolAddress,
      tokenA,
      tokenB,
      aum,
      token0Amount,
      token0AmountUSD: token0Amount * p0,
      token1Amount,
      token1AmountUSD: token1Amount * p1,
      priceAinA: tickToPrice(tokenB, tokenA, tickUpper).toSignificant(6),
      priceBinA: tickToPrice(tokenB, tokenA, tickLower).toSignificant(6),
      priceAinB: tickToPrice(tokenA, tokenB, tickLower).toSignificant(6),
      priceBinB: tickToPrice(tokenA, tokenB, tickUpper).toSignificant(6),
      currentPriceInA: tickToPrice(tokenA, tokenB, currentTick).toSignificant(
        6
      ),
      currentPriceInB: tickToPrice(tokenB, tokenA, currentTick).toSignificant(
        6
      ),
      token0: {
        address: token0,
        decimals: d0,
        symbol: s0,
        logo: s0 && getIconUrlOr404(s0),
      },
      token1: {
        address: token1,
        decimals: d1,
        symbol: s1,
        logo: s0 && getIconUrlOr404(s1),
      },
    }
  }

  static computeFees(
    isZero: boolean,
    feeGrowthInsideLast: BigNumber,
    tick: number,
    liquidity: BigNumber,
    decimals: number,
    {
      tickLower,
      tickUpper,
      feeGrowthGlobal0X128,
      feeGrowthGlobal1X128,
      tickL,
      tickU,
    }: {
      tickLower: number
      tickUpper: number
      feeGrowthGlobal0X128: BigNumber
      feeGrowthGlobal1X128: BigNumber
      tickL: {
        feeGrowthOutside0X128: BigNumber
        feeGrowthOutside1X128: BigNumber
      }
      tickU: {
        feeGrowthOutside0X128: BigNumber
        feeGrowthOutside1X128: BigNumber
      }
    }
  ): number {
    let feeGrowthOutsideLower: BigNumber
    let feeGrowthOutsideUpper: BigNumber
    let feeGrowthGlobal: BigNumber
    let feeGrowthBelow: BigNumber
    let feeGrowthAbove: BigNumber

    if (isZero) {
      feeGrowthGlobal = feeGrowthGlobal0X128
      feeGrowthOutsideLower = tickL.feeGrowthOutside0X128
      feeGrowthOutsideUpper = tickU.feeGrowthOutside0X128
      // (, , feeGrowthOutsideLower, , , , , ) = pool.ticks(lowerTick);
      // (, , feeGrowthOutsideUpper, , , , , ) = pool.ticks(upperTick);
    } else {
      feeGrowthGlobal = feeGrowthGlobal1X128

      feeGrowthOutsideLower = tickL.feeGrowthOutside1X128
      feeGrowthOutsideUpper = tickU.feeGrowthOutside1X128
      // (, , , feeGrowthOutsideLower, , , , ) = pool.ticks(lowerTick);
      // (, , , feeGrowthOutsideUpper, , , , ) = pool.ticks(upperTick);
    }

    if (tick >= tickLower) {
      feeGrowthBelow = feeGrowthOutsideLower
    } else {
      feeGrowthBelow = feeGrowthGlobal.sub(feeGrowthOutsideLower)
    }

    // calculate fee growth above
    if (tick < tickUpper) {
      feeGrowthAbove = feeGrowthOutsideUpper
    } else {
      feeGrowthAbove = feeGrowthGlobal.sub(feeGrowthOutsideUpper)
    }

    const feeGrowthInside = feeGrowthGlobal
      .sub(feeGrowthBelow)
      .sub(feeGrowthAbove)

    return Math.max(
      0,
      +formatBigInt(
        liquidity
          .mul(feeGrowthInside.sub(feeGrowthInsideLast))
          .div(BigNumber.from('0x100000000000000000000000000000000')),
        decimals
      )
    )
  }

  static async currentTick(poolAddress: string): Promise<number> {
    const contract = usePoolContract(poolAddress)

    if (!contract) return Number.NaN

    const { tick } = await contract[
      [Dex.Camelot, Dex.Stellaswap, Dex.Thena].includes(web3Store.dex)
        ? 'globalState'
        : 'slot0'
    ]()

    return tick
  }
}

export default UniswapNFTService
