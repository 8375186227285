import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.API_URL + '/calculations',
  // baseURL: process.client
  //   ? window.location.protocol + '//' + window.location.host + '/calculations'
  //   : '',
})

export function calc_Z_W(
  price: number,
  ranges: number[][]
): Promise<{
  Z: number
  W: number
}> {
  return axios.post('calc_Z_W', { price, ranges }).then(({ data }) => data)
}

export function swap_ranges(
  x: number,
  y: number,
  price: number,
  ranges: number[][]
): Promise<{
  Xi: number[]
  Yi: number[]
}> {
  return axios
    .post('swap_ranges', { x, y, price, ranges })
    .then(({ data }) => data)
}

export function swap_amounts(
  x: number,
  y: number,
  price: number,
  ranges: number[][],
  f: number
): Promise<{
  swapped_x: number
  swapped_y: number
  zeroToOne: boolean | null
}> {
  return axios
    .post('swap_amounts', { x, y, price, ranges, f })
    .then(({ data }) => data)
}

export function swap_amounts_new(
  x: number,
  y: number,
  ranges: number[][],
  f: number,
  L: number,
  price: number,
  decimals0: number,
  decimals1: number,
  tolerance = 1e-3
): Promise<{
  swapped_x: number
  swapped_y: number
  zeroToOne: boolean | null
}> {
  return axios
    .post('swap_amounts_new', {
      x,
      y,
      price,
      ranges,
      f,
      L,
      decimals0,
      decimals1,
      tolerance,
    })
    .then(({ data }) => data)
}

export function do_swap(
  x: number,
  y: number,
  swapped_x: number,
  swapped_y: number,
  price: number,
  f: number
): Promise<{
  x: number
  y: number
}> {
  return axios
    .post('do_swap', { x, y, swapped_x, swapped_y, price, f })
    .then(({ data }) => data)
}

export function precisionCalculation(
  x: number,
  y: number,
  Xi: number[],
  Yi: number[],
  price: number, // New p after swap, if swap happens
  ranges: number[][]
): Promise<{
  Xi: number[]
  Yi: number[]
  leftover: number
}> {
  return axios
    .post('precisionCalculation', { x, y, Xi, Yi, price, ranges })
    .then(({ data }) => data)
}

export function precisionCalculation2(
  x: number,
  y: number,
  Xi: number[],
  Yi: number[],
  price: number, // New p after swap, if swap happens
  ranges: number[][],
  atomic = false,
  t = 1e-8,
  f = 0.003
): Promise<{
  Xi: number[]
  Yi: number[]
  leftover?: number
}> {
  return axios
    .post('precisionCalculation2', {
      x,
      y,
      Xi,
      Yi,
      price,
      ranges,
      t,
      atomic,
      f,
    })
    .then(({ data }) => data)
}
