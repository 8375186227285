import { MerklAPIData } from '@angleprotocol/sdk'
import Axios, { AxiosRequestConfig } from 'axios'
import shortHash from 'shorthash2'

import * as calculations from './calculations'
import * as strategy from './strategy'

import { CamelotData } from '~/types/camelot'
import { Network } from '~/types/web3'

const axios = Axios.create({
  baseURL: process.env.API_URL,
  // baseURL: process.client
  //   ? window.location.protocol + '//' + window.location.host + '/calculations'
  //   : '',
})

const api = {
  calculations,
  strategy,
  merkl: (url: string, config?: AxiosRequestConfig) =>
    axios.get<MerklAPIData>(url, config),
  camelot: (config?: AxiosRequestConfig) =>
    axios
      .get<{ data: CamelotData }>('/camelot/nft-pools', config)
      .then(({ data }) => data.data),
  tokenPrice: (token: string) => axios.get(`/${token}/price`),
  suggestReturnToken: (network: Network) =>
    axios.get(`/suggest-return-token/${network}`),
  reportIssue: (params: {
    referenceId: string
    errorMessage: string
    eventId: string
    userAddress: string
  }) => {
    const sentryUrl = `https://unbound-finance-r6.sentry.io/issues/?project=6315839&query=${encodeURIComponent(
      `id:${params.eventId}`
    )}`

    const pageUrl = window.location.href

    return axios.post(`/report-issue`, {
      ...params,
      sentryUrl,
      pageUrl,
      referenceId: `${params.referenceId}#${shortHash(
        sentryUrl + pageUrl + params.errorMessage
      )}`,
    })
  },
}

export default api
