/* eslint-disable import/order */

import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import { AbstractConnector } from '~/connectors/abstract-connector'
import ELEVATED_ADDRESSES from '~/constants/elevatedAddresses'
import { IProviderInfo } from 'web3modal'
import rangeOrderResolvers from '~/constants/rangeOrderResolvers'
import { ToastApi } from 'vue-toast-notification'
import { isClient, isStaging } from '~/utils'
import { Context } from '@nuxt/types'
import { Web3Error } from '~/types'
import shortid from 'shortid'
import api from '~/api'
import { Dex, Network, ChainId } from '~/types/web3'

export enum ActionType {
  ACTIVATE_CONNECTOR,
  UPDATE,
  UPDATE_FROM_ERROR,
  ERROR,
  ERROR_FROM_ACTIVATION,
  DEACTIVATE_CONNECTOR,
}

export interface ActionArgs {
  type: ActionType
  payload?: any
}

export function getExplorerInfo(
  networkNameGetter: any,
  chainId: any,
  hash?: string,
  path: 'tx' | 'address' | 'hash' = 'tx'
): [url: string, explorer: string] {
  if (!hash) return ['', '']
  const network = networkNameGetter

  let url, explorer
  switch (chainId) {
    case ChainId.polygon:
      explorer = 'polygonscan'
      url = `https://polygonscan.com/${path}/${hash}`
      break
    case ChainId.optimism:
      explorer = 'optimistic'
      url = `https://optimistic.etherscan.io/${path}/${hash}`
      break
    case ChainId.arbitrum:
      explorer = 'arbiscan'
      url = `https://arbiscan.io/${path}/${hash}`
      break
    case ChainId.moonbeam:
      explorer = 'moonscan'
      url = `https://https://moonbeam.moonscan.io/${path}/${hash}`
      break
    case ChainId.bsc:
      explorer = 'bscscan'
      url = `https://bscscan.com/${path}/${hash}`
      break
    default:
      if (network === '') url = `https://etherscan.io/${path}/${hash}`
      else url = `https://${network}.etherscan.io/${path}/${hash}`
      explorer = `etherscan`
      break
  }

  return [url, explorer]
}

export type ChainConfig = {
  chainId: ChainId
  chainName: string // For displaying in network select menu and alert
  defiEdgeClient: string
  hasLimitOrder: boolean
  hasTwap?: boolean
  nativeToken: string
  network: Network
  pokeMeClient: string
  production: boolean
  uniswapClient: string
  uniswapBlockClient: string
  use1InchRouter: boolean
  keepersNetwork?: string
  dex: Dex
}

type Web3Status = {
  account?: string | null
  chainId?: ChainId | null
  chainName?: String | null
  connector?: AbstractConnector
  error?: Error
  nativeToken?: string | null
  networkName?: String | null
  onError?: (error: Error) => void
  provider?: any
  providerInfo?: IProviderInfo | null
  signer?: JsonRpcSigner
  chainWiseDomainName?: { [key: string]: string } | null
}

const allChainConfigs: ChainConfig[] = isStaging
  ? [
      {
        chainId: ChainId.mainnet,
        chainName: 'Ethereum Mainnet',
        defiEdgeClient: 'defiEdge',
        dex: Dex.Uniswap,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'ETH',
        network: 'mainnet',
        pokeMeClient: 'pokeMe',
        production: true,
        uniswapBlockClient: 'uniswapBlock',
        uniswapClient: 'uniswap',
        use1InchRouter: true,
      },
      {
        chainId: ChainId.polygon,
        chainName: 'Polygon',
        defiEdgeClient: 'defiEdgePolygon',
        dex: Dex.Uniswap,
        hasLimitOrder: true,
        hasTwap: true,
        nativeToken: 'MATIC',
        network: 'polygon',
        pokeMeClient: 'pokeMePolygon',
        production: true,
        uniswapBlockClient: 'uniswapBlockPolygon',
        uniswapClient: 'uniswapPolygon',
        use1InchRouter: true,
      },
      {
        chainId: ChainId.arbitrum,
        chainName: 'Arbitrum One',
        defiEdgeClient: 'defiEdgeArbitrum',
        dex: Dex.Uniswap,
        hasLimitOrder: true,
        hasTwap: true,
        keepersNetwork: 'ethereum-mainnet-arbitrum-1',
        nativeToken: 'AETH',
        network: 'arbitrum',
        pokeMeClient: 'pokeMeArbitrum',
        production: true,
        uniswapBlockClient: 'uniswapBlockArbitrum',
        uniswapClient: 'uniswapArbitrum',
        use1InchRouter: true,
      },
      {
        chainId: ChainId.arbitrum,
        chainName: 'Arbitrum One',
        defiEdgeClient: 'defiEdgeArbitrum',
        dex: Dex.Arbidex,
        hasLimitOrder: false,
        hasTwap: true,
        keepersNetwork: 'ethereum-mainnet-arbitrum-1',
        nativeToken: 'AETH',
        network: 'arbitrum',
        pokeMeClient: 'pokeMeArbitrum',
        production: true,
        uniswapBlockClient: 'uniswapBlockArbitrum',
        uniswapClient: 'aridexArbitrum', // todo
        use1InchRouter: true,
      },
      {
        chainId: ChainId.optimism,
        chainName: 'Optimism',
        defiEdgeClient: 'defiEdgeOptimism',
        dex: Dex.Uniswap,
        hasLimitOrder: true,
        hasTwap: true,
        keepersNetwork: 'ethereum-mainnet-optimism-1',
        nativeToken: 'ETH',
        network: 'optimism',
        pokeMeClient: 'pokeMeOptimism',
        production: true,
        uniswapBlockClient: 'uniswapBlockArbitrum',
        uniswapClient: 'uniswapOptimism',
        use1InchRouter: true,
      },
      // {
      //   chainId: ChainId.goerli,
      //   chainName: 'Goerli',
      //   defiEdgeClient: 'defiEdgeGoerli',
      //   dex: Dex.Uniswap,
      //   hasLimitOrder: false,
      //   hasTwap: false,
      //   nativeToken: 'ETH',
      //   network: 'goerli',
      //   pokeMeClient: 'pokeMeGoerli',
      //   production: false,
      //   uniswapBlockClient: '',
      //   uniswapClient: 'uniswapGoerli',
      //   use1InchRouter: true,
      // },
      {
        chainId: ChainId.bsc,
        chainName: 'BNB chain',
        defiEdgeClient: 'defiEdgeBsc',
        dex: Dex.Apeswap,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'BNB',
        network: 'bsc',
        pokeMeClient: 'pokeMeBsc',
        production: true,
        uniswapBlockClient: 'uniswapBlockBsc', // todo
        uniswapClient: 'apeSwapBsc', // todo
        use1InchRouter: true,
      },
      {
        chainId: ChainId.bsc,
        chainName: 'BNB chain',
        defiEdgeClient: 'defiEdgeBsc',
        dex: Dex.Pancakeswap,
        hasLimitOrder: true,
        hasTwap: true,
        nativeToken: 'BNB',
        network: 'bsc',
        pokeMeClient: 'pokeMeBsc',
        production: true,
        uniswapBlockClient: 'uniswapBlockBsc', // todo
        uniswapClient: 'pancakeSwapBsc', // todo
        use1InchRouter: true,
      },
      {
        chainId: ChainId.bsc,
        chainName: 'BNB chain',
        defiEdgeClient: 'defiEdgeBsc',
        dex: Dex.Uniswap,
        hasLimitOrder: true,
        hasTwap: true,
        nativeToken: 'BNB',
        network: 'bsc',
        pokeMeClient: 'pokeMeBsc',
        production: true,
        uniswapBlockClient: 'uniswapBlockBsc',
        uniswapClient: 'uniswapBsc',
        use1InchRouter: true,
      },
      {
        chainId: ChainId.arbitrum,
        chainName: 'Arbitrum One',
        defiEdgeClient: 'defiEdgeArbitrum',
        dex: Dex.Camelot,
        hasLimitOrder: true,
        hasTwap: true,
        keepersNetwork: 'ethereum-mainnet-arbitrum-1',
        nativeToken: 'AETH',
        network: 'arbitrum',
        pokeMeClient: 'pokeMeArbitrum',
        production: true,
        uniswapBlockClient: 'uniswapBlockArbitrum',
        uniswapClient: 'algebraArbitrum', // todo
        use1InchRouter: true,
      },
      {
        chainId: ChainId.moonbeam,
        chainName: 'Moonbeam',
        defiEdgeClient: 'defiEdgeMoonbeam',
        dex: Dex.Stellaswap,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'GLMR',
        network: 'moonbeam',
        pokeMeClient: 'pokeMe', // todo
        production: true,
        uniswapBlockClient: 'uniswapBlock',
        uniswapClient: 'stellaswapMoonbeam',
        use1InchRouter: false,
      },
      {
        chainId: ChainId.arbitrum,
        chainName: 'Arbitrum One',
        defiEdgeClient: 'defiEdgeArbitrum',
        dex: Dex.Sushiswap,
        hasLimitOrder: false,
        hasTwap: true,
        keepersNetwork: 'ethereum-mainnet-arbitrum-1',
        nativeToken: 'AETH',
        network: 'arbitrum',
        pokeMeClient: 'pokeMeArbitrum',
        production: true,
        uniswapBlockClient: 'sushiswapBlockArbitrum',
        uniswapClient: 'sushiswapArbitrum', // todo
        use1InchRouter: true,
      },
      {
        chainId: ChainId.arbitrum,
        chainName: 'Arbitrum One',
        defiEdgeClient: 'defiEdgeArbitrum',
        dex: Dex.Ramses,
        hasLimitOrder: false,
        hasTwap: true,
        keepersNetwork: 'ethereum-mainnet-arbitrum-1',
        nativeToken: 'AETH',
        network: 'arbitrum',
        pokeMeClient: 'pokeMeArbitrum',
        production: true,
        uniswapBlockClient: 'uniswapBlockArbitrum',
        uniswapClient: 'ramsesArbitrum', // todo
        use1InchRouter: true,
      },
      {
        chainId: 42161,
        chainName: 'Arbitrum One',
        defiEdgeClient: 'defiEdgeArbitrum',
        dex: Dex.Pancakeswap,
        hasLimitOrder: true,
        hasTwap: true,
        nativeToken: 'AETH',
        network: 'arbitrum',
        pokeMeClient: 'pokeMeArbitrum',
        production: true,
        uniswapBlockClient: 'uniswapBlockArbitrum',
        uniswapClient: 'pancakeSwapArb',
        use1InchRouter: true,
      },
      {
        chainId: ChainId.polygon,
        chainName: 'Polygon',
        defiEdgeClient: 'defiEdgePolygon',
        dex: Dex.Sushiswap,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'MATIC',
        network: 'polygon',
        pokeMeClient: 'pokeMePolygon',
        production: true,
        uniswapBlockClient: 'sushiswapBlockPolygon', // todo
        uniswapClient: 'sushiswapPolygon',
        use1InchRouter: true,
      },
      {
        chainId: ChainId.polygon,
        chainName: 'Polygon',
        defiEdgeClient: 'defiEdgePolygon',
        dex: Dex.Retro,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'MATIC',
        network: 'polygon',
        pokeMeClient: 'pokeMePolygon',
        production: true,
        uniswapBlockClient: 'retroBlockPolygon', // todo
        uniswapClient: 'retroPolygon',
        use1InchRouter: true,
      },
      {
        chainId: ChainId.bsc,
        chainName: 'BNB chain',
        defiEdgeClient: 'defiEdgeBsc',
        dex: Dex.Thena,
        hasLimitOrder: true,
        hasTwap: true,
        nativeToken: 'BNB',
        network: 'bsc',
        pokeMeClient: 'pokeMeBsc',
        production: true,
        uniswapBlockClient: 'uniswapBlockBsc',
        uniswapClient: 'thenaBsc',
        use1InchRouter: true,
      },
      {
        chainId: ChainId.mantle,
        chainName: 'Mantle',
        defiEdgeClient: 'defiEdgeMantle',
        dex: Dex.Fusionx,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'MNT',
        network: 'mantle',
        pokeMeClient: 'pokeMeBsc',
        production: true,
        uniswapBlockClient: 'fusionXBlockMantle',
        uniswapClient: 'fusionXMantle',
        use1InchRouter: false,
      },
      {
        chainId: ChainId.base,
        chainName: 'Base',
        defiEdgeClient: 'defiEdgeBase',
        dex: Dex.Uniswap,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'ETH',
        network: 'base',
        pokeMeClient: 'pokeMeBase',
        production: true,
        uniswapBlockClient: '', // todo
        uniswapClient: 'uniswapBase',
        use1InchRouter: false,
      },
      {
        chainId: ChainId.base,
        chainName: 'Base',
        defiEdgeClient: 'defiEdgeBase',
        dex: Dex.Pancakeswap,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'ETH',
        network: 'base',
        pokeMeClient: 'pokeMeBase',
        production: true,
        uniswapBlockClient: 'pancakeSwapBlockBase',
        uniswapClient: 'pancakeSwapBase',
        use1InchRouter: false,
      },
      {
        chainId: ChainId.baseTestnet,
        chainName: 'Base Testnet',
        defiEdgeClient: 'defiEdgeBaseTestnet',
        dex: Dex.Horiza,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'ETH',
        network: 'baseTestnet',
        pokeMeClient: 'pokeMeBase',
        production: true,
        uniswapBlockClient: '', // todo
        uniswapClient: 'horizaBaseTestnet',
        use1InchRouter: false,
      },
      {
        chainId: ChainId.avalanche,
        chainName: 'Avalanche',
        defiEdgeClient: 'defiEdgeAvalanche',
        dex: Dex.Pangolin,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'AVAX',
        network: 'avalanche',
        pokeMeClient: 'pokeMeBsc',
        production: true,
        uniswapBlockClient: 'pangolinBlockAvalanche',
        uniswapClient: 'pangolinAvalanche',
        use1InchRouter: true,
      },
      {
        chainId: ChainId.arbitrum,
        chainName: 'Arbitrum One',
        defiEdgeClient: 'defiEdgeArbitrum',
        dex: Dex.Horiza,
        hasLimitOrder: true,
        hasTwap: true,
        nativeToken: 'AETH',
        network: 'arbitrum',
        pokeMeClient: 'pokeMeArbitrum',
        production: true,
        uniswapBlockClient: 'uniswapBlockArbitrum',
        uniswapClient: 'horizaArbitrum',
        use1InchRouter: true,
      },
      {
        chainId: ChainId.base,
        chainName: 'Base',
        defiEdgeClient: 'defiEdgeBase',
        dex: Dex.Baseswap,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'ETH',
        network: 'base',
        pokeMeClient: 'pokeMeBase',
        production: true,
        uniswapBlockClient: 'pancakeSwapBlockBase',
        uniswapClient: 'baseswapBase',
        use1InchRouter: false,
      },
      {
        chainId: ChainId.zksyncEra,
        chainName: 'zksyncEra',
        defiEdgeClient: 'defiEdgeZksyncEra',
        dex: Dex.Pancakeswap,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'ETH',
        network: 'zksyncEra',
        pokeMeClient: 'pokeMeBsc',
        production: true,
        uniswapBlockClient: 'pancakeswapBlockZksyncEra',
        uniswapClient: 'pancakeswapZksyncEra',
        use1InchRouter: false,
      },
    ]
  : // Production chains
    [
      {
        chainId: ChainId.arbitrum,
        chainName: 'Arbitrum One',
        defiEdgeClient: 'defiEdgeArbitrum',
        dex: Dex.Ramses,
        hasLimitOrder: false,
        hasTwap: true,
        keepersNetwork: 'ethereum-mainnet-arbitrum-1',
        nativeToken: 'AETH',
        network: 'arbitrum',
        pokeMeClient: 'pokeMeArbitrum',
        production: true,
        uniswapBlockClient: 'uniswapBlockArbitrum',
        uniswapClient: 'ramsesArbitrum', // todo
        use1InchRouter: true,
      },
      {
        chainId: 1,
        chainName: 'Ethereum Mainnet',
        defiEdgeClient: 'defiEdge',
        dex: Dex.Uniswap,
        hasLimitOrder: true,
        hasTwap: true,
        nativeToken: 'ETH',
        network: 'mainnet',
        pokeMeClient: 'pokeMe',
        production: true,
        uniswapBlockClient: 'uniswapBlock',
        uniswapClient: 'uniswap',
        use1InchRouter: true,
      },
      {
        chainId: 10,
        chainName: 'Optimism',
        defiEdgeClient: 'defiEdgeOptimism',
        dex: Dex.Uniswap,
        hasLimitOrder: true,
        hasTwap: true,
        keepersNetwork: 'ethereum-mainnet-optimism-1',
        nativeToken: 'ETH',
        network: 'optimism',
        pokeMeClient: 'pokeMeOptimism',
        production: true,
        uniswapBlockClient: 'uniswapBlockOptimism',
        uniswapClient: 'uniswapOptimism',
        use1InchRouter: true,
      },
      {
        chainId: 137,
        chainName: 'Polygon',
        defiEdgeClient: 'defiEdgePolygon',
        dex: Dex.Uniswap,
        hasLimitOrder: true,
        hasTwap: true,
        nativeToken: 'MATIC',
        network: 'polygon',
        pokeMeClient: 'pokeMePolygon',
        production: true,
        uniswapBlockClient: 'uniswapBlockPolygon',
        uniswapClient: 'uniswapPolygon',
        use1InchRouter: true,
      },
      {
        chainId: 42161,
        chainName: 'Arbitrum One',
        defiEdgeClient: 'defiEdgeArbitrum',
        dex: Dex.Uniswap,
        hasLimitOrder: true,
        hasTwap: true,
        keepersNetwork: 'ethereum-mainnet-arbitrum-1',
        nativeToken: 'AETH',
        network: 'arbitrum',
        pokeMeClient: 'pokeMeArbitrum',
        production: true,
        uniswapBlockClient: 'uniswapBlockArbitrum',
        uniswapClient: 'uniswapArbitrum',
        use1InchRouter: true,
      },
      {
        chainId: ChainId.arbitrum,
        chainName: 'Arbitrum One',
        defiEdgeClient: 'defiEdgeArbitrum',
        dex: Dex.Arbidex,
        hasLimitOrder: true,
        hasTwap: true,
        keepersNetwork: 'ethereum-mainnet-arbitrum-1',
        nativeToken: 'AETH',
        network: 'arbitrum',
        pokeMeClient: 'pokeMeArbitrum',
        production: true,
        uniswapBlockClient: 'uniswapBlockArbitrum',
        uniswapClient: 'aridexArbitrum', // todo
        use1InchRouter: true,
      },
      {
        chainId: ChainId.bsc,
        chainName: 'BNB chain',
        defiEdgeClient: 'defiEdgeBsc',
        dex: Dex.Uniswap,
        hasLimitOrder: true,
        hasTwap: true,
        nativeToken: 'BNB',
        network: 'bsc',
        pokeMeClient: 'pokeMeBsc',
        production: true,
        uniswapBlockClient: 'uniswapBlockBsc',
        uniswapClient: 'uniswapBsc',
        use1InchRouter: true,
      },
      {
        chainId: ChainId.bsc,
        chainName: 'BNB chain',
        defiEdgeClient: 'defiEdgeBsc',
        dex: Dex.Apeswap,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'BNB',
        network: 'bsc',
        pokeMeClient: 'pokeMeBsc',
        production: true,
        uniswapBlockClient: 'uniswapBlockBsc', // todo
        uniswapClient: 'apeSwapBsc', // todo
        use1InchRouter: true,
      },
      {
        chainId: ChainId.bsc,
        chainName: 'BNB chain',
        defiEdgeClient: 'defiEdgeBsc',
        dex: Dex.Pancakeswap,
        hasLimitOrder: true,
        hasTwap: true,
        nativeToken: 'BNB',
        network: 'bsc',
        pokeMeClient: 'pokeMeBsc',
        production: true,
        uniswapBlockClient: 'uniswapBlockBsc', // todo
        uniswapClient: 'pancakeSwapBsc', // todo
        use1InchRouter: true,
      },
      {
        chainId: ChainId.arbitrum,
        chainName: 'Arbitrum One',
        defiEdgeClient: 'defiEdgeArbitrum',
        dex: Dex.Sushiswap,
        hasLimitOrder: false,
        hasTwap: true,
        keepersNetwork: 'ethereum-mainnet-arbitrum-1',
        nativeToken: 'AETH',
        network: 'arbitrum',
        pokeMeClient: 'pokeMeArbitrum',
        production: true,
        uniswapBlockClient: 'sushiswapBlockArbitrum',
        uniswapClient: 'sushiswapArbitrum', // todo
        use1InchRouter: true,
      },
      {
        chainId: ChainId.polygon,
        chainName: 'Polygon',
        defiEdgeClient: 'defiEdgePolygon',
        dex: Dex.Sushiswap,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'MATIC',
        network: 'polygon',
        pokeMeClient: 'pokeMePolygon',
        production: true,
        uniswapBlockClient: 'sushiswapBlockPolygon', // todo
        uniswapClient: 'sushiswapPolygon',
        use1InchRouter: true,
      },
      {
        chainId: ChainId.bsc,
        chainName: 'BNB chain',
        defiEdgeClient: 'defiEdgeBsc',
        dex: Dex.Thena,
        hasLimitOrder: true,
        hasTwap: true,
        nativeToken: 'BNB',
        network: 'bsc',
        pokeMeClient: 'pokeMeBsc',
        production: true,
        uniswapBlockClient: 'uniswapBlockBsc',
        uniswapClient: 'thenaBsc',
        use1InchRouter: true,
      },
      {
        chainId: ChainId.arbitrum,
        chainName: 'Arbitrum One',
        defiEdgeClient: 'defiEdgeArbitrum',
        dex: Dex.Camelot,
        hasLimitOrder: true,
        hasTwap: true,
        keepersNetwork: 'ethereum-mainnet-arbitrum-1',
        nativeToken: 'AETH',
        network: 'arbitrum',
        pokeMeClient: 'pokeMeArbitrum',
        production: true,
        uniswapBlockClient: 'uniswapBlockArbitrum',
        uniswapClient: 'algebraArbitrum', // todo
        use1InchRouter: true,
      },
      {
        chainId: ChainId.polygon,
        chainName: 'Polygon',
        defiEdgeClient: 'defiEdgePolygon',
        dex: Dex.Retro,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'MATIC',
        network: 'polygon',
        pokeMeClient: 'pokeMePolygon',
        production: true,
        uniswapBlockClient: 'retroBlockPolygon', // todo
        uniswapClient: 'retroPolygon',
        use1InchRouter: true,
      },
      {
        chainId: 42161,
        chainName: 'Arbitrum One',
        defiEdgeClient: 'defiEdgeArbitrum',
        dex: Dex.Pancakeswap,
        hasLimitOrder: true,
        hasTwap: true,
        nativeToken: 'AETH',
        network: 'arbitrum',
        pokeMeClient: 'pokeMeArbitrum',
        production: true,
        uniswapBlockClient: 'uniswapBlockArbitrum',
        uniswapClient: 'pancakeSwapArb',
        use1InchRouter: true,
      },
      {
        chainId: ChainId.base,
        chainName: 'Base',
        defiEdgeClient: 'defiEdgeBase',
        dex: Dex.Uniswap,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'ETH',
        network: 'base',
        pokeMeClient: 'pokeMeBase',
        production: true,
        uniswapBlockClient: '', // todo
        uniswapClient: 'uniswapBase',
        use1InchRouter: false,
      },
      {
        chainId: ChainId.base,
        chainName: 'Base',
        defiEdgeClient: 'defiEdgeBase',
        dex: Dex.Pancakeswap,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'ETH',
        network: 'base',
        pokeMeClient: 'pokeMeBase',
        production: true,
        uniswapBlockClient: 'pancakeSwapBlockBase',
        uniswapClient: 'pancakeSwapBase',
        use1InchRouter: false,
      },
      {
        chainId: ChainId.arbitrum,
        chainName: 'Arbitrum One',
        defiEdgeClient: 'defiEdgeArbitrum',
        dex: Dex.Horiza,
        hasLimitOrder: true,
        hasTwap: true,
        nativeToken: 'AETH',
        network: 'arbitrum',
        pokeMeClient: 'pokeMeArbitrum',
        production: true,
        uniswapBlockClient: 'uniswapBlockArbitrum',
        uniswapClient: 'horizaArbitrum',
        use1InchRouter: true,
      },
      {
        chainId: ChainId.avalanche,
        chainName: 'Avalanche',
        defiEdgeClient: 'defiEdgeAvalanche',
        dex: Dex.Pangolin,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'AVAX',
        network: 'avalanche',
        pokeMeClient: 'pokeMeBsc',
        production: true,
        uniswapBlockClient: 'pangolinBlockAvalanche',
        uniswapClient: 'pangolinAvalanche',
        use1InchRouter: true,
      },
      {
        chainId: ChainId.base,
        chainName: 'Base',
        defiEdgeClient: 'defiEdgeBase',
        dex: Dex.Baseswap,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'ETH',
        network: 'base',
        pokeMeClient: 'pokeMeBase',
        production: true,
        uniswapBlockClient: 'pancakeSwapBlockBase',
        uniswapClient: 'baseswapBase',
        use1InchRouter: false,
      },
      {
        chainId: ChainId.zksyncEra,
        chainName: 'zksyncEra',
        defiEdgeClient: 'defiEdgeZksyncEra',
        dex: Dex.Pancakeswap,
        hasLimitOrder: false,
        hasTwap: true,
        nativeToken: 'ETH',
        network: 'zksyncEra',
        pokeMeClient: 'pokeMeBsc',
        production: true,
        uniswapBlockClient: 'pancakeswapBlockZksyncEra',
        uniswapClient: 'pancakeswapZksyncEra',
        use1InchRouter: false,
      },
    ]

@Module({
  name: 'web3',
  stateFactory: true,
  namespaced: true,
})
class Web3Store extends VuexModule {
  updateBusterRef: number = 0
  tried: boolean = false
  disableConnectBtn: boolean = false
  blockChangeCount: number = 0
  pendingTransactions: string[] = []
  dex: Dex = Dex.Uniswap
  errorModel = {
    open: false,
    error: null as any,
    errorMessage: '',
    referenceId: '',
  }

  allChainConfigs = allChainConfigs
  chainConfigs = [...allChainConfigs].filter((e) => e.dex === Dex.Uniswap)

  status: Web3Status = {
    chainId: this.chainConfigs[0]?.chainId ?? 1,
  }

  get currentChain() {
    return this.chainConfigs.find(
      (item) => item.chainId === this.status.chainId
    )
  }

  get network() {
    return this.currentChain?.network
  }

  get defiEdgeClient() {
    return this.currentChain?.defiEdgeClient
  }

  get nativeToken() {
    return this.currentChain?.nativeToken
  }

  get chainName() {
    return this.currentChain?.chainName
  }

  get uniswapClient() {
    return this.currentChain?.uniswapClient
  }

  get use1InchRouter() {
    return this.currentChain?.use1InchRouter
  }

  get rangeOrderResolverAddress() {
    if (!this.status.chainId) return () => null
    return (strategyAddress: string) =>
      // @ts-ignore
      rangeOrderResolvers[this.status.chainId][strategyAddress.toLowerCase()] ??
      null
  }

  /**
   * Checks if core functionality is available to the user
   *
   * If production is true, then true
   * If production is false,
   *     - if elevated address then true
   *     - else false
   * @returns boolean stating if access is permitted or not
   */
  get accessPermitted() {
    const isProduction = this.currentChain?.production
    if (isProduction === true) return () => Promise.resolve(true)

    return async (web3Provider: Web3Provider) => {
      if (!web3Provider) return false
      const userAddress = (
        await web3Provider.getSigner().getAddress()
      ).toLowerCase()
      if (ELEVATED_ADDRESSES.includes(userAddress)) return true
      return false
    }
  }

  @Mutation
  incrementBlockChangeCount() {
    this.blockChangeCount += 1
  }

  @Mutation
  resetBlockChangeCount() {
    this.blockChangeCount = 0
  }

  get networkNameGetter() {
    if (this.status.networkName === 'homestead') return ''
    else return this.status.networkName
  }

  @Mutation
  setDex(dex?: Dex) {
    this.dex = dex && Dex[dex] ? dex : Dex.Uniswap

    if (isClient && window.$nuxt)
      window.$nuxt.$cookies.set('defiedge.useDex', this.dex)

    const chainConfigs = [...allChainConfigs].filter((e) => e.dex === this.dex)

    if (
      isClient &&
      (!this.currentChain ||
        chainConfigs.findIndex(
          (e) => e.chainId === this.currentChain!.chainId
        ) === -1)
    ) {
      const [firstChain] = chainConfigs

      if (firstChain && !this.status.account)
        this.status = {
          ...this.status,
          chainId: firstChain.chainId,
          networkName: firstChain.chainName,
        }
    }

    this.chainConfigs = chainConfigs
  }

  @Mutation
  _addTransaction(hash: string) {
    return this.pendingTransactions.push(hash)
  }

  @Mutation
  removeTransaction(hash: string) {
    const i = this.pendingTransactions.indexOf(hash)
    if (i !== -1) {
      this.pendingTransactions.splice(i, 1)
    }
  }

  @Action
  async addTransaction({
    result,
    $toast,
    $sentry,
    source,
    errorModel = false,
    confirmation = 2,
  }: {
    result: { hash: string; wait: (confirmation: number) => Promise<any> }
    $toast?: ToastApi
    source?: string
    errorModel?: boolean
    $sentry?: Context['$sentry']
    confirmation?: number
  }) {
    if (!result) return

    this.context.commit('_addTransaction', result.hash)

    return await result
      .wait?.(confirmation)
      .then(async (e) => {
        if (!$toast) return e

        const receipt = await window.$nuxt.web3Provider?.getTransactionReceipt(
          result.hash
        )

        if (receipt) {
          const status = !!+(receipt.status ?? '').toString().replace('0x', '')

          if (status)
            this.context.dispatch('toastOnTransactionSuccess', {
              hash: result.hash,
              $toast,
            })
          else throw new Error('Transaction Failed')
        } else {
          console.debug('receipt not found for', result.hash)
        }

        return e
      })
      .catch((e) => {
        if ($toast)
          this.context.dispatch('toastOnTransactionError', {
            hash: result.hash,
            $sentry,
            $toast,
            source,
            errorModel,
          })
        else throw e
      })
      .finally(() => this.context.commit('removeTransaction', result.hash))
  }

  @Action
  toastOnTransactionSuccess({ hash }: { hash: string }) {
    const [url, explorer] = getExplorerInfo(
      this.networkNameGetter,
      this.status.chainId,
      hash
    )
    const { $toast } = window.$nuxt

    $toast.open({
      message: `
        <div class='text-lg'>Transaction confirmed</div>
        <div class='max-w-sm text-xs tracking-wide text-gray-100'>${hash}</div>
        <div>
          <a class='mt-2 text-sm text-primary-1 hover:underline' target='_blank' href='${url}'>
            View on ${explorer} <img src='/assets/external_link.svg' class='mb-1 inline-block' height=13 width=13 />
          </a>
          <span class='mt-2 text-sm ml-1 text-primary-1 hover:underline'>Dismiss</span>
        </div>
        <div>
          <a class='mt-2 text-sm text-primary-1 hover:underline' target='_blank' href='https://defiedge.olvy.co/feedback'>
            Give Feedback 💌
          </a>
        </div>
      `.trim(),
      type: 'default',
      duration: 20000,
    })
  }

  @Action
  toastOnTransactionError({
    hash,
    $toast,
    error,
    source,
    $sentry,
    errorModel = false,
  }: {
    hash?: string
    error?: Partial<Web3Error & { data?: { message: string } }>
    source?: string
    $sentry?: Context['$sentry']
    errorModel?: boolean
    $toast?: ToastApi
  }) {
    let errorMessage = error && getErrorMessage(error)
    const referenceId = shortid.generate()
    let eventId = undefined as string | undefined

    if ($sentry) {
      eventId = $sentry.captureException(error, (scope) => {
        scope.clear()
        scope.setTransactionName(source)
        if (hash) scope.setExtra('hash', hash)
        if (errorMessage) {
          scope.setExtra('errorMessage', errorMessage)
          scope.setTag('errorMessage', errorMessage)
        }

        scope.setTag('referenceId', referenceId)

        return scope
      })
    }

    if (errorMessage?.includes('insufficient funds ')) {
      errorMessage = 'Insufficient funds'
    }

    const [url, explorer] = getExplorerInfo(
      this.networkNameGetter,
      this.status.chainId,
      hash
    )

    const filterMessages =
      !!errorMessage?.includes('user rejected transaction') ||
      !!errorMessage?.includes('Not Enough Balance') ||
      !!errorMessage?.includes('User denied message signature')

    if (errorMessage && !filterMessages && referenceId && eventId) {
      api.reportIssue({
        referenceId,
        errorMessage,
        eventId,
        userAddress: this.status.account!,
      })

      this.openErrorModel({
        errorModel,
        referenceId,
        message: errorMessage,
        error,
      })
    } else if ($toast)
      $toast.open({
        message: (hash
          ? `
          <div class='text-lg'>Transaction failed</div>
          <div class='max-w-sm text-xs tracking-wide text-gray-100'>${hash}</div>
          <div>
            <a class='mt-2 text-sm text-white-1 hover:underline' target='_blank' href='${url}'>
              View on ${explorer} <img src='/assets/external_link.svg' class='mb-1 inline-block' height=13 width=13 />
            </a>
            <span class='mt-2 text-sm ml-1 text-white-1 hover:underline'>Dismiss</span></div>
          <div>
            <a class='mt-2 text-sm text-primary-1 hover:underline' target='_blank' href='https://defiedge.olvy.co/feedback'>
              Give Feedback 💌
            </a>
          </div>
        `
          : `
          <div class='text-lg'>${errorMessage}</div>
          <div class='max-w-sm text-xs tracking-wide text-gray-100'>
            If you think this is an issue, connect with us on discord.
          </div>
          <div>
            <a class='mt-2 text-sm text-primary-1 hover:underline' target='_blank' href='https://defiedge.olvy.co/feedback'>
              Give Feedback 💌
            </a>
          </div>
        `
        ).trim(),
        type: 'default',
        duration: 10000,
      })
  }

  @Mutation
  private openErrorModel(payload: {
    error: any
    message: string
    errorModel?: boolean
    referenceId: string
  }) {
    this.errorModel = {
      open: payload.errorModel ?? true,
      errorMessage: payload.message,
      error: payload.error,
      referenceId: payload.referenceId,
    }
  }

  @Mutation
  closeErrorModel() {
    this.errorModel = {
      open: false,
      errorMessage: '',
      error: '',
      referenceId: '',
    }
  }

  @Mutation
  setStatus(state: Partial<Web3Status>) {
    this.status = {
      ...this.status,
      ...state,
    }
  }

  @Mutation
  setDisableConnectBtn(payload: boolean) {
    this.disableConnectBtn = payload
  }
  //

  @Mutation
  setTried(payload: boolean) {
    this.tried = payload
  }

  @Mutation
  updateState({ type, payload }: ActionArgs) {
    const { connector, error, provider, chainId, account, onError } = payload

    switch (type) {
      case ActionType.ACTIVATE_CONNECTOR: {
        this.status = {
          connector,
          provider,
          chainId,
          account,
          onError,
          nativeToken: this.nativeToken,
          chainName: this.chainName,
        }
        break
      }
      case ActionType.UPDATE: {
        this.status = {
          ...this.status,
          ...(provider === undefined ? {} : { provider }),
          ...(chainId === undefined ? {} : { chainId }),
          ...(account === undefined ? {} : { account }),
          nativeToken: this.nativeToken,
          chainName: this.chainName,
        }
        break
      }
      case ActionType.UPDATE_FROM_ERROR: {
        this.status = {
          ...this.status,
          ...(provider === undefined ? {} : { provider }),
          ...(chainId === undefined ? {} : { chainId }),
          ...(account === undefined ? {} : { account }),
          error: undefined,
          nativeToken: this.nativeToken,
          chainName: this.chainName,
        }
        break
      }
      case ActionType.ERROR: {
        const { connector, onError } = this.status

        this.status = {
          error,
          connector,
          onError,
          nativeToken: this.nativeToken,
          chainName: this.chainName,
        }
        break
      }
      case ActionType.ERROR_FROM_ACTIVATION: {
        this.status = {
          connector,
          error,
          nativeToken: this.nativeToken,
          chainName: this.chainName,
        }
        break
      }
      case ActionType.DEACTIVATE_CONNECTOR: {
        this.status = {}
        break
      }
    }
  }
}

export const strict = false // In Reference to this error: https://stackoverflow.com/questions/51686555/vuex-inside-nuxt-app-throws-do-not-mutate-vuex-store-state-outside-mutation-han
export default Web3Store

export function getErrorMessage(
  error: Partial<Web3Error & { data?: { message: string } | undefined }>
) {
  return (
    (error &&
      (error.error?.data?.message ||
        error.data?.message ||
        error.reason ||
        error.message)) ||
    ''
  ).split('(data=')[0]
}
