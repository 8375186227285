import { providers } from '@0xsequence/multicall'
import { MulticallProvider } from '@0xsequence/multicall/dist/declarations/src/providers'
import { getAddress } from '@ethersproject/address'
import { BigNumber } from '@ethersproject/bignumber'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { parseEther } from '@ethersproject/units'
import { BigNumber as BN } from 'bignumber.js'
import { ethers, Signer } from 'ethers'
import type { MutationTree } from 'vuex'
import { getChainId as _getChainId } from 'web3modal'

import { web3Store } from './store'
import UniswapNFTService from './uniswap/uniswapPool.service'

import { getMigratorAddress } from '~/constants/config.helper'
import { ChainId } from '~/types/web3'

export const isClient = process.client
// eslint-disable-next-line import/named
export const isStaging =
  process.env.USE_STAGING_ADDRESSES?.toString() === 'true'
export const secondsInYear = 365 * 24 * 60 * 60

export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function getSigner(
  library: Web3Provider,
  account: string
): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

export function getProviderOrSigner(
  library: Web3Provider,
  account?: string
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

let provider: MulticallProvider | null = null

const multicallProviderMap: Partial<Record<ChainId, string>> = {
  [ChainId.optimism]: '0x1E98A0973AAe4C5E27C0D6aaEb10170e70d9381f',
}

export function getContract(
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw new Error(`Invalid 'address' parameter '${address}'.`)
  }

  const libraryOrProvider = account
    ? getProviderOrSigner(library, account)
    : library

  if (!libraryOrProvider) throw new Error(`No Library`)

  if (!(libraryOrProvider as any).created) {
    if (account)
      provider = new providers.MulticallProvider(library, {
        batchSize: 500,
        timeWindow: 50,
        contract: multicallProviderMap[web3Store.currentChain?.chainId!],
      })
    ;(libraryOrProvider as any).created = true
  }

  return new Contract(address, ABI, account ? libraryOrProvider : provider!)
}

export function reverseInputs(a: any, b: any) {
  return (b = [a, (a = b)][0])
}

export function countDecimals(value: string) {
  if (Math.floor(Number(value)) === Number(value)) return 0
  return value.toString().split('.')[1]?.length || 0
}

export function parseBigInt(
  value: number | string,
  decimals: number
): BigNumber {
  // if (countDecimals(value) > 18) {
  // }
  value = Number(value).toFixed(18)

  const bi_18 = parseEther(value.toString())
  const divisor = BigNumber.from(10).pow(18 - decimals)
  return bi_18.div(BigNumber.from(divisor))
}

export function formatBigInt(
  value: number | string | BigNumber,
  decimals: number
): string {
  const big_val = new BN(value.toString())
  const divisor = new BN(10).pow(decimals)

  return big_val.div(divisor).toString()
}

export function makeMutations<S>(...arr: string[]) {
  return arr.reduce((obj, key) => {
    obj[key] = (state, value) => assignValue(state, key, value)

    return obj
  }, {} as MutationTree<S>)
}

function assignValue(src: any, key: string, value: any) {
  const paths = key.split('.')

  if (!src) return

  if (paths.length === 1) src[paths[0]] = value
  else assignValue(src[paths[0]], paths.slice(1).join('.'), value)
}

export function expandTo18Decimals(value: BigNumber, decimals?: number) {
  // console.log({
  //   expandedVal: value.mul(
  //     BigNumber.from(10).pow(
  //       BigNumber.from(18).sub(BigNumber.from(decimals ?? 0))
  //     )
  //   ),
  // })

  return value.mul(
    BigNumber.from(10).pow(
      BigNumber.from(18).sub(BigNumber.from(decimals ?? 0))
    )
  )
}

export const toFixed = (
  n: number | string,
  decimals: number = 3,
  decimalsForLessThanOne = 8
) => {
  const number = Number(n)

  if (Math.abs(+number.toFixed(decimals)) === 0)
    return +number.toFixed(decimalsForLessThanOne)

  return +number.toFixed(decimals)
}

export const toFixedFloor = (v: number, d: number) =>
  +Number(Math.floor(v * 10 ** d) / 10 ** d).toFixed(d)

export const fixToBalance = (
  n: number | string,
  decimals: number = 3,
  decimalsForLessThanOne = 8
) => {
  const number = Number(n)
  const num = toFixedFloor(number, decimals)

  if (Math.abs(num) === 0) return toFixedFloor(number, decimalsForLessThanOne)

  return num
}

// Get nonce from Uniswap for the permits
export const getNonce = async (
  tokenAddress: string,
  signer: Signer
  // useTwap: boolean
) => {
  // return 0
  const userAddress = await signer.getAddress()
  const abi = ['function nonces(address) view returns (uint256)']
  // const abi = useTwap ? StrategyTwapABI : StrategyABI
  const contract = new ethers.Contract(
    tokenAddress,
    abi || [
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        name: 'nonces',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    signer.provider
  )

  try {
    const nonce = await contract.nonces(userAddress)

    return +nonce.toString()
  } catch (error: any) {
    throw new Error('Something went wrong getting nonce.' + error.message)
  }
}

// Generating an EIP712 Signature
// https://github.com/ethereum/EIPs/blob/8a34d644aacf0f9f8f00815307fd7dd5da07655f/EIPS/eip-2612.md
export const getEIP712Signature = (
  tokenAddress: string,
  spender: string,
  userAddress: string,
  amount: string,
  nonce: number,
  deadline: number,
  domainName: string,
  chainId: number
): string => {
  const Permit = [
    {
      name: 'owner',
      type: 'address',
    },
    {
      name: 'spender',
      type: 'address',
    },
    {
      name: 'value',
      type: 'uint256',
    },
    {
      name: 'nonce',
      type: 'uint256',
    },
    {
      name: 'deadline',
      type: 'uint256',
    },
  ]
  const EIP712Domain = [
    {
      name: 'name',
      type: 'string',
    },
    {
      name: 'version',
      type: 'string',
    },
    {
      name: 'chainId',
      type: 'uint256',
    },
    {
      name: 'verifyingContract',
      type: 'address',
    },
  ]
  const message = {
    owner: userAddress,
    spender,
    value: amount,
    nonce,
    deadline,
  }
  const domain = {
    name: domainName,
    version: '1',
    chainId: +chainId,
    verifyingContract: tokenAddress,
  }
  const data = JSON.stringify({
    types: {
      Permit,
      EIP712Domain,
    },
    primaryType: 'Permit',
    domain,
    message,
  })

  // console.log('Signature data', {
  //   types: {
  //     Permit,
  //     EIP712Domain,
  //   },
  //   primaryType: 'Permit',
  //   domain,
  //   message,
  // })

  return data
}

export function getPermitNFTSignature(
  tokenId: number,
  domainName: string,
  nonce: number,
  deadline: number,
  twap: boolean,
  chainId: ChainId = web3Store.status.chainId!,
  userAddress: string = web3Store.status.account!,
  spender: string = getMigratorAddress(twap)!
) {
  const Permit = [
    {
      name: 'spender',
      type: 'address',
    },
    {
      name: 'tokenId',
      type: 'uint256',
    },
    {
      name: 'nonce',
      type: 'uint256',
    },
    {
      name: 'deadline',
      type: 'uint256',
    },
  ]
  const EIP712Domain = [
    {
      name: 'name',
      type: 'string',
    },
    {
      name: 'version',
      type: 'string',
    },
    {
      name: 'chainId',
      type: 'uint256',
    },
    {
      name: 'verifyingContract',
      type: 'address',
    },
  ]
  const message = {
    owner: userAddress,
    spender,
    tokenId,
    nonce,
    deadline,
  }
  const domain = {
    name: domainName,
    version: '1',
    chainId: +chainId,
    verifyingContract: UniswapNFTService.MANAGER_ADDRESS,
  }
  const data = JSON.stringify({
    types: { Permit, EIP712Domain },
    primaryType: 'Permit',
    domain,
    message,
  })

  return data
}

/**
 * Run concurrent promises with a maximum concurrency level
 * @param concurrency The number of concurrently running promises
 * @param funcs An array of functions that return promises
 * @returns a promise that resolves to an array of the resolved values from the promises returned by funcs
 */
export function concurrent<V>(
  concurrency: number,
  funcs: (() => Promise<V>)[]
): Promise<V[]> {
  return new Promise((resolve, reject) => {
    let index = -1
    const p: Promise<V>[] = []
    for (let i = 0; i < Math.max(1, Math.min(concurrency, funcs.length)); i++)
      runPromise()
    function runPromise() {
      if (++index < funcs.length)
        (p[p.length] = funcs[index]()).then(runPromise).catch(reject)
      else if (index === funcs.length)
        Promise.all(p).then(resolve).catch(reject)
    }
  })
}

export function getChainId(network: string): number {
  if (!network) return 0
  return +(
    Object.entries(ChainId).find((e) => e[0] === (network as any))?.[1] ||
    _getChainId(network.replace('polygon', 'matic').replace('bsc', 'binance'))
  )
}

export const filterNonNull = <T>(item: T | null | undefined): item is T =>
  item !== null && item !== undefined
