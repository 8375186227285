import { MaxUint256 } from '@ethersproject/constants'
import { Token } from '@uniswap/sdk-core'

import { web3Store } from '~/store'
import { formatBigInt, parseBigInt } from '~/utils'
import { calculateGasMargin } from '~/utils/calculateGasMargin'
import { useTokenContract } from '~/utils/useContract'

class ERC20 {
  public async tokenAllowance(
    token?: string,
    owner?: string,
    spender?: string,
    decimals: number = 18
  ) {
    const contract = useTokenContract(token)
    const allowance = contract
      ? await contract.allowance(owner, spender)
      : undefined

    if (token && allowance) return +formatBigInt(allowance, decimals)

    return undefined
  }

  public async isSpendingAllowed(
    token?: Pick<Token, 'address'> & Pick<Partial<Token>, 'decimals'>,
    spender?: string,
    amount?: number | string | null
  ): Promise<boolean> {
    const address = web3Store.status.account
    if (!token?.address || !address) return false

    const currentAllowance =
      (await this.tokenAllowance(
        token.address,
        address,
        spender,
        token?.decimals
      )) ?? 0

    return currentAllowance !== 0 && currentAllowance >= +(amount ?? 0)
  }

  public async approveToken(
    token?: Pick<Token, 'address'> & Pick<Partial<Token>, 'decimals'>,
    spender?: string,
    amount?: string | number
  ) {
    const tokenContract = useTokenContract(token?.address)

    if (!token) throw new Error('no token')
    if (!tokenContract) throw new Error('tokenContract is null')
    if (!spender) throw new Error('no spender')

    const amountBN = amount
      ? parseBigInt(amount, token.decimals || 18)
      : MaxUint256

    const gasLimit = calculateGasMargin(
      await tokenContract.estimateGas.approve(spender, amountBN)
    )

    return await tokenContract.approve(spender, amountBN, { gasLimit })
  }

  public async tokenBalanceWithRaw(token: string, decimals?: number) {
    const account = web3Store.status.account?.toLowerCase() ?? ''

    const tokenContract = useTokenContract(token.toLowerCase())

    if (!tokenContract || !account)
      throw new Error('Cannot initialized tokenContract')

    const balRaw = await tokenContract.balanceOf(account)

    return { balRaw, bal: +formatBigInt(balRaw, decimals ?? 18) }
  }

  cache = {} as any

  public async tokenBalance(
    token: string,
    decimals?: number,
    cache = false
  ): Promise<number> {
    const account = web3Store.status.account?.toLowerCase() ?? ''
    if (cache && this.cache[account]?.[token]) return this.cache[account][token]

    const bal = +(await this.tokenBalanceWithRaw(token, decimals)).bal
    if (cache) {
      if (!this.cache[account]) this.cache[account] = {}
      if (this.cache[account][token]) this.cache[account][token] = bal
    }
    return bal
  }
}

export default ERC20
