import { web3Store } from './store'

import chainConfigMetamask from '~/constants/chainConfigs'

export async function switchNetwork(chainId: number): Promise<void> {
  const chainConfigs = web3Store.chainConfigs

  const chainIdHex = '0x' + chainId.toString(16)

  if (!chainConfigs.find((chain) => chain.chainId === chainId)) {
    throw new Error('Chain ID not supported')
  }

  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainIdHex }],
    })
  } catch (switchError: any) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: chainIdHex,
              // @ts-ignore
              ...chainConfigMetamask[chainId],
            },
          ],
        })
      } catch (addError) {
        // handle "add" error
        throw new Error(
          "Couldn't add network, it's possible that user has rejected the change"
        )
      }
    } else {
      throw new Error("Couldn't switch networks. Error: " + switchError.message)
    }
    // handle other "switch" errors
  }
}
