import { render, staticRenderFns } from "./AppHeader.vue?vue&type=template&id=68aa4584&scoped=true&"
import script from "./AppHeader.vue?vue&type=script&lang=ts&"
export * from "./AppHeader.vue?vue&type=script&lang=ts&"
import style0 from "./AppHeader.vue?vue&type=style&index=0&id=68aa4584&prod&scoped=true&lang=scss&"
import style1 from "./AppHeader.vue?vue&type=style&index=1&id=68aa4584&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68aa4584",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HamburgerIcon: require('/tmp/build_7e4316b3/components/Icons/HamburgerIcon.vue').default,Logo: require('/tmp/build_7e4316b3/components/Icons/Logo.vue').default,DexSelectMenu: require('/tmp/build_7e4316b3/components/DexSelectMenu.vue').default,NetworkSelectMenu: require('/tmp/build_7e4316b3/components/NetworkSelectMenu.vue').default,CreateStrategyButton: require('/tmp/build_7e4316b3/components/CreateStrategyButton..vue').default,Wallet: require('/tmp/build_7e4316b3/components/Wallet.vue').default,Clipboard: require('/tmp/build_7e4316b3/components/Icons/Clipboard.vue').default,OpenInNewIcon: require('/tmp/build_7e4316b3/components/Icons/OpenInNewIcon.vue').default})
