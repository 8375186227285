/* eslint-disable import/order */

import { Price, Token } from '@uniswap/sdk-core'
import { useContract, usePoolContract } from '~/utils/useContract'

import { BigNumber } from 'ethers'
import JSBI from 'jsbi'
import LiquidityHelper from '~/constants/abis/LiquidityHelper.json'
import PositionKeyHelper from '~/constants/abis/PositionKeyHelper.json'
import { Strategy } from '~/types'
import { getPositionKey } from '~/utils/shared/positionKey'
import {
  getLiquidityHelperAddress,
  getPositionKeyHelperAddress,
} from '~/constants/config.helper'
import { web3Store } from '~/store'
import { Dex } from '~/types/web3'

// import { tickToPrice } from '@uniswap/v3-sdk'

export const Q96 = JSBI.exponentiate(JSBI.BigInt(2), JSBI.BigInt(96))
export const Q192 = JSBI.exponentiate(Q96, JSBI.BigInt(2))

export async function currentPrice(
  address: string,
  baseToken?: Token,
  quoteToken?: Token
): Promise<{
  cp: number
  tick: any
  sqrtPriceX96: any
  liquidity: number
  error?: undefined
}> {
  const contract = usePoolContract(address)
  if (!contract) throw new Error("Can't initialize pool contract")

  const liquidity = await contract.liquidity()

  const { tick, sqrtPriceX96, price } = await contract[
    [Dex.Camelot, Dex.Stellaswap, Dex.Thena].includes(web3Store.dex)
      ? 'globalState'
      : 'slot0'
  ]()

  const sp = sqrtPriceX96 || price
  let cp = 0

  if (baseToken && quoteToken) {
    // cp = Number(tickToPrice(baseToken, quoteToken, tick).toSignificant(6))
    const sqrtRatioX96 = JSBI.BigInt(sp)
    cp = Number(
      new Price(
        baseToken,
        quoteToken,
        Q192,
        JSBI.multiply(sqrtRatioX96, sqrtRatioX96)
      ).toSignificant(6)
    )
  }

  return { cp, tick, sqrtPriceX96: sp, liquidity: Number(liquidity.toString()) }
}

export async function tickSpacing(address: string): Promise<number> {
  const contract = usePoolContract(address)

  if (!contract) throw new Error('Pool contract cannot be initialized')

  const _tickSpacing = await contract.tickSpacing()

  return _tickSpacing
}

export async function getPosition(
  strategy: Pick<Strategy, 'address' | 'type' | 'poolAddress'>,
  tickLower: number,
  tickUpper: number
) {
  const pool = usePoolContract(strategy.poolAddress)
  if (!pool) throw new Error('Pool contract cannot be initialized')

  let address = getPositionKeyHelperAddress(strategy.type === 'Twap')

  const positionKey =
    (address &&
      (await useContract(address, PositionKeyHelper)
        ?.getKeyForPosition(strategy.address, tickLower, tickUpper)
        // eslint-disable-next-line no-sequences
        .catch((e: any) => (console.log(e), (address = 'catch'), '')))) ||
    getPositionKey(strategy.address, tickLower, tickUpper)

  const position = await pool.positions(positionKey)
  return position
}

export async function getPoolPositionKey(
  strategy: Pick<Strategy, 'address' | 'type' | 'poolAddress'>,
  tickLower: number,
  tickUpper: number
) {
  const pool = usePoolContract(strategy.poolAddress)
  if (!pool) throw new Error('Pool contract cannot be initialized')

  let address = getPositionKeyHelperAddress(strategy.type === 'Twap')

  const positionKey =
    (address &&
      (await useContract(address, PositionKeyHelper)
        ?.getKeyForPosition(strategy.address, tickLower, tickUpper)
        // eslint-disable-next-line no-sequences
        .catch((e: any) => (console.log(e), (address = 'catch'), '')))) ||
    getPositionKey(strategy.address, tickLower, tickUpper)

  return positionKey
}

export async function getAmountsForLiquidity(
  strategy: Pick<Strategy, 'poolAddress' | 'type'>,
  _tickLower: number,
  _tickUpper: number,
  _liquidity: BigNumber
) {
  const liquidityHelperAddress = getLiquidityHelperAddress(
    strategy.type === 'Twap'
  )!
  const contract = useContract(liquidityHelperAddress, LiquidityHelper)

  if (!contract)
    throw new Error('Liquidity helper contract cannot be initialized')

  console.log({
    function: 'getAmountsForLiquidity',
    params: [strategy.poolAddress, _tickLower, _tickUpper, _liquidity],
  })

  const liquidity = await contract.getAmountsForLiquidity(
    strategy.poolAddress,
    _tickLower,
    _tickUpper,
    _liquidity
  )

  return liquidity
}
