import { CHAIN_ICONS } from './chainConfigs'

import { web3Store } from '~/store'
import { ChainId, Dex, Network } from '~/types/web3'
import { getIconUrlOr404 } from '~/utils/tokenIcon'

export const liquidityMiningMap: Record<
  ChainId,
  Record<
    string,
    { address: string; pid: number; singleSideEnabled?: boolean; dex: Dex }
  >
> = {
  // 'strategyAddress/stakeToken': {
  //   address: 'unboundStaking' | 'minichef',
  //   pid: 0,
  // }
  [ChainId.bsc]: {
    '0xc9b737c034bab8a4eacf0b7f33234170f4533d92': {
      address: '0x0bceab2572f9ee57ca84031e2c19dbd2b98a7e9d',
      pid: 0,
      dex: Dex.Pancakeswap,
    },
    '0xf8f4802f7a254d3266485e7c3fd7ae85745c1ec9': {
      address: '0xA4Ba7E83eEF7A93d6Aed64A2d3664d9eD160408d',
      pid: 0,
      dex: Dex.Thena,
    },
    '0x7383bf33370c8b8b5f30bace05d9405a87c3dab9': {
      address: '0x4802E76Ca5912BFE8bF42E29c99789e1D0170751',
      pid: 0,
      dex: Dex.Thena,
    },
    '0x0860b804263f4513f8d124721a119d1e2bf9f8e5': {
      address: '0x43d48aD250B9Cb2748A8Edd523C2087723A34C86',
      pid: 0,
      dex: Dex.Thena,
    },
  },
  [ChainId.goerli]: {},
  [ChainId.base]: {},
  [ChainId.mainnet]: {},
  [ChainId.polygon]: {
    // '0x7b65c8dc79f767266d67186d39fc23fbdc35e5a2': {
    //   address: '0xc5250a0f4c0b63e7daf40c4c36b83ed8781fa126',
    //   pid: 0,
    // },
    // "0x65238772ef02eb8c1de02f9c8f8142edb6aefbae": {
    //   address: "0xc5250a0f4c0b63e7daf40c4c36b83ed8781fa126",
    //   pid: 0,
    // },
    // "0x01260100c615e9c1407066df710b705b1ca006c3": {
    //   address: "0x308D069f98E05493D8c24f47cb2fb7121F826Ec2",
    //   pid: 0,
    // },
    // "0x95e2245f0640775b936d05a6d28ae6aa4cc13b43": {
    //   address: "0x14c712fa53588087193e43b8f3fa468ac6f6b0ae",
    //   pid: 0,
    // },
    // "0xb421e621894e8b7f9c604d34ab64e6f8dca6573b": {
    //   address: "0x160f59e6979e7ed3eb6daec2a096f4d2e2e76914",
    //   pid: 1,
    // },
    // "0x6cddc40144c791e5df86dde48a229bbe044685bd": {
    //   address: "0x160f59e6979e7ed3eb6daec2a096f4d2e2e76914",
    //   pid: 2,
    // },
    // "0xd8f1c513ae5420a4e2b4815c995a4c46c8cfbf29": {
    //   address: "0x160f59e6979e7ed3eb6daec2a096f4d2e2e76914",
    //   pid: 3,
    // },
    // "0x1503e9a43e5417a87561d27688fdc74cbe394c28": {
    //   address: "0x7bdd632a94d4430676813bb7c114a3582ea560ea",
    //   pid: 0,
    // },
    // "0x84afa81e3a35c3570049aa4d6d8073e3cb091f28": {
    //   address: "0x2512b1357b5560953f3b2c3f7b862947b645c67b",
    //   pid: 0,
    // },
    // "0xc617327fdb3aa54f2eacab39209571272e31ed47": {
    //   address: "0x7787feC05BF0Db93B7FCe296B9A591FA8314E79a",
    //   pid: 0,
    // },
    // "0x4fac5b910c3e3a769f901474e583b15e670d220f": {
    //   address: "0x72350aeC5320751eADd38108D629705EF2970523",
    //   pid: 0,
    // },
  },
  [ChainId.kovan]: {},
  [ChainId.rinkeby]: {},
  [ChainId.arbitrum]: {
    '0x4784be205996d53d7a40bdb4e1d7b84e551d71d4': {
      address: '0x598ec284B304316DC478BcBa4d487256eeA92321',
      pid: 0,
      dex: Dex.Uniswap,
    },
    '0x376023fdb2afb70ec2c14e804b55aea402210723': {
      address: '0xE77FA4C5521EE2287667F2C9b0Ab022F2f639cEd',
      pid: 0,
      dex: Dex.Uniswap,
    },
    '0x903ab0e50f7823ee592bbb5b4cd3cdc1ff3f0ffc': {
      address: '0x2d726AC962d4CC178DE749FC8004535aC8091EEf',
      pid: 0,
      dex: Dex.Pancakeswap,
    },
    // '0x376023fdb2afb70ec2c14e804b55aea402210723': {
    //   address: '0x5AbdF46E906d0e2E7F312148db4b4410d0af7635',
    //   pid: 0,
    //   dex: Dex.Uniswap,
    // },
    // '0xf5ee81b3e65f141561e83666b17a5141ea243181': {
    //   address: '0x60A70e64842D318adC15323f38836486c09C6cE5',
    //   pid: 0,
    // },
    // '0xde1e3985bb2e9f6c934a369c57264b4cc39e12fc': {
    //   address: '0x2fd466b1eE0C70EF36a551609a66fbc5048abF33',
    //   pid: 0,
    // },
    // '0xc6e2591b5ecf0a8970a2bcd140e2bab5c773a61d': {
    //   address: '0x1Ab97A2cfDc5F89D6c0b56b261BE3F7a86709785',
    //   pid: 0,
    // },
    // "0x244e24ebbdc49761ee4aeef4ef7b2c8b0eda834d": {
    //   address: "0x138c8F988AcB9ec57e764c592615e611d9a20330",
    //   pid: 0,
    // },
    // "0x43bca30a3d91007542844fd845fa4fb950599b17": {
    //   address: "0xa67e92845a5105d5743658cff003369c91dce956",
    //   pid: 0,
    // },
    // "0x4f0366185059cab10f94f381670b2086f7d0f1f0": {
    //   address: "0x72350aec5320751eadd38108d629705ef2970523",
    //   pid: 0,
    // },
  },
  [ChainId.optimism]: {
    '0xd1c33d0af58eb7403f7c01b21307713aa18b29d3': {
      address: '0xD7cf8Dc79b15a61714061C5B7A1c12ddE9f3f088',
      pid: 0,
      dex: Dex.Uniswap,
    },
    '0xad5c9f2ed63823281a49cd17d9d37f86070ca5fe': {
      address: '0x8598b71675d53Dde77BB04dD841a2D07Df03b86B',
      pid: 0,
      dex: Dex.Uniswap,
    },
    '0xd3b276dd747db1c996cbee1edb5a80cb29e26929': {
      address: '0x474A23E4Fb2E937D5876A487560D71fe9829fCDF',
      pid: 0,
      dex: Dex.Uniswap,
    },
    '0x35efc28fe9ecdf95be37750e4a5d108585cbf3c9': {
      address: '0x884E3383538632084D2580c0E8f3676b77C9ed0c',
      pid: 0,
      dex: Dex.Uniswap,
    },
    '0xdb980a3a5d68d82f8e782f07aeda442291779bf2': {
      address: '0xe7D126F88AB24867c3FDa76425000Aab8441e85D',
      pid: 0,
      dex: Dex.Uniswap,
    },
    '0x0032613d5c87346d02a5ac4b37553b801fee06ee': {
      address: '0xb3c96482572460af42Cad50D4F93ee945237a8f4',
      pid: 0,
      dex: Dex.Uniswap,
    },
    '0xf845f775c947524dce835ccc45c84d37296f37e8': {
      address: '0x6F6AB478b4882043b8ec5a37d560582755726F0A',
      pid: 0,
      dex: Dex.Uniswap,
    },

    '0x1d0fe57b80e38ecd9aea1415bcff8c426dc0fdae': {
      address: '0xfef36f452f9585066838c90993575e3e5901a4f7',
      pid: 0,
      dex: Dex.Uniswap,
    },
    '0xd19077f505ba21b970f88d7f1b81bf3a8a59bcc2': {
      address: '0xB17EE17c0B21a11cEea4378eAA235Df86D82Ba18',
      pid: 0,
      dex: Dex.Uniswap,
    },
    '0xf07c9e48a92de4d1229965935b4c70389d8a498a': {
      address: '0xEDc5eBE9Fe347497703ae595BAEC028a34E315A8',
      pid: 0,
      singleSideEnabled: true,
      dex: Dex.Uniswap,
    },
    '0xe26aca79771f69c8beb1be423ecf3bf16a1bc7f8': {
      address: '0xC0D6A410Ed141C1369f64aDb061851f28C112aD2',
      pid: 0,
      singleSideEnabled: true,
      dex: Dex.Uniswap,
    },
    '0xf06e0c89749adb5976a114fde5b74e925531ee1b': {
      address: '0xdcf476Df1165d85477BE841f4907569cf1413745',
      pid: 0,
      dex: Dex.Uniswap,
    },
    // "0x45797d8274cf4eee728a50a28027adea0e4ab005": {
    //   address: "0x13df6416202c9ca5a51a34153d0df24c20edfbff",
    //   pid: 0,
    // },
    // "0xbb0fc86e5ad5dc1451203bcef069b3981667389d": {
    //   address: "0x2cc08df00cf338748c40f1370d9955e3ed7a0f40",
    //   pid: 0,
    // },
    // "0x035255e08550419980b2bf95eab0a25136412c10": {
    //   address: "0x2cc08df00cf338748c40f1370d9955e3ed7a0f40",
    //   pid: 1,
    // },
  },
  [ChainId.moonbeam]: {},
  [ChainId.mantle]: {},
  [ChainId.avalanche]: {},
  [ChainId.baseTestnet]: {},
  [ChainId.zksyncEra]: {},
}

function getDexesFromMap(chainId: ChainId) {
  return [
    ...new Set(Object.values(liquidityMiningMap[chainId]).map((e) => e.dex)),
  ]
}

type RewardInfo = {
  network: Network
  active?: boolean
  logo?: string
  name?: string
  dexes: Dex[]
}

export const rewardInfo: Partial<Record<ChainId, RewardInfo[]>> = {
  [ChainId.optimism]: [
    {
      active: true,
      dexes: getDexesFromMap(ChainId.optimism),
      logo: CHAIN_ICONS[10],
      name: 'OP',
      network: 'optimism',
    },
  ],
  [ChainId.bsc]: [
    {
      active: true,
      dexes: [Dex.Pancakeswap],
      logo: getIconUrlOr404('bnb'),
      name: 'BSC Pancakeswap',
      network: 'bsc',
    },
    {
      active: true,
      dexes: [Dex.Thena],
      logo: getIconUrlOr404('THE'),
      name: 'Thena',
      network: 'bsc',
    },
  ],
  [ChainId.polygon]: [
    {
      active: false,
      dexes: getDexesFromMap(ChainId.polygon),
      logo: getIconUrlOr404('ORARE'),
      name: 'OneRare',
      network: 'polygon',
    },
  ],
  [ChainId.arbitrum]: [
    {
      active: true,
      dexes: [Dex.Pancakeswap],
      logo: 'https://assets.coingecko.com/coins/images/12632/large/pancakeswap-cake-logo_%281%29.png',
      name: 'CAKE',
      network: 'arbitrum',
    },
    {
      active: true,
      dexes: [Dex.Uniswap],
      logo: 'https://assets.coingecko.com/coins/images/31970/large/OVN.png?1696959174',
      name: 'OVN',
      network: 'arbitrum',
    },
  ],
}
export function getStrategiesAddress(
  chainId: ChainId = web3Store.status.chainId!
): string[] {
  return Object.entries(liquidityMiningMap[chainId] ?? {})
    .filter((e) => e[1].dex === web3Store.dex)
    .map((e) => e[0])
}

export function getLiquidityMiningConfig(
  strategyAddress: string,
  chainId: ChainId = web3Store.status.chainId!
) {
  return liquidityMiningMap[chainId]?.[strategyAddress] || {}
}

export const getRewardInfo = (
  chainId: ChainId = web3Store.status.chainId!
): RewardInfo =>
  rewardInfo[chainId]?.find(
    (e) => e.active && e.dexes.includes(web3Store.dex)
  ) ?? { network: web3Store.network!, dexes: [web3Store.dex], active: false }

export const liquidityAllianceAddress = [
  '0x3f436e96f7fbba9dbd764294c424b38e9ff3fff8',
  '0xb7b616fd8b72b8c227e1492a66ec609a5f68e2bc',
]
