
import { Vue, Component, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-use-before-define
@Component<PrimaryButton>({})
export default class PrimaryButton extends Vue {
  @Prop({ type: String, default: 'button' }) type!: string
  @Prop({ type: Boolean }) block!: boolean
  @Prop({ type: Boolean }) large!: boolean
  @Prop({ type: Boolean }) disabled!: boolean
}
