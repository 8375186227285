import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import Web3Store from '~/store/web3'

let web3Store: Web3Store

function initializeStores(store: Store<any>): void {
  web3Store = getModule(Web3Store, store)
}

export { initializeStores, web3Store }
