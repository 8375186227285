
import { BIconGiftFill } from 'bootstrap-vue'
import { Vue, Component, VModel } from 'vue-property-decorator'

import { web3Store } from '~/store'
import { getExplorerInfo } from '~/store/web3'
import { Dex } from '~/types/web3'
const linkTitleMap: { [key: string]: string } = {
  index: 'Dashboard',
  leaderboard: 'Leaderboard',
  profile: 'Manager Profile',
  's-network-id': 'Strategy Overview',
  'profile-strategy-id': 'Manage Strategy',
  tokens: 'Tokens',
}
// eslint-disable-next-line no-use-before-define
@Component<AppHeader>({
  components: { BIconGiftFill },
  destroyed() {
    // clearInterval(this.timer)
    this.$root.$off('login_complete', this.checkPermitted)
  },

  mounted() {
    // this.timer = setInterval(() => {
    //   this.now = new Date()
    // }, 1000)
    this.$root.$on('login_complete', this.checkPermitted)
    this.checkPermitted()
  },
})
export default class AppHeader extends Vue {
  @VModel({ type: Boolean }) model!: boolean
  dropdown = false
  accessPermitted = false
  dexes = Dex
  links = Object.freeze([
    {
      name: 'Profile',
      props: { to: '/profile' },
      is: 'NuxtLink',
      show: () => !!this.account,
      icon: 'activityIcon',
    },
    {
      name: 'Guide',
      props: {
        href: 'https://docs.defiedge.io/guides',
        target: '_blank',
      },
      is: 'a',
      icon: 'GuideIcon',
    },
    {
      name: 'Liquidity alliance',
      props: {
        href: 'https://defiedge.io/liquidityalliance',
        target: '_blank',
      },
      is: 'a',
      icon: 'LAIcon',
    },
    {
      name: 'Privacy Policy',
      props: { href: 'https://defiedge.io/privacypolicy', target: '_blank' },
      is: 'a',
      icon: 'PolicyIcon',
    },
    {
      name: 'T&C',
      props: { href: 'https://defiedge.io/terms', target: '_blank' },
      is: 'a',
      icon: 'TermsIcon',
    },
    {
      name: 'Feedback',
      props: { href: 'https://defiedge.olvy.co/feedback', target: '_blank' },
      is: 'a',
      icon: 'RateIcon',
    },
    {
      name: 'Theme',
      is: 'ToggleDarkMode',
    },
  ])

  socialMedia = Object.freeze([
    {
      icon: 'DiscordBlueIcon',
      path: `https://discord.gg/n3XjPv7PPN`,
      class: 'transform scale-90',
    },
    {
      icon: 'TwitterIcon',
      path: `https://twitter.com/DefiEdge/`,
      class: 'transform scale-125',
    },
    { icon: 'MediumIcon', path: `https://medium.com/@DefiEdge` },
    {
      icon: 'SubstackIcon',
      path: `https://defiedge.substack.com/`,
      class: 'transform scale-80',
    },
  ])

  tools = Object.freeze([
    {
      name: 'Ideal Fee compounding',
      props: { to: '/ideal-fee-compunding' },
      is: 'NuxtLink',
      icon: 'UniswapIcon',
    },
  ])

  get explorerUrl() {
    return getExplorerInfo(
      web3Store.networkNameGetter,
      web3Store.status.chainId,
      this.account!.toLowerCase(),
      'address'
    )[0]
  }

  get chainName() {
    return web3Store.status.networkName
  }

  get chainId() {
    return web3Store.status.chainId
  }

  get account() {
    return web3Store.status.account
  }

  get isMetaMask() {
    const name = web3Store.status.providerInfo?.name

    return !name || name === 'MetaMask'
  }

  get dex() {
    return web3Store.dex ?? Dex.Uniswap
  }

  get pageTitle() {
    return linkTitleMap[this.$route.name ?? '']
  }

  get logo() {
    return web3Store.status.providerInfo?.logo
  }

  async checkPermitted() {
    if (!this.$root.web3Provider) return

    this.accessPermitted = await web3Store.accessPermitted(
      this.$root.web3Provider
    )
  }

  changeWallet() {
    this.$web3Modal.clearCachedProvider()
    window.location.reload()
  }
}
