/* eslint-disable import/order */

import '../types/plugins'

import Aggregator from '../services/Aggregator'
import ERC20 from '../services/ERC20'
import { Plugin } from '@nuxt/types'
import WalletConnectProvider from '@walletconnect/web3-provider'
import Web3Manager from '../services/Web3Manager'
import { SafeAppWeb3Modal } from '@gnosis.pm/safe-apps-web3modal'
import { ChainId } from '~/types/web3'
import { isClient } from '~/utils'
import { fixTokenName, fixTokenSymbol } from '~/utils/uiFix'

export default (({ app: { store, ...app } }, inject) => {
  inject('web3Manager', new Web3Manager())
  inject('ERC20', new ERC20())
  inject('aggregator', new Aggregator())
  inject('safeBack', (url: string) => {
    const router = app.router
    if (!router || !isClient) return

    if (
      !document.referrer ||
      window.history.length <= 2 ||
      !(
        document.referrer.includes('defiedge') ||
        document.referrer.includes('localhost')
      )
    )
      return router.replace(url)

    router.back()
  })

  inject('fixTokenSymbol', fixTokenSymbol)
  inject('fixTokenName', fixTokenName)

  inject(
    'web3Modal',
    new SafeAppWeb3Modal({
      network: 'mainnet',
      cacheProvider: true,
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            rpc: {
              [ChainId.mainnet]:
                'https://stylish-green-scion.quiknode.pro/18f0fa763d29aaf23490eb9428b76786ddf8a1d8/',
              [ChainId.arbitrum]: 'https://arb1.arbitrum.io/rpc',
              [ChainId.optimism]: 'https://mainnet.optimism.io',
              [ChainId.polygon]: 'https://polygon-rpc.com',
              [ChainId.moonbeam]:
                'https://moonbeam.blastapi.io/52278028-0033-4fce-b8ee-c154d22f9991',
            },
          },
        },
      },
    })
  )

  store?.commit('app/initState')
}) as Plugin
