
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import { web3Store } from '~/store'

// eslint-disable-next-line no-use-before-define
@Component<Wallet>({
  mounted() {
    this.setName()
  },
})
export default class Wallet extends Vue {
  @Prop({ type: String, default: undefined }) buttonClass: string | undefined

  @Prop({ type: Boolean }) toolbar!: boolean

  get wallet() {
    return web3Store.status
  }

  get disableConnectBtn() {
    return web3Store.disableConnectBtn
  }

  get pendingTransactions() {
    return web3Store.pendingTransactions
  }

  get network() {
    return web3Store.network
  }

  showNameOrAddress = ''

  @Watch('network')
  @Watch('wallet.chainWiseDomainName')
  @Watch('wallet.account')
  setName() {
    const name =
      this.network && this.wallet?.chainWiseDomainName
        ? this.wallet.chainWiseDomainName[this.network]
        : null
    const address = this.wallet?.account
      ? this.beautifyStringLongerThan(this.wallet.account)
      : null
    this.showNameOrAddress = name || address || ''
  }

  beautifyStringLongerThan(str: string, len: number = 6): string {
    if (str.length <= 2 * len) return str
    return str.substr(0, len) + '...' + str.substr(-len)
  }

  connectInjected(ev: any): void {
    if (!this.wallet.account || this.wallet.account === '') {
      this.$root.$emit('login', true)
    } else if (this.toolbar) {
      this.$emit('click', ev)
    } else {
      this.$router.push('/profile')
    }
  }

  handleError(error: Error): void {
    alert(error.message) // todo
  }
}
