import Vue from 'vue'

import { isClient } from '~/utils'
// New, Google Analytics 4
if (isClient && !Vue.__ga_mixin__) {
  Vue.__ga_mixin__ = true
  Vue.mixin({
    mounted() {
      if (
        !document.title.startsWith('App') &&
        document.title !== Vue.__prev_title__
      ) {
        Vue.__prev_title__ = document.title
        window.dataLayer = window.dataLayer || []
        function gtag() {
          dataLayer.push(arguments)
        }
        gtag('js', new Date())

        gtag('config', 'G-KJ0FH5L8D8')
      }
    },
  })
}
