
import { gql } from 'graphql-tag'
import { Component, Vue } from 'vue-property-decorator'

import { getCookie, setCookie } from '~/utils/cookie'

// eslint-disable-next-line no-use-before-define
@Component<SubscribeModel>({
  mounted() {
    this.subscribe = !getCookie('subscribemodel')
  },
})
export default class SubscribeModel extends Vue {
  subscribe = false
  subscribeModel = false
  setCookie = setCookie
  email = ''
  loading = false

  get model() {
    return this.subscribe || this.subscribeModel
  }

  get isValid() {
    const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,24})+$/gi
    return EMAIL_REGEX.test(this.email)
  }

  sendSubscribeEmail() {
    this.loading = true
    this.$apollo
      .mutate({
        client: 'metadata',
        variables: {
          email: this.email,
        },
        mutation: gql`
          mutation createOneNewsLetters($email: String!) {
            createOneNewsLetters(data: { email: $email }) {
              id
            }
          }
        `,
      })
      .then(() => {
        this.$toast.success('Subscribed!')
        this.email = ''
        setCookie('subscribemodel', 'true', 7)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        this.loading = false
      })

    this.onClose()
  }

  onClose() {
    if (this.subscribe) {
      setCookie('subscribemodel', 'true', 1)
    }

    this.subscribe = this.subscribeModel = false
  }
}
