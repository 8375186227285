import type { ActionTree, MutationTree } from 'vuex'

import { web3Store } from '.'

import { ChainId } from '~/types/web3'
import { isClient } from '~/utils'

const STATE_KEY = 'defiedge.app'

export type AppState = {
  watchListMap: Record<ChainId, string[]>
  userAddressAgreed: string[]
  strategyOverviewActiveTab: 'Share Price' | 'Fees'
  depositDisclaimer: boolean
  navBarCollapse: boolean
  scrolled: boolean
  swapProtocolMap: Partial<Record<ChainId, string>>
  warning: { deposit: Boolean; secureBanner: Boolean }
}

const _state = () => {
  const stateString = isClient ? localStorage.getItem(STATE_KEY) : null

  let state: AppState | null = null

  try {
    if (stateString) state = JSON.parse(stateString)
  } catch (error) {
    state = null
  }

  state = {
    userAddressAgreed: [],
    depositDisclaimer: false,
    navBarCollapse: false,
    strategyOverviewActiveTab: 'Share Price',
    warning: { deposit: true, secureBanner: true, ...state?.warning },
    scrolled: false,
    watchListMap: {
      [ChainId.arbitrum]: [],
      [ChainId.base]: [],
      [ChainId.bsc]: [],
      [ChainId.goerli]: [],
      [ChainId.kovan]: [],
      [ChainId.mainnet]: [],
      [ChainId.moonbeam]: [],
      [ChainId.optimism]: [],
      [ChainId.polygon]: [],
      [ChainId.rinkeby]: [],
      [ChainId.mantle]: [],
      [ChainId.baseTestnet]: [],
      [ChainId.avalanche]: [],
      [ChainId.zksyncEra]: [],
      ...state?.watchListMap,
    },
    swapProtocolMap: {
      ...state?.swapProtocolMap,
    },
    ...state,
  }

  if (isClient) {
    localStorage.setItem(STATE_KEY, JSON.stringify(state))
  }

  return state
}

export type RootState = AppState

export { _state as state }

export const mutations: MutationTree<RootState> = {
  toggleWatchList(
    state,
    { address, chainId }: { address: string; chainId: ChainId }
  ) {
    let list = [...(state.watchListMap[chainId] ?? [])]
    if (list.includes(address)) list = list.filter((e) => e !== address)
    else list.push(address)

    state.watchListMap[chainId] = list
    localStorage.setItem(STATE_KEY, JSON.stringify(state))
  },
  setProtocols(
    state,
    { protocols, chainId }: { protocols: string; chainId: ChainId }
  ) {
    state.swapProtocolMap[chainId] = protocols

    localStorage.setItem(STATE_KEY, JSON.stringify(state))
  },
  depositWarning(state, warning: boolean) {
    state.warning.deposit = warning
    localStorage.setItem(STATE_KEY, JSON.stringify(state))
  },
  setSecureBanner(state, secureBanner: boolean) {
    state.warning.secureBanner = secureBanner
    localStorage.setItem(STATE_KEY, JSON.stringify(state))
  },
  setInUserAddressAgreed(state, address: string) {
    state.userAddressAgreed = [
      ...new Set([...state.userAddressAgreed, address.toLowerCase()]),
    ]
    localStorage.setItem(STATE_KEY, JSON.stringify(state))
  },
  setDepositDisclaimer(state, depositDisclaimer: boolean) {
    state.depositDisclaimer = depositDisclaimer

    localStorage.setItem(STATE_KEY, JSON.stringify(state))
  },
  setNavbar(state, navBarCollapse: boolean) {
    state.navBarCollapse = navBarCollapse

    localStorage.setItem(STATE_KEY, JSON.stringify(state))
  },
  setScrolled(state, scrolled) {
    state.scrolled = scrolled
  },
  setStrategyOverviewActiveTab(state, strategyOverviewActiveTab: any) {
    state.strategyOverviewActiveTab = strategyOverviewActiveTab

    localStorage.setItem(STATE_KEY, JSON.stringify(state))
  },
  initState(state) {
    Object.assign(state, _state())
  },
}

export const actions: ActionTree<RootState, RootState> = {
  getWatchList({ state }) {
    return state.watchListMap[web3Store.status.chainId!]
  },
}
