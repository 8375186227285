import configMap, {
  AddressConfig,
  Addresses,
  Config,
  LimitOrderProvider,
} from './addresses'

import { web3Store } from '~/store'
import { Dex } from '~/types/web3'
import { isStaging } from '~/utils'

export function getConfig<K extends keyof Config>(
  config: K,
  subConfig: keyof Config[K],
  twap?: boolean,
  {
    staging = isStaging,
    chainId = web3Store.status.chainId,
    dex = web3Store.dex,
  } = {}
) {
  if (!chainId) return null

  let addressObj: AddressConfig | undefined = configMap[chainId][config][
    subConfig
  ] as any

  if (dex && dex !== Dex.Uniswap && typeof addressObj === 'object') {
    addressObj = addressObj[dex] ?? addressObj
  }

  if (typeof addressObj === 'undefined') {
    throw new TypeError(
      `Config ${config}.${subConfig as string} not found. Options:` +
        Object.entries({ twap, chainId, staging, dex })
          .map((e) => e.join(':'))
          .join(', ')
    )
  }

  if (typeof addressObj === 'string') return addressObj

  const address = ((twap && addressObj?.twap) || addressObj) as
    | Addresses
    | string

  if (!address) return null

  if (typeof address === 'string') return address

  return (staging && address.staging) || address.default
}

export const getPokeMeAddress = (isTwap: boolean) =>
  getConfig('limitOrderGelato', 'pokeMeAddress', isTwap)

export const getKeepersRegistrarAddress = (isTwap: boolean) =>
  getConfig('limitOrderKeeper', 'registrarAddress', isTwap)
export const getKeepersRegistryAddress = (isTwap: boolean) =>
  getConfig('limitOrderKeeper', 'registryAddress', isTwap)

export const getFactoryAddress = (isTwap: boolean) =>
  getConfig('defiEdge', 'factoryAddress', isTwap)

export const getMigratorAddress = (isTwap: boolean) => {
  try {
    return getConfig('defiEdge', 'migratorAddress', isTwap)
  } catch (error: any) {
    return null
  }
}

export const getSwapProxyAddress = (isTwap: boolean) => {
  try {
    return getConfig('defiEdge', 'swapProxy', isTwap)
  } catch (error: any) {
    return null
  }
}

export const getPositionKeyHelperAddress = (isTwap: boolean) => {
  try {
    return getConfig('defiEdge', 'positionKeyHelper', isTwap)
  } catch (error: any) {
    return null
  }
}

export const getResolverFactoryAddress = (
  isTwap: boolean,
  type: LimitOrderProvider
) => getConfig(('limitOrder' + type) as any, 'resolverFactoryAddress', isTwap)

export const getTaskTreasuryAddress = (isTwap: boolean) =>
  getConfig('limitOrderGelato', 'taskTreasuryAddress', isTwap)

export const getOracleAddress = (isTwap: boolean) =>
  getConfig('defiEdge', 'oracleAddress', isTwap)

export const getChainLinkRegistryAddresses = (isTwap: boolean) =>
  getConfig('defiEdge', 'chainLinkRegistryAddress', isTwap)
export const getLiquidityHelperAddress = (isTwap: boolean) =>
  getConfig('defiEdge', 'liquidityHelperAddress', isTwap)

export const getQuoterAddress = (isTwap: boolean) =>
  getConfig('defiEdge', 'quoter', isTwap)

export const getLinkTokenAddress = () => getConfig('others', 'linkToken')
export const getTickLensAddress = () => getConfig('others', 'tickLens')
