import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6511528e = () => interopDefault(import('../pages/access_denied.vue' /* webpackChunkName: "pages/access_denied" */))
const _8c93c9ba = () => interopDefault(import('../pages/farm.vue' /* webpackChunkName: "pages/farm" */))
const _3044d172 = () => interopDefault(import('../pages/ideal-fee-compunding.vue' /* webpackChunkName: "pages/ideal-fee-compunding" */))
const _0be1f6c6 = () => interopDefault(import('../pages/index_old.vue' /* webpackChunkName: "pages/index_old" */))
const _d66e02c0 = () => interopDefault(import('../pages/leaderboard.vue' /* webpackChunkName: "pages/leaderboard" */))
const _4a50e1f5 = () => interopDefault(import('../pages/merkl-rewards.vue' /* webpackChunkName: "pages/merkl-rewards" */))
const _65705fee = () => interopDefault(import('../pages/portfolio/index.vue' /* webpackChunkName: "pages/portfolio/index" */))
const _584da866 = () => interopDefault(import('../pages/positions.vue' /* webpackChunkName: "pages/positions" */))
const _f80ef462 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _66a74549 = () => interopDefault(import('../pages/redirect.vue' /* webpackChunkName: "pages/redirect" */))
const _cc69e604 = () => interopDefault(import('../pages/strategies.vue' /* webpackChunkName: "pages/strategies" */))
const _720b2a8a = () => interopDefault(import('../pages/tokens/index.vue' /* webpackChunkName: "pages/tokens/index" */))
const _b8a8303e = () => interopDefault(import('../pages/unauthorized.vue' /* webpackChunkName: "pages/unauthorized" */))
const _22c0697e = () => interopDefault(import('../pages/strategy/create/index.vue' /* webpackChunkName: "pages/strategy/create/index" */))
const _1497bce7 = () => interopDefault(import('../pages/strategy/import/index.vue' /* webpackChunkName: "pages/strategy/import/index" */))
const _6b2c65b1 = () => interopDefault(import('../pages/profile/strategy/_id.vue' /* webpackChunkName: "pages/profile/strategy/_id" */))
const _1a9b3f15 = () => interopDefault(import('../pages/strategy/theme/_slug.vue' /* webpackChunkName: "pages/strategy/theme/_slug" */))
const _01a1f1fb = () => interopDefault(import('../pages/manager/_id.vue' /* webpackChunkName: "pages/manager/_id" */))
const _385cf454 = () => interopDefault(import('../pages/portfolio/_id.vue' /* webpackChunkName: "pages/portfolio/_id" */))
const _0e5b7c84 = () => interopDefault(import('../pages/position/_id/index.vue' /* webpackChunkName: "pages/position/_id/index" */))
const _5b8671ef = () => interopDefault(import('../pages/tokens/_symbol.vue' /* webpackChunkName: "pages/tokens/_symbol" */))
const _a5c45cb4 = () => interopDefault(import('../pages/s/_network/_id/index.vue' /* webpackChunkName: "pages/s/_network/_id/index" */))
const _099cd8d5 = () => interopDefault(import('../pages/s/_network/_id/Add.vue' /* webpackChunkName: "pages/s/_network/_id/Add" */))
const _4435a548 = () => interopDefault(import('../pages/s/_network/_id/History.vue' /* webpackChunkName: "pages/s/_network/_id/History" */))
const _a85d4f06 = () => interopDefault(import('../pages/s/_network/_id/Rebalance.vue' /* webpackChunkName: "pages/s/_network/_id/Rebalance" */))
const _f5fe4500 = () => interopDefault(import('../pages/s/_network/_id/Remove.vue' /* webpackChunkName: "pages/s/_network/_id/Remove" */))
const _dc041c96 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/access_denied",
    component: _6511528e,
    name: "access_denied"
  }, {
    path: "/farm",
    component: _8c93c9ba,
    name: "farm"
  }, {
    path: "/ideal-fee-compunding",
    component: _3044d172,
    name: "ideal-fee-compunding"
  }, {
    path: "/index_old",
    component: _0be1f6c6,
    name: "index_old"
  }, {
    path: "/leaderboard",
    component: _d66e02c0,
    name: "leaderboard"
  }, {
    path: "/merkl-rewards",
    component: _4a50e1f5,
    name: "merkl-rewards"
  }, {
    path: "/portfolio",
    component: _65705fee,
    name: "portfolio"
  }, {
    path: "/positions",
    component: _584da866,
    name: "positions"
  }, {
    path: "/profile",
    component: _f80ef462,
    name: "profile"
  }, {
    path: "/redirect",
    component: _66a74549,
    name: "redirect"
  }, {
    path: "/strategies",
    component: _cc69e604,
    name: "strategies"
  }, {
    path: "/tokens",
    component: _720b2a8a,
    name: "tokens"
  }, {
    path: "/unauthorized",
    component: _b8a8303e,
    name: "unauthorized"
  }, {
    path: "/strategy/create",
    component: _22c0697e,
    name: "strategy-create"
  }, {
    path: "/strategy/import",
    component: _1497bce7,
    name: "strategy-import"
  }, {
    path: "/profile/strategy/:id?",
    component: _6b2c65b1,
    name: "profile-strategy-id"
  }, {
    path: "/strategy/theme/:slug?",
    component: _1a9b3f15,
    name: "strategy-theme-slug"
  }, {
    path: "/manager/:id?",
    component: _01a1f1fb,
    name: "manager-id"
  }, {
    path: "/portfolio/:id",
    component: _385cf454,
    name: "portfolio-id"
  }, {
    path: "/position/:id",
    component: _0e5b7c84,
    name: "position-id"
  }, {
    path: "/tokens/:symbol",
    component: _5b8671ef,
    name: "tokens-symbol"
  }, {
    path: "/s/:network?/:id",
    component: _a5c45cb4,
    name: "s-network-id"
  }, {
    path: "/s/:network?/:id?/Add",
    component: _099cd8d5,
    name: "s-network-id-Add"
  }, {
    path: "/s/:network?/:id?/History",
    component: _4435a548,
    name: "s-network-id-History"
  }, {
    path: "/s/:network?/:id?/Rebalance",
    component: _a85d4f06,
    name: "s-network-id-Rebalance"
  }, {
    path: "/s/:network?/:id?/Remove",
    component: _f5fe4500,
    name: "s-network-id-Remove"
  }, {
    path: "/",
    component: _dc041c96,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
