import Vue from 'vue'

const setFontSize =(el : HTMLElement) =>{
    setTimeout(() => {
    let maxSize = Number.parseInt( window.getComputedStyle(el).fontSize)
    const scrollWidth = el.scrollWidth
    const containerWidth =  el.clientWidth
    while(true){
        const scrollWidth = el.scrollWidth
        const containerWidth =  el.clientWidth
        if(scrollWidth <= containerWidth){
            break
        }
        maxSize =  maxSize - 2
        el.style.fontSize =  maxSize + 'px'
    }
    },50)
}

Vue.directive('auto-shrink-font-size',{
    bind: setFontSize,
    inserted : setFontSize,
    update : setFontSize,
    componentUpdated: setFontSize
})