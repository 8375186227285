var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"rounded-xl inline-flex justify-between items-center",class:_vm.pendingTransactions.length > 0
      ? 'bg-primary-600'
      : 'bg-white dark:bg-dark-700'},[_c('transition',{attrs:{"name":"slide"}},[(_vm.pendingTransactions.length > 0)?_c('span',{staticClass:"text-white pr-3 pl-5 hidden justify-center whitespace-nowrap items-center md:flex"},[_c('img',{staticClass:"mr-2",attrs:{"alt":"Loading SVG","height":"15","src":require("assets/oval.svg"),"width":"15"}}),_vm._v("\n      "+_vm._s(_vm.pendingTransactions.length)+" Pending\n    ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"bg-white rounded-xl w-full dark:bg-[#1E1E1E]",class:_vm.pendingTransactions.length > 0 ? 'm-[1px]' : ''},[_c(_vm.toolbar ? 'NeutralButton' : 'button',{tag:"component",staticClass:"font-light w-full px-4 transition appearance-none !text-xs dark:bg-dark-400 dark:!text-primary-400 focus:outline-none disabled:opacity-50",class:[
        ...(_vm.wallet.account
          ? _vm.pendingTransactions.length > 0
            ? ['bg-gray-100 rounded-xl font-semibold !py-1']
            : [
                'border border-gray-200 bg-gray-100  rounded-lg font-semibold dark:border-dark-100 !py-1',
                { '!text-primary-600': !_vm.toolbar },
              ]
          : _vm.toolbar
          ? []
          : ['!text-white bg-primary-600 rounded-lg !py-3']),
        _vm.disableConnectBtn ? 'cursor-not-allowed' : '',
        {
          '!bg-red-500 !dark:bg-red-500 ': _vm.toolbar && !_vm.wallet.account,
        },
        _vm.buttonClass,
      ],attrs:{"disabled":_vm.disableConnectBtn,"title":_vm.wallet.account
          ? _vm.wallet.account
          : _vm.disableConnectBtn
          ? 'Waiting for your response to an ongoing connection request'
          : 'Connect Wallet'},on:{"click":_vm.connectInjected}},[(_vm.wallet.account)?_c('div',{staticClass:"inline-flex justify-center items-center md:space-x-2"},[_c('Jazzicon',{staticClass:"hidden md:mt-1 md:inline",attrs:{"address":_vm.wallet.account,"diameter":20}}),_vm._v(" "),_c('div',{staticClass:"flex font-semibold items-center md:space-x-4"},[_c('span',{staticClass:"hidden md:inline"},[_vm._v("\n            "+_vm._s(_vm.showNameOrAddress)+"\n          ")]),_vm._v(" "),(_vm.toolbar)?_c('SettingsIcon',{staticClass:"mt-1 md:mt-0"}):_vm._e()],1)],1):_c('div',{staticClass:"text-white whitespace-nowrap"},[_vm._v("Connect Wallet")])])],1),_vm._v(" "),(_vm.toolbar && !_vm.wallet.account)?_c('NeutralButton',{staticClass:"ml-1 md:ml-3 !p-2",on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('SettingsIcon')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }