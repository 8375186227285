
import { Component, VModel, Vue, Prop } from 'vue-property-decorator'

import { web3Store } from '~/store'

@Component
export default class TransactionStatusModal extends Vue {
  @VModel() open!: boolean

  /**
   * @Prop Defines current state of transaction
   * @dev state
   * 0 = failed
   * 1 = success
   * 2 = awaiting
   *
   * @dev hash - transaction hash
   */

  @Prop({ type: Number, default: 2 }) state!: number | undefined
  @Prop({ type: Number, default: 1 }) total!: number
  @Prop({ type: Number, default: 1 }) current!: number

  get errorModel() {
    return web3Store.errorModel
  }

  closeErrorModel() {
    web3Store.closeErrorModel()
  }

  title = {
    0: 'Transaction Failed',
    1: 'Transaction Submitted',
    2: 'Waiting for confirmation',
  }

  subtitle = {
    0: '😔 Transaction rejected or something went wrong.',
    1: 'Transaction successful, check explorer for more details.',
    2: 'Confirm this transaction in your wallet.',
  }
}
