import iconMapJson from '@/json/iconMap.json'

const tokenAliases = {
  ageur: 'EUR',
  mimatic: 'USDT',
  wbnb: 'BNB',
  wbtc: 'BTC',
  weth: 'ETH',
  wglmr: 'GLMR',
  wmatic: 'MATIC',
  // always at the bottom
  btcb: 'BTC',
}
export const tokenAliasesReverse = Object.entries(tokenAliases).reduce(
  (o, [k, v]) => {
    o[v] = k
    return o
  },
  {} as Record<string, string>
)

export const iconMap: { [key: string]: string } = iconMapJson

export default tokenAliases
