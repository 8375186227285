import warning from 'tiny-warning'

import { normalizeAccount, normalizeChainId } from './normalizer'

import { AbstractConnector } from '~/connectors/abstract-connector'
import { ConnectorEvent, ConnectorUpdate } from '~/connectors/declarations/'
import { web3Store } from '~/store'
import { ActionType, ActionArgs } from '~/store/web3'

class StaleConnectorError extends Error {
  constructor() {
    super()
    this.name = this.constructor.name
  }
}

export class UnsupportedChainIdError extends Error {
  public constructor(
    unsupportedChainId: number,
    supportedChainIds?: readonly number[]
  ) {
    super()
    this.name = this.constructor.name
    this.message = `Unsupported chain id: ${unsupportedChainId}. Supported chain ids are: ${supportedChainIds}.`
  }
}

async function augmentConnectorUpdate(
  connector: AbstractConnector,
  update: ConnectorUpdate
): Promise<ConnectorUpdate<number>> {
  const provider =
    update.provider === undefined
      ? await connector.getProvider()
      : update.provider
  const [_chainId, _account] = (await Promise.all([
    update.chainId === undefined ? connector.getChainId() : update.chainId,
    update.account === undefined ? connector.getAccount() : update.account,
  ])) as [
    Required<ConnectorUpdate>['chainId'],
    Required<ConnectorUpdate>['account']
  ]

  const chainId = normalizeChainId(_chainId)
  if (
    !!connector.supportedChainIds &&
    !connector.supportedChainIds.includes(chainId)
  ) {
    throw new UnsupportedChainIdError(chainId, connector.supportedChainIds)
  }
  const account = _account === null ? _account : normalizeAccount(_account)

  return { provider, chainId, account }
}

class Web3Manager {
  private get error() {
    return web3Store.status.error
  }

  private get onError() {
    return web3Store.status.onError
  }

  get connector() {
    return web3Store.status.connector
  }

  get address() {
    return web3Store.status.account
  }

  dispatch(args: ActionArgs) {
    web3Store.updateState(args)
  }

  async activate(
    connector: AbstractConnector,
    onError?: (error: Error) => void,
    throwErrors: boolean = false
  ): Promise<void> {
    let activated = false
    const updateBusterInitial = web3Store.updateBusterRef

    try {
      const update = await connector
        .activate()
        .then((update): ConnectorUpdate => {
          activated = true
          return update
        })

      const augmentedUpdate = await augmentConnectorUpdate(connector, update)

      if (web3Store.updateBusterRef > updateBusterInitial) {
        throw new StaleConnectorError()
      }

      this.dispatch({
        type: ActionType.ACTIVATE_CONNECTOR,
        payload: { connector, ...augmentedUpdate, onError },
      })
    } catch (error: any) {
      if (error instanceof StaleConnectorError) {
        activated && connector.deactivate()
        warning(false, `Suppressed stale connector activation ${connector}`)
      } else if (throwErrors) {
        activated && connector.deactivate()
        throw error
      } else if (onError) {
        activated && connector.deactivate()
        onError(error)
      } else {
        // we don't call activated && connector.deactivate() here because it'll be handled in the useEffect
        this.dispatch({
          type: ActionType.ERROR_FROM_ACTIVATION,
          payload: { connector, error },
        })
        activated && connector.deactivate()
      }
    }
  }

  setError(error: Error) {
    this.dispatch({ type: ActionType.ERROR, payload: { error } })
  }

  deactivate(): void {
    this.dispatch({ type: ActionType.DEACTIVATE_CONNECTOR })
  }

  async handleUpdate(update: ConnectorUpdate): Promise<void> {
    const connector = web3Store.status.connector

    if (!connector) {
      throw new Error(
        "This should never happen, it's just so Typescript stops complaining"
      )
    }

    const updateBusterInitial = web3Store.updateBusterRef

    // updates are handled differently depending on whether the connector is active vs in an error state
    if (!this.error) {
      const chainId =
        update.chainId === undefined
          ? undefined
          : normalizeChainId(update.chainId)
      if (
        chainId !== undefined &&
        !!connector.supportedChainIds &&
        !connector.supportedChainIds.includes(chainId)
      ) {
        const error = new UnsupportedChainIdError(
          chainId,
          connector.supportedChainIds
        )
        this.onError
          ? this.onError(error)
          : this.dispatch({ type: ActionType.ERROR, payload: { error } })
      } else {
        const account =
          typeof update.account === 'string'
            ? normalizeAccount(update.account)
            : update.account
        this.dispatch({
          type: ActionType.UPDATE,
          payload: { provider: update.provider, chainId, account },
        })
      }
    } else {
      try {
        const augmentedUpdate = await augmentConnectorUpdate(connector, update)

        if (web3Store.updateBusterRef > updateBusterInitial) {
          throw new StaleConnectorError()
        }
        this.dispatch({
          type: ActionType.UPDATE_FROM_ERROR,
          payload: augmentedUpdate,
        })
      } catch (error: any) {
        if (error instanceof StaleConnectorError) {
          warning(
            false,
            `Suppressed stale connector update from error state ${connector} ${update}`
          )
        } else {
          // though we don't have to, we're re-circulating the new error
          this.onError
            ? this.onError(error)
            : this.dispatch({ type: ActionType.ERROR, payload: { error } })
        }
      }
    }
  }

  handleError(error: Error): void {
    this.onError
      ? this.onError(error)
      : this.dispatch({ type: ActionType.ERROR, payload: { error } })
  }

  handleDeactivate(): void {
    this.dispatch({ type: ActionType.DEACTIVATE_CONNECTOR })
  }

  dispose() {
    if (this.connector) {
      this.connector
        .off(ConnectorEvent.Update, this.handleUpdate)
        .off(ConnectorEvent.Error, this.handleError)
        .off(ConnectorEvent.Deactivate, this.handleDeactivate)
      this.connector.deactivate()
    }
  }
}

export default Web3Manager
