
import { Web3Provider } from '@ethersproject/providers'
import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapMutations } from 'vuex'
import { getProviderInfo, IProviderInfo } from 'web3modal'

import { web3Store } from '~/store'
import { getChainId, getSigner } from '~/utils'

const VIEW_ALLOWED_NETWORKS = [
  's-network-id',
  'profile-strategy-id',
  'leaderboard',
  'index',
  'manager-id',
  'tokens',
  'tokens-symbol',
]

// eslint-disable-next-line no-use-before-define
@Component<DefaultLayout>({
  computed: {
    ...mapState({
      userAddressAgreed: (state: any) => state.app.userAddressAgreed,
      depositDisclaimer: (state: any) => state.app.depositDisclaimer,
    }),
  },

  methods: {
    ...mapMutations('app', ['setInUserAddressAgreed', 'setDepositDisclaimer']),
  },
  async mounted() {
    this.canShowModals = false
    this.supportedNetworkNames = web3Store.chainConfigs.map(
      (item) => item.chainName
    )

    const a = setTimeout(() => {
      this.isReady = true
    }, 5000)

    try {
      await this.login(false)
      this.canShowModals = true
    } catch (e) {
      this.canShowModals = true
      console.error(e)
    }

    this.$root.$on('login', (args: any) => {
      this.$nextTick(() => {
        clearTimeout(a)
        this.isReady = true
      })
      this.login(args).catch((e) => console.error(e))
    })

    this.$nextTick(() => {
      clearTimeout(a)
      this.isReady = true
    })
  },
  beforeDestroy() {
    this.$root.$off('login')
    this.$root.$off('showWalletInfo')
  },
  watch: {
    walletAddress() {
      this.checkbox = false
    },
  },
})
export default class DefaultLayout extends Vue {
  walletInfoUI = false
  providerInfo = {} as IProviderInfo
  changingWallet = false
  checkbox = false
  checkboxDeposit = false
  supportedNetworkNames = ['Mainnet']
  canShowModals = true // Try to show modals only after login process has finished on first load to avoid sudden opening and closing of modals
  isReady = false

  get shouldShowConnectModel() {
    return !VIEW_ALLOWED_NETWORKS.includes(this.$route.name ?? '')
  }

  get incorrectChain() {
    // If user disconnects while this popup is displayed
    if (!web3Store.status.account) return false

    const chainId = web3Store.status.chainId
    const chainIdList = web3Store.chainConfigs.map((item) => item.chainId)

    return !chainIdList.includes(chainId!)
  }

  get walletAddress() {
    return web3Store.status.account
  }

  get errorModel() {
    return web3Store.errorModel
  }

  closeErrorModel() {
    web3Store.closeErrorModel()
  }

  async login(fromConnectButton: boolean) {
    // Check for existing accessible Metamask account
    if (window.ethereum) {
      // Check wallet permissions
      // eslint-disable-next-line camelcase
      const wallet_permissions = await window.ethereum.request({
        method: 'wallet_getPermissions',
      })
      // If we have wallet permissions, attempt to get the user account. NOTE: There may be a better way to handle this.
      if (
        wallet_permissions.length > 0 &&
        wallet_permissions[0].parentCapability === 'eth_accounts' &&
        fromConnectButton === false
      ) {
        if (this.$web3Modal.cachedProvider) {
          // Check if Metamask is locked or unlocked
          const isUnlocked = await window.ethereum._metamask.isUnlocked()
          if (isUnlocked) {
            await this.login_popup()
          }
        }
      } else if (fromConnectButton) {
        // If not already logged in and on page load, do nothing
        // But if not already logged in and is called from the login button, conenct with signin popup
        // If logged in, don't do anything
        if (
          web3Store.status.account &&
          typeof web3Store.status.account === 'string' &&
          web3Store.status.account !== ''
        ) {
          return
        }

        await this.login_popup()
      }
    } else if (fromConnectButton) {
      // No window.ethereum detected
      await this.login_popup()
    }
  }

  async login_popup() {
    console.log('login_popup')

    web3Store.setDisableConnectBtn(true)

    // Get and set the provider
    let provider: any
    try {
      provider = await this.$web3Modal.requestProvider()
      // Check if switching from WalletConnect to Metamask, in which case disconnect or face bugs
      if (
        // this.providerInfo?.name === 'WalletConnect' &&
        // getProviderInfo(provider).name === 'MetaMask'
        this.changingWallet
      ) {
        console.log('Switching wallet condition met')
        this.changingWallet = false
        await this.disconnect()
      }

      this.providerInfo = getProviderInfo(provider)
      web3Store.setStatus({ providerInfo: getProviderInfo(provider) })

      this.$root.provider = provider
    } catch (error: any) {
      if (error?.code === 4001) {
        console.log('User rejected request')
      } else console.log('login_popup>', { error })
      web3Store.setDisableConnectBtn(false)
      return
    }

    const ethersWeb3Provider = new Web3Provider(provider, 'any')

    if (!ethersWeb3Provider) {
      console.error(
        'Got no provider. Probable: Metamask is asking user to enter password when using it after a long time'
      )
      web3Store.setDisableConnectBtn(false)
      return
    }

    this.$root.web3Provider = ethersWeb3Provider

    // Get account from ethers Web3Provider
    const accounts = await ethersWeb3Provider.listAccounts()
    const account = accounts[0]

    web3Store.setStatus({ account })
    //

    // Get and set signer
    const signer = getSigner(ethersWeb3Provider, account)
    this.$root.signer = signer
    // web3Store.setSigner(JSON.parse(JSON.stringify(signer)))

    // Check Network/Chain and set it into state
    // eslint-disable-next-line camelcase
    const currentChain = await ethersWeb3Provider.detectNetwork()
    web3Store.setStatus({
      chainId: currentChain.chainId,
      networkName: currentChain.name,
    })

    this.$root.$emit('login_complete')
    this.fetchName(account).then((chainWiseDomainName) => {
      web3Store.setStatus({ chainWiseDomainName })
    })

    // Subscribe to events
    provider.on('accountsChanged', (accounts: string[]) => {
      if (accounts.length === 0 || accounts[0] === undefined) {
        web3Store.setStatus({
          chainId: null,
          networkName: null,
        })

        this.$root.provider = null
        this.$root.signer = null
        this.walletInfoUI = false

        provider.removeAllListeners() // Manually found through the console using $nuxt.provider

        this.$root.$emit('login_complete') // Trigger an update in pages or components that depend on the wallet connection
      } else {
        web3Store.setStatus({ account: accounts[0] })

        this.$root.$emit('login_complete')
      }
    })

    provider.on('chainChanged', this.onNetworkChange)

    provider.on('close', () => {
      this.$web3Modal.clearCachedProvider()
      web3Store.setStatus({ account: null, chainId: null })
      this.$root.provider = null
      this.$root.signer = null
      this.walletInfoUI = false
    })

    provider.on('disconnect', () => {
      this.$web3Modal.clearCachedProvider()
      web3Store.setStatus({ account: null, chainId: null })

      this.$root.provider = null
      this.$root.signer = null
      this.walletInfoUI = false
    })

    if (ethersWeb3Provider.listeners().length === 0) {
      ethersWeb3Provider.on('block', () => {
        this.$root.$emit('block')
        web3Store.incrementBlockChangeCount()
      })
    }

    web3Store.setDisableConnectBtn(false)
  }

  async disconnect() {
    // Doesn't work for Metamask (User needs to manually disconnect). Works for WalletConnect
    const provider = this.$root.provider
    this.providerInfo = {} as IProviderInfo
    web3Store.setStatus({ providerInfo: null })
    if (provider.disconnect) {
      await provider.disconnect()
    } else if (provider.close) {
      await provider.close()
    }
  }

  async onNetworkChange() {
    // eslint-disable-next-line camelcase
    const currentChain = await this.$root.web3Provider?.detectNetwork()

    web3Store.setStatus({
      chainId: currentChain?.chainId,
      networkName: currentChain?.name,
    })

    this.$root.$emit('login_complete')

    this.$nextTick(() => {
      if (!['farm', ...VIEW_ALLOWED_NETWORKS].includes(this.$route.name ?? ''))
        this.$router.push('/')
    })
  }

  async fetchName(address: string) {
    let data = {}

    try {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const SID = require('@siddomains/sidjs').default
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const SIDfunctions = require('@siddomains/sidjs')

      const networks = [web3Store.network!]

      const web3Provider = this.$root.web3Provider

      const names = await Promise.all(
        networks.map(async (network) => {
          const sid = new SID({
            provider: web3Provider,
            sidAddress: SIDfunctions.getSidAddress(
              getChainId(network as string)
            ),
          })

          try {
            return {
              name: (await sid.getName(address))?.name ?? null,
              network,
            }
          } catch (error) {
            return { network }
          }
        })
      )

      data = names.reduce((obj, { name, network }) => {
        obj[network] = name
        return obj
      }, {} as any)
    } catch (error) {
      // data = {
      //   bsc: 'test1',
      //   arbitrum: 'test2',
      // }
    }

    return { ...web3Store.status.chainWiseDomainName, ...data }
  }

  changeWallet() {
    this.changingWallet = true
    this.walletInfoUI = false
    this.$web3Modal.clearCachedProvider()
    this.login_popup()
  }
}
