import Axios, { CancelToken } from 'axios'

import { web3Store } from '~/store'
import { Dex, Network } from '~/types/web3'

const axios = Axios.create({
  baseURL: process.env.API_URL,
})

export type Liquidity = {
  amount0: number
  amount1: number
  unusedAmount0: number
  unusedAmount1: number
  amount0Total: number
  amount1Total: number
  unusedAmount0BigNumber: unknown
  unusedAmount1BigNumber: unknown
}
type Fees = {
  fee0: number
  fee1: number
  amount0: number
  amount1: number
}

interface StrategyDetails {
  token0Price: number
  token1Price: number
  sharePrice: number
  aum: number
  totalSupply: number
  managementFee: number
  performanceFee: number
  fees: Fees
  strategyLimit: number
}

export function details(
  network: Network,
  strategyId: string
): Promise<StrategyDetails> {
  return axios.get(`${network}/${strategyId}/details`).then(({ data }) => data)
}

export function fees(network: Network, strategyId: string): Promise<Fees> {
  return axios.get(`${network}/${strategyId}/fees`).then(({ data }) => data)
}

export function liquidity(
  network: Network = web3Store.network!,
  strategyId: string
): Promise<Liquidity> {
  return axios
    .get(`${network}/${strategyId}/liquidity`)
    .then(({ data }) => data)
}

export function whitelistedAddress(
  network: Network = web3Store.network!,
  strategyId: string,
  type: string
): Promise<{ whitelistedAddresses: string[]; isStrategyPrivate: boolean }> {
  return axios
    .get(`${network}/${strategyId}/${type}/whitelistedAddresses`)
    .then(({ data }) => data)
}

export function sharePrice(
  network: Network,
  strategyId: string
): Promise<{ sharePrice: number }> {
  return axios
    .get(`${network}/${strategyId}/sharePrice`)
    .then(({ data }) => data)
}

export function rangesFees(
  network: Network,
  strategyId: string,
  {
    withAmount = false,
    withPositionInfo = false,
  }: {
    withAmount?: boolean
    withPositionInfo?: boolean
  } = {}
): Promise<{
  ranges: {
    tokensOwed0: number
    tokensOwed1: number
    tickLower: number
    tickUpper: number
    amount0: { type: 'BigNumber'; hex: string }
    amount1: { type: 'BigNumber'; hex: string }
    lowerBound: number
    upperBound: number
  }[]
}> {
  return axios
    .get(
      `${network}/${strategyId}/rangesFees?withAmount=${withAmount}&withPositionInfo=${withPositionInfo}`
    )
    .then(({ data }) => data)
}

export function currentLimit(
  network: Network,
  strategyId: string
): Promise<{ currentLimit: number }> {
  return axios
    .get(`${network}/${strategyId}/currentLimit`)
    .then(({ data }) => data)
}

interface Token {
  id: string
  decimals: string
  name: string
  symbol: string
  totalSupply: string
  __typename: string
}

export interface FarmTransaction {
  address: string
  archived: boolean
  dataFeed: string
  description: string
  dex: string
  id: string
  network: string
  private: boolean
  subTitle: string
  title: string
  verified: boolean
  pool: string
  token0: Token
  token1: Token
  amount0: number
  amount1: number
  profit: number
  holdingDE: number
  mintedDE: number
  burntDE: number
  amount0USD: number
  amount1USD: number
  tokenPairLabel: string
  deposited: number
  farmStats: {
    apr: number
    totalStake: number
    stakedBalance: number
    rewardRate: number
    stakeSymbol: string
  } | null
}
interface StrategyFarmTransaction {
  strategies: FarmTransaction[]
}

type StrategyParams = {
  address: string
  network?: Network
  dex?: Dex
  cancelToken?: CancelToken
}

export function portfolio({
  address,
  network = web3Store.currentChain?.network,
  dex = web3Store.dex,
  cancelToken,
}: StrategyParams): Promise<StrategyFarmTransaction['strategies']> {
  return axios
    .get<StrategyFarmTransaction>(`/${address}/${network}/${dex}/portfolio`, {
      cancelToken,
    })
    .then(({ data }) => data.strategies)
}
