// import { formatUnits, parseUnits } from '@ethersproject/units'
// import { Strategy } from '~/types'
// import { useStrategyContract, useTokenContract } from '~/utils/useContract'

class Aggregator {
  // StrategyLiquidity(strategy: Strategy) {
  //   throw new Error('Method not implemented.')
  // }
  // public async IncreaseLiquidity(
  //   strategy: Strategy,
  //   amount0: string,
  //   amount1: string,
  //   amount0Min: string = '0',
  //   amount1Min: string = '0'
  // ): Promise<void | null> {
  //   try {
  //     const contract = useStrategyContract(strategy?.address)
  //     if (!contract) return null
  //     if (
  //       parseInt(strategy?.token0.address ?? '', 16) >
  //       parseInt(strategy?.token1.address ?? '', 16)
  //     ) {
  //       amount1 = [amount0, (amount0 = amount1)][0]
  //     }
  //     console.log(contract)
  //     console.log({
  //       strategy: strategy?.address,
  //       sAmount0: amount0,
  //       sAmount1: amount1,
  //       amount0: parseUnits(amount0, strategy?.token0.decimals),
  //       amount1: parseUnits(amount1, strategy?.token1.decimals),
  //       amount0Min: parseUnits(amount0Min, strategy?.token0.decimals),
  //       amount1Min: parseUnits(amount1Min, strategy?.token0.decimals),
  //     })
  //     return await contract.mint(
  //       parseUnits(amount0, strategy?.token0.decimals),
  //       parseUnits(amount1, strategy?.token1.decimals),
  //       parseUnits(amount0Min, strategy?.token0.decimals),
  //       parseUnits(amount1Min, strategy?.token1.decimals),
  //       0
  //     )
  //   } catch (error) {
  //     throw new Error(error)
  //   }
  // }
  // public async DecreaseLiquidity(
  //   strategy: Strategy,
  //   shares: string,
  //   amount0Min: string = '0',
  //   amount1Min: string = '0'
  // ) {
  //   try {
  //     const contract = useStrategyContract(strategy.address)
  //     if (!contract) return null
  //     console.log({
  //       strategy: strategy?.address,
  //       shares: parseUnits(shares),
  //       sharess: shares,
  //       amount0Min: parseUnits(amount0Min, strategy?.token0.decimals),
  //       amount1Min: parseUnits(amount1Min, strategy?.token1.decimals),
  //     })
  //     return await contract.burn(
  //       parseUnits(shares),
  //       parseUnits(amount0Min, strategy?.token0.decimals),
  //       parseUnits(amount1Min, strategy?.token1.decimals)
  //     )
  //   } catch (error) {
  //     throw new Error(error)
  //   }
  // }
  // public async StrategyLiquidity(strategy: Strategy) {
  //   try {
  //     const aggregator = useStrategyContract(strategy.address)
  //     if (!aggregator) return null
  //     const token0 = useTokenContract(strategy.token0.address)
  //     const token1 = useTokenContract(strategy.token1.address)
  //     const unusedAmount0 = await token0?.balanceOf(strategy.address)
  //     const unusedAmount1 = await token1?.balanceOf(strategy.address)
  //     // let orders: any
  //     // returns [[amount0, amount1, tickLower, tickUpper], ...]
  //     // orders = await aggregator.getTicks(strategy.address)
  //     return {
  //       amount0: Number(formatUnits(amount0, strategy?.token0.decimals)),
  //       amount1: Number(formatUnits(amount1, strategy?.token1.decimals)),
  //       unusedAmount0: Number(
  //         formatUnits(unusedAmount0, strategy?.token0.decimals)
  //       ),
  //       unusedAmount1: Number(
  //         formatUnits(unusedAmount1, strategy?.token1.decimals)
  //       ),
  //     }
  //   } catch (error) {}
  // }
  // public async StrategyShares(strategyAddress: string) {
  //   try {
  //     const strategy = useStrategyContract(strategyAddress)
  //     if (!strategy) return null
  //     const totalShares = await strategy.balanceOf(strategyAddress)
  //     console.log({
  //       totalShares: Number(formatUnits(totalShares)),
  //     })
  //     return {
  //       totalShares: Number(formatUnits(totalShares)),
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
}

export default Aggregator
