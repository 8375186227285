export const FIX_FOR_TWITTER: { [key: string]: string } = {
  LunarCRUSH: 'LunarCrush',
}

export const FIX_FOR_TOKEN_SYMBOL: { [key: string]: string } = {
  eLunr: 'Lunr',
}

export const FIX_FOR_TOKEN_NAME: { [key: string]: string } = {
  'Ethereum-bridged Lunr': 'Lunr',
  'Ethereum-bridged Lunr Token': 'Lunr',
}

export const fixTokenSymbol = (token: string) =>
  FIX_FOR_TOKEN_SYMBOL[token] || token

export const fixTokenName = (token: string) =>
  FIX_FOR_TOKEN_NAME[token] || token

export const fixForTwitter = (name: string) => FIX_FOR_TWITTER[name] || name
