import { formatUnits } from '@ethersproject/units'
import Vue from 'vue'

import { fixTokenName, fixTokenSymbol } from '~/utils/uiFix'

Vue.filter('formatUnits', (a: number | string) => {
  const amount = a.toString()
  return formatUnits(amount)
})

Vue.filter('truncateAddress', (a: string) => {
  return a.slice(0, 8) + '...' + a.slice(-8)
})

export const convertScientificNotationNumber = (value: string | number) => {
  const decimalsPart = value?.toString()?.split('.')?.[1] || ''
  const eDecimals = Number(decimalsPart?.split('e-')?.[1]) || 0
  const countOfDecimals = decimalsPart.length + eDecimals

  return Number(value)
    .toFixed(countOfDecimals)
    .replace(/(\.0+|0+)$/, '')
}

Vue.directive('copy-text', {
  unbind(el) {
    if ((el as any).copyTextClickEventListener)
      el.addEventListener('click', (el as any).copyTextClickEventListener)
  },
  bind(el: any, { value, modifiers }: any) {
    const { text, onCopied }: any =
      typeof value === 'string' ? { text: value } : value ?? {}

    ;(el as any).copyTextClickEventListener = function (ev: Event) {
      const el = document.createElement('textarea')
      el.value = text
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      onCopied?.()

      if (modifiers.prevent) {
        ev.preventDefault()
      }

      if (modifiers.stop) {
        ev.stopPropagation()
      }
    }

    el.addEventListener('click', (el as any).copyTextClickEventListener, {
      capture: modifiers.capture,
    })
  },
})

export const toFixed = (
  n: number | string,
  decimals = 3,
  decimalsForLessThanOne = 6,
  singleDigitNumber?: number,
  useInfinity?: boolean
) => {
  const num = Number(n)

  if (useInfinity && num) {
    if (+num > Number.MAX_SAFE_INTEGER) return '∞'
    if (num <= 2.93954e-24) return '-∞'
  }

  if (singleDigitNumber)
    decimals = Math.abs(num) < 10 ? singleDigitNumber : decimals

  if (Math.abs(+num.toFixed(decimals)) === 0)
    return (
      convertScientificNotationNumber(num.toFixed(decimalsForLessThanOne)) ||
      '0'
    )

  return +num.toFixed(decimals)
}

Vue.filter('toFixed', toFixed)

export const Abbreviate = (num: number, fixed: number, html = false) => {
  if (num === null || num === undefined) {
    return ''
  } // terminate early

  if (+num === 0) {
    return '0'
  } // terminate early

  fixed = !fixed || fixed < 0 ? 0 : fixed // number of decimal places to show
  const b = num.toPrecision(2).split('e') // get power
  // @ts-ignore
  const k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3) // floor at decimals, ceiling at trillions
  const c =
    k < 1
      ? num.toFixed(0 + fixed)
      : (num / Math.pow(10, k * 3)).toFixed(1 + fixed) // divide by power
  // @ts-ignore
  const d = c < 0 ? c : Math.abs(c) // enforce -0 is 0
  const sufix = ['', 'K', 'M', 'B', 'T'][k]

  const e = d + (html ? `<span>${sufix}<span/>` : sufix) // append power
  return e
}

export const toCompact = (
  num: number,
  digits = 2,
  locale = 'en-US',
  afterZero = 5,
  useInfinity?: boolean
) => {
  if (useInfinity && num) {
    if (num > Number.MAX_SAFE_INTEGER) return '∞'
    if (num <= 2.93954e-24) return '-∞'
  }

  return Intl.NumberFormat(locale, {
    notation: 'compact',
    maximumFractionDigits: Math.abs(num) < 10 ? afterZero : digits,
  }).format(num)
}

Vue.filter('Abbreviate', Abbreviate)

Vue.filter('compact', toCompact)

Vue.filter(
  'toCurrency',
  (num: any, returnInToken?: string, noFix?: boolean, noCompact?: boolean) => {
    const number = !+num
      ? 0
      : noFix
      ? num
      : noCompact
      ? toFixed(num, num > 1e5 ? 1 : 2)
      : toCompact(num)

    return !+num
      ? 0
      : !returnInToken || returnInToken === 'USD'
      ? `$${number}`
      : `${number} ${returnInToken}`
  }
)
Vue.filter('fixTokenName', fixTokenName)
Vue.filter('fixTokenSymbol', fixTokenSymbol)

Vue.directive('numeric-input', {
  bind(el: any) {
    el.addEventListener('keypress', function (evt: any) {
      evt = evt || window.event
      const expectedValue = (el as HTMLInputElement).value + evt.key
      if (evt.key.match(/^\d|\.$/) && expectedValue.match(/^\d+(.\d{0,12})?$/))
        return true
      else evt.preventDefault()
    })
    el.addEventListener('input', function () {
      // when values are directly pasted in text box
      if (!el.value.match(/^\d+(.\d{0,12})?$/)) el.value = ''
    })
  },
})
