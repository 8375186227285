import { Web3Provider } from '@ethersproject/providers'

import { AGGREGATOR_ADDRESS, QUOTER_ADDRESS } from '~/constants'
import {
  AGGREGATOR_ABI,
  CAMELOT_STRATEGY_ABI,
  ERC20_ABI,
  FACTORY_ABI,
  FACTORY_TWAP_ABI,
  KEEPERS_ABI,
  KEEPERS_RESOLVER_FACTORY_ABI,
  MIGRATOR_ABI,
  MIGRATOR_TWAP_ABI,
  MINI_CHEF_V2,
  MULTI_REWARDS_STAKING_ABI,
  NONFUNGIBLE_POSITION_MANAGER_ABI,
  OPS_ABI,
  ORACLE_ABI,
  POKE_ME_ABI,
  POOL_ABI,
  QUOTER_ABI,
  RESOLVER_ABI,
  RESOLVER_FACTORY_ABI,
  STRATEGY_ABI,
  STRATEGY_MANAGER_ABI,
  STRATEGY_TWAP_ABI,
  TASK_TREASURY_ABI,
  TICK_LENS_ABI,
  RAMSES_VENFT_ABI,
  RAMSES_POOL_ABI,
} from '~/constants/abis'
import { LimitOrderProvider } from '~/constants/addresses'
import {
  getKeepersRegistrarAddress,
  getLinkTokenAddress,
  getMigratorAddress,
  getPokeMeAddress,
  getQuoterAddress,
  getResolverFactoryAddress,
  getTaskTreasuryAddress,
  getTickLensAddress,
} from '~/constants/config.helper'
import { web3Store } from '~/store'
import { Dex, Network, NetworkEnum } from '~/types/web3'
import { getContract } from '~/utils'

const RAMSES_VENFT = '0xaaa343032aa79ee9a6897dab03bef967c3289a06'

export function useContract(
  address: string | undefined,
  ABI: any,
  withSigner = true
) {
  if (!address || !ABI) return null

  try {
    return getContract(
      address,
      ABI,
      window.$nuxt.web3Provider as Web3Provider, // @ts-ignore
      withSigner && web3Store.status.account
        ? web3Store.status.account
        : undefined
    )
  } catch (error) {
    console.warn('Failed to get contract', error)
    return null
  }
}

export const useAggregatorContract = (withSigner = true) =>
  useContract(AGGREGATOR_ADDRESS, AGGREGATOR_ABI, withSigner)

export const useFactoryContract = (
  address: string,
  withSigner = true,
  useTwap: boolean
) => useContract(address, useTwap ? FACTORY_TWAP_ABI : FACTORY_ABI, withSigner)

export function useQuoterContract(withSigner = true) {
  return useContract(
    getQuoterAddress(false) ?? QUOTER_ADDRESS,
    QUOTER_ABI,
    withSigner
  )
}

export function useRamsesVeNftContract(withSigner = true) {
  return useContract(RAMSES_VENFT, RAMSES_VENFT_ABI, withSigner)
}

export function useRamsesPoolContract(pool: string, withSigner = true) {
  return useContract(pool, RAMSES_POOL_ABI, withSigner)
}

const v2Map: Partial<Record<Dex, true | Partial<Record<Network, true>>>> = {
  [Dex.Camelot]: true,
  [Dex.Stellaswap]: true,
  [Dex.Thena]: true,
  [Dex.Fusionx]: true,
  [Dex.Pangolin]: true,
  [Dex.Retro]: true,
  [Dex.Horiza]: true,
  [Dex.Baseswap]: true,
  [Dex.Ramses]: true,
  [Dex.Uniswap]: {
    [NetworkEnum.base]: true,
  },
  [Dex.Pancakeswap]: {
    [NetworkEnum.arbitrum]: true,
    [NetworkEnum.base]: true,
    [NetworkEnum.zksyncEra]: true,
  },
}

export const isV2 = () => {
  const dexValue = v2Map[web3Store.dex]

  if (dexValue === true) return true
  else if (dexValue && dexValue[web3Store.network!]) {
    return dexValue[web3Store.network!] === true
  } else return false
}

export function useStrategyContract(address: string, useTwap: boolean) {
  return useContract(
    address,
    isV2() ? CAMELOT_STRATEGY_ABI : useTwap ? STRATEGY_TWAP_ABI : STRATEGY_ABI
  )
}

export function useLimitOrderResolverContract(address: string) {
  return useContract(address, RESOLVER_ABI)
}

export function useStrategyManagerContract(address: string) {
  return useContract(address, STRATEGY_MANAGER_ABI)
}

export function useChainLinkOracleContract(address: string) {
  return useContract(address, ORACLE_ABI)
}

export function usePoolContract(address: string, withSigner?: boolean) {
  return useContract(address, POOL_ABI, withSigner)
}

export function useTokenContract(tokenAddress?: string) {
  return useContract(tokenAddress, ERC20_ABI)
}

export function useNonfungiblePositionManagerContract(tokenAddress?: string) {
  return useContract(tokenAddress, NONFUNGIBLE_POSITION_MANAGER_ABI)
}

export function usePokeMeContract(twap: boolean) {
  return useContract(getPokeMeAddress(twap)!, POKE_ME_ABI)
}

export function useOpsContract(address?: string, twap?: boolean) {
  address = address ?? getPokeMeAddress(!!twap)!
  return useContract(address, OPS_ABI)
}

export function useKeepersContract(address?: string, twap?: boolean) {
  address = address ?? getKeepersRegistrarAddress(!!twap)!

  return useContract(address, KEEPERS_ABI)
}

export function useTickLens(address?: string) {
  address = address ?? getTickLensAddress()!

  return useContract(address, TICK_LENS_ABI)
}

export function useLinkContract() {
  const address = getLinkTokenAddress()
  if (!address) return null

  return useContract(address, [
    {
      name: 'transferAndCall',
      inputs: [
        { internalType: 'address', name: '_to', type: 'address' },
        { internalType: 'uint256', name: '_value', type: 'uint256' },
        { internalType: 'bytes', name: '_data', type: 'bytes' },
      ],
      type: 'function',
      outputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }],
      stateMutability: 'payable',
    },
  ])
}

export function useTaskTreasuryContract(twap: boolean) {
  return useContract(getTaskTreasuryAddress(twap)!, TASK_TREASURY_ABI)
}

export const useResolverFactoryContract = <T extends LimitOrderProvider>(
  twap: boolean,
  type: T
) =>
  useContract(
    getResolverFactoryAddress(twap, type)!,
    type === 'Gelato' ? RESOLVER_FACTORY_ABI : KEEPERS_RESOLVER_FACTORY_ABI
  )

export function useLiquidityMiningContract(liquidityMiningAddress: string) {
  return useContract(liquidityMiningAddress, MULTI_REWARDS_STAKING_ABI)
}

export function useMiniChefV2Contract(liquidityMiningAddress: string) {
  return useContract(liquidityMiningAddress, MINI_CHEF_V2)
}

export const useMigratorContract = (
  twap: boolean,
  address: string | null = getMigratorAddress(twap)
) => useContract(address ?? undefined, twap ? MIGRATOR_TWAP_ABI : MIGRATOR_ABI)
