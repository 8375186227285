import { ChainId, Dex } from '~/types/web3'

export type Addresses = {
  staging?: string
  default: string
}

export type _AddressConfig = Addresses & {
  twap?: Addresses | string
}

export type AddressConfig =
  | (_AddressConfig & {
      [key in Exclude<Dex, 'Uniswap'>]?: _AddressConfig | string
    })
  | string

export type LimitOrderConfig = {
  resolverFactoryAddress: AddressConfig
  pokeMeAddress?: AddressConfig
  taskTreasuryAddress?: AddressConfig
  registrarAddress?: AddressConfig
  registryAddress?: AddressConfig
}

export type LimitOrderProvider = 'Gelato' | 'Keeper'

export type Config = {
  defiEdge: {
    factoryAddress: AddressConfig
    oracleAddress: AddressConfig
    chainLinkRegistryAddress: AddressConfig
    liquidityHelperAddress: AddressConfig
    migratorAddress?: AddressConfig
    positionKeyHelper?: AddressConfig
    swapProxy?: AddressConfig
    quoter?: AddressConfig
  }
  others: { linkToken?: AddressConfig; tickLens?: AddressConfig }
} & Record<`limitOrder${LimitOrderProvider}`, LimitOrderConfig>

const addressConfig: Record<ChainId, Config> = {
  [ChainId.arbitrum]: {
    defiEdge: {
      factoryAddress: {
        default: '0x7d55d4ff054f5e2b748ea2cf0765fbfba7ae222f',
        staging: '0x771229e712527af2f4a51f2506d7c25f4f2c777a',
        twap: {
          default: '0x4082c6aef2cc2e478e624f6ac89dd6e8e0483b90',
          staging: '0x4082c6aef2cc2e478e624f6ac89dd6e8e0483b90',
        },
        [Dex.Arbidex]: {
          default: '0xbfba325437051a29ea1230de05b6020ea3e47979',
          staging: '0xbfba325437051a29ea1230de05b6020ea3e47979',
          twap: {
            default: '0xbd4e23278b01ceb2dc18ea94ba7f04c5031c5283',
            staging: '0xbd4e23278b01ceb2dc18ea94ba7f04c5031c5283',
          },
        },
        [Dex.Camelot]: {
          default: '0x8aA209415b5fA0BB3ffA93b80Dd96b93185F934c',
          staging: '0x8aA209415b5fA0BB3ffA93b80Dd96b93185F934c',
          twap: {
            default: '0x5C67032e4ea64B2118f208C797E837732B92aa3C',
            staging: '0x5C67032e4ea64B2118f208C797E837732B92aa3C',
          },
        },
        [Dex.Sushiswap]: {
          default: '0x5015422641c162221fa35bbb60bb13c744f7ac7a',
          staging: '0x5015422641c162221fa35bbb60bb13c744f7ac7a',
          twap: {
            default: '0x95b0575c803e5eec7b3c37338817822659a6ac84',
            staging: '0x95b0575c803e5eec7b3c37338817822659a6ac84',
          },
        },
        [Dex.Ramses]: {
          default: '0xcc4C4d7F487DE08Ea11D13a0550D45093cCD6a7a',
          staging: '0xcc4C4d7F487DE08Ea11D13a0550D45093cCD6a7a',
          twap: {
            default: '0xD5B02244d23Ccca988B1163525d10BACf0f280b7',
            staging: '0xD5B02244d23Ccca988B1163525d10BACf0f280b7',
          },
        },
        [Dex.Pancakeswap]: {
          default: '0xBc805030469c36A483713EFAf9fFC8f0E8a81aeD',
          twap: '0x913d1Cc7b0F7946e3724cC59DEd7E172201EAc91',
        },
        [Dex.Horiza]: {
          default: '0x935b5815E77F06AC88c509ef16729aff30628815',
          twap: '0x70ac9F15651721E56DDc6eD7d053f5722aA949c7',
        },
      },
      migratorAddress: {
        default: '0xe09346241c8d52836e7410f43ff6345baae352a9',
        staging: undefined,
      },
      chainLinkRegistryAddress: '0xe43b4cde662165ba1f646022e7d41aade8993b11',
      oracleAddress: '0x5bb95d1b824bddd1e7fbf6e011ed3bc21dcbcf11',
      liquidityHelperAddress: {
        default: '0x61622417f3c3a7f6b29784ec1a5297bae6eeafc4',
        [Dex.Camelot]: '0x44ef745AF9653bc308FFFaE06a6f79f3A47713c4',
        [Dex.Ramses]: '0xdD06288279528593Db866Ba44d1D18494C6fAa1f',
        [Dex.Pancakeswap]: '0x85Ab2Eda11bC0cb73Cf798d76A1F2498CF60C841',
        [Dex.Horiza]: '0x72239eC62DfE283EAeEBcE8FFF4e41DC75A9147e',
      },
      positionKeyHelper: {
        default: '',
        [Dex.Camelot]: '0x8aC8c590f502c52F0933417ADF599E80354E8fb5',
        [Dex.Pancakeswap]: '0x649b92e970181eEbAf73EE100fE41CB4AD7d06Ba',
        [Dex.Ramses]: '0x84fe7A22f1113778Be5495eBEfF76483d6854099',
        [Dex.Horiza]: '0x3279097A7B4B335f2a9c65d14FCFafc179B94c33',
      },
      swapProxy: {
        default: '',
        [Dex.Ramses]: {
          default: '0xAFDE003451d1A7600f24729769635f0254092645',
          twap: '0x94EA2fD1905487365DBd73C556F01B46058935bd',
        },
        [Dex.Camelot]: {
          default: '0xfB2aA971867b4448da1cABd7865c30120af3EE80',
          twap: '0x19A73fecDa24318b86eAB237679963cF69b39E8f',
        },
        [Dex.Horiza]: {
          default: '0x5F4FbC0dA65975c8b39C7b4E8178d2a88460f5A2',
          twap: '0x795aE25C94555Ad780049c346000E4EA89d765dD',
        },
      },
    },
    limitOrderGelato: {
      pokeMeAddress: '0xb3f5503f93d5ef84b06993a1975b9d21b962892f',
      resolverFactoryAddress: {
        default: '0x6acf62296051c21562883064c378ca6a2ce62c14',
        staging: '0x4f3f2f7421e12f285dcf4cbe4e52fb1aed1105e3',
        twap: {
          default: '0x81627dcef07384f4b9311427a1922a16b7ed5eff',
          staging: '0xdfeec9b7737e6b1e435e03eeb2741ed6ef8a9ad9',
        },
        [Dex.Arbidex]: {
          default: '0x61C2b3e5948D0eFDEb6d5E6C119218B1422beBDd',
          twap: '0x3A6b7A328c248dEAe7D3389427775C76A79db484',
        },
        [Dex.Camelot]: {
          default: '0x0fba2e4a96e32bd67b4b1ad392fbb2c28acbe1ac',
          twap: '0x3a97f824bf44ee0b95e7bde5cb76132be0389ac7',
        },
      },
      taskTreasuryAddress: '0x527a819db1eb0e34426297b03bae11f2f8b3a19e',
    },
    limitOrderKeeper: {
      registryAddress: '0x75c0530885f385721fdda23c539af3701d6183d4',
      registrarAddress: '0x4f3af332a30973106fe146af0b4220bbbea748ec',
      resolverFactoryAddress: {
        default: '0x848ab8964d0bc53a53b37ec88691ad56400adcfb',
        staging: '0x1e9daaa6057fc3d6b50abce0cd8c8dafa3419342',
        [Dex.Arbidex]: {
          default: '',
        },
        [Dex.Camelot]: {
          default: '',
        },
      },
    },
    others: {
      tickLens: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
      linkToken: '0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
    },
  },
  [ChainId.polygon]: {
    defiEdge: {
      factoryAddress: {
        default: '0x49c868a7710bf4d91a77a035324ed34d2886c41c',
        staging: '0x6ed18424236977f137ba96af7e7e0ee26b17f52a',
        twap: {
          default: '0xf9654b734bf76069b04d7efd9bf2268691a0ff00',
          staging: '0xf9654b734bf76069b04d7efd9bf2268691a0ff00',
        },
        [Dex.Sushiswap]: {
          default: '0xd510A6Ad3549d4862339Cc54bb649706D2885a28',
          staging: '0xd510A6Ad3549d4862339Cc54bb649706D2885a28',
          twap: {
            default: '0x0bcEAB2572F9EE57ca84031E2c19Dbd2B98A7e9d',
            staging: '0x0bcEAB2572F9EE57ca84031E2c19Dbd2B98A7e9d',
          },
        },
        [Dex.Retro]: {
          default: '0x7fBf00017c094e6b9398e27A105EcA71D3D2ACbB',
          staging: '0x7fBf00017c094e6b9398e27A105EcA71D3D2ACbB',
          twap: {
            default: '0x053Ca1F70fF5506DB5004DfFA901064cBc4A2F7A',
            staging: '0x053Ca1F70fF5506DB5004DfFA901064cBc4A2F7A',
          },
        },
      },
      chainLinkRegistryAddress: '0xe7edf6ed8197247589a9c59510e428e6955f8207',
      oracleAddress: '0x3989f5cc5fcccdc62891f5f513a6fbb58e602390',
      liquidityHelperAddress: {
        default: '0x8ec08892a88506d2ce679e1cf83bfbbe03b3a835',
        [Dex.Retro]: {
          default: '0x84C51D6eA7853dcE4496f10e55D03eD1c7c2553F',
          twap: {
            default: '0x84C51D6eA7853dcE4496f10e55D03eD1c7c2553F',
          },
        },
      },
      migratorAddress: {
        default: '0xe09346241c8d52836e7410f43ff6345baae352a9',
        staging: undefined,
      },
      positionKeyHelper: {
        default: '',
        [Dex.Retro]: {
          default: '0xbDF46441E57Cb9b068f9Fa63e6840Acf8dD8Ac32',
        },
        [Dex.Thena]: {
          default: '0xeFccEEAD2Ee62Ddeb0f463ee49C4D5E48e29c7A6',
        },
      },
    },
    limitOrderGelato: {
      pokeMeAddress: '0x527a819db1eb0e34426297b03bae11f2f8b3a19e',
      taskTreasuryAddress: '0xa8a7bbe83960b29789d5cb06dcd2e6c1df20581c',
      resolverFactoryAddress: {
        default: '0x7d8aa0815d8fc9b8b87144beada801c5afbc86ba',
        staging: '0x41bc13b094bdcec0817491b6761f61d2d6e5a0e2',
        twap: {
          default: '0x545bda75bc944b47767d1d13ca5e0cd1bf091b2f',
          staging: '0xc18f2b1d56a4b958594ffde81b507f6300b0c380',
        },
      },
    },
    limitOrderKeeper: { resolverFactoryAddress: '' },
    others: {
      linkToken: '',
      tickLens: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573 ',
    },
  },
  [ChainId.mainnet]: {
    defiEdge: {
      factoryAddress: {
        default: '0xa631c80f5f4739565d8793cab6fd08812ce3337d',
        staging: '0x22462fa03b4a7bb744c8a25f2b6d85d8dd2987ad',
        twap: {
          default: '0xeeff8227ed1600e59787d9767485ba905f2f07c3',
          staging: '0x055405f46344447efd7f771c747a5d09a81413fd',
        },
      },
      oracleAddress: '0x748b4404dccef46a51dba99271e4150cfc6737ec',
      chainLinkRegistryAddress: '0xb89319a57fc0e542992cc23c1a4c97832c67700e',
      liquidityHelperAddress: '0x34e001532d9bb140d43ee5a6abc7b790da85d540',
      migratorAddress: {
        default: '0xe09346241c8d52836e7410f43ff6345baae352a9',
        twap: '0x63be5cebcffa32b1dfd344562d8c5a06bcf051ef',
        staging: undefined,
      },
    },
    limitOrderKeeper: {
      resolverFactoryAddress: '',
      pokeMeAddress: '',
      taskTreasuryAddress: '',
    },
    limitOrderGelato: {
      pokeMeAddress: '0xb3f5503f93d5ef84b06993a1975b9d21b962892f',
      taskTreasuryAddress: '0x2807b4ae232b624023f87d0e237a3b1bf200fd99',
      resolverFactoryAddress: {
        default: '0xc6364f745be7dbe6c1ccc3b39d708edfeafed54f',
        twap: '0xb8f6b698e77955f95d3a83c2249bbdc52398f10f',
      },
    },
    others: { linkToken: '' },
  },
  [ChainId.rinkeby]: {
    defiEdge: {
      factoryAddress: '',
      oracleAddress: '',
      chainLinkRegistryAddress: '',
      liquidityHelperAddress: '',
    },
    limitOrderGelato: { resolverFactoryAddress: '' },
    limitOrderKeeper: { resolverFactoryAddress: '' },
    others: { linkToken: '' },
  },
  [ChainId.optimism]: {
    defiEdge: {
      factoryAddress: {
        default: '0x106a524c49b924a92194f6fe4f3da81c2d42e607',
        staging: '0x96123f8480d565c8946023892831bf4bb40dcc87',
        twap: {
          default: '0xe1fd038cbe37475faab39c9d676d9daeaa47da0c',
          staging: '0xe1fd038cbe37475faab39c9d676d9daeaa47da0c',
        },
      },
      oracleAddress: '0x5bb95d1b824bddd1e7fbf6e011ed3bc21dcbcf11',
      chainLinkRegistryAddress: '0x7deb95eb81b25e50400271b478b5766bf74579c2',
      liquidityHelperAddress: '0xe43b4cde662165ba1f646022e7d41aade8993b11',
      migratorAddress: {
        default: '0xe09346241c8d52836e7410f43ff6345baae352a9',
        staging: undefined,
      },
    },
    limitOrderGelato: {
      resolverFactoryAddress: {
        default: '0x58b8062d2c4966c70c905c0e8d1655d0986c3cdc',
        staging: '0xa0eaf1f7b8f386c8a1ca43995130209fae3f6c71',
        twap: {
          default: '0xeaaeeb43470a9cf8fa7f0f5495e65a03424ab661',
          staging: '0xb74fa1bbae6aa9294658ac5a2773de6f406d3841',
        },
      },
      pokeMeAddress: '0x340759c8346a1e6ed92035fb8b6ec57ce1d82c2c',
      taskTreasuryAddress: '0xb3f5503f93d5ef84b06993a1975b9d21b962892f',
    },
    limitOrderKeeper: {
      resolverFactoryAddress: '0xae25ad53b387504b879d04bc731ead01079371c9',
      registryAddress: '0x75c0530885f385721fdda23c539af3701d6183d4',
      registrarAddress: '0x4f3af332a30973106fe146af0b4220bbbea748ec',
    },
    others: { linkToken: '0x350a791bfc2c21f9ed5d10980dad2e2638ffa7f6' },
  },
  [ChainId.kovan]: {
    defiEdge: {
      factoryAddress: '',
      oracleAddress: '',
      chainLinkRegistryAddress: '',
      liquidityHelperAddress: '',
    },
    limitOrderGelato: { resolverFactoryAddress: '' },
    limitOrderKeeper: { resolverFactoryAddress: '' },
    others: { linkToken: '' },
  },
  [ChainId.goerli]: {
    defiEdge: {
      factoryAddress: {
        default: '0xdc331e026c5ca66463ac51833746a85e905988c5',
        staging: '0xdc331e026c5ca66463ac51833746a85e905988c5',
      },
      oracleAddress: '0xaa5b9f6a0e1cfc8b789f4e2f2fb0c51754874c0a',
      chainLinkRegistryAddress: '0x95e161297bb3ff37398197ecca8bcd652ecf1970',
      liquidityHelperAddress: '0x069182ebf79ca52b8cd11707fc10710a6ec8d9ea',
    },
    limitOrderGelato: { resolverFactoryAddress: '' },
    limitOrderKeeper: { resolverFactoryAddress: '' },
    others: { linkToken: '' },
  },
  [ChainId.bsc]: {
    defiEdge: {
      factoryAddress: {
        default: '0x5a7bfb597323decd653a44e9771ad6a0f9cc6df9',
        staging: '0x5a7bfb597323decd653a44e9771ad6a0f9cc6df9',
        twap: {
          default: '0xe09b82d9b9da83921eb21dca99da43c6abba9353',
          staging: '0xe09b82d9b9da83921eb21dca99da43c6abba9353',
        },
        [Dex.Apeswap]: {
          default: '0x54ba7b033fe40d44b2eb22bf4a5d19e6d6308591',
          twap: '0x5854d5ea3d9cbde4dc28aa9b2c621c457ac6b9cc',
        },
        [Dex.Pancakeswap]: {
          default: '0x4FD639cd1aFc3DDd4d2622e24E778a35a4141eA9',
          twap: '0x1d48012740FABf7bcd83976a852DfE88174D56A5',
        },
        [Dex.Thena]: {
          default: '0x1e6E17Be3b1B5f2257B91Aa37cDDBCDb02eabfa2',
          twap: '0x0de721fb11eA4B7e190C937cC4f6D15A94CCd5d6',
        },
      },
      migratorAddress: '0xd66c919058eacb989a7cc73b79d558e09774a993',
      chainLinkRegistryAddress: '0xb8f51c936c28eaeb928ed9d3b3ba02cfcecd8e25',
      oracleAddress: '0xf85795e865485176c1309c04e707c571b26b9863',
      liquidityHelperAddress: {
        default: '0x6b47052b21f0976d189956661774242b26802ce4',
        [Dex.Apeswap]: '0x6b47052b21f0976d189956661774242b26802ce4',
        [Dex.Pancakeswap]: '0x289736d8eC789510f698CfbfeE858EA8061061A1',
        [Dex.Thena]: '0x95b0575c803e5eEC7b3c37338817822659A6ac84',
      },
      positionKeyHelper: {
        default: '',
        [Dex.Thena]: '0xd27794501d2ae4b462721a0afca73f63664d7bc0',
      },
      swapProxy: {
        default: '',
        [Dex.Thena]: {
          default: '0x34577e75658aBeF9eED77FA98c553CD58F30E688',
          twap: '0x7d951c6574801142b32Ab2de4b78ef8bd6016caB',
        },
      },
    },
    limitOrderGelato: {
      resolverFactoryAddress: {
        default: '0xd102414864bd55b569cad804f016baa61e1730de',
        [Dex.Pancakeswap]: {
          default: '0xd9e3a4d96f7f0ddc164f6e47912fb3d06eea41b3',
          twap: '0x869cf9b6cbf629595767f862ed36d6cd65185dca',
        },
        twap: {
          default: '0xea08ace6f7ebd06c7a411bd2c00338ccb0f0462f',
        },
      },
      pokeMeAddress: '0x527a819db1eb0e34426297b03bae11f2f8b3a19e',
      taskTreasuryAddress: '0x63c51b1d80b209cf336bec5a3e17d3523b088cdb',
    },
    limitOrderKeeper: { resolverFactoryAddress: '' },
    others: {
      linkToken: '',
      tickLens: '0xD9270014D396281579760619CCf4c3af0501A47C',
    },
  },
  [ChainId.moonbeam]: {
    defiEdge: {
      factoryAddress: {
        default: '0x5361AfaE1E25509C811B164a221A5D32effDBe90',
        twap: '0xd0e1596eaf70421141d56E0E720e44821Ac06dAF',
      },
      oracleAddress: '0x4d9AF7DbdAF9aB14b64EadF012fE25019D093B2A',
      chainLinkRegistryAddress: '0x0bf2acf5a53975333cf3fb5ef74eb7aa420fe1de',
      liquidityHelperAddress: {
        default: '0xcB691BbbA105Cd08CBA1a80E53cd1ae34B24ba3E',
        twap: '0xcB691BbbA105Cd08CBA1a80E53cd1ae34B24ba3E',
      },
      swapProxy: {
        default: '0x38944499Ea91De6184fD25F0701274154a0Ce34A',
        twap: '0x38944499Ea91De6184fD25F0701274154a0Ce34A',
      },
      positionKeyHelper: '0x55f92dFc651D308436BeAF79585321d8Ca73A073',
    },
    limitOrderGelato: { resolverFactoryAddress: '' },
    limitOrderKeeper: {
      pokeMeAddress: '0x6c3224f9b3fee000a444681d5d45e4532d5ba531',
      resolverFactoryAddress: '',
    },
    others: { linkToken: '' },
  },
  [ChainId.mantle]: {
    defiEdge: {
      factoryAddress: {
        default: '',
        twap: '',
        [Dex.Fusionx]: {
          default: '0x5015422641C162221fa35bBB60bb13C744f7aC7A',
          twap: '0x95b0575c803e5eEC7b3c37338817822659A6ac84',
        },
      },
      oracleAddress: {
        default: '',
        twap: '',
        [Dex.Fusionx]: {
          default: '0xd0e1596eaf70421141d56E0E720e44821Ac06dAF',
          twap: '0xA093729b392fE82b5fA6fC7f54E6a6C33ca3C250',
        },
      },
      chainLinkRegistryAddress: '0xA2aa501b19aff244D90cc15a4Cf739D2725B5729',
      liquidityHelperAddress: {
        default: '',
        twap: '',
        [Dex.Fusionx]: {
          default: '0x2b857BFedAF65C8422663e5c6E4fe12a6FE4853A',
          twap: '0x2b857BFedAF65C8422663e5c6E4fe12a6FE4853A',
        },
      },
      swapProxy: {
        default: '',
        twap: '',
        [Dex.Fusionx]: {
          default: '0x10129AE6Bbb29DE424733ee17dE08aE28A58C64A',
          twap: '0xEDAd36f5Ee536F032b5AC03B3C535454E1E6D376',
        },
      },
      quoter: '0x90f72244294E7c5028aFd6a96E18CC2c1E913995',
      positionKeyHelper: '0x876cCF15555DA67510707c751EbcAC65cee48Bbb',
    },
    limitOrderGelato: { resolverFactoryAddress: '' },
    limitOrderKeeper: { resolverFactoryAddress: '' },
    others: { linkToken: '' },
  },
  [ChainId.base]: {
    defiEdge: {
      factoryAddress: {
        default: '0x6e5837bb5C18Db65383bC12B69520533cBBE4dD5',
        twap: '0x535bDcaD61A2C964C1f7Da021bB26A2b290B911d',
        [Dex.Pancakeswap]: {
          default: '0x48D365550245a209d2A1F06244b6d8549C928C62',
          staging: '0x48D365550245a209d2A1F06244b6d8549C928C62',
          twap: '0x4e34e5aF7B3d94e0b6C1f1B8af2333dB7e64D4F9',
        },
        [Dex.Baseswap]: {
          default: '0x39869aD930E53C859cf611a503534b67803E6a44',
          twap: '0x9E1650DCCa7377306a1f2F832549559AeF27903f',
        },
      },
      oracleAddress: {
        default: '0xF165f9f256518C82882cD7b7002b0C046Ea5C2Fc',
        twap: '0xd15e8fAD8037b57b109cf31AFb3A70B94D712cBB',
        [Dex.Pancakeswap]: {
          default: '0xe3372aFd58a6cd3DE9927Fb56E3783D0bA6eDeEB',
          staging: '0xe3372aFd58a6cd3DE9927Fb56E3783D0bA6eDeEB',
          twap: '0x3afBA71C36Ac7343B816d6a02A724918c71C50e3',
        },
        [Dex.Baseswap]: {
          default: '0xD042ef1882F32B6A4a704CB406Bd9f963f709119',
          twap: '0x89F877407EA8518f678825C45F706Bec5A8B94A6',
        },
      },
      chainLinkRegistryAddress: {
        default: '0x18E2C78e52399A9AA077CF4Aa3062c85870fB4Ea',
      },
      liquidityHelperAddress: {
        default: '0x6E9149617D54401C12bDd7050538d8aC4CE81dF5',
        [Dex.Pancakeswap]: {
          default: '0xde6Fd20C3e4d6C3fee837A4D239ee9117E9C0D63',
        },
        [Dex.Baseswap]: {
          default: '0xC80DA0435affBa5bC0eA35D8a802eBCBd669AB4A',
        },
      },
      swapProxy: {
        default: '0xeFccEEAD2Ee62Ddeb0f463ee49C4D5E48e29c7A6',
        twap: '0xd27794501d2ae4B462721A0afcA73F63664D7bC0',
        [Dex.Pancakeswap]: {
          default: '0x52DDDEe992E568bfBf44a28e8609df5d13E741da',
          twap: '0x69dC22F1d229392a68686C276584538b84Cd8Fb2',
        },
        [Dex.Baseswap]: {
          default: '0xD56B61D48C367050A53DB9f69AD87087759CBeE7',
          twap: '0xe01C6B17D9Df1Dab243E84940d6095A0151d1675',
        },
      },
      positionKeyHelper: {
        default: '0xb9d226F9c660F24CB0E08804C020e08c783bC726',
      },
      quoter: {
        default: '0x3d4e44Eb1374240CE5F1B871ab261CD16335B76a',
        [Dex.Baseswap]: {
          default: '0x4fDBD73aD4B1DDde594BF05497C15f76308eFfb9',
        },
      },
    },
    limitOrderGelato: { resolverFactoryAddress: '' },
    limitOrderKeeper: { resolverFactoryAddress: '' },
    others: { linkToken: '' },
  },
  [ChainId.baseTestnet]: {
    defiEdge: {
      factoryAddress: {
        default: '',
        [Dex.Horiza]: {
          default: '0x63455a68a549Bb40C841E4ad699dC3d2f91d0de0',
          twap: '0x1a2BBc875624bFAC848e236a1AE080F0D39b0929',
        },
      },
      oracleAddress: {
        default: '',
        [Dex.Horiza]: {
          default: '0x99A648501397649B748E392baD5CfE136AeF42E3',
          twap: '0x6E9149617D54401C12bDd7050538d8aC4CE81dF5',
        },
      },
      chainLinkRegistryAddress: '0x391248f3b5CFf92D60e2F96A1D981BE7113870fC',
      liquidityHelperAddress: '0xaDF7AcB68985789ca494D9e2AD7656E315359e1e',
      swapProxy: {
        default: '',
        [Dex.Horiza]: {
          default: '0x292d0b1fCCE0bD34e29D48520C475fC8521239ae',
          twap: '0x4d9AF7DbdAF9aB14b64EadF012fE25019D093B2A',
        },
      },
      positionKeyHelper: '0xdD22e94A8C717aBc0a0AE6Dc9Dd327dA9C34528f',
      quoter: '0xa73F4d074028EBb0944390f6eB19e01D587d947D',
    },
    limitOrderGelato: { resolverFactoryAddress: '' },
    limitOrderKeeper: { resolverFactoryAddress: '' },
    others: { linkToken: '' },
  },
  [ChainId.avalanche]: {
    defiEdge: {
      factoryAddress: {
        default: '',
        [Dex.Pangolin]: {
          default: '0x4d9AF7DbdAF9aB14b64EadF012fE25019D093B2A',
          twap: '0x7fBf00017c094e6b9398e27A105EcA71D3D2ACbB',
        },
      },
      oracleAddress: {
        default: '0x1a2BBc875624bFAC848e236a1AE080F0D39b0929',
        twap: '0xaDF7AcB68985789ca494D9e2AD7656E315359e1e',
      },
      chainLinkRegistryAddress: '0xD7cf8Dc79b15a61714061C5B7A1c12ddE9f3f088',
      liquidityHelperAddress: {
        default: '',
        [Dex.Pangolin]: {
          default: '0x3A2FBC329F429B0D780E2C730e9547d8D3fB382a',
        },
      },
      swapProxy: {
        default: '',
        [Dex.Pangolin]: {
          default: '0x5e6d6e83D4Ee22A4B7a1931aE515EA4D9249790F',
          twap: '0xacD58E20e82c79319DF320Fe3FCdbAB93EFDAEBE',
        },
      },
      positionKeyHelper: '0x2b857BFedAF65C8422663e5c6E4fe12a6FE4853A',
    },
    limitOrderGelato: { resolverFactoryAddress: '' },
    limitOrderKeeper: { resolverFactoryAddress: '' },
    others: { linkToken: '' },
  },
  [ChainId.zksyncEra]: {
    defiEdge: {
      factoryAddress: {
        default: '',
        [Dex.Pancakeswap]: {
          default: '0x836AbBfA48d73D337bCfF946608582F070987CA2',
          twap: '0x860B5F58AF34A94553cfD729884f0523Dd73bE4D',
        },
      },
      oracleAddress: {
        default: '0xa04Cd6752aF6228D640932e38d7535CEa11Cee0a',
      },
      chainLinkRegistryAddress: '0xf087c864AEccFb6A2Bf1Af6A0382B0d0f6c5D834',
      liquidityHelperAddress: {
        default: '',
        [Dex.Pancakeswap]: {
          default: '0x3861a86D2997Db6417Bb950e33be0a96840A70d1',
        },
      },
      swapProxy: {
        default: '',
        [Dex.Pancakeswap]: {
          default: '0xdAd7E19020b244b9003B877ceBb761D521d83dF2',
          twap: '0x978E24d7b05d1AdA8Bdb3e2E6E4145AEb174d49f',
        },
      },
      quoter: '0x3d146FcE6c1006857750cBe8aF44f76a28041CCc',
    },
    limitOrderGelato: { resolverFactoryAddress: '' },
    limitOrderKeeper: { resolverFactoryAddress: '' },
    others: { linkToken: '' },
  },
}

export default addressConfig
