/* eslint-disable import/order */

import '../types/plugins'

import { Plugin } from '@nuxt/types'
import api from '~/api'

export default ((_, inject) => {
  inject('api', api)
}) as Plugin
