
export default {
  name: 'Modal',
  props: {
    value: Boolean,
    persistent: {
      type: Boolean,
      default: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modal: this.value,
    }
  },
  watch: {
    value(a) {
      const hasScrollbar = document.body.scrollHeight > window.innerHeight

      if (a) {
        const scrollbarWidth = window.innerWidth - document.body.offsetWidth

        if (hasScrollbar) {
          document.body.classList.add('overflow-hidden')
          document.body.style.paddingRight = `${scrollbarWidth + 1}px`
        }
      } else if (hasScrollbar) {
        document.body.classList.remove('overflow-hidden')
        document.body.style.paddingRight = ''
      }

      this.modal = a
    },
    modal(a) {
      this.$emit('input', a)
    },
  },
  mounted() {
    document.body.addEventListener('keyup', this.onKeyUpListener)
  },
  beforeDestroy() {
    const hasScrollbar = document.body.scrollHeight > window.innerHeight
    if (hasScrollbar) {
      document.body.classList.remove('overflow-hidden')
      document.body.style.paddingRight = ''
    }

    document.body.removeEventListener('keyup', this.onKeyUpListener)
  },
  methods: {
    onKeyUpListener(e) {
      if (this.show && e.keyCode === 27) {
        this.close()
      }
    },
    close() {
      if (this.persistent) return

      this.modal = false
      this.$emit('close')
    },
  },
}
