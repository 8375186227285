// import urlExists from './urlExists'

import tokenAliases, { iconMap } from '~/constants/tokenAliases'

export function getIconUrl(name: string): string {
  if (name && iconMap[name.toLowerCase()]) return iconMap[name.toLowerCase()]

  if (
    name &&
    name.length > 0 &&
    Object.keys(tokenAliases).includes(name.toLowerCase())
  ) {
    // @ts-ignore
    name = tokenAliases[name.toLowerCase()]
  }

  const BASE_URL =
    'https://raw.githubusercontent.com/spothq/cryptocurrency-icons/master/svg/icon/'
  const result = `${BASE_URL}${name.toLowerCase()}.svg`

  return result
}

export function onImageError(event: any) {
  event.target.src = '/ban.svg'
}

/**
 * Get token image from 1inch. Will work only on networks supported by 1inch
 * Note: tokenAddress is NOT correct. maybe it takes token contract addresses, need to find out...
 */
export function getIconFrom1Inch(tokenAddress: string) {
  const url = `https://tokens.1inch.io/${tokenAddress}.png`
  return url
}

const cache: Record<string, string | false> = {}

// export function getIconUrlOr404(name: string, inPromise: true): Promise<string>
// // eslint-disable-next-line no-redeclare
// export function getIconUrlOr404(name: string, inPromise?: false): string
// // eslint-disable-next-line no-redeclare
export function getIconUrlOr404(
  name: string
  // inPromise: boolean = false
): string /*  | Promise<string> */ {
  if (cache[name] === false) return '/ban.svg'

  let url = cache[name]

  // if (typeof url === 'string') return url

  url = getIconUrl(name)

  // const promise = urlExists(url)
  //   .then((e) => {
  //     cache[name] = e === true ? url : false

  //     return getIconUrlOr404(name)
  //   })
  //   .catch(() => '/ban.svg')

  // if (inPromise) {
  //   return promise
  // }

  return url
}
