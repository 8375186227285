import { render, staticRenderFns } from "./Wallet.vue?vue&type=template&id=d541b762&"
import script from "./Wallet.vue?vue&type=script&lang=ts&"
export * from "./Wallet.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SettingsIcon: require('/tmp/build_7e4316b3/components/Icons/SettingsIcon.vue').default,NeutralButton: require('/tmp/build_7e4316b3/components/NeutralButton.vue').default})
