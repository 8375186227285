export const CollectFeeModal = () => import('../../components/Modal/CollectFeeModal.vue' /* webpackChunkName: "components/collect-fee-modal" */).then(c => wrapFunctional(c.default || c))
export const DistributionModal = () => import('../../components/Modal/DistributionModal.vue' /* webpackChunkName: "components/distribution-modal" */).then(c => wrapFunctional(c.default || c))
export const ImageSelectModal = () => import('../../components/Modal/ImageSelectModal.vue' /* webpackChunkName: "components/image-select-modal" */).then(c => wrapFunctional(c.default || c))
export const IncreaseLiquidityModal = () => import('../../components/Modal/IncreaseLiquidityModal.vue' /* webpackChunkName: "components/increase-liquidity-modal" */).then(c => wrapFunctional(c.default || c))
export const PoolSelectModal = () => import('../../components/Modal/PoolSelectModal.vue' /* webpackChunkName: "components/pool-select-modal" */).then(c => wrapFunctional(c.default || c))
export const RebalanceModal = () => import('../../components/Modal/RebalanceModal.vue' /* webpackChunkName: "components/rebalance-modal" */).then(c => wrapFunctional(c.default || c))
export const RemoveLiquidityModal = () => import('../../components/Modal/RemoveLiquidityModal.vue' /* webpackChunkName: "components/remove-liquidity-modal" */).then(c => wrapFunctional(c.default || c))
export const TokenSelectModal = () => import('../../components/Modal/TokenSelectModal.vue' /* webpackChunkName: "components/token-select-modal" */).then(c => wrapFunctional(c.default || c))
export const TolenSelectModal = () => import('../../components/Modal/TolenSelectModal.vue' /* webpackChunkName: "components/tolen-select-modal" */).then(c => wrapFunctional(c.default || c))
export const TransactionStatusModal = () => import('../../components/Modal/TransactionStatusModal.vue' /* webpackChunkName: "components/transaction-status-modal" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/Modal/index.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const ActivityIcon = () => import('../../components/Icons/ActivityIcon.vue' /* webpackChunkName: "components/activity-icon" */).then(c => wrapFunctional(c.default || c))
export const AlarmIcon = () => import('../../components/Icons/AlarmIcon.vue' /* webpackChunkName: "components/alarm-icon" */).then(c => wrapFunctional(c.default || c))
export const ArrowDownIcon = () => import('../../components/Icons/ArrowDownIcon.vue' /* webpackChunkName: "components/arrow-down-icon" */).then(c => wrapFunctional(c.default || c))
export const ArrowLeft = () => import('../../components/Icons/ArrowLeft.vue' /* webpackChunkName: "components/arrow-left" */).then(c => wrapFunctional(c.default || c))
export const ArrowUpIcon = () => import('../../components/Icons/ArrowUpIcon.vue' /* webpackChunkName: "components/arrow-up-icon" */).then(c => wrapFunctional(c.default || c))
export const BellRingingIcon = () => import('../../components/Icons/BellRingingIcon.vue' /* webpackChunkName: "components/bell-ringing-icon" */).then(c => wrapFunctional(c.default || c))
export const BitcoinIcon = () => import('../../components/Icons/BitcoinIcon.vue' /* webpackChunkName: "components/bitcoin-icon" */).then(c => wrapFunctional(c.default || c))
export const ChartPieSliceIcon = () => import('../../components/Icons/ChartPieSliceIcon.vue' /* webpackChunkName: "components/chart-pie-slice-icon" */).then(c => wrapFunctional(c.default || c))
export const CheckCircleIcon = () => import('../../components/Icons/CheckCircleIcon.vue' /* webpackChunkName: "components/check-circle-icon" */).then(c => wrapFunctional(c.default || c))
export const CheckCircleSolid = () => import('../../components/Icons/CheckCircleSolid.vue' /* webpackChunkName: "components/check-circle-solid" */).then(c => wrapFunctional(c.default || c))
export const ChevronDownIcon = () => import('../../components/Icons/ChevronDownIcon.vue' /* webpackChunkName: "components/chevron-down-icon" */).then(c => wrapFunctional(c.default || c))
export const ChevronLeft = () => import('../../components/Icons/ChevronLeft.vue' /* webpackChunkName: "components/chevron-left" */).then(c => wrapFunctional(c.default || c))
export const ChevronRightIcon = () => import('../../components/Icons/ChevronRightIcon.vue' /* webpackChunkName: "components/chevron-right-icon" */).then(c => wrapFunctional(c.default || c))
export const ChevronUpIcon = () => import('../../components/Icons/ChevronUpIcon.vue' /* webpackChunkName: "components/chevron-up-icon" */).then(c => wrapFunctional(c.default || c))
export const Clipboard = () => import('../../components/Icons/Clipboard.vue' /* webpackChunkName: "components/clipboard" */).then(c => wrapFunctional(c.default || c))
export const ColumnsIcon = () => import('../../components/Icons/ColumnsIcon.vue' /* webpackChunkName: "components/columns-icon" */).then(c => wrapFunctional(c.default || c))
export const CrossIcon = () => import('../../components/Icons/CrossIcon.vue' /* webpackChunkName: "components/cross-icon" */).then(c => wrapFunctional(c.default || c))
export const DashboardIcon = () => import('../../components/Icons/DashboardIcon.vue' /* webpackChunkName: "components/dashboard-icon" */).then(c => wrapFunctional(c.default || c))
export const DiscordBlueIcon = () => import('../../components/Icons/DiscordBlueIcon.vue' /* webpackChunkName: "components/discord-blue-icon" */).then(c => wrapFunctional(c.default || c))
export const DiscordIcon = () => import('../../components/Icons/DiscordIcon.vue' /* webpackChunkName: "components/discord-icon" */).then(c => wrapFunctional(c.default || c))
export const DollarIcon = () => import('../../components/Icons/DollarIcon.vue' /* webpackChunkName: "components/dollar-icon" */).then(c => wrapFunctional(c.default || c))
export const DollarIconDropdown = () => import('../../components/Icons/DollarIconDropdown.vue' /* webpackChunkName: "components/dollar-icon-dropdown" */).then(c => wrapFunctional(c.default || c))
export const EthIcon = () => import('../../components/Icons/EthIcon.vue' /* webpackChunkName: "components/eth-icon" */).then(c => wrapFunctional(c.default || c))
export const EthLogoIcon = () => import('../../components/Icons/EthLogoIcon.vue' /* webpackChunkName: "components/eth-logo-icon" */).then(c => wrapFunctional(c.default || c))
export const ExternalLinkIcon = () => import('../../components/Icons/ExternalLinkIcon.vue' /* webpackChunkName: "components/external-link-icon" */).then(c => wrapFunctional(c.default || c))
export const FarmIcon = () => import('../../components/Icons/FarmIcon.vue' /* webpackChunkName: "components/farm-icon" */).then(c => wrapFunctional(c.default || c))
export const FileTextIcon = () => import('../../components/Icons/FileTextIcon.vue' /* webpackChunkName: "components/file-text-icon" */).then(c => wrapFunctional(c.default || c))
export const FilterIcon = () => import('../../components/Icons/FilterIcon.vue' /* webpackChunkName: "components/filter-icon" */).then(c => wrapFunctional(c.default || c))
export const GitDiffIcon = () => import('../../components/Icons/GitDiffIcon.vue' /* webpackChunkName: "components/git-diff-icon" */).then(c => wrapFunctional(c.default || c))
export const GradientLint = () => import('../../components/Icons/GradientLint.vue' /* webpackChunkName: "components/gradient-lint" */).then(c => wrapFunctional(c.default || c))
export const GuideIcon = () => import('../../components/Icons/GuideIcon.vue' /* webpackChunkName: "components/guide-icon" */).then(c => wrapFunctional(c.default || c))
export const HamburgerIcon = () => import('../../components/Icons/HamburgerIcon.vue' /* webpackChunkName: "components/hamburger-icon" */).then(c => wrapFunctional(c.default || c))
export const IncognitoIcon = () => import('../../components/Icons/IncognitoIcon.vue' /* webpackChunkName: "components/incognito-icon" */).then(c => wrapFunctional(c.default || c))
export const InfoCircleIcon = () => import('../../components/Icons/InfoCircleIcon.vue' /* webpackChunkName: "components/info-circle-icon" */).then(c => wrapFunctional(c.default || c))
export const InfoIcon = () => import('../../components/Icons/InfoIcon.vue' /* webpackChunkName: "components/info-icon" */).then(c => wrapFunctional(c.default || c))
export const LAIcon = () => import('../../components/Icons/LAIcon.vue' /* webpackChunkName: "components/l-a-icon" */).then(c => wrapFunctional(c.default || c))
export const LeaderboardIcon = () => import('../../components/Icons/LeaderboardIcon.vue' /* webpackChunkName: "components/leaderboard-icon" */).then(c => wrapFunctional(c.default || c))
export const LeafIcon = () => import('../../components/Icons/LeafIcon.vue' /* webpackChunkName: "components/leaf-icon" */).then(c => wrapFunctional(c.default || c))
export const Lighting = () => import('../../components/Icons/Lighting.vue' /* webpackChunkName: "components/lighting" */).then(c => wrapFunctional(c.default || c))
export const LineSegmentsIcon = () => import('../../components/Icons/LineSegmentsIcon.vue' /* webpackChunkName: "components/line-segments-icon" */).then(c => wrapFunctional(c.default || c))
export const Lock = () => import('../../components/Icons/Lock.vue' /* webpackChunkName: "components/lock" */).then(c => wrapFunctional(c.default || c))
export const LockIcon = () => import('../../components/Icons/LockIcon.vue' /* webpackChunkName: "components/lock-icon" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Icons/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const ManageStrategyIcon = () => import('../../components/Icons/ManageStrategyIcon.vue' /* webpackChunkName: "components/manage-strategy-icon" */).then(c => wrapFunctional(c.default || c))
export const ManagerIcon = () => import('../../components/Icons/ManagerIcon.vue' /* webpackChunkName: "components/manager-icon" */).then(c => wrapFunctional(c.default || c))
export const MaticLogoIcon = () => import('../../components/Icons/MaticLogoIcon.vue' /* webpackChunkName: "components/matic-logo-icon" */).then(c => wrapFunctional(c.default || c))
export const MediumIcon = () => import('../../components/Icons/MediumIcon.vue' /* webpackChunkName: "components/medium-icon" */).then(c => wrapFunctional(c.default || c))
export const MinusSmallIcon = () => import('../../components/Icons/MinusSmallIcon.vue' /* webpackChunkName: "components/minus-small-icon" */).then(c => wrapFunctional(c.default || c))
export const MoreHorzIcon = () => import('../../components/Icons/MoreHorzIcon.vue' /* webpackChunkName: "components/more-horz-icon" */).then(c => wrapFunctional(c.default || c))
export const MoreIcon = () => import('../../components/Icons/MoreIcon.vue' /* webpackChunkName: "components/more-icon" */).then(c => wrapFunctional(c.default || c))
export const NewslettersIcon = () => import('../../components/Icons/NewslettersIcon.vue' /* webpackChunkName: "components/newsletters-icon" */).then(c => wrapFunctional(c.default || c))
export const OpenInNewIcon = () => import('../../components/Icons/OpenInNewIcon.vue' /* webpackChunkName: "components/open-in-new-icon" */).then(c => wrapFunctional(c.default || c))
export const Pencil = () => import('../../components/Icons/Pencil.vue' /* webpackChunkName: "components/pencil" */).then(c => wrapFunctional(c.default || c))
export const PlusSmallIcon = () => import('../../components/Icons/PlusSmallIcon.vue' /* webpackChunkName: "components/plus-small-icon" */).then(c => wrapFunctional(c.default || c))
export const PolicyIcon = () => import('../../components/Icons/PolicyIcon.vue' /* webpackChunkName: "components/policy-icon" */).then(c => wrapFunctional(c.default || c))
export const PolygonIcon = () => import('../../components/Icons/PolygonIcon.vue' /* webpackChunkName: "components/polygon-icon" */).then(c => wrapFunctional(c.default || c))
export const PortfolioIcon = () => import('../../components/Icons/PortfolioIcon.vue' /* webpackChunkName: "components/portfolio-icon" */).then(c => wrapFunctional(c.default || c))
export const RateIcon = () => import('../../components/Icons/RateIcon.vue' /* webpackChunkName: "components/rate-icon" */).then(c => wrapFunctional(c.default || c))
export const RefreshIcon = () => import('../../components/Icons/RefreshIcon.vue' /* webpackChunkName: "components/refresh-icon" */).then(c => wrapFunctional(c.default || c))
export const SettingIcon = () => import('../../components/Icons/SettingIcon.vue' /* webpackChunkName: "components/setting-icon" */).then(c => wrapFunctional(c.default || c))
export const SettingsIcon = () => import('../../components/Icons/SettingsIcon.vue' /* webpackChunkName: "components/settings-icon" */).then(c => wrapFunctional(c.default || c))
export const SlidersIcon = () => import('../../components/Icons/SlidersIcon.vue' /* webpackChunkName: "components/sliders-icon" */).then(c => wrapFunctional(c.default || c))
export const StarIcon = () => import('../../components/Icons/StarIcon.vue' /* webpackChunkName: "components/star-icon" */).then(c => wrapFunctional(c.default || c))
export const Stars = () => import('../../components/Icons/Stars.vue' /* webpackChunkName: "components/stars" */).then(c => wrapFunctional(c.default || c))
export const SubstackIcon = () => import('../../components/Icons/SubstackIcon.vue' /* webpackChunkName: "components/substack-icon" */).then(c => wrapFunctional(c.default || c))
export const TermsIcon = () => import('../../components/Icons/TermsIcon.vue' /* webpackChunkName: "components/terms-icon" */).then(c => wrapFunctional(c.default || c))
export const TokenIcon = () => import('../../components/Icons/TokenIcon.vue' /* webpackChunkName: "components/token-icon" */).then(c => wrapFunctional(c.default || c))
export const TransferIcon = () => import('../../components/Icons/TransferIcon.vue' /* webpackChunkName: "components/transfer-icon" */).then(c => wrapFunctional(c.default || c))
export const TrashIcon = () => import('../../components/Icons/TrashIcon.vue' /* webpackChunkName: "components/trash-icon" */).then(c => wrapFunctional(c.default || c))
export const TriangleDownIcon = () => import('../../components/Icons/TriangleDownIcon.vue' /* webpackChunkName: "components/triangle-down-icon" */).then(c => wrapFunctional(c.default || c))
export const TriangleUpIcon = () => import('../../components/Icons/TriangleUpIcon.vue' /* webpackChunkName: "components/triangle-up-icon" */).then(c => wrapFunctional(c.default || c))
export const TwitterIcon = () => import('../../components/Icons/TwitterIcon.vue' /* webpackChunkName: "components/twitter-icon" */).then(c => wrapFunctional(c.default || c))
export const UnLock = () => import('../../components/Icons/UnLock.vue' /* webpackChunkName: "components/un-lock" */).then(c => wrapFunctional(c.default || c))
export const Unicorn = () => import('../../components/Icons/Unicorn.vue' /* webpackChunkName: "components/unicorn" */).then(c => wrapFunctional(c.default || c))
export const UniswapIcon = () => import('../../components/Icons/UniswapIcon.vue' /* webpackChunkName: "components/uniswap-icon" */).then(c => wrapFunctional(c.default || c))
export const UpDownArrow = () => import('../../components/Icons/UpDownArrow.vue' /* webpackChunkName: "components/up-down-arrow" */).then(c => wrapFunctional(c.default || c))
export const WarningIcon = () => import('../../components/Icons/WarningIcon.vue' /* webpackChunkName: "components/warning-icon" */).then(c => wrapFunctional(c.default || c))
export const XCircleIcon = () => import('../../components/Icons/XCircleIcon.vue' /* webpackChunkName: "components/x-circle-icon" */).then(c => wrapFunctional(c.default || c))
export const AlertBar = () => import('../../components/UI/AlertBar.vue' /* webpackChunkName: "components/alert-bar" */).then(c => wrapFunctional(c.default || c))
export const Dropdown = () => import('../../components/UI/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c))
export const LabelInput = () => import('../../components/UI/LabelInput.vue' /* webpackChunkName: "components/label-input" */).then(c => wrapFunctional(c.default || c))
export const LiquidityChart = () => import('../../components/UI/LiquidityChart.vue' /* webpackChunkName: "components/liquidity-chart" */).then(c => wrapFunctional(c.default || c))
export const OutlinedButton = () => import('../../components/UI/OutlinedButton.vue' /* webpackChunkName: "components/outlined-button" */).then(c => wrapFunctional(c.default || c))
export const PoolDropdown = () => import('../../components/UI/PoolDropdown.vue' /* webpackChunkName: "components/pool-dropdown" */).then(c => wrapFunctional(c.default || c))
export const PoolFinder = () => import('../../components/UI/PoolFinder.vue' /* webpackChunkName: "components/pool-finder" */).then(c => wrapFunctional(c.default || c))
export const PrimaryButton = () => import('../../components/UI/PrimaryButton.vue' /* webpackChunkName: "components/primary-button" */).then(c => wrapFunctional(c.default || c))
export const ProgressLoader = () => import('../../components/UI/ProgressLoader.vue' /* webpackChunkName: "components/progress-loader" */).then(c => wrapFunctional(c.default || c))
export const Progressbar = () => import('../../components/UI/Progressbar.vue' /* webpackChunkName: "components/progressbar" */).then(c => wrapFunctional(c.default || c))
export const RangeBar = () => import('../../components/UI/RangeBar.vue' /* webpackChunkName: "components/range-bar" */).then(c => wrapFunctional(c.default || c))
export const RangeDisplay = () => import('../../components/UI/RangeDisplay.vue' /* webpackChunkName: "components/range-display" */).then(c => wrapFunctional(c.default || c))
export const RangeInput = () => import('../../components/UI/RangeInput.vue' /* webpackChunkName: "components/range-input" */).then(c => wrapFunctional(c.default || c))
export const RangeInputRebalance = () => import('../../components/UI/RangeInputRebalance.vue' /* webpackChunkName: "components/range-input-rebalance" */).then(c => wrapFunctional(c.default || c))
export const RebalanceRangeOrder = () => import('../../components/UI/RebalanceRangeOrder.vue' /* webpackChunkName: "components/rebalance-range-order" */).then(c => wrapFunctional(c.default || c))
export const SecondaryButton = () => import('../../components/UI/SecondaryButton.vue' /* webpackChunkName: "components/secondary-button" */).then(c => wrapFunctional(c.default || c))
export const Sparkline = () => import('../../components/UI/Sparkline.vue' /* webpackChunkName: "components/sparkline" */).then(c => wrapFunctional(c.default || c))
export const Stepper = () => import('../../components/UI/Stepper.vue' /* webpackChunkName: "components/stepper" */).then(c => wrapFunctional(c.default || c))
export const TokenPrice = () => import('../../components/UI/TokenPrice.vue' /* webpackChunkName: "components/token-price" */).then(c => wrapFunctional(c.default || c))
export const TokenTabSwitch = () => import('../../components/UI/TokenTabSwitch.vue' /* webpackChunkName: "components/token-tab-switch" */).then(c => wrapFunctional(c.default || c))
export const StarButton = () => import('../../components/Tables/StarButton.vue' /* webpackChunkName: "components/star-button" */).then(c => wrapFunctional(c.default || c))
export const StrategyTable = () => import('../../components/Tables/StrategyTable.vue' /* webpackChunkName: "components/strategy-table" */).then(c => wrapFunctional(c.default || c))
export const TablePortfolio = () => import('../../components/Tables/TablePortfolio.vue' /* webpackChunkName: "components/table-portfolio" */).then(c => wrapFunctional(c.default || c))
export const TableRanges = () => import('../../components/Tables/TableRanges.vue' /* webpackChunkName: "components/table-ranges" */).then(c => wrapFunctional(c.default || c))
export const TableRebalance = () => import('../../components/Tables/TableRebalance.vue' /* webpackChunkName: "components/table-rebalance" */).then(c => wrapFunctional(c.default || c))
export const TableTransactions = () => import('../../components/Tables/TableTransactions.vue' /* webpackChunkName: "components/table-transactions" */).then(c => wrapFunctional(c.default || c))
export const TokenTable = () => import('../../components/Tables/TokenTable.vue' /* webpackChunkName: "components/token-table" */).then(c => wrapFunctional(c.default || c))
export const SkeletonManageStrategy = () => import('../../components/Skeletons/SkeletonManageStrategy.vue' /* webpackChunkName: "components/skeleton-manage-strategy" */).then(c => wrapFunctional(c.default || c))
export const SkeletonManageStrategyCards = () => import('../../components/Skeletons/SkeletonManageStrategyCards.vue' /* webpackChunkName: "components/skeleton-manage-strategy-cards" */).then(c => wrapFunctional(c.default || c))
export const SkeletonStats = () => import('../../components/Skeletons/SkeletonStats.vue' /* webpackChunkName: "components/skeleton-stats" */).then(c => wrapFunctional(c.default || c))
export const SkeletonStrategyDetails = () => import('../../components/Skeletons/SkeletonStrategyDetails.vue' /* webpackChunkName: "components/skeleton-strategy-details" */).then(c => wrapFunctional(c.default || c))
export const SkeletonTable = () => import('../../components/Skeletons/SkeletonTable.vue' /* webpackChunkName: "components/skeleton-table" */).then(c => wrapFunctional(c.default || c))
export const Alert = () => import('../../components/Alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppInput = () => import('../../components/AppInput.vue' /* webpackChunkName: "components/app-input" */).then(c => wrapFunctional(c.default || c))
export const AppInputWithTokenSelection = () => import('../../components/AppInputWithTokenSelection.vue' /* webpackChunkName: "components/app-input-with-token-selection" */).then(c => wrapFunctional(c.default || c))
export const AppInputOld = () => import('../../components/AppInput_old.vue' /* webpackChunkName: "components/app-input-old" */).then(c => wrapFunctional(c.default || c))
export const AppLayout = () => import('../../components/AppLayout.vue' /* webpackChunkName: "components/app-layout" */).then(c => wrapFunctional(c.default || c))
export const AutoRouter = () => import('../../components/AutoRouter.vue' /* webpackChunkName: "components/auto-router" */).then(c => wrapFunctional(c.default || c))
export const AutoRouterOneInch = () => import('../../components/AutoRouterOneInch.vue' /* webpackChunkName: "components/auto-router-one-inch" */).then(c => wrapFunctional(c.default || c))
export const Badge = () => import('../../components/Badge.vue' /* webpackChunkName: "components/badge" */).then(c => wrapFunctional(c.default || c))
export const Chart = () => import('../../components/Chart.vue' /* webpackChunkName: "components/chart" */).then(c => wrapFunctional(c.default || c))
export const ClaimFeesButton = () => import('../../components/ClaimFeesButton.vue' /* webpackChunkName: "components/claim-fees-button" */).then(c => wrapFunctional(c.default || c))
export const CompactNumber = () => import('../../components/CompactNumber.vue' /* webpackChunkName: "components/compact-number" */).then(c => wrapFunctional(c.default || c))
export const CreateStrategyButton = () => import('../../components/CreateStrategyButton..vue' /* webpackChunkName: "components/create-strategy-button" */).then(c => wrapFunctional(c.default || c))
export const CurrencySelector = () => import('../../components/CurrencySelector.vue' /* webpackChunkName: "components/currency-selector" */).then(c => wrapFunctional(c.default || c))
export const DangerButton = () => import('../../components/DangerButton.vue' /* webpackChunkName: "components/danger-button" */).then(c => wrapFunctional(c.default || c))
export const DashboardStats = () => import('../../components/DashboardStats.vue' /* webpackChunkName: "components/dashboard-stats" */).then(c => wrapFunctional(c.default || c))
export const DexSelectMenu = () => import('../../components/DexSelectMenu.vue' /* webpackChunkName: "components/dex-select-menu" */).then(c => wrapFunctional(c.default || c))
export const DoubleLogo = () => import('../../components/DoubleLogo.vue' /* webpackChunkName: "components/double-logo" */).then(c => wrapFunctional(c.default || c))
export const FarmContent = () => import('../../components/FarmContent.vue' /* webpackChunkName: "components/farm-content" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const NetworkSelectMenu = () => import('../../components/NetworkSelectMenu.vue' /* webpackChunkName: "components/network-select-menu" */).then(c => wrapFunctional(c.default || c))
export const NeutralButton = () => import('../../components/NeutralButton.vue' /* webpackChunkName: "components/neutral-button" */).then(c => wrapFunctional(c.default || c))
export const OnboardingScreen = () => import('../../components/OnboardingScreen.vue' /* webpackChunkName: "components/onboarding-screen" */).then(c => wrapFunctional(c.default || c))
export const Overview = () => import('../../components/Overview.vue' /* webpackChunkName: "components/overview" */).then(c => wrapFunctional(c.default || c))
export const PositionRow = () => import('../../components/PositionRow.vue' /* webpackChunkName: "components/position-row" */).then(c => wrapFunctional(c.default || c))
export const Positions = () => import('../../components/Positions.vue' /* webpackChunkName: "components/positions" */).then(c => wrapFunctional(c.default || c))
export const PriceRange = () => import('../../components/PriceRange.vue' /* webpackChunkName: "components/price-range" */).then(c => wrapFunctional(c.default || c))
export const PriceRangeLine = () => import('../../components/PriceRangeLine.vue' /* webpackChunkName: "components/price-range-line" */).then(c => wrapFunctional(c.default || c))
export const RankNumber = () => import('../../components/RankNumber.vue' /* webpackChunkName: "components/rank-number" */).then(c => wrapFunctional(c.default || c))
export const RebalanceHistoryItem = () => import('../../components/RebalanceHistoryItem.vue' /* webpackChunkName: "components/rebalance-history-item" */).then(c => wrapFunctional(c.default || c))
export const SafeImage = () => import('../../components/SafeImage.vue' /* webpackChunkName: "components/safe-image" */).then(c => wrapFunctional(c.default || c))
export const SafeStrategyImage = () => import('../../components/SafeStrategyImage.vue' /* webpackChunkName: "components/safe-strategy-image" */).then(c => wrapFunctional(c.default || c))
export const ShareMenu = () => import('../../components/ShareMenu.vue' /* webpackChunkName: "components/share-menu" */).then(c => wrapFunctional(c.default || c))
export const ShareStrategyButton = () => import('../../components/ShareStrategyButton.vue' /* webpackChunkName: "components/share-strategy-button" */).then(c => wrapFunctional(c.default || c))
export const SlippageField = () => import('../../components/SlippageField.vue' /* webpackChunkName: "components/slippage-field" */).then(c => wrapFunctional(c.default || c))
export const StatsDistribution = () => import('../../components/StatsDistribution.vue' /* webpackChunkName: "components/stats-distribution" */).then(c => wrapFunctional(c.default || c))
export const StatsGraph = () => import('../../components/StatsGraph.vue' /* webpackChunkName: "components/stats-graph" */).then(c => wrapFunctional(c.default || c))
export const StrategiesList = () => import('../../components/StrategiesList.vue' /* webpackChunkName: "components/strategies-list" */).then(c => wrapFunctional(c.default || c))
export const StrategiesListItem = () => import('../../components/StrategiesListItem.vue' /* webpackChunkName: "components/strategies-list-item" */).then(c => wrapFunctional(c.default || c))
export const StrategyAdmin = () => import('../../components/StrategyAdmin.vue' /* webpackChunkName: "components/strategy-admin" */).then(c => wrapFunctional(c.default || c))
export const StrategyCard = () => import('../../components/StrategyCard.vue' /* webpackChunkName: "components/strategy-card" */).then(c => wrapFunctional(c.default || c))
export const SubscribeField = () => import('../../components/SubscribeField.vue' /* webpackChunkName: "components/subscribe-field" */).then(c => wrapFunctional(c.default || c))
export const SubscribeModel = () => import('../../components/SubscribeModel.vue' /* webpackChunkName: "components/subscribe-model" */).then(c => wrapFunctional(c.default || c))
export const Swap = () => import('../../components/Swap.vue' /* webpackChunkName: "components/swap" */).then(c => wrapFunctional(c.default || c))
export const SwapForStrategy = () => import('../../components/SwapForStrategy.vue' /* webpackChunkName: "components/swap-for-strategy" */).then(c => wrapFunctional(c.default || c))
export const SwapProtocols = () => import('../../components/SwapProtocols.vue' /* webpackChunkName: "components/swap-protocols" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const TabsFilled = () => import('../../components/TabsFilled.vue' /* webpackChunkName: "components/tabs-filled" */).then(c => wrapFunctional(c.default || c))
export const ToggleDarkMode = () => import('../../components/ToggleDarkMode.vue' /* webpackChunkName: "components/toggle-dark-mode" */).then(c => wrapFunctional(c.default || c))
export const TokenTab = () => import('../../components/TokenTab.vue' /* webpackChunkName: "components/token-tab" */).then(c => wrapFunctional(c.default || c))
export const Wallet = () => import('../../components/Wallet.vue' /* webpackChunkName: "components/wallet" */).then(c => wrapFunctional(c.default || c))
export const WysiwygEditor = () => import('../../components/WysiwygEditor.vue' /* webpackChunkName: "components/wysiwyg-editor" */).then(c => wrapFunctional(c.default || c))
export const DepositRanges = () => import('../../components/Deposit/Ranges.vue' /* webpackChunkName: "components/deposit-ranges" */).then(c => wrapFunctional(c.default || c))
export const DepositTabs = () => import('../../components/Deposit/Tabs.vue' /* webpackChunkName: "components/deposit-tabs" */).then(c => wrapFunctional(c.default || c))
export const LiquidityChartRangeInputArea = () => import('../../components/LiquidityChartRangeInput/Area.vue' /* webpackChunkName: "components/liquidity-chart-range-input-area" */).then(c => wrapFunctional(c.default || c))
export const LiquidityChartRangeInputAxisBottom = () => import('../../components/LiquidityChartRangeInput/AxisBottom.vue' /* webpackChunkName: "components/liquidity-chart-range-input-axis-bottom" */).then(c => wrapFunctional(c.default || c))
export const LiquidityChartRangeInputButton = () => import('../../components/LiquidityChartRangeInput/Button.vue' /* webpackChunkName: "components/liquidity-chart-range-input-button" */).then(c => wrapFunctional(c.default || c))
export const LiquidityChartRangeInputLine = () => import('../../components/LiquidityChartRangeInput/Line.vue' /* webpackChunkName: "components/liquidity-chart-range-input-line" */).then(c => wrapFunctional(c.default || c))
export const LiquidityChartRangeInputZoom = () => import('../../components/LiquidityChartRangeInput/Zoom.vue' /* webpackChunkName: "components/liquidity-chart-range-input-zoom" */).then(c => wrapFunctional(c.default || c))
export const LiquidityChartRangeInputZoomOverlay = () => import('../../components/LiquidityChartRangeInput/ZoomOverlay.vue' /* webpackChunkName: "components/liquidity-chart-range-input-zoom-overlay" */).then(c => wrapFunctional(c.default || c))
export const LiquidityChartRangeInput = () => import('../../components/LiquidityChartRangeInput/index.vue' /* webpackChunkName: "components/liquidity-chart-range-input" */).then(c => wrapFunctional(c.default || c))
export const StrategyCreateBasicInfo = () => import('../../components/StrategyCreate/BasicInfo.vue' /* webpackChunkName: "components/strategy-create-basic-info" */).then(c => wrapFunctional(c.default || c))
export const StrategyCreateFees = () => import('../../components/StrategyCreate/Fees.vue' /* webpackChunkName: "components/strategy-create-fees" */).then(c => wrapFunctional(c.default || c))
export const StrategyCreatePools = () => import('../../components/StrategyCreate/Pools.vue' /* webpackChunkName: "components/strategy-create-pools" */).then(c => wrapFunctional(c.default || c))
export const StrategyCreatePublish = () => import('../../components/StrategyCreate/Publish.vue' /* webpackChunkName: "components/strategy-create-publish" */).then(c => wrapFunctional(c.default || c))
export const StrategyCreateRange = () => import('../../components/StrategyCreate/Range.vue' /* webpackChunkName: "components/strategy-create-range" */).then(c => wrapFunctional(c.default || c))
export const StrategyCreateSetupInfo = () => import('../../components/StrategyCreate/SetupInfo.vue' /* webpackChunkName: "components/strategy-create-setup-info" */).then(c => wrapFunctional(c.default || c))
export const StrategyCreateType = () => import('../../components/StrategyCreate/Type.vue' /* webpackChunkName: "components/strategy-create-type" */).then(c => wrapFunctional(c.default || c))
export const StrategyImportBasicInfo = () => import('../../components/StrategyImport/BasicInfo.vue' /* webpackChunkName: "components/strategy-import-basic-info" */).then(c => wrapFunctional(c.default || c))
export const StrategyImportFees = () => import('../../components/StrategyImport/Fees.vue' /* webpackChunkName: "components/strategy-import-fees" */).then(c => wrapFunctional(c.default || c))
export const StrategyImportNftDetail = () => import('../../components/StrategyImport/NftDetail.vue' /* webpackChunkName: "components/strategy-import-nft-detail" */).then(c => wrapFunctional(c.default || c))
export const StrategyImportPublish = () => import('../../components/StrategyImport/Publish.vue' /* webpackChunkName: "components/strategy-import-publish" */).then(c => wrapFunctional(c.default || c))
export const StrategyImportSetupInfo = () => import('../../components/StrategyImport/SetupInfo.vue' /* webpackChunkName: "components/strategy-import-setup-info" */).then(c => wrapFunctional(c.default || c))
export const StrategyImportType = () => import('../../components/StrategyImport/Type.vue' /* webpackChunkName: "components/strategy-import-type" */).then(c => wrapFunctional(c.default || c))
export const LMyRewards = () => import('../../components/l/MyRewards.vue' /* webpackChunkName: "components/l-my-rewards" */).then(c => wrapFunctional(c.default || c))
export const LStake = () => import('../../components/l/Stake.vue' /* webpackChunkName: "components/l-stake" */).then(c => wrapFunctional(c.default || c))
export const LUnstake = () => import('../../components/l/Unstake.vue' /* webpackChunkName: "components/l-unstake" */).then(c => wrapFunctional(c.default || c))
export const MCheckboxList = () => import('../../components/m/mCheckboxList.vue' /* webpackChunkName: "components/m-checkbox-list" */).then(c => wrapFunctional(c.default || c))
export const MGeneral = () => import('../../components/m/mGeneral.vue' /* webpackChunkName: "components/m-general" */).then(c => wrapFunctional(c.default || c))
export const MLimitOrder = () => import('../../components/m/mLimitOrder.vue' /* webpackChunkName: "components/m-limit-order" */).then(c => wrapFunctional(c.default || c))
export const MLimitOrderGelato = () => import('../../components/m/mLimitOrderGelato.vue' /* webpackChunkName: "components/m-limit-order-gelato" */).then(c => wrapFunctional(c.default || c))
export const MLimitOrderKeeper = () => import('../../components/m/mLimitOrderKeeper.vue' /* webpackChunkName: "components/m-limit-order-keeper" */).then(c => wrapFunctional(c.default || c))
export const MManagementFees = () => import('../../components/m/mManagementFees.vue' /* webpackChunkName: "components/m-management-fees" */).then(c => wrapFunctional(c.default || c))
export const MManagers = () => import('../../components/m/mManagers.vue' /* webpackChunkName: "components/m-managers" */).then(c => wrapFunctional(c.default || c))
export const MNotifications = () => import('../../components/m/mNotifications.vue' /* webpackChunkName: "components/m-notifications" */).then(c => wrapFunctional(c.default || c))
export const MOverview = () => import('../../components/m/mOverview.vue' /* webpackChunkName: "components/m-overview" */).then(c => wrapFunctional(c.default || c))
export const MRebalanceHistory = () => import('../../components/m/mRebalanceHistory.vue' /* webpackChunkName: "components/m-rebalance-history" */).then(c => wrapFunctional(c.default || c))
export const MStrategyLimit = () => import('../../components/m/mStrategyLimit.vue' /* webpackChunkName: "components/m-strategy-limit" */).then(c => wrapFunctional(c.default || c))
export const MStrategyVisibility = () => import('../../components/m/mStrategyVisibility.vue' /* webpackChunkName: "components/m-strategy-visibility" */).then(c => wrapFunctional(c.default || c))
export const MSwap = () => import('../../components/m/mSwap.vue' /* webpackChunkName: "components/m-swap" */).then(c => wrapFunctional(c.default || c))
export const MWhitelistedAddresses = () => import('../../components/m/mWhitelistedAddresses.vue' /* webpackChunkName: "components/m-whitelisted-addresses" */).then(c => wrapFunctional(c.default || c))
export const PManageStrategy = () => import('../../components/p/pManageStrategy.vue' /* webpackChunkName: "components/p-manage-strategy" */).then(c => wrapFunctional(c.default || c))
export const POverview = () => import('../../components/p/pOverview.vue' /* webpackChunkName: "components/p-overview" */).then(c => wrapFunctional(c.default || c))
export const PWatchList = () => import('../../components/p/pWatchList.vue' /* webpackChunkName: "components/p-watch-list" */).then(c => wrapFunctional(c.default || c))
export const SAboutProduct = () => import('../../components/s/sAboutProduct.vue' /* webpackChunkName: "components/s-about-product" */).then(c => wrapFunctional(c.default || c))
export const SDepositWidget = () => import('../../components/s/sDepositWidget.vue' /* webpackChunkName: "components/s-deposit-widget" */).then(c => wrapFunctional(c.default || c))
export const SFeesHistory = () => import('../../components/s/sFeesHistory.vue' /* webpackChunkName: "components/s-fees-history" */).then(c => wrapFunctional(c.default || c))
export const SOverview = () => import('../../components/s/sOverview.vue' /* webpackChunkName: "components/s-overview" */).then(c => wrapFunctional(c.default || c))
export const SPagination = () => import('../../components/s/sPagination.vue' /* webpackChunkName: "components/s-pagination" */).then(c => wrapFunctional(c.default || c))
export const SRanges = () => import('../../components/s/sRanges.vue' /* webpackChunkName: "components/s-ranges" */).then(c => wrapFunctional(c.default || c))
export const SRebalance = () => import('../../components/s/sRebalance.vue' /* webpackChunkName: "components/s-rebalance" */).then(c => wrapFunctional(c.default || c))
export const SRemoveWidget = () => import('../../components/s/sRemoveWidget.vue' /* webpackChunkName: "components/s-remove-widget" */).then(c => wrapFunctional(c.default || c))
export const STransactions = () => import('../../components/s/sTransactions.vue' /* webpackChunkName: "components/s-transactions" */).then(c => wrapFunctional(c.default || c))
export const LiquidityChartRangeInputBrushHandle = () => import('../../components/LiquidityChartRangeInput/Brush/Handle.vue' /* webpackChunkName: "components/liquidity-chart-range-input-brush-handle" */).then(c => wrapFunctional(c.default || c))
export const LiquidityChartRangeInputBrushHandleAccent = () => import('../../components/LiquidityChartRangeInput/Brush/HandleAccent.vue' /* webpackChunkName: "components/liquidity-chart-range-input-brush-handle-accent" */).then(c => wrapFunctional(c.default || c))
export const LiquidityChartRangeInputBrushLabelGroup = () => import('../../components/LiquidityChartRangeInput/Brush/LabelGroup.vue' /* webpackChunkName: "components/liquidity-chart-range-input-brush-label-group" */).then(c => wrapFunctional(c.default || c))
export const LiquidityChartRangeInputBrushOffScreenHandle = () => import('../../components/LiquidityChartRangeInput/Brush/OffScreenHandle.vue' /* webpackChunkName: "components/liquidity-chart-range-input-brush-off-screen-handle" */).then(c => wrapFunctional(c.default || c))
export const LiquidityChartRangeInputBrushTooltip = () => import('../../components/LiquidityChartRangeInput/Brush/Tooltip.vue' /* webpackChunkName: "components/liquidity-chart-range-input-brush-tooltip" */).then(c => wrapFunctional(c.default || c))
export const LiquidityChartRangeInputBrushTooltipBackground = () => import('../../components/LiquidityChartRangeInput/Brush/TooltipBackground.vue' /* webpackChunkName: "components/liquidity-chart-range-input-brush-tooltip-background" */).then(c => wrapFunctional(c.default || c))
export const LiquidityChartRangeInputBrush = () => import('../../components/LiquidityChartRangeInput/Brush/index.vue' /* webpackChunkName: "components/liquidity-chart-range-input-brush" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
