import Vue from 'vue'
import VueApollo from 'vue-apollo'
import 'cross-fetch/polyfill'
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client/src'
import Cookie from 'universal-cookie'
import { InMemoryCache } from 'apollo-cache-inmemory'

Vue.use(VueApollo)

export default (ctx, inject) => {
  const providerOptions = { clients: {} }
  const { app, beforeNuxtRender, req } = ctx
  const AUTH_TOKEN_NAME = 'apollo-token'
  const COOKIE_ATTRIBUTES = {"expires":7,"path":"\u002F","secure":false}
  const AUTH_TYPE = 'Bearer '
  const cookies = new Cookie(req && req.headers.cookie)

  // Config

      const uniswapAPITokenName = ''  || AUTH_TOKEN_NAME

      function uniswapAPIGetAuth () {
        const token = cookies.get(uniswapAPITokenName)
        return token && uniswapAPIClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let uniswapAPIClientConfig

        uniswapAPIClientConfig = {
  "httpEndpoint": "https://api.uniswap.org/v1/graphql"
}

      const uniswapAPIValidateToken = () => true

      if (!uniswapAPIClientConfig.validateToken) {
        uniswapAPIClientConfig.validateToken = uniswapAPIValidateToken
      }

      const uniswapAPICache = uniswapAPIClientConfig.cache
        ? uniswapAPIClientConfig.cache
        : new InMemoryCache(uniswapAPIClientConfig.inMemoryCacheOptions ? uniswapAPIClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        uniswapAPICache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.uniswapAPI : null)
      }

      if (!uniswapAPIClientConfig.getAuth) {
        uniswapAPIClientConfig.getAuth = uniswapAPIGetAuth
      }

      if (process.client && uniswapAPIClientConfig.browserHttpEndpoint) {
        uniswapAPIClientConfig.httpEndpoint = uniswapAPIClientConfig.browserHttpEndpoint
      }

      uniswapAPIClientConfig.ssr = !!process.server
      uniswapAPIClientConfig.cache = uniswapAPICache
      uniswapAPIClientConfig.tokenName = uniswapAPITokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!uniswapAPIClientConfig.httpLinkOptions) {
          uniswapAPIClientConfig.httpLinkOptions = {}
        }
        if (!uniswapAPIClientConfig.httpLinkOptions.headers) {
          uniswapAPIClientConfig.httpLinkOptions.headers = {}
        }
        uniswapAPIClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let uniswapAPIApolloCreation = createApolloClient({
        ...uniswapAPIClientConfig
      })
      uniswapAPIApolloCreation.apolloClient.wsClient = uniswapAPIApolloCreation.wsClient

          providerOptions.clients.uniswapAPI = uniswapAPIApolloCreation.apolloClient

      const defiEdgeTokenName = ''  || AUTH_TOKEN_NAME

      function defiEdgeGetAuth () {
        const token = cookies.get(defiEdgeTokenName)
        return token && defiEdgeClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let defiEdgeClientConfig

        defiEdgeClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/pranavraut033/mainnet-v1"
}

      const defiEdgeValidateToken = () => true

      if (!defiEdgeClientConfig.validateToken) {
        defiEdgeClientConfig.validateToken = defiEdgeValidateToken
      }

      const defiEdgeCache = defiEdgeClientConfig.cache
        ? defiEdgeClientConfig.cache
        : new InMemoryCache(defiEdgeClientConfig.inMemoryCacheOptions ? defiEdgeClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        defiEdgeCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.defiEdge : null)
      }

      if (!defiEdgeClientConfig.getAuth) {
        defiEdgeClientConfig.getAuth = defiEdgeGetAuth
      }

      if (process.client && defiEdgeClientConfig.browserHttpEndpoint) {
        defiEdgeClientConfig.httpEndpoint = defiEdgeClientConfig.browserHttpEndpoint
      }

      defiEdgeClientConfig.ssr = !!process.server
      defiEdgeClientConfig.cache = defiEdgeCache
      defiEdgeClientConfig.tokenName = defiEdgeTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!defiEdgeClientConfig.httpLinkOptions) {
          defiEdgeClientConfig.httpLinkOptions = {}
        }
        if (!defiEdgeClientConfig.httpLinkOptions.headers) {
          defiEdgeClientConfig.httpLinkOptions.headers = {}
        }
        defiEdgeClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let defiEdgeApolloCreation = createApolloClient({
        ...defiEdgeClientConfig
      })
      defiEdgeApolloCreation.apolloClient.wsClient = defiEdgeApolloCreation.wsClient

          providerOptions.clients.defiEdge = defiEdgeApolloCreation.apolloClient

      const defiEdgeKovanTokenName = ''  || AUTH_TOKEN_NAME

      function defiEdgeKovanGetAuth () {
        const token = cookies.get(defiEdgeKovanTokenName)
        return token && defiEdgeKovanClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let defiEdgeKovanClientConfig

        defiEdgeKovanClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/unbound-finance/defiedge-kovan"
}

      const defiEdgeKovanValidateToken = () => true

      if (!defiEdgeKovanClientConfig.validateToken) {
        defiEdgeKovanClientConfig.validateToken = defiEdgeKovanValidateToken
      }

      const defiEdgeKovanCache = defiEdgeKovanClientConfig.cache
        ? defiEdgeKovanClientConfig.cache
        : new InMemoryCache(defiEdgeKovanClientConfig.inMemoryCacheOptions ? defiEdgeKovanClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        defiEdgeKovanCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.defiEdgeKovan : null)
      }

      if (!defiEdgeKovanClientConfig.getAuth) {
        defiEdgeKovanClientConfig.getAuth = defiEdgeKovanGetAuth
      }

      if (process.client && defiEdgeKovanClientConfig.browserHttpEndpoint) {
        defiEdgeKovanClientConfig.httpEndpoint = defiEdgeKovanClientConfig.browserHttpEndpoint
      }

      defiEdgeKovanClientConfig.ssr = !!process.server
      defiEdgeKovanClientConfig.cache = defiEdgeKovanCache
      defiEdgeKovanClientConfig.tokenName = defiEdgeKovanTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!defiEdgeKovanClientConfig.httpLinkOptions) {
          defiEdgeKovanClientConfig.httpLinkOptions = {}
        }
        if (!defiEdgeKovanClientConfig.httpLinkOptions.headers) {
          defiEdgeKovanClientConfig.httpLinkOptions.headers = {}
        }
        defiEdgeKovanClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let defiEdgeKovanApolloCreation = createApolloClient({
        ...defiEdgeKovanClientConfig
      })
      defiEdgeKovanApolloCreation.apolloClient.wsClient = defiEdgeKovanApolloCreation.wsClient

          providerOptions.clients.defiEdgeKovan = defiEdgeKovanApolloCreation.apolloClient

      const defiEdgeRinkebyTokenName = ''  || AUTH_TOKEN_NAME

      function defiEdgeRinkebyGetAuth () {
        const token = cookies.get(defiEdgeRinkebyTokenName)
        return token && defiEdgeRinkebyClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let defiEdgeRinkebyClientConfig

        defiEdgeRinkebyClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/unbound-finance/defiedge-rinkeby"
}

      const defiEdgeRinkebyValidateToken = () => true

      if (!defiEdgeRinkebyClientConfig.validateToken) {
        defiEdgeRinkebyClientConfig.validateToken = defiEdgeRinkebyValidateToken
      }

      const defiEdgeRinkebyCache = defiEdgeRinkebyClientConfig.cache
        ? defiEdgeRinkebyClientConfig.cache
        : new InMemoryCache(defiEdgeRinkebyClientConfig.inMemoryCacheOptions ? defiEdgeRinkebyClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        defiEdgeRinkebyCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.defiEdgeRinkeby : null)
      }

      if (!defiEdgeRinkebyClientConfig.getAuth) {
        defiEdgeRinkebyClientConfig.getAuth = defiEdgeRinkebyGetAuth
      }

      if (process.client && defiEdgeRinkebyClientConfig.browserHttpEndpoint) {
        defiEdgeRinkebyClientConfig.httpEndpoint = defiEdgeRinkebyClientConfig.browserHttpEndpoint
      }

      defiEdgeRinkebyClientConfig.ssr = !!process.server
      defiEdgeRinkebyClientConfig.cache = defiEdgeRinkebyCache
      defiEdgeRinkebyClientConfig.tokenName = defiEdgeRinkebyTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!defiEdgeRinkebyClientConfig.httpLinkOptions) {
          defiEdgeRinkebyClientConfig.httpLinkOptions = {}
        }
        if (!defiEdgeRinkebyClientConfig.httpLinkOptions.headers) {
          defiEdgeRinkebyClientConfig.httpLinkOptions.headers = {}
        }
        defiEdgeRinkebyClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let defiEdgeRinkebyApolloCreation = createApolloClient({
        ...defiEdgeRinkebyClientConfig
      })
      defiEdgeRinkebyApolloCreation.apolloClient.wsClient = defiEdgeRinkebyApolloCreation.wsClient

          providerOptions.clients.defiEdgeRinkeby = defiEdgeRinkebyApolloCreation.apolloClient

      const defiEdgePolygonTokenName = ''  || AUTH_TOKEN_NAME

      function defiEdgePolygonGetAuth () {
        const token = cookies.get(defiEdgePolygonTokenName)
        return token && defiEdgePolygonClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let defiEdgePolygonClientConfig

        defiEdgePolygonClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/pranavraut033/polygon-v1"
}

      const defiEdgePolygonValidateToken = () => true

      if (!defiEdgePolygonClientConfig.validateToken) {
        defiEdgePolygonClientConfig.validateToken = defiEdgePolygonValidateToken
      }

      const defiEdgePolygonCache = defiEdgePolygonClientConfig.cache
        ? defiEdgePolygonClientConfig.cache
        : new InMemoryCache(defiEdgePolygonClientConfig.inMemoryCacheOptions ? defiEdgePolygonClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        defiEdgePolygonCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.defiEdgePolygon : null)
      }

      if (!defiEdgePolygonClientConfig.getAuth) {
        defiEdgePolygonClientConfig.getAuth = defiEdgePolygonGetAuth
      }

      if (process.client && defiEdgePolygonClientConfig.browserHttpEndpoint) {
        defiEdgePolygonClientConfig.httpEndpoint = defiEdgePolygonClientConfig.browserHttpEndpoint
      }

      defiEdgePolygonClientConfig.ssr = !!process.server
      defiEdgePolygonClientConfig.cache = defiEdgePolygonCache
      defiEdgePolygonClientConfig.tokenName = defiEdgePolygonTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!defiEdgePolygonClientConfig.httpLinkOptions) {
          defiEdgePolygonClientConfig.httpLinkOptions = {}
        }
        if (!defiEdgePolygonClientConfig.httpLinkOptions.headers) {
          defiEdgePolygonClientConfig.httpLinkOptions.headers = {}
        }
        defiEdgePolygonClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let defiEdgePolygonApolloCreation = createApolloClient({
        ...defiEdgePolygonClientConfig
      })
      defiEdgePolygonApolloCreation.apolloClient.wsClient = defiEdgePolygonApolloCreation.wsClient

          providerOptions.clients.defiEdgePolygon = defiEdgePolygonApolloCreation.apolloClient

      const defiEdgeArbitrumTokenName = ''  || AUTH_TOKEN_NAME

      function defiEdgeArbitrumGetAuth () {
        const token = cookies.get(defiEdgeArbitrumTokenName)
        return token && defiEdgeArbitrumClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let defiEdgeArbitrumClientConfig

        defiEdgeArbitrumClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/pranavraut033/arbitrum-v1"
}

      const defiEdgeArbitrumValidateToken = () => true

      if (!defiEdgeArbitrumClientConfig.validateToken) {
        defiEdgeArbitrumClientConfig.validateToken = defiEdgeArbitrumValidateToken
      }

      const defiEdgeArbitrumCache = defiEdgeArbitrumClientConfig.cache
        ? defiEdgeArbitrumClientConfig.cache
        : new InMemoryCache(defiEdgeArbitrumClientConfig.inMemoryCacheOptions ? defiEdgeArbitrumClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        defiEdgeArbitrumCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.defiEdgeArbitrum : null)
      }

      if (!defiEdgeArbitrumClientConfig.getAuth) {
        defiEdgeArbitrumClientConfig.getAuth = defiEdgeArbitrumGetAuth
      }

      if (process.client && defiEdgeArbitrumClientConfig.browserHttpEndpoint) {
        defiEdgeArbitrumClientConfig.httpEndpoint = defiEdgeArbitrumClientConfig.browserHttpEndpoint
      }

      defiEdgeArbitrumClientConfig.ssr = !!process.server
      defiEdgeArbitrumClientConfig.cache = defiEdgeArbitrumCache
      defiEdgeArbitrumClientConfig.tokenName = defiEdgeArbitrumTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!defiEdgeArbitrumClientConfig.httpLinkOptions) {
          defiEdgeArbitrumClientConfig.httpLinkOptions = {}
        }
        if (!defiEdgeArbitrumClientConfig.httpLinkOptions.headers) {
          defiEdgeArbitrumClientConfig.httpLinkOptions.headers = {}
        }
        defiEdgeArbitrumClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let defiEdgeArbitrumApolloCreation = createApolloClient({
        ...defiEdgeArbitrumClientConfig
      })
      defiEdgeArbitrumApolloCreation.apolloClient.wsClient = defiEdgeArbitrumApolloCreation.wsClient

          providerOptions.clients.defiEdgeArbitrum = defiEdgeArbitrumApolloCreation.apolloClient

      const defiEdgeOptimismTokenName = ''  || AUTH_TOKEN_NAME

      function defiEdgeOptimismGetAuth () {
        const token = cookies.get(defiEdgeOptimismTokenName)
        return token && defiEdgeOptimismClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let defiEdgeOptimismClientConfig

        defiEdgeOptimismClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/pranavraut033/optimism-v1"
}

      const defiEdgeOptimismValidateToken = () => true

      if (!defiEdgeOptimismClientConfig.validateToken) {
        defiEdgeOptimismClientConfig.validateToken = defiEdgeOptimismValidateToken
      }

      const defiEdgeOptimismCache = defiEdgeOptimismClientConfig.cache
        ? defiEdgeOptimismClientConfig.cache
        : new InMemoryCache(defiEdgeOptimismClientConfig.inMemoryCacheOptions ? defiEdgeOptimismClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        defiEdgeOptimismCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.defiEdgeOptimism : null)
      }

      if (!defiEdgeOptimismClientConfig.getAuth) {
        defiEdgeOptimismClientConfig.getAuth = defiEdgeOptimismGetAuth
      }

      if (process.client && defiEdgeOptimismClientConfig.browserHttpEndpoint) {
        defiEdgeOptimismClientConfig.httpEndpoint = defiEdgeOptimismClientConfig.browserHttpEndpoint
      }

      defiEdgeOptimismClientConfig.ssr = !!process.server
      defiEdgeOptimismClientConfig.cache = defiEdgeOptimismCache
      defiEdgeOptimismClientConfig.tokenName = defiEdgeOptimismTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!defiEdgeOptimismClientConfig.httpLinkOptions) {
          defiEdgeOptimismClientConfig.httpLinkOptions = {}
        }
        if (!defiEdgeOptimismClientConfig.httpLinkOptions.headers) {
          defiEdgeOptimismClientConfig.httpLinkOptions.headers = {}
        }
        defiEdgeOptimismClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let defiEdgeOptimismApolloCreation = createApolloClient({
        ...defiEdgeOptimismClientConfig
      })
      defiEdgeOptimismApolloCreation.apolloClient.wsClient = defiEdgeOptimismApolloCreation.wsClient

          providerOptions.clients.defiEdgeOptimism = defiEdgeOptimismApolloCreation.apolloClient

      const defiEdgeGoerliTokenName = ''  || AUTH_TOKEN_NAME

      function defiEdgeGoerliGetAuth () {
        const token = cookies.get(defiEdgeGoerliTokenName)
        return token && defiEdgeGoerliClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let defiEdgeGoerliClientConfig

        defiEdgeGoerliClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/unbound-finance/goerli"
}

      const defiEdgeGoerliValidateToken = () => true

      if (!defiEdgeGoerliClientConfig.validateToken) {
        defiEdgeGoerliClientConfig.validateToken = defiEdgeGoerliValidateToken
      }

      const defiEdgeGoerliCache = defiEdgeGoerliClientConfig.cache
        ? defiEdgeGoerliClientConfig.cache
        : new InMemoryCache(defiEdgeGoerliClientConfig.inMemoryCacheOptions ? defiEdgeGoerliClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        defiEdgeGoerliCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.defiEdgeGoerli : null)
      }

      if (!defiEdgeGoerliClientConfig.getAuth) {
        defiEdgeGoerliClientConfig.getAuth = defiEdgeGoerliGetAuth
      }

      if (process.client && defiEdgeGoerliClientConfig.browserHttpEndpoint) {
        defiEdgeGoerliClientConfig.httpEndpoint = defiEdgeGoerliClientConfig.browserHttpEndpoint
      }

      defiEdgeGoerliClientConfig.ssr = !!process.server
      defiEdgeGoerliClientConfig.cache = defiEdgeGoerliCache
      defiEdgeGoerliClientConfig.tokenName = defiEdgeGoerliTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!defiEdgeGoerliClientConfig.httpLinkOptions) {
          defiEdgeGoerliClientConfig.httpLinkOptions = {}
        }
        if (!defiEdgeGoerliClientConfig.httpLinkOptions.headers) {
          defiEdgeGoerliClientConfig.httpLinkOptions.headers = {}
        }
        defiEdgeGoerliClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let defiEdgeGoerliApolloCreation = createApolloClient({
        ...defiEdgeGoerliClientConfig
      })
      defiEdgeGoerliApolloCreation.apolloClient.wsClient = defiEdgeGoerliApolloCreation.wsClient

          providerOptions.clients.defiEdgeGoerli = defiEdgeGoerliApolloCreation.apolloClient

      const defiEdgeBscTokenName = ''  || AUTH_TOKEN_NAME

      function defiEdgeBscGetAuth () {
        const token = cookies.get(defiEdgeBscTokenName)
        return token && defiEdgeBscClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let defiEdgeBscClientConfig

        defiEdgeBscClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/pranavraut033/bsc-v1"
}

      const defiEdgeBscValidateToken = () => true

      if (!defiEdgeBscClientConfig.validateToken) {
        defiEdgeBscClientConfig.validateToken = defiEdgeBscValidateToken
      }

      const defiEdgeBscCache = defiEdgeBscClientConfig.cache
        ? defiEdgeBscClientConfig.cache
        : new InMemoryCache(defiEdgeBscClientConfig.inMemoryCacheOptions ? defiEdgeBscClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        defiEdgeBscCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.defiEdgeBsc : null)
      }

      if (!defiEdgeBscClientConfig.getAuth) {
        defiEdgeBscClientConfig.getAuth = defiEdgeBscGetAuth
      }

      if (process.client && defiEdgeBscClientConfig.browserHttpEndpoint) {
        defiEdgeBscClientConfig.httpEndpoint = defiEdgeBscClientConfig.browserHttpEndpoint
      }

      defiEdgeBscClientConfig.ssr = !!process.server
      defiEdgeBscClientConfig.cache = defiEdgeBscCache
      defiEdgeBscClientConfig.tokenName = defiEdgeBscTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!defiEdgeBscClientConfig.httpLinkOptions) {
          defiEdgeBscClientConfig.httpLinkOptions = {}
        }
        if (!defiEdgeBscClientConfig.httpLinkOptions.headers) {
          defiEdgeBscClientConfig.httpLinkOptions.headers = {}
        }
        defiEdgeBscClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let defiEdgeBscApolloCreation = createApolloClient({
        ...defiEdgeBscClientConfig
      })
      defiEdgeBscApolloCreation.apolloClient.wsClient = defiEdgeBscApolloCreation.wsClient

          providerOptions.clients.defiEdgeBsc = defiEdgeBscApolloCreation.apolloClient

      const defiEdgeMoonbeamTokenName = ''  || AUTH_TOKEN_NAME

      function defiEdgeMoonbeamGetAuth () {
        const token = cookies.get(defiEdgeMoonbeamTokenName)
        return token && defiEdgeMoonbeamClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let defiEdgeMoonbeamClientConfig

        defiEdgeMoonbeamClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/unbound-finance/moonbeam-staging"
}

      const defiEdgeMoonbeamValidateToken = () => true

      if (!defiEdgeMoonbeamClientConfig.validateToken) {
        defiEdgeMoonbeamClientConfig.validateToken = defiEdgeMoonbeamValidateToken
      }

      const defiEdgeMoonbeamCache = defiEdgeMoonbeamClientConfig.cache
        ? defiEdgeMoonbeamClientConfig.cache
        : new InMemoryCache(defiEdgeMoonbeamClientConfig.inMemoryCacheOptions ? defiEdgeMoonbeamClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        defiEdgeMoonbeamCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.defiEdgeMoonbeam : null)
      }

      if (!defiEdgeMoonbeamClientConfig.getAuth) {
        defiEdgeMoonbeamClientConfig.getAuth = defiEdgeMoonbeamGetAuth
      }

      if (process.client && defiEdgeMoonbeamClientConfig.browserHttpEndpoint) {
        defiEdgeMoonbeamClientConfig.httpEndpoint = defiEdgeMoonbeamClientConfig.browserHttpEndpoint
      }

      defiEdgeMoonbeamClientConfig.ssr = !!process.server
      defiEdgeMoonbeamClientConfig.cache = defiEdgeMoonbeamCache
      defiEdgeMoonbeamClientConfig.tokenName = defiEdgeMoonbeamTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!defiEdgeMoonbeamClientConfig.httpLinkOptions) {
          defiEdgeMoonbeamClientConfig.httpLinkOptions = {}
        }
        if (!defiEdgeMoonbeamClientConfig.httpLinkOptions.headers) {
          defiEdgeMoonbeamClientConfig.httpLinkOptions.headers = {}
        }
        defiEdgeMoonbeamClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let defiEdgeMoonbeamApolloCreation = createApolloClient({
        ...defiEdgeMoonbeamClientConfig
      })
      defiEdgeMoonbeamApolloCreation.apolloClient.wsClient = defiEdgeMoonbeamApolloCreation.wsClient

          providerOptions.clients.defiEdgeMoonbeam = defiEdgeMoonbeamApolloCreation.apolloClient

      const defiEdgeMantleTokenName = ''  || AUTH_TOKEN_NAME

      function defiEdgeMantleGetAuth () {
        const token = cookies.get(defiEdgeMantleTokenName)
        return token && defiEdgeMantleClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let defiEdgeMantleClientConfig

        defiEdgeMantleClientConfig = {
  "httpEndpoint": "https://graph.fusionx.finance/subgraphs/name/unbound-finance/mantle-staging"
}

      const defiEdgeMantleValidateToken = () => true

      if (!defiEdgeMantleClientConfig.validateToken) {
        defiEdgeMantleClientConfig.validateToken = defiEdgeMantleValidateToken
      }

      const defiEdgeMantleCache = defiEdgeMantleClientConfig.cache
        ? defiEdgeMantleClientConfig.cache
        : new InMemoryCache(defiEdgeMantleClientConfig.inMemoryCacheOptions ? defiEdgeMantleClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        defiEdgeMantleCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.defiEdgeMantle : null)
      }

      if (!defiEdgeMantleClientConfig.getAuth) {
        defiEdgeMantleClientConfig.getAuth = defiEdgeMantleGetAuth
      }

      if (process.client && defiEdgeMantleClientConfig.browserHttpEndpoint) {
        defiEdgeMantleClientConfig.httpEndpoint = defiEdgeMantleClientConfig.browserHttpEndpoint
      }

      defiEdgeMantleClientConfig.ssr = !!process.server
      defiEdgeMantleClientConfig.cache = defiEdgeMantleCache
      defiEdgeMantleClientConfig.tokenName = defiEdgeMantleTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!defiEdgeMantleClientConfig.httpLinkOptions) {
          defiEdgeMantleClientConfig.httpLinkOptions = {}
        }
        if (!defiEdgeMantleClientConfig.httpLinkOptions.headers) {
          defiEdgeMantleClientConfig.httpLinkOptions.headers = {}
        }
        defiEdgeMantleClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let defiEdgeMantleApolloCreation = createApolloClient({
        ...defiEdgeMantleClientConfig
      })
      defiEdgeMantleApolloCreation.apolloClient.wsClient = defiEdgeMantleApolloCreation.wsClient

          providerOptions.clients.defiEdgeMantle = defiEdgeMantleApolloCreation.apolloClient

      const defiEdgeAvalancheTokenName = ''  || AUTH_TOKEN_NAME

      function defiEdgeAvalancheGetAuth () {
        const token = cookies.get(defiEdgeAvalancheTokenName)
        return token && defiEdgeAvalancheClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let defiEdgeAvalancheClientConfig

        defiEdgeAvalancheClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/defiedge/defiedge-avalanche"
}

      const defiEdgeAvalancheValidateToken = () => true

      if (!defiEdgeAvalancheClientConfig.validateToken) {
        defiEdgeAvalancheClientConfig.validateToken = defiEdgeAvalancheValidateToken
      }

      const defiEdgeAvalancheCache = defiEdgeAvalancheClientConfig.cache
        ? defiEdgeAvalancheClientConfig.cache
        : new InMemoryCache(defiEdgeAvalancheClientConfig.inMemoryCacheOptions ? defiEdgeAvalancheClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        defiEdgeAvalancheCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.defiEdgeAvalanche : null)
      }

      if (!defiEdgeAvalancheClientConfig.getAuth) {
        defiEdgeAvalancheClientConfig.getAuth = defiEdgeAvalancheGetAuth
      }

      if (process.client && defiEdgeAvalancheClientConfig.browserHttpEndpoint) {
        defiEdgeAvalancheClientConfig.httpEndpoint = defiEdgeAvalancheClientConfig.browserHttpEndpoint
      }

      defiEdgeAvalancheClientConfig.ssr = !!process.server
      defiEdgeAvalancheClientConfig.cache = defiEdgeAvalancheCache
      defiEdgeAvalancheClientConfig.tokenName = defiEdgeAvalancheTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!defiEdgeAvalancheClientConfig.httpLinkOptions) {
          defiEdgeAvalancheClientConfig.httpLinkOptions = {}
        }
        if (!defiEdgeAvalancheClientConfig.httpLinkOptions.headers) {
          defiEdgeAvalancheClientConfig.httpLinkOptions.headers = {}
        }
        defiEdgeAvalancheClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let defiEdgeAvalancheApolloCreation = createApolloClient({
        ...defiEdgeAvalancheClientConfig
      })
      defiEdgeAvalancheApolloCreation.apolloClient.wsClient = defiEdgeAvalancheApolloCreation.wsClient

          providerOptions.clients.defiEdgeAvalanche = defiEdgeAvalancheApolloCreation.apolloClient

      const defiEdgeZksyncEraTokenName = ''  || AUTH_TOKEN_NAME

      function defiEdgeZksyncEraGetAuth () {
        const token = cookies.get(defiEdgeZksyncEraTokenName)
        return token && defiEdgeZksyncEraClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let defiEdgeZksyncEraClientConfig

        defiEdgeZksyncEraClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/defiedge/defiedge-zksync-era"
}

      const defiEdgeZksyncEraValidateToken = () => true

      if (!defiEdgeZksyncEraClientConfig.validateToken) {
        defiEdgeZksyncEraClientConfig.validateToken = defiEdgeZksyncEraValidateToken
      }

      const defiEdgeZksyncEraCache = defiEdgeZksyncEraClientConfig.cache
        ? defiEdgeZksyncEraClientConfig.cache
        : new InMemoryCache(defiEdgeZksyncEraClientConfig.inMemoryCacheOptions ? defiEdgeZksyncEraClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        defiEdgeZksyncEraCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.defiEdgeZksyncEra : null)
      }

      if (!defiEdgeZksyncEraClientConfig.getAuth) {
        defiEdgeZksyncEraClientConfig.getAuth = defiEdgeZksyncEraGetAuth
      }

      if (process.client && defiEdgeZksyncEraClientConfig.browserHttpEndpoint) {
        defiEdgeZksyncEraClientConfig.httpEndpoint = defiEdgeZksyncEraClientConfig.browserHttpEndpoint
      }

      defiEdgeZksyncEraClientConfig.ssr = !!process.server
      defiEdgeZksyncEraClientConfig.cache = defiEdgeZksyncEraCache
      defiEdgeZksyncEraClientConfig.tokenName = defiEdgeZksyncEraTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!defiEdgeZksyncEraClientConfig.httpLinkOptions) {
          defiEdgeZksyncEraClientConfig.httpLinkOptions = {}
        }
        if (!defiEdgeZksyncEraClientConfig.httpLinkOptions.headers) {
          defiEdgeZksyncEraClientConfig.httpLinkOptions.headers = {}
        }
        defiEdgeZksyncEraClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let defiEdgeZksyncEraApolloCreation = createApolloClient({
        ...defiEdgeZksyncEraClientConfig
      })
      defiEdgeZksyncEraApolloCreation.apolloClient.wsClient = defiEdgeZksyncEraApolloCreation.wsClient

          providerOptions.clients.defiEdgeZksyncEra = defiEdgeZksyncEraApolloCreation.apolloClient

      const defiEdgeBaseTokenName = ''  || AUTH_TOKEN_NAME

      function defiEdgeBaseGetAuth () {
        const token = cookies.get(defiEdgeBaseTokenName)
        return token && defiEdgeBaseClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let defiEdgeBaseClientConfig

        defiEdgeBaseClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/unbound-finance/base-goerli-staging"
}

      const defiEdgeBaseValidateToken = () => true

      if (!defiEdgeBaseClientConfig.validateToken) {
        defiEdgeBaseClientConfig.validateToken = defiEdgeBaseValidateToken
      }

      const defiEdgeBaseCache = defiEdgeBaseClientConfig.cache
        ? defiEdgeBaseClientConfig.cache
        : new InMemoryCache(defiEdgeBaseClientConfig.inMemoryCacheOptions ? defiEdgeBaseClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        defiEdgeBaseCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.defiEdgeBase : null)
      }

      if (!defiEdgeBaseClientConfig.getAuth) {
        defiEdgeBaseClientConfig.getAuth = defiEdgeBaseGetAuth
      }

      if (process.client && defiEdgeBaseClientConfig.browserHttpEndpoint) {
        defiEdgeBaseClientConfig.httpEndpoint = defiEdgeBaseClientConfig.browserHttpEndpoint
      }

      defiEdgeBaseClientConfig.ssr = !!process.server
      defiEdgeBaseClientConfig.cache = defiEdgeBaseCache
      defiEdgeBaseClientConfig.tokenName = defiEdgeBaseTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!defiEdgeBaseClientConfig.httpLinkOptions) {
          defiEdgeBaseClientConfig.httpLinkOptions = {}
        }
        if (!defiEdgeBaseClientConfig.httpLinkOptions.headers) {
          defiEdgeBaseClientConfig.httpLinkOptions.headers = {}
        }
        defiEdgeBaseClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let defiEdgeBaseApolloCreation = createApolloClient({
        ...defiEdgeBaseClientConfig
      })
      defiEdgeBaseApolloCreation.apolloClient.wsClient = defiEdgeBaseApolloCreation.wsClient

          providerOptions.clients.defiEdgeBase = defiEdgeBaseApolloCreation.apolloClient

      const defiEdgeBaseTestnetTokenName = ''  || AUTH_TOKEN_NAME

      function defiEdgeBaseTestnetGetAuth () {
        const token = cookies.get(defiEdgeBaseTestnetTokenName)
        return token && defiEdgeBaseTestnetClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let defiEdgeBaseTestnetClientConfig

        defiEdgeBaseTestnetClientConfig = {
  "httpEndpoint": "https://subgraph.goerli.horiza.io/subgraphs/name/unbound-finance/base-goerli-staging"
}

      const defiEdgeBaseTestnetValidateToken = () => true

      if (!defiEdgeBaseTestnetClientConfig.validateToken) {
        defiEdgeBaseTestnetClientConfig.validateToken = defiEdgeBaseTestnetValidateToken
      }

      const defiEdgeBaseTestnetCache = defiEdgeBaseTestnetClientConfig.cache
        ? defiEdgeBaseTestnetClientConfig.cache
        : new InMemoryCache(defiEdgeBaseTestnetClientConfig.inMemoryCacheOptions ? defiEdgeBaseTestnetClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        defiEdgeBaseTestnetCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.defiEdgeBaseTestnet : null)
      }

      if (!defiEdgeBaseTestnetClientConfig.getAuth) {
        defiEdgeBaseTestnetClientConfig.getAuth = defiEdgeBaseTestnetGetAuth
      }

      if (process.client && defiEdgeBaseTestnetClientConfig.browserHttpEndpoint) {
        defiEdgeBaseTestnetClientConfig.httpEndpoint = defiEdgeBaseTestnetClientConfig.browserHttpEndpoint
      }

      defiEdgeBaseTestnetClientConfig.ssr = !!process.server
      defiEdgeBaseTestnetClientConfig.cache = defiEdgeBaseTestnetCache
      defiEdgeBaseTestnetClientConfig.tokenName = defiEdgeBaseTestnetTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!defiEdgeBaseTestnetClientConfig.httpLinkOptions) {
          defiEdgeBaseTestnetClientConfig.httpLinkOptions = {}
        }
        if (!defiEdgeBaseTestnetClientConfig.httpLinkOptions.headers) {
          defiEdgeBaseTestnetClientConfig.httpLinkOptions.headers = {}
        }
        defiEdgeBaseTestnetClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let defiEdgeBaseTestnetApolloCreation = createApolloClient({
        ...defiEdgeBaseTestnetClientConfig
      })
      defiEdgeBaseTestnetApolloCreation.apolloClient.wsClient = defiEdgeBaseTestnetApolloCreation.wsClient

          providerOptions.clients.defiEdgeBaseTestnet = defiEdgeBaseTestnetApolloCreation.apolloClient

      const uniswapTokenName = ''  || AUTH_TOKEN_NAME

      function uniswapGetAuth () {
        const token = cookies.get(uniswapTokenName)
        return token && uniswapClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let uniswapClientConfig

        uniswapClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3"
}

      const uniswapValidateToken = () => true

      if (!uniswapClientConfig.validateToken) {
        uniswapClientConfig.validateToken = uniswapValidateToken
      }

      const uniswapCache = uniswapClientConfig.cache
        ? uniswapClientConfig.cache
        : new InMemoryCache(uniswapClientConfig.inMemoryCacheOptions ? uniswapClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        uniswapCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.uniswap : null)
      }

      if (!uniswapClientConfig.getAuth) {
        uniswapClientConfig.getAuth = uniswapGetAuth
      }

      if (process.client && uniswapClientConfig.browserHttpEndpoint) {
        uniswapClientConfig.httpEndpoint = uniswapClientConfig.browserHttpEndpoint
      }

      uniswapClientConfig.ssr = !!process.server
      uniswapClientConfig.cache = uniswapCache
      uniswapClientConfig.tokenName = uniswapTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!uniswapClientConfig.httpLinkOptions) {
          uniswapClientConfig.httpLinkOptions = {}
        }
        if (!uniswapClientConfig.httpLinkOptions.headers) {
          uniswapClientConfig.httpLinkOptions.headers = {}
        }
        uniswapClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let uniswapApolloCreation = createApolloClient({
        ...uniswapClientConfig
      })
      uniswapApolloCreation.apolloClient.wsClient = uniswapApolloCreation.wsClient

          providerOptions.clients.uniswap = uniswapApolloCreation.apolloClient

      const uniswapKovanTokenName = ''  || AUTH_TOKEN_NAME

      function uniswapKovanGetAuth () {
        const token = cookies.get(uniswapKovanTokenName)
        return token && uniswapKovanClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let uniswapKovanClientConfig

        uniswapKovanClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/renpu-mcarlo/uniswap-v3-kovan"
}

      const uniswapKovanValidateToken = () => true

      if (!uniswapKovanClientConfig.validateToken) {
        uniswapKovanClientConfig.validateToken = uniswapKovanValidateToken
      }

      const uniswapKovanCache = uniswapKovanClientConfig.cache
        ? uniswapKovanClientConfig.cache
        : new InMemoryCache(uniswapKovanClientConfig.inMemoryCacheOptions ? uniswapKovanClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        uniswapKovanCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.uniswapKovan : null)
      }

      if (!uniswapKovanClientConfig.getAuth) {
        uniswapKovanClientConfig.getAuth = uniswapKovanGetAuth
      }

      if (process.client && uniswapKovanClientConfig.browserHttpEndpoint) {
        uniswapKovanClientConfig.httpEndpoint = uniswapKovanClientConfig.browserHttpEndpoint
      }

      uniswapKovanClientConfig.ssr = !!process.server
      uniswapKovanClientConfig.cache = uniswapKovanCache
      uniswapKovanClientConfig.tokenName = uniswapKovanTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!uniswapKovanClientConfig.httpLinkOptions) {
          uniswapKovanClientConfig.httpLinkOptions = {}
        }
        if (!uniswapKovanClientConfig.httpLinkOptions.headers) {
          uniswapKovanClientConfig.httpLinkOptions.headers = {}
        }
        uniswapKovanClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let uniswapKovanApolloCreation = createApolloClient({
        ...uniswapKovanClientConfig
      })
      uniswapKovanApolloCreation.apolloClient.wsClient = uniswapKovanApolloCreation.wsClient

          providerOptions.clients.uniswapKovan = uniswapKovanApolloCreation.apolloClient

      const uniswapRinkebyTokenName = ''  || AUTH_TOKEN_NAME

      function uniswapRinkebyGetAuth () {
        const token = cookies.get(uniswapRinkebyTokenName)
        return token && uniswapRinkebyClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let uniswapRinkebyClientConfig

        uniswapRinkebyClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/renpu-mcarlo/uniswap-v3-rinkeby"
}

      const uniswapRinkebyValidateToken = () => true

      if (!uniswapRinkebyClientConfig.validateToken) {
        uniswapRinkebyClientConfig.validateToken = uniswapRinkebyValidateToken
      }

      const uniswapRinkebyCache = uniswapRinkebyClientConfig.cache
        ? uniswapRinkebyClientConfig.cache
        : new InMemoryCache(uniswapRinkebyClientConfig.inMemoryCacheOptions ? uniswapRinkebyClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        uniswapRinkebyCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.uniswapRinkeby : null)
      }

      if (!uniswapRinkebyClientConfig.getAuth) {
        uniswapRinkebyClientConfig.getAuth = uniswapRinkebyGetAuth
      }

      if (process.client && uniswapRinkebyClientConfig.browserHttpEndpoint) {
        uniswapRinkebyClientConfig.httpEndpoint = uniswapRinkebyClientConfig.browserHttpEndpoint
      }

      uniswapRinkebyClientConfig.ssr = !!process.server
      uniswapRinkebyClientConfig.cache = uniswapRinkebyCache
      uniswapRinkebyClientConfig.tokenName = uniswapRinkebyTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!uniswapRinkebyClientConfig.httpLinkOptions) {
          uniswapRinkebyClientConfig.httpLinkOptions = {}
        }
        if (!uniswapRinkebyClientConfig.httpLinkOptions.headers) {
          uniswapRinkebyClientConfig.httpLinkOptions.headers = {}
        }
        uniswapRinkebyClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let uniswapRinkebyApolloCreation = createApolloClient({
        ...uniswapRinkebyClientConfig
      })
      uniswapRinkebyApolloCreation.apolloClient.wsClient = uniswapRinkebyApolloCreation.wsClient

          providerOptions.clients.uniswapRinkeby = uniswapRinkebyApolloCreation.apolloClient

      const uniswapPolygonTokenName = ''  || AUTH_TOKEN_NAME

      function uniswapPolygonGetAuth () {
        const token = cookies.get(uniswapPolygonTokenName)
        return token && uniswapPolygonClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let uniswapPolygonClientConfig

        uniswapPolygonClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/revert-finance/uniswap-v3-polygon"
}

      const uniswapPolygonValidateToken = () => true

      if (!uniswapPolygonClientConfig.validateToken) {
        uniswapPolygonClientConfig.validateToken = uniswapPolygonValidateToken
      }

      const uniswapPolygonCache = uniswapPolygonClientConfig.cache
        ? uniswapPolygonClientConfig.cache
        : new InMemoryCache(uniswapPolygonClientConfig.inMemoryCacheOptions ? uniswapPolygonClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        uniswapPolygonCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.uniswapPolygon : null)
      }

      if (!uniswapPolygonClientConfig.getAuth) {
        uniswapPolygonClientConfig.getAuth = uniswapPolygonGetAuth
      }

      if (process.client && uniswapPolygonClientConfig.browserHttpEndpoint) {
        uniswapPolygonClientConfig.httpEndpoint = uniswapPolygonClientConfig.browserHttpEndpoint
      }

      uniswapPolygonClientConfig.ssr = !!process.server
      uniswapPolygonClientConfig.cache = uniswapPolygonCache
      uniswapPolygonClientConfig.tokenName = uniswapPolygonTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!uniswapPolygonClientConfig.httpLinkOptions) {
          uniswapPolygonClientConfig.httpLinkOptions = {}
        }
        if (!uniswapPolygonClientConfig.httpLinkOptions.headers) {
          uniswapPolygonClientConfig.httpLinkOptions.headers = {}
        }
        uniswapPolygonClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let uniswapPolygonApolloCreation = createApolloClient({
        ...uniswapPolygonClientConfig
      })
      uniswapPolygonApolloCreation.apolloClient.wsClient = uniswapPolygonApolloCreation.wsClient

          providerOptions.clients.uniswapPolygon = uniswapPolygonApolloCreation.apolloClient

      const uniswapArbitrumTokenName = ''  || AUTH_TOKEN_NAME

      function uniswapArbitrumGetAuth () {
        const token = cookies.get(uniswapArbitrumTokenName)
        return token && uniswapArbitrumClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let uniswapArbitrumClientConfig

        uniswapArbitrumClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/revert-finance/uniswap-v3-arbitrum"
}

      const uniswapArbitrumValidateToken = () => true

      if (!uniswapArbitrumClientConfig.validateToken) {
        uniswapArbitrumClientConfig.validateToken = uniswapArbitrumValidateToken
      }

      const uniswapArbitrumCache = uniswapArbitrumClientConfig.cache
        ? uniswapArbitrumClientConfig.cache
        : new InMemoryCache(uniswapArbitrumClientConfig.inMemoryCacheOptions ? uniswapArbitrumClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        uniswapArbitrumCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.uniswapArbitrum : null)
      }

      if (!uniswapArbitrumClientConfig.getAuth) {
        uniswapArbitrumClientConfig.getAuth = uniswapArbitrumGetAuth
      }

      if (process.client && uniswapArbitrumClientConfig.browserHttpEndpoint) {
        uniswapArbitrumClientConfig.httpEndpoint = uniswapArbitrumClientConfig.browserHttpEndpoint
      }

      uniswapArbitrumClientConfig.ssr = !!process.server
      uniswapArbitrumClientConfig.cache = uniswapArbitrumCache
      uniswapArbitrumClientConfig.tokenName = uniswapArbitrumTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!uniswapArbitrumClientConfig.httpLinkOptions) {
          uniswapArbitrumClientConfig.httpLinkOptions = {}
        }
        if (!uniswapArbitrumClientConfig.httpLinkOptions.headers) {
          uniswapArbitrumClientConfig.httpLinkOptions.headers = {}
        }
        uniswapArbitrumClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let uniswapArbitrumApolloCreation = createApolloClient({
        ...uniswapArbitrumClientConfig
      })
      uniswapArbitrumApolloCreation.apolloClient.wsClient = uniswapArbitrumApolloCreation.wsClient

          providerOptions.clients.uniswapArbitrum = uniswapArbitrumApolloCreation.apolloClient

      const uniswapOptimismTokenName = ''  || AUTH_TOKEN_NAME

      function uniswapOptimismGetAuth () {
        const token = cookies.get(uniswapOptimismTokenName)
        return token && uniswapOptimismClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let uniswapOptimismClientConfig

        uniswapOptimismClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/ianlapham/optimism-post-regenesis"
}

      const uniswapOptimismValidateToken = () => true

      if (!uniswapOptimismClientConfig.validateToken) {
        uniswapOptimismClientConfig.validateToken = uniswapOptimismValidateToken
      }

      const uniswapOptimismCache = uniswapOptimismClientConfig.cache
        ? uniswapOptimismClientConfig.cache
        : new InMemoryCache(uniswapOptimismClientConfig.inMemoryCacheOptions ? uniswapOptimismClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        uniswapOptimismCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.uniswapOptimism : null)
      }

      if (!uniswapOptimismClientConfig.getAuth) {
        uniswapOptimismClientConfig.getAuth = uniswapOptimismGetAuth
      }

      if (process.client && uniswapOptimismClientConfig.browserHttpEndpoint) {
        uniswapOptimismClientConfig.httpEndpoint = uniswapOptimismClientConfig.browserHttpEndpoint
      }

      uniswapOptimismClientConfig.ssr = !!process.server
      uniswapOptimismClientConfig.cache = uniswapOptimismCache
      uniswapOptimismClientConfig.tokenName = uniswapOptimismTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!uniswapOptimismClientConfig.httpLinkOptions) {
          uniswapOptimismClientConfig.httpLinkOptions = {}
        }
        if (!uniswapOptimismClientConfig.httpLinkOptions.headers) {
          uniswapOptimismClientConfig.httpLinkOptions.headers = {}
        }
        uniswapOptimismClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let uniswapOptimismApolloCreation = createApolloClient({
        ...uniswapOptimismClientConfig
      })
      uniswapOptimismApolloCreation.apolloClient.wsClient = uniswapOptimismApolloCreation.wsClient

          providerOptions.clients.uniswapOptimism = uniswapOptimismApolloCreation.apolloClient

      const uniswapGoerliTokenName = ''  || AUTH_TOKEN_NAME

      function uniswapGoerliGetAuth () {
        const token = cookies.get(uniswapGoerliTokenName)
        return token && uniswapGoerliClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let uniswapGoerliClientConfig

        uniswapGoerliClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/liqwiz/uniswap-v3-goerli"
}

      const uniswapGoerliValidateToken = () => true

      if (!uniswapGoerliClientConfig.validateToken) {
        uniswapGoerliClientConfig.validateToken = uniswapGoerliValidateToken
      }

      const uniswapGoerliCache = uniswapGoerliClientConfig.cache
        ? uniswapGoerliClientConfig.cache
        : new InMemoryCache(uniswapGoerliClientConfig.inMemoryCacheOptions ? uniswapGoerliClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        uniswapGoerliCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.uniswapGoerli : null)
      }

      if (!uniswapGoerliClientConfig.getAuth) {
        uniswapGoerliClientConfig.getAuth = uniswapGoerliGetAuth
      }

      if (process.client && uniswapGoerliClientConfig.browserHttpEndpoint) {
        uniswapGoerliClientConfig.httpEndpoint = uniswapGoerliClientConfig.browserHttpEndpoint
      }

      uniswapGoerliClientConfig.ssr = !!process.server
      uniswapGoerliClientConfig.cache = uniswapGoerliCache
      uniswapGoerliClientConfig.tokenName = uniswapGoerliTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!uniswapGoerliClientConfig.httpLinkOptions) {
          uniswapGoerliClientConfig.httpLinkOptions = {}
        }
        if (!uniswapGoerliClientConfig.httpLinkOptions.headers) {
          uniswapGoerliClientConfig.httpLinkOptions.headers = {}
        }
        uniswapGoerliClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let uniswapGoerliApolloCreation = createApolloClient({
        ...uniswapGoerliClientConfig
      })
      uniswapGoerliApolloCreation.apolloClient.wsClient = uniswapGoerliApolloCreation.wsClient

          providerOptions.clients.uniswapGoerli = uniswapGoerliApolloCreation.apolloClient

      const uniswapBscTokenName = ''  || AUTH_TOKEN_NAME

      function uniswapBscGetAuth () {
        const token = cookies.get(uniswapBscTokenName)
        return token && uniswapBscClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let uniswapBscClientConfig

        uniswapBscClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-bsc"
}

      const uniswapBscValidateToken = () => true

      if (!uniswapBscClientConfig.validateToken) {
        uniswapBscClientConfig.validateToken = uniswapBscValidateToken
      }

      const uniswapBscCache = uniswapBscClientConfig.cache
        ? uniswapBscClientConfig.cache
        : new InMemoryCache(uniswapBscClientConfig.inMemoryCacheOptions ? uniswapBscClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        uniswapBscCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.uniswapBsc : null)
      }

      if (!uniswapBscClientConfig.getAuth) {
        uniswapBscClientConfig.getAuth = uniswapBscGetAuth
      }

      if (process.client && uniswapBscClientConfig.browserHttpEndpoint) {
        uniswapBscClientConfig.httpEndpoint = uniswapBscClientConfig.browserHttpEndpoint
      }

      uniswapBscClientConfig.ssr = !!process.server
      uniswapBscClientConfig.cache = uniswapBscCache
      uniswapBscClientConfig.tokenName = uniswapBscTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!uniswapBscClientConfig.httpLinkOptions) {
          uniswapBscClientConfig.httpLinkOptions = {}
        }
        if (!uniswapBscClientConfig.httpLinkOptions.headers) {
          uniswapBscClientConfig.httpLinkOptions.headers = {}
        }
        uniswapBscClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let uniswapBscApolloCreation = createApolloClient({
        ...uniswapBscClientConfig
      })
      uniswapBscApolloCreation.apolloClient.wsClient = uniswapBscApolloCreation.wsClient

          providerOptions.clients.uniswapBsc = uniswapBscApolloCreation.apolloClient

      const uniswapBaseTokenName = ''  || AUTH_TOKEN_NAME

      function uniswapBaseGetAuth () {
        const token = cookies.get(uniswapBaseTokenName)
        return token && uniswapBaseClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let uniswapBaseClientConfig

        uniswapBaseClientConfig = {
  "httpEndpoint": "https://api.studio.thegraph.com/query/48211/uniswap-v3-base/version/latest"
}

      const uniswapBaseValidateToken = () => true

      if (!uniswapBaseClientConfig.validateToken) {
        uniswapBaseClientConfig.validateToken = uniswapBaseValidateToken
      }

      const uniswapBaseCache = uniswapBaseClientConfig.cache
        ? uniswapBaseClientConfig.cache
        : new InMemoryCache(uniswapBaseClientConfig.inMemoryCacheOptions ? uniswapBaseClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        uniswapBaseCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.uniswapBase : null)
      }

      if (!uniswapBaseClientConfig.getAuth) {
        uniswapBaseClientConfig.getAuth = uniswapBaseGetAuth
      }

      if (process.client && uniswapBaseClientConfig.browserHttpEndpoint) {
        uniswapBaseClientConfig.httpEndpoint = uniswapBaseClientConfig.browserHttpEndpoint
      }

      uniswapBaseClientConfig.ssr = !!process.server
      uniswapBaseClientConfig.cache = uniswapBaseCache
      uniswapBaseClientConfig.tokenName = uniswapBaseTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!uniswapBaseClientConfig.httpLinkOptions) {
          uniswapBaseClientConfig.httpLinkOptions = {}
        }
        if (!uniswapBaseClientConfig.httpLinkOptions.headers) {
          uniswapBaseClientConfig.httpLinkOptions.headers = {}
        }
        uniswapBaseClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let uniswapBaseApolloCreation = createApolloClient({
        ...uniswapBaseClientConfig
      })
      uniswapBaseApolloCreation.apolloClient.wsClient = uniswapBaseApolloCreation.wsClient

          providerOptions.clients.uniswapBase = uniswapBaseApolloCreation.apolloClient

      const aridexArbitrumTokenName = ''  || AUTH_TOKEN_NAME

      function aridexArbitrumGetAuth () {
        const token = cookies.get(aridexArbitrumTokenName)
        return token && aridexArbitrumClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let aridexArbitrumClientConfig

        aridexArbitrumClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/hekman-eth/arbidex-v3"
}

      const aridexArbitrumValidateToken = () => true

      if (!aridexArbitrumClientConfig.validateToken) {
        aridexArbitrumClientConfig.validateToken = aridexArbitrumValidateToken
      }

      const aridexArbitrumCache = aridexArbitrumClientConfig.cache
        ? aridexArbitrumClientConfig.cache
        : new InMemoryCache(aridexArbitrumClientConfig.inMemoryCacheOptions ? aridexArbitrumClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        aridexArbitrumCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.aridexArbitrum : null)
      }

      if (!aridexArbitrumClientConfig.getAuth) {
        aridexArbitrumClientConfig.getAuth = aridexArbitrumGetAuth
      }

      if (process.client && aridexArbitrumClientConfig.browserHttpEndpoint) {
        aridexArbitrumClientConfig.httpEndpoint = aridexArbitrumClientConfig.browserHttpEndpoint
      }

      aridexArbitrumClientConfig.ssr = !!process.server
      aridexArbitrumClientConfig.cache = aridexArbitrumCache
      aridexArbitrumClientConfig.tokenName = aridexArbitrumTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!aridexArbitrumClientConfig.httpLinkOptions) {
          aridexArbitrumClientConfig.httpLinkOptions = {}
        }
        if (!aridexArbitrumClientConfig.httpLinkOptions.headers) {
          aridexArbitrumClientConfig.httpLinkOptions.headers = {}
        }
        aridexArbitrumClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let aridexArbitrumApolloCreation = createApolloClient({
        ...aridexArbitrumClientConfig
      })
      aridexArbitrumApolloCreation.apolloClient.wsClient = aridexArbitrumApolloCreation.wsClient

          providerOptions.clients.aridexArbitrum = aridexArbitrumApolloCreation.apolloClient

      const apeSwapBscTokenName = ''  || AUTH_TOKEN_NAME

      function apeSwapBscGetAuth () {
        const token = cookies.get(apeSwapBscTokenName)
        return token && apeSwapBscClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let apeSwapBscClientConfig

        apeSwapBscClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/prof-sd/as-bsc-v3"
}

      const apeSwapBscValidateToken = () => true

      if (!apeSwapBscClientConfig.validateToken) {
        apeSwapBscClientConfig.validateToken = apeSwapBscValidateToken
      }

      const apeSwapBscCache = apeSwapBscClientConfig.cache
        ? apeSwapBscClientConfig.cache
        : new InMemoryCache(apeSwapBscClientConfig.inMemoryCacheOptions ? apeSwapBscClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        apeSwapBscCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.apeSwapBsc : null)
      }

      if (!apeSwapBscClientConfig.getAuth) {
        apeSwapBscClientConfig.getAuth = apeSwapBscGetAuth
      }

      if (process.client && apeSwapBscClientConfig.browserHttpEndpoint) {
        apeSwapBscClientConfig.httpEndpoint = apeSwapBscClientConfig.browserHttpEndpoint
      }

      apeSwapBscClientConfig.ssr = !!process.server
      apeSwapBscClientConfig.cache = apeSwapBscCache
      apeSwapBscClientConfig.tokenName = apeSwapBscTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!apeSwapBscClientConfig.httpLinkOptions) {
          apeSwapBscClientConfig.httpLinkOptions = {}
        }
        if (!apeSwapBscClientConfig.httpLinkOptions.headers) {
          apeSwapBscClientConfig.httpLinkOptions.headers = {}
        }
        apeSwapBscClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let apeSwapBscApolloCreation = createApolloClient({
        ...apeSwapBscClientConfig
      })
      apeSwapBscApolloCreation.apolloClient.wsClient = apeSwapBscApolloCreation.wsClient

          providerOptions.clients.apeSwapBsc = apeSwapBscApolloCreation.apolloClient

      const algebraArbitrumTokenName = ''  || AUTH_TOKEN_NAME

      function algebraArbitrumGetAuth () {
        const token = cookies.get(algebraArbitrumTokenName)
        return token && algebraArbitrumClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let algebraArbitrumClientConfig

        algebraArbitrumClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/camelotlabs/camelot-amm-v3"
}

      const algebraArbitrumValidateToken = () => true

      if (!algebraArbitrumClientConfig.validateToken) {
        algebraArbitrumClientConfig.validateToken = algebraArbitrumValidateToken
      }

      const algebraArbitrumCache = algebraArbitrumClientConfig.cache
        ? algebraArbitrumClientConfig.cache
        : new InMemoryCache(algebraArbitrumClientConfig.inMemoryCacheOptions ? algebraArbitrumClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        algebraArbitrumCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.algebraArbitrum : null)
      }

      if (!algebraArbitrumClientConfig.getAuth) {
        algebraArbitrumClientConfig.getAuth = algebraArbitrumGetAuth
      }

      if (process.client && algebraArbitrumClientConfig.browserHttpEndpoint) {
        algebraArbitrumClientConfig.httpEndpoint = algebraArbitrumClientConfig.browserHttpEndpoint
      }

      algebraArbitrumClientConfig.ssr = !!process.server
      algebraArbitrumClientConfig.cache = algebraArbitrumCache
      algebraArbitrumClientConfig.tokenName = algebraArbitrumTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!algebraArbitrumClientConfig.httpLinkOptions) {
          algebraArbitrumClientConfig.httpLinkOptions = {}
        }
        if (!algebraArbitrumClientConfig.httpLinkOptions.headers) {
          algebraArbitrumClientConfig.httpLinkOptions.headers = {}
        }
        algebraArbitrumClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let algebraArbitrumApolloCreation = createApolloClient({
        ...algebraArbitrumClientConfig
      })
      algebraArbitrumApolloCreation.apolloClient.wsClient = algebraArbitrumApolloCreation.wsClient

          providerOptions.clients.algebraArbitrum = algebraArbitrumApolloCreation.apolloClient

      const sushiswapArbitrumTokenName = ''  || AUTH_TOKEN_NAME

      function sushiswapArbitrumGetAuth () {
        const token = cookies.get(sushiswapArbitrumTokenName)
        return token && sushiswapArbitrumClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let sushiswapArbitrumClientConfig

        sushiswapArbitrumClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/sushi-v3/v3-arbitrum"
}

      const sushiswapArbitrumValidateToken = () => true

      if (!sushiswapArbitrumClientConfig.validateToken) {
        sushiswapArbitrumClientConfig.validateToken = sushiswapArbitrumValidateToken
      }

      const sushiswapArbitrumCache = sushiswapArbitrumClientConfig.cache
        ? sushiswapArbitrumClientConfig.cache
        : new InMemoryCache(sushiswapArbitrumClientConfig.inMemoryCacheOptions ? sushiswapArbitrumClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        sushiswapArbitrumCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.sushiswapArbitrum : null)
      }

      if (!sushiswapArbitrumClientConfig.getAuth) {
        sushiswapArbitrumClientConfig.getAuth = sushiswapArbitrumGetAuth
      }

      if (process.client && sushiswapArbitrumClientConfig.browserHttpEndpoint) {
        sushiswapArbitrumClientConfig.httpEndpoint = sushiswapArbitrumClientConfig.browserHttpEndpoint
      }

      sushiswapArbitrumClientConfig.ssr = !!process.server
      sushiswapArbitrumClientConfig.cache = sushiswapArbitrumCache
      sushiswapArbitrumClientConfig.tokenName = sushiswapArbitrumTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!sushiswapArbitrumClientConfig.httpLinkOptions) {
          sushiswapArbitrumClientConfig.httpLinkOptions = {}
        }
        if (!sushiswapArbitrumClientConfig.httpLinkOptions.headers) {
          sushiswapArbitrumClientConfig.httpLinkOptions.headers = {}
        }
        sushiswapArbitrumClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let sushiswapArbitrumApolloCreation = createApolloClient({
        ...sushiswapArbitrumClientConfig
      })
      sushiswapArbitrumApolloCreation.apolloClient.wsClient = sushiswapArbitrumApolloCreation.wsClient

          providerOptions.clients.sushiswapArbitrum = sushiswapArbitrumApolloCreation.apolloClient

      const sushiswapPolygonTokenName = ''  || AUTH_TOKEN_NAME

      function sushiswapPolygonGetAuth () {
        const token = cookies.get(sushiswapPolygonTokenName)
        return token && sushiswapPolygonClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let sushiswapPolygonClientConfig

        sushiswapPolygonClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/sushi-v3/v3-polygon"
}

      const sushiswapPolygonValidateToken = () => true

      if (!sushiswapPolygonClientConfig.validateToken) {
        sushiswapPolygonClientConfig.validateToken = sushiswapPolygonValidateToken
      }

      const sushiswapPolygonCache = sushiswapPolygonClientConfig.cache
        ? sushiswapPolygonClientConfig.cache
        : new InMemoryCache(sushiswapPolygonClientConfig.inMemoryCacheOptions ? sushiswapPolygonClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        sushiswapPolygonCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.sushiswapPolygon : null)
      }

      if (!sushiswapPolygonClientConfig.getAuth) {
        sushiswapPolygonClientConfig.getAuth = sushiswapPolygonGetAuth
      }

      if (process.client && sushiswapPolygonClientConfig.browserHttpEndpoint) {
        sushiswapPolygonClientConfig.httpEndpoint = sushiswapPolygonClientConfig.browserHttpEndpoint
      }

      sushiswapPolygonClientConfig.ssr = !!process.server
      sushiswapPolygonClientConfig.cache = sushiswapPolygonCache
      sushiswapPolygonClientConfig.tokenName = sushiswapPolygonTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!sushiswapPolygonClientConfig.httpLinkOptions) {
          sushiswapPolygonClientConfig.httpLinkOptions = {}
        }
        if (!sushiswapPolygonClientConfig.httpLinkOptions.headers) {
          sushiswapPolygonClientConfig.httpLinkOptions.headers = {}
        }
        sushiswapPolygonClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let sushiswapPolygonApolloCreation = createApolloClient({
        ...sushiswapPolygonClientConfig
      })
      sushiswapPolygonApolloCreation.apolloClient.wsClient = sushiswapPolygonApolloCreation.wsClient

          providerOptions.clients.sushiswapPolygon = sushiswapPolygonApolloCreation.apolloClient

      const retroPolygonTokenName = ''  || AUTH_TOKEN_NAME

      function retroPolygonGetAuth () {
        const token = cookies.get(retroPolygonTokenName)
        return token && retroPolygonClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let retroPolygonClientConfig

        retroPolygonClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/ruvlol/univ3-test"
}

      const retroPolygonValidateToken = () => true

      if (!retroPolygonClientConfig.validateToken) {
        retroPolygonClientConfig.validateToken = retroPolygonValidateToken
      }

      const retroPolygonCache = retroPolygonClientConfig.cache
        ? retroPolygonClientConfig.cache
        : new InMemoryCache(retroPolygonClientConfig.inMemoryCacheOptions ? retroPolygonClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        retroPolygonCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.retroPolygon : null)
      }

      if (!retroPolygonClientConfig.getAuth) {
        retroPolygonClientConfig.getAuth = retroPolygonGetAuth
      }

      if (process.client && retroPolygonClientConfig.browserHttpEndpoint) {
        retroPolygonClientConfig.httpEndpoint = retroPolygonClientConfig.browserHttpEndpoint
      }

      retroPolygonClientConfig.ssr = !!process.server
      retroPolygonClientConfig.cache = retroPolygonCache
      retroPolygonClientConfig.tokenName = retroPolygonTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!retroPolygonClientConfig.httpLinkOptions) {
          retroPolygonClientConfig.httpLinkOptions = {}
        }
        if (!retroPolygonClientConfig.httpLinkOptions.headers) {
          retroPolygonClientConfig.httpLinkOptions.headers = {}
        }
        retroPolygonClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let retroPolygonApolloCreation = createApolloClient({
        ...retroPolygonClientConfig
      })
      retroPolygonApolloCreation.apolloClient.wsClient = retroPolygonApolloCreation.wsClient

          providerOptions.clients.retroPolygon = retroPolygonApolloCreation.apolloClient

      const pancakeSwapArbTokenName = ''  || AUTH_TOKEN_NAME

      function pancakeSwapArbGetAuth () {
        const token = cookies.get(pancakeSwapArbTokenName)
        return token && pancakeSwapArbClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let pancakeSwapArbClientConfig

        pancakeSwapArbClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-arb"
}

      const pancakeSwapArbValidateToken = () => true

      if (!pancakeSwapArbClientConfig.validateToken) {
        pancakeSwapArbClientConfig.validateToken = pancakeSwapArbValidateToken
      }

      const pancakeSwapArbCache = pancakeSwapArbClientConfig.cache
        ? pancakeSwapArbClientConfig.cache
        : new InMemoryCache(pancakeSwapArbClientConfig.inMemoryCacheOptions ? pancakeSwapArbClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        pancakeSwapArbCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.pancakeSwapArb : null)
      }

      if (!pancakeSwapArbClientConfig.getAuth) {
        pancakeSwapArbClientConfig.getAuth = pancakeSwapArbGetAuth
      }

      if (process.client && pancakeSwapArbClientConfig.browserHttpEndpoint) {
        pancakeSwapArbClientConfig.httpEndpoint = pancakeSwapArbClientConfig.browserHttpEndpoint
      }

      pancakeSwapArbClientConfig.ssr = !!process.server
      pancakeSwapArbClientConfig.cache = pancakeSwapArbCache
      pancakeSwapArbClientConfig.tokenName = pancakeSwapArbTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!pancakeSwapArbClientConfig.httpLinkOptions) {
          pancakeSwapArbClientConfig.httpLinkOptions = {}
        }
        if (!pancakeSwapArbClientConfig.httpLinkOptions.headers) {
          pancakeSwapArbClientConfig.httpLinkOptions.headers = {}
        }
        pancakeSwapArbClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let pancakeSwapArbApolloCreation = createApolloClient({
        ...pancakeSwapArbClientConfig
      })
      pancakeSwapArbApolloCreation.apolloClient.wsClient = pancakeSwapArbApolloCreation.wsClient

          providerOptions.clients.pancakeSwapArb = pancakeSwapArbApolloCreation.apolloClient

      const pancakeSwapBscTokenName = ''  || AUTH_TOKEN_NAME

      function pancakeSwapBscGetAuth () {
        const token = cookies.get(pancakeSwapBscTokenName)
        return token && pancakeSwapBscClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let pancakeSwapBscClientConfig

        pancakeSwapBscClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-bsc"
}

      const pancakeSwapBscValidateToken = () => true

      if (!pancakeSwapBscClientConfig.validateToken) {
        pancakeSwapBscClientConfig.validateToken = pancakeSwapBscValidateToken
      }

      const pancakeSwapBscCache = pancakeSwapBscClientConfig.cache
        ? pancakeSwapBscClientConfig.cache
        : new InMemoryCache(pancakeSwapBscClientConfig.inMemoryCacheOptions ? pancakeSwapBscClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        pancakeSwapBscCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.pancakeSwapBsc : null)
      }

      if (!pancakeSwapBscClientConfig.getAuth) {
        pancakeSwapBscClientConfig.getAuth = pancakeSwapBscGetAuth
      }

      if (process.client && pancakeSwapBscClientConfig.browserHttpEndpoint) {
        pancakeSwapBscClientConfig.httpEndpoint = pancakeSwapBscClientConfig.browserHttpEndpoint
      }

      pancakeSwapBscClientConfig.ssr = !!process.server
      pancakeSwapBscClientConfig.cache = pancakeSwapBscCache
      pancakeSwapBscClientConfig.tokenName = pancakeSwapBscTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!pancakeSwapBscClientConfig.httpLinkOptions) {
          pancakeSwapBscClientConfig.httpLinkOptions = {}
        }
        if (!pancakeSwapBscClientConfig.httpLinkOptions.headers) {
          pancakeSwapBscClientConfig.httpLinkOptions.headers = {}
        }
        pancakeSwapBscClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let pancakeSwapBscApolloCreation = createApolloClient({
        ...pancakeSwapBscClientConfig
      })
      pancakeSwapBscApolloCreation.apolloClient.wsClient = pancakeSwapBscApolloCreation.wsClient

          providerOptions.clients.pancakeSwapBsc = pancakeSwapBscApolloCreation.apolloClient

      const stellaswapMoonbeamTokenName = ''  || AUTH_TOKEN_NAME

      function stellaswapMoonbeamGetAuth () {
        const token = cookies.get(stellaswapMoonbeamTokenName)
        return token && stellaswapMoonbeamClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let stellaswapMoonbeamClientConfig

        stellaswapMoonbeamClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/stellaswap/pulsar"
}

      const stellaswapMoonbeamValidateToken = () => true

      if (!stellaswapMoonbeamClientConfig.validateToken) {
        stellaswapMoonbeamClientConfig.validateToken = stellaswapMoonbeamValidateToken
      }

      const stellaswapMoonbeamCache = stellaswapMoonbeamClientConfig.cache
        ? stellaswapMoonbeamClientConfig.cache
        : new InMemoryCache(stellaswapMoonbeamClientConfig.inMemoryCacheOptions ? stellaswapMoonbeamClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        stellaswapMoonbeamCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.stellaswapMoonbeam : null)
      }

      if (!stellaswapMoonbeamClientConfig.getAuth) {
        stellaswapMoonbeamClientConfig.getAuth = stellaswapMoonbeamGetAuth
      }

      if (process.client && stellaswapMoonbeamClientConfig.browserHttpEndpoint) {
        stellaswapMoonbeamClientConfig.httpEndpoint = stellaswapMoonbeamClientConfig.browserHttpEndpoint
      }

      stellaswapMoonbeamClientConfig.ssr = !!process.server
      stellaswapMoonbeamClientConfig.cache = stellaswapMoonbeamCache
      stellaswapMoonbeamClientConfig.tokenName = stellaswapMoonbeamTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!stellaswapMoonbeamClientConfig.httpLinkOptions) {
          stellaswapMoonbeamClientConfig.httpLinkOptions = {}
        }
        if (!stellaswapMoonbeamClientConfig.httpLinkOptions.headers) {
          stellaswapMoonbeamClientConfig.httpLinkOptions.headers = {}
        }
        stellaswapMoonbeamClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let stellaswapMoonbeamApolloCreation = createApolloClient({
        ...stellaswapMoonbeamClientConfig
      })
      stellaswapMoonbeamApolloCreation.apolloClient.wsClient = stellaswapMoonbeamApolloCreation.wsClient

          providerOptions.clients.stellaswapMoonbeam = stellaswapMoonbeamApolloCreation.apolloClient

      const ramsesArbitrumTokenName = ''  || AUTH_TOKEN_NAME

      function ramsesArbitrumGetAuth () {
        const token = cookies.get(ramsesArbitrumTokenName)
        return token && ramsesArbitrumClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let ramsesArbitrumClientConfig

        ramsesArbitrumClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/ramsesexchange/concentrated-liquidity-graph"
}

      const ramsesArbitrumValidateToken = () => true

      if (!ramsesArbitrumClientConfig.validateToken) {
        ramsesArbitrumClientConfig.validateToken = ramsesArbitrumValidateToken
      }

      const ramsesArbitrumCache = ramsesArbitrumClientConfig.cache
        ? ramsesArbitrumClientConfig.cache
        : new InMemoryCache(ramsesArbitrumClientConfig.inMemoryCacheOptions ? ramsesArbitrumClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        ramsesArbitrumCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.ramsesArbitrum : null)
      }

      if (!ramsesArbitrumClientConfig.getAuth) {
        ramsesArbitrumClientConfig.getAuth = ramsesArbitrumGetAuth
      }

      if (process.client && ramsesArbitrumClientConfig.browserHttpEndpoint) {
        ramsesArbitrumClientConfig.httpEndpoint = ramsesArbitrumClientConfig.browserHttpEndpoint
      }

      ramsesArbitrumClientConfig.ssr = !!process.server
      ramsesArbitrumClientConfig.cache = ramsesArbitrumCache
      ramsesArbitrumClientConfig.tokenName = ramsesArbitrumTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!ramsesArbitrumClientConfig.httpLinkOptions) {
          ramsesArbitrumClientConfig.httpLinkOptions = {}
        }
        if (!ramsesArbitrumClientConfig.httpLinkOptions.headers) {
          ramsesArbitrumClientConfig.httpLinkOptions.headers = {}
        }
        ramsesArbitrumClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let ramsesArbitrumApolloCreation = createApolloClient({
        ...ramsesArbitrumClientConfig
      })
      ramsesArbitrumApolloCreation.apolloClient.wsClient = ramsesArbitrumApolloCreation.wsClient

          providerOptions.clients.ramsesArbitrum = ramsesArbitrumApolloCreation.apolloClient

      const thenaBscTokenName = ''  || AUTH_TOKEN_NAME

      function thenaBscGetAuth () {
        const token = cookies.get(thenaBscTokenName)
        return token && thenaBscClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let thenaBscClientConfig

        thenaBscClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/thenaursa/thena-fusion"
}

      const thenaBscValidateToken = () => true

      if (!thenaBscClientConfig.validateToken) {
        thenaBscClientConfig.validateToken = thenaBscValidateToken
      }

      const thenaBscCache = thenaBscClientConfig.cache
        ? thenaBscClientConfig.cache
        : new InMemoryCache(thenaBscClientConfig.inMemoryCacheOptions ? thenaBscClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        thenaBscCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.thenaBsc : null)
      }

      if (!thenaBscClientConfig.getAuth) {
        thenaBscClientConfig.getAuth = thenaBscGetAuth
      }

      if (process.client && thenaBscClientConfig.browserHttpEndpoint) {
        thenaBscClientConfig.httpEndpoint = thenaBscClientConfig.browserHttpEndpoint
      }

      thenaBscClientConfig.ssr = !!process.server
      thenaBscClientConfig.cache = thenaBscCache
      thenaBscClientConfig.tokenName = thenaBscTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!thenaBscClientConfig.httpLinkOptions) {
          thenaBscClientConfig.httpLinkOptions = {}
        }
        if (!thenaBscClientConfig.httpLinkOptions.headers) {
          thenaBscClientConfig.httpLinkOptions.headers = {}
        }
        thenaBscClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let thenaBscApolloCreation = createApolloClient({
        ...thenaBscClientConfig
      })
      thenaBscApolloCreation.apolloClient.wsClient = thenaBscApolloCreation.wsClient

          providerOptions.clients.thenaBsc = thenaBscApolloCreation.apolloClient

      const fusionXMantleTokenName = ''  || AUTH_TOKEN_NAME

      function fusionXMantleGetAuth () {
        const token = cookies.get(fusionXMantleTokenName)
        return token && fusionXMantleClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let fusionXMantleClientConfig

        fusionXMantleClientConfig = {
  "httpEndpoint": "https://graph.fusionx.finance/subgraphs/name/fusionx/exchange-v3"
}

      const fusionXMantleValidateToken = () => true

      if (!fusionXMantleClientConfig.validateToken) {
        fusionXMantleClientConfig.validateToken = fusionXMantleValidateToken
      }

      const fusionXMantleCache = fusionXMantleClientConfig.cache
        ? fusionXMantleClientConfig.cache
        : new InMemoryCache(fusionXMantleClientConfig.inMemoryCacheOptions ? fusionXMantleClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        fusionXMantleCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.fusionXMantle : null)
      }

      if (!fusionXMantleClientConfig.getAuth) {
        fusionXMantleClientConfig.getAuth = fusionXMantleGetAuth
      }

      if (process.client && fusionXMantleClientConfig.browserHttpEndpoint) {
        fusionXMantleClientConfig.httpEndpoint = fusionXMantleClientConfig.browserHttpEndpoint
      }

      fusionXMantleClientConfig.ssr = !!process.server
      fusionXMantleClientConfig.cache = fusionXMantleCache
      fusionXMantleClientConfig.tokenName = fusionXMantleTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!fusionXMantleClientConfig.httpLinkOptions) {
          fusionXMantleClientConfig.httpLinkOptions = {}
        }
        if (!fusionXMantleClientConfig.httpLinkOptions.headers) {
          fusionXMantleClientConfig.httpLinkOptions.headers = {}
        }
        fusionXMantleClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let fusionXMantleApolloCreation = createApolloClient({
        ...fusionXMantleClientConfig
      })
      fusionXMantleApolloCreation.apolloClient.wsClient = fusionXMantleApolloCreation.wsClient

          providerOptions.clients.fusionXMantle = fusionXMantleApolloCreation.apolloClient

      const pancakeSwapBaseTokenName = ''  || AUTH_TOKEN_NAME

      function pancakeSwapBaseGetAuth () {
        const token = cookies.get(pancakeSwapBaseTokenName)
        return token && pancakeSwapBaseClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let pancakeSwapBaseClientConfig

        pancakeSwapBaseClientConfig = {
  "httpEndpoint": "https://api.studio.thegraph.com/query/45376/exchange-v3-base/version/latest"
}

      const pancakeSwapBaseValidateToken = () => true

      if (!pancakeSwapBaseClientConfig.validateToken) {
        pancakeSwapBaseClientConfig.validateToken = pancakeSwapBaseValidateToken
      }

      const pancakeSwapBaseCache = pancakeSwapBaseClientConfig.cache
        ? pancakeSwapBaseClientConfig.cache
        : new InMemoryCache(pancakeSwapBaseClientConfig.inMemoryCacheOptions ? pancakeSwapBaseClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        pancakeSwapBaseCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.pancakeSwapBase : null)
      }

      if (!pancakeSwapBaseClientConfig.getAuth) {
        pancakeSwapBaseClientConfig.getAuth = pancakeSwapBaseGetAuth
      }

      if (process.client && pancakeSwapBaseClientConfig.browserHttpEndpoint) {
        pancakeSwapBaseClientConfig.httpEndpoint = pancakeSwapBaseClientConfig.browserHttpEndpoint
      }

      pancakeSwapBaseClientConfig.ssr = !!process.server
      pancakeSwapBaseClientConfig.cache = pancakeSwapBaseCache
      pancakeSwapBaseClientConfig.tokenName = pancakeSwapBaseTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!pancakeSwapBaseClientConfig.httpLinkOptions) {
          pancakeSwapBaseClientConfig.httpLinkOptions = {}
        }
        if (!pancakeSwapBaseClientConfig.httpLinkOptions.headers) {
          pancakeSwapBaseClientConfig.httpLinkOptions.headers = {}
        }
        pancakeSwapBaseClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let pancakeSwapBaseApolloCreation = createApolloClient({
        ...pancakeSwapBaseClientConfig
      })
      pancakeSwapBaseApolloCreation.apolloClient.wsClient = pancakeSwapBaseApolloCreation.wsClient

          providerOptions.clients.pancakeSwapBase = pancakeSwapBaseApolloCreation.apolloClient

      const pangolinAvalancheTokenName = ''  || AUTH_TOKEN_NAME

      function pangolinAvalancheGetAuth () {
        const token = cookies.get(pangolinAvalancheTokenName)
        return token && pangolinAvalancheClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let pangolinAvalancheClientConfig

        pangolinAvalancheClientConfig = {
  "httpEndpoint": "https://graph-avalanche.pangolin.network/subgraphs/name/avalanche/pangolin-elixir"
}

      const pangolinAvalancheValidateToken = () => true

      if (!pangolinAvalancheClientConfig.validateToken) {
        pangolinAvalancheClientConfig.validateToken = pangolinAvalancheValidateToken
      }

      const pangolinAvalancheCache = pangolinAvalancheClientConfig.cache
        ? pangolinAvalancheClientConfig.cache
        : new InMemoryCache(pangolinAvalancheClientConfig.inMemoryCacheOptions ? pangolinAvalancheClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        pangolinAvalancheCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.pangolinAvalanche : null)
      }

      if (!pangolinAvalancheClientConfig.getAuth) {
        pangolinAvalancheClientConfig.getAuth = pangolinAvalancheGetAuth
      }

      if (process.client && pangolinAvalancheClientConfig.browserHttpEndpoint) {
        pangolinAvalancheClientConfig.httpEndpoint = pangolinAvalancheClientConfig.browserHttpEndpoint
      }

      pangolinAvalancheClientConfig.ssr = !!process.server
      pangolinAvalancheClientConfig.cache = pangolinAvalancheCache
      pangolinAvalancheClientConfig.tokenName = pangolinAvalancheTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!pangolinAvalancheClientConfig.httpLinkOptions) {
          pangolinAvalancheClientConfig.httpLinkOptions = {}
        }
        if (!pangolinAvalancheClientConfig.httpLinkOptions.headers) {
          pangolinAvalancheClientConfig.httpLinkOptions.headers = {}
        }
        pangolinAvalancheClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let pangolinAvalancheApolloCreation = createApolloClient({
        ...pangolinAvalancheClientConfig
      })
      pangolinAvalancheApolloCreation.apolloClient.wsClient = pangolinAvalancheApolloCreation.wsClient

          providerOptions.clients.pangolinAvalanche = pangolinAvalancheApolloCreation.apolloClient

      const pancakeswapZksyncEraTokenName = ''  || AUTH_TOKEN_NAME

      function pancakeswapZksyncEraGetAuth () {
        const token = cookies.get(pancakeswapZksyncEraTokenName)
        return token && pancakeswapZksyncEraClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let pancakeswapZksyncEraClientConfig

        pancakeswapZksyncEraClientConfig = {
  "httpEndpoint": "https://api.studio.thegraph.com/query/45376/exchange-v3-zksync/version/latest"
}

      const pancakeswapZksyncEraValidateToken = () => true

      if (!pancakeswapZksyncEraClientConfig.validateToken) {
        pancakeswapZksyncEraClientConfig.validateToken = pancakeswapZksyncEraValidateToken
      }

      const pancakeswapZksyncEraCache = pancakeswapZksyncEraClientConfig.cache
        ? pancakeswapZksyncEraClientConfig.cache
        : new InMemoryCache(pancakeswapZksyncEraClientConfig.inMemoryCacheOptions ? pancakeswapZksyncEraClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        pancakeswapZksyncEraCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.pancakeswapZksyncEra : null)
      }

      if (!pancakeswapZksyncEraClientConfig.getAuth) {
        pancakeswapZksyncEraClientConfig.getAuth = pancakeswapZksyncEraGetAuth
      }

      if (process.client && pancakeswapZksyncEraClientConfig.browserHttpEndpoint) {
        pancakeswapZksyncEraClientConfig.httpEndpoint = pancakeswapZksyncEraClientConfig.browserHttpEndpoint
      }

      pancakeswapZksyncEraClientConfig.ssr = !!process.server
      pancakeswapZksyncEraClientConfig.cache = pancakeswapZksyncEraCache
      pancakeswapZksyncEraClientConfig.tokenName = pancakeswapZksyncEraTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!pancakeswapZksyncEraClientConfig.httpLinkOptions) {
          pancakeswapZksyncEraClientConfig.httpLinkOptions = {}
        }
        if (!pancakeswapZksyncEraClientConfig.httpLinkOptions.headers) {
          pancakeswapZksyncEraClientConfig.httpLinkOptions.headers = {}
        }
        pancakeswapZksyncEraClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let pancakeswapZksyncEraApolloCreation = createApolloClient({
        ...pancakeswapZksyncEraClientConfig
      })
      pancakeswapZksyncEraApolloCreation.apolloClient.wsClient = pancakeswapZksyncEraApolloCreation.wsClient

          providerOptions.clients.pancakeswapZksyncEra = pancakeswapZksyncEraApolloCreation.apolloClient

      const horizaBaseTestnetTokenName = ''  || AUTH_TOKEN_NAME

      function horizaBaseTestnetGetAuth () {
        const token = cookies.get(horizaBaseTestnetTokenName)
        return token && horizaBaseTestnetClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let horizaBaseTestnetClientConfig

        horizaBaseTestnetClientConfig = {
  "httpEndpoint": "https://subgraph.goerli.horiza.io/subgraphs/name/base-uniswap-v3-testnet"
}

      const horizaBaseTestnetValidateToken = () => true

      if (!horizaBaseTestnetClientConfig.validateToken) {
        horizaBaseTestnetClientConfig.validateToken = horizaBaseTestnetValidateToken
      }

      const horizaBaseTestnetCache = horizaBaseTestnetClientConfig.cache
        ? horizaBaseTestnetClientConfig.cache
        : new InMemoryCache(horizaBaseTestnetClientConfig.inMemoryCacheOptions ? horizaBaseTestnetClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        horizaBaseTestnetCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.horizaBaseTestnet : null)
      }

      if (!horizaBaseTestnetClientConfig.getAuth) {
        horizaBaseTestnetClientConfig.getAuth = horizaBaseTestnetGetAuth
      }

      if (process.client && horizaBaseTestnetClientConfig.browserHttpEndpoint) {
        horizaBaseTestnetClientConfig.httpEndpoint = horizaBaseTestnetClientConfig.browserHttpEndpoint
      }

      horizaBaseTestnetClientConfig.ssr = !!process.server
      horizaBaseTestnetClientConfig.cache = horizaBaseTestnetCache
      horizaBaseTestnetClientConfig.tokenName = horizaBaseTestnetTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!horizaBaseTestnetClientConfig.httpLinkOptions) {
          horizaBaseTestnetClientConfig.httpLinkOptions = {}
        }
        if (!horizaBaseTestnetClientConfig.httpLinkOptions.headers) {
          horizaBaseTestnetClientConfig.httpLinkOptions.headers = {}
        }
        horizaBaseTestnetClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let horizaBaseTestnetApolloCreation = createApolloClient({
        ...horizaBaseTestnetClientConfig
      })
      horizaBaseTestnetApolloCreation.apolloClient.wsClient = horizaBaseTestnetApolloCreation.wsClient

          providerOptions.clients.horizaBaseTestnet = horizaBaseTestnetApolloCreation.apolloClient

      const horizaArbitrumTokenName = ''  || AUTH_TOKEN_NAME

      function horizaArbitrumGetAuth () {
        const token = cookies.get(horizaArbitrumTokenName)
        return token && horizaArbitrumClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let horizaArbitrumClientConfig

        horizaArbitrumClientConfig = {
  "httpEndpoint": "https://subgraph-prod.goerli.horiza.io/subgraphs/name/retro-arbitrum-one-uniswap-v3"
}

      const horizaArbitrumValidateToken = () => true

      if (!horizaArbitrumClientConfig.validateToken) {
        horizaArbitrumClientConfig.validateToken = horizaArbitrumValidateToken
      }

      const horizaArbitrumCache = horizaArbitrumClientConfig.cache
        ? horizaArbitrumClientConfig.cache
        : new InMemoryCache(horizaArbitrumClientConfig.inMemoryCacheOptions ? horizaArbitrumClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        horizaArbitrumCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.horizaArbitrum : null)
      }

      if (!horizaArbitrumClientConfig.getAuth) {
        horizaArbitrumClientConfig.getAuth = horizaArbitrumGetAuth
      }

      if (process.client && horizaArbitrumClientConfig.browserHttpEndpoint) {
        horizaArbitrumClientConfig.httpEndpoint = horizaArbitrumClientConfig.browserHttpEndpoint
      }

      horizaArbitrumClientConfig.ssr = !!process.server
      horizaArbitrumClientConfig.cache = horizaArbitrumCache
      horizaArbitrumClientConfig.tokenName = horizaArbitrumTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!horizaArbitrumClientConfig.httpLinkOptions) {
          horizaArbitrumClientConfig.httpLinkOptions = {}
        }
        if (!horizaArbitrumClientConfig.httpLinkOptions.headers) {
          horizaArbitrumClientConfig.httpLinkOptions.headers = {}
        }
        horizaArbitrumClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let horizaArbitrumApolloCreation = createApolloClient({
        ...horizaArbitrumClientConfig
      })
      horizaArbitrumApolloCreation.apolloClient.wsClient = horizaArbitrumApolloCreation.wsClient

          providerOptions.clients.horizaArbitrum = horizaArbitrumApolloCreation.apolloClient

      const baseswapBaseTokenName = ''  || AUTH_TOKEN_NAME

      function baseswapBaseGetAuth () {
        const token = cookies.get(baseswapBaseTokenName)
        return token && baseswapBaseClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let baseswapBaseClientConfig

        baseswapBaseClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/baseswapfi/v3-base"
}

      const baseswapBaseValidateToken = () => true

      if (!baseswapBaseClientConfig.validateToken) {
        baseswapBaseClientConfig.validateToken = baseswapBaseValidateToken
      }

      const baseswapBaseCache = baseswapBaseClientConfig.cache
        ? baseswapBaseClientConfig.cache
        : new InMemoryCache(baseswapBaseClientConfig.inMemoryCacheOptions ? baseswapBaseClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        baseswapBaseCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.baseswapBase : null)
      }

      if (!baseswapBaseClientConfig.getAuth) {
        baseswapBaseClientConfig.getAuth = baseswapBaseGetAuth
      }

      if (process.client && baseswapBaseClientConfig.browserHttpEndpoint) {
        baseswapBaseClientConfig.httpEndpoint = baseswapBaseClientConfig.browserHttpEndpoint
      }

      baseswapBaseClientConfig.ssr = !!process.server
      baseswapBaseClientConfig.cache = baseswapBaseCache
      baseswapBaseClientConfig.tokenName = baseswapBaseTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!baseswapBaseClientConfig.httpLinkOptions) {
          baseswapBaseClientConfig.httpLinkOptions = {}
        }
        if (!baseswapBaseClientConfig.httpLinkOptions.headers) {
          baseswapBaseClientConfig.httpLinkOptions.headers = {}
        }
        baseswapBaseClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let baseswapBaseApolloCreation = createApolloClient({
        ...baseswapBaseClientConfig
      })
      baseswapBaseApolloCreation.apolloClient.wsClient = baseswapBaseApolloCreation.wsClient

          providerOptions.clients.baseswapBase = baseswapBaseApolloCreation.apolloClient

      const uniswapBlockTokenName = ''  || AUTH_TOKEN_NAME

      function uniswapBlockGetAuth () {
        const token = cookies.get(uniswapBlockTokenName)
        return token && uniswapBlockClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let uniswapBlockClientConfig

        uniswapBlockClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks"
}

      const uniswapBlockValidateToken = () => true

      if (!uniswapBlockClientConfig.validateToken) {
        uniswapBlockClientConfig.validateToken = uniswapBlockValidateToken
      }

      const uniswapBlockCache = uniswapBlockClientConfig.cache
        ? uniswapBlockClientConfig.cache
        : new InMemoryCache(uniswapBlockClientConfig.inMemoryCacheOptions ? uniswapBlockClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        uniswapBlockCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.uniswapBlock : null)
      }

      if (!uniswapBlockClientConfig.getAuth) {
        uniswapBlockClientConfig.getAuth = uniswapBlockGetAuth
      }

      if (process.client && uniswapBlockClientConfig.browserHttpEndpoint) {
        uniswapBlockClientConfig.httpEndpoint = uniswapBlockClientConfig.browserHttpEndpoint
      }

      uniswapBlockClientConfig.ssr = !!process.server
      uniswapBlockClientConfig.cache = uniswapBlockCache
      uniswapBlockClientConfig.tokenName = uniswapBlockTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!uniswapBlockClientConfig.httpLinkOptions) {
          uniswapBlockClientConfig.httpLinkOptions = {}
        }
        if (!uniswapBlockClientConfig.httpLinkOptions.headers) {
          uniswapBlockClientConfig.httpLinkOptions.headers = {}
        }
        uniswapBlockClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let uniswapBlockApolloCreation = createApolloClient({
        ...uniswapBlockClientConfig
      })
      uniswapBlockApolloCreation.apolloClient.wsClient = uniswapBlockApolloCreation.wsClient

          providerOptions.clients.uniswapBlock = uniswapBlockApolloCreation.apolloClient

      const uniswapBlockPolygonTokenName = ''  || AUTH_TOKEN_NAME

      function uniswapBlockPolygonGetAuth () {
        const token = cookies.get(uniswapBlockPolygonTokenName)
        return token && uniswapBlockPolygonClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let uniswapBlockPolygonClientConfig

        uniswapBlockPolygonClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/ianlapham/polygon-blocks"
}

      const uniswapBlockPolygonValidateToken = () => true

      if (!uniswapBlockPolygonClientConfig.validateToken) {
        uniswapBlockPolygonClientConfig.validateToken = uniswapBlockPolygonValidateToken
      }

      const uniswapBlockPolygonCache = uniswapBlockPolygonClientConfig.cache
        ? uniswapBlockPolygonClientConfig.cache
        : new InMemoryCache(uniswapBlockPolygonClientConfig.inMemoryCacheOptions ? uniswapBlockPolygonClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        uniswapBlockPolygonCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.uniswapBlockPolygon : null)
      }

      if (!uniswapBlockPolygonClientConfig.getAuth) {
        uniswapBlockPolygonClientConfig.getAuth = uniswapBlockPolygonGetAuth
      }

      if (process.client && uniswapBlockPolygonClientConfig.browserHttpEndpoint) {
        uniswapBlockPolygonClientConfig.httpEndpoint = uniswapBlockPolygonClientConfig.browserHttpEndpoint
      }

      uniswapBlockPolygonClientConfig.ssr = !!process.server
      uniswapBlockPolygonClientConfig.cache = uniswapBlockPolygonCache
      uniswapBlockPolygonClientConfig.tokenName = uniswapBlockPolygonTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!uniswapBlockPolygonClientConfig.httpLinkOptions) {
          uniswapBlockPolygonClientConfig.httpLinkOptions = {}
        }
        if (!uniswapBlockPolygonClientConfig.httpLinkOptions.headers) {
          uniswapBlockPolygonClientConfig.httpLinkOptions.headers = {}
        }
        uniswapBlockPolygonClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let uniswapBlockPolygonApolloCreation = createApolloClient({
        ...uniswapBlockPolygonClientConfig
      })
      uniswapBlockPolygonApolloCreation.apolloClient.wsClient = uniswapBlockPolygonApolloCreation.wsClient

          providerOptions.clients.uniswapBlockPolygon = uniswapBlockPolygonApolloCreation.apolloClient

      const uniswapBlockArbitrumTokenName = ''  || AUTH_TOKEN_NAME

      function uniswapBlockArbitrumGetAuth () {
        const token = cookies.get(uniswapBlockArbitrumTokenName)
        return token && uniswapBlockArbitrumClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let uniswapBlockArbitrumClientConfig

        uniswapBlockArbitrumClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-one-blocks"
}

      const uniswapBlockArbitrumValidateToken = () => true

      if (!uniswapBlockArbitrumClientConfig.validateToken) {
        uniswapBlockArbitrumClientConfig.validateToken = uniswapBlockArbitrumValidateToken
      }

      const uniswapBlockArbitrumCache = uniswapBlockArbitrumClientConfig.cache
        ? uniswapBlockArbitrumClientConfig.cache
        : new InMemoryCache(uniswapBlockArbitrumClientConfig.inMemoryCacheOptions ? uniswapBlockArbitrumClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        uniswapBlockArbitrumCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.uniswapBlockArbitrum : null)
      }

      if (!uniswapBlockArbitrumClientConfig.getAuth) {
        uniswapBlockArbitrumClientConfig.getAuth = uniswapBlockArbitrumGetAuth
      }

      if (process.client && uniswapBlockArbitrumClientConfig.browserHttpEndpoint) {
        uniswapBlockArbitrumClientConfig.httpEndpoint = uniswapBlockArbitrumClientConfig.browserHttpEndpoint
      }

      uniswapBlockArbitrumClientConfig.ssr = !!process.server
      uniswapBlockArbitrumClientConfig.cache = uniswapBlockArbitrumCache
      uniswapBlockArbitrumClientConfig.tokenName = uniswapBlockArbitrumTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!uniswapBlockArbitrumClientConfig.httpLinkOptions) {
          uniswapBlockArbitrumClientConfig.httpLinkOptions = {}
        }
        if (!uniswapBlockArbitrumClientConfig.httpLinkOptions.headers) {
          uniswapBlockArbitrumClientConfig.httpLinkOptions.headers = {}
        }
        uniswapBlockArbitrumClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let uniswapBlockArbitrumApolloCreation = createApolloClient({
        ...uniswapBlockArbitrumClientConfig
      })
      uniswapBlockArbitrumApolloCreation.apolloClient.wsClient = uniswapBlockArbitrumApolloCreation.wsClient

          providerOptions.clients.uniswapBlockArbitrum = uniswapBlockArbitrumApolloCreation.apolloClient

      const uniswapBlockOptimismTokenName = ''  || AUTH_TOKEN_NAME

      function uniswapBlockOptimismGetAuth () {
        const token = cookies.get(uniswapBlockOptimismTokenName)
        return token && uniswapBlockOptimismClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let uniswapBlockOptimismClientConfig

        uniswapBlockOptimismClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/ianlapham/uni-testing-subgraph"
}

      const uniswapBlockOptimismValidateToken = () => true

      if (!uniswapBlockOptimismClientConfig.validateToken) {
        uniswapBlockOptimismClientConfig.validateToken = uniswapBlockOptimismValidateToken
      }

      const uniswapBlockOptimismCache = uniswapBlockOptimismClientConfig.cache
        ? uniswapBlockOptimismClientConfig.cache
        : new InMemoryCache(uniswapBlockOptimismClientConfig.inMemoryCacheOptions ? uniswapBlockOptimismClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        uniswapBlockOptimismCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.uniswapBlockOptimism : null)
      }

      if (!uniswapBlockOptimismClientConfig.getAuth) {
        uniswapBlockOptimismClientConfig.getAuth = uniswapBlockOptimismGetAuth
      }

      if (process.client && uniswapBlockOptimismClientConfig.browserHttpEndpoint) {
        uniswapBlockOptimismClientConfig.httpEndpoint = uniswapBlockOptimismClientConfig.browserHttpEndpoint
      }

      uniswapBlockOptimismClientConfig.ssr = !!process.server
      uniswapBlockOptimismClientConfig.cache = uniswapBlockOptimismCache
      uniswapBlockOptimismClientConfig.tokenName = uniswapBlockOptimismTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!uniswapBlockOptimismClientConfig.httpLinkOptions) {
          uniswapBlockOptimismClientConfig.httpLinkOptions = {}
        }
        if (!uniswapBlockOptimismClientConfig.httpLinkOptions.headers) {
          uniswapBlockOptimismClientConfig.httpLinkOptions.headers = {}
        }
        uniswapBlockOptimismClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let uniswapBlockOptimismApolloCreation = createApolloClient({
        ...uniswapBlockOptimismClientConfig
      })
      uniswapBlockOptimismApolloCreation.apolloClient.wsClient = uniswapBlockOptimismApolloCreation.wsClient

          providerOptions.clients.uniswapBlockOptimism = uniswapBlockOptimismApolloCreation.apolloClient

      const uniswapBlockBscTokenName = ''  || AUTH_TOKEN_NAME

      function uniswapBlockBscGetAuth () {
        const token = cookies.get(uniswapBlockBscTokenName)
        return token && uniswapBlockBscClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let uniswapBlockBscClientConfig

        uniswapBlockBscClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/wombat-exchange/bnb-chain-block"
}

      const uniswapBlockBscValidateToken = () => true

      if (!uniswapBlockBscClientConfig.validateToken) {
        uniswapBlockBscClientConfig.validateToken = uniswapBlockBscValidateToken
      }

      const uniswapBlockBscCache = uniswapBlockBscClientConfig.cache
        ? uniswapBlockBscClientConfig.cache
        : new InMemoryCache(uniswapBlockBscClientConfig.inMemoryCacheOptions ? uniswapBlockBscClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        uniswapBlockBscCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.uniswapBlockBsc : null)
      }

      if (!uniswapBlockBscClientConfig.getAuth) {
        uniswapBlockBscClientConfig.getAuth = uniswapBlockBscGetAuth
      }

      if (process.client && uniswapBlockBscClientConfig.browserHttpEndpoint) {
        uniswapBlockBscClientConfig.httpEndpoint = uniswapBlockBscClientConfig.browserHttpEndpoint
      }

      uniswapBlockBscClientConfig.ssr = !!process.server
      uniswapBlockBscClientConfig.cache = uniswapBlockBscCache
      uniswapBlockBscClientConfig.tokenName = uniswapBlockBscTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!uniswapBlockBscClientConfig.httpLinkOptions) {
          uniswapBlockBscClientConfig.httpLinkOptions = {}
        }
        if (!uniswapBlockBscClientConfig.httpLinkOptions.headers) {
          uniswapBlockBscClientConfig.httpLinkOptions.headers = {}
        }
        uniswapBlockBscClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let uniswapBlockBscApolloCreation = createApolloClient({
        ...uniswapBlockBscClientConfig
      })
      uniswapBlockBscApolloCreation.apolloClient.wsClient = uniswapBlockBscApolloCreation.wsClient

          providerOptions.clients.uniswapBlockBsc = uniswapBlockBscApolloCreation.apolloClient

      const uniswapBlockBaseTokenName = ''  || AUTH_TOKEN_NAME

      function uniswapBlockBaseGetAuth () {
        const token = cookies.get(uniswapBlockBaseTokenName)
        return token && uniswapBlockBaseClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let uniswapBlockBaseClientConfig

        uniswapBlockBaseClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks"
}

      const uniswapBlockBaseValidateToken = () => true

      if (!uniswapBlockBaseClientConfig.validateToken) {
        uniswapBlockBaseClientConfig.validateToken = uniswapBlockBaseValidateToken
      }

      const uniswapBlockBaseCache = uniswapBlockBaseClientConfig.cache
        ? uniswapBlockBaseClientConfig.cache
        : new InMemoryCache(uniswapBlockBaseClientConfig.inMemoryCacheOptions ? uniswapBlockBaseClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        uniswapBlockBaseCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.uniswapBlockBase : null)
      }

      if (!uniswapBlockBaseClientConfig.getAuth) {
        uniswapBlockBaseClientConfig.getAuth = uniswapBlockBaseGetAuth
      }

      if (process.client && uniswapBlockBaseClientConfig.browserHttpEndpoint) {
        uniswapBlockBaseClientConfig.httpEndpoint = uniswapBlockBaseClientConfig.browserHttpEndpoint
      }

      uniswapBlockBaseClientConfig.ssr = !!process.server
      uniswapBlockBaseClientConfig.cache = uniswapBlockBaseCache
      uniswapBlockBaseClientConfig.tokenName = uniswapBlockBaseTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!uniswapBlockBaseClientConfig.httpLinkOptions) {
          uniswapBlockBaseClientConfig.httpLinkOptions = {}
        }
        if (!uniswapBlockBaseClientConfig.httpLinkOptions.headers) {
          uniswapBlockBaseClientConfig.httpLinkOptions.headers = {}
        }
        uniswapBlockBaseClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let uniswapBlockBaseApolloCreation = createApolloClient({
        ...uniswapBlockBaseClientConfig
      })
      uniswapBlockBaseApolloCreation.apolloClient.wsClient = uniswapBlockBaseApolloCreation.wsClient

          providerOptions.clients.uniswapBlockBase = uniswapBlockBaseApolloCreation.apolloClient

      const sushiswapBlockArbitrumTokenName = ''  || AUTH_TOKEN_NAME

      function sushiswapBlockArbitrumGetAuth () {
        const token = cookies.get(sushiswapBlockArbitrumTokenName)
        return token && sushiswapBlockArbitrumClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let sushiswapBlockArbitrumClientConfig

        sushiswapBlockArbitrumClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/sushiswap/arbitrum-blocks"
}

      const sushiswapBlockArbitrumValidateToken = () => true

      if (!sushiswapBlockArbitrumClientConfig.validateToken) {
        sushiswapBlockArbitrumClientConfig.validateToken = sushiswapBlockArbitrumValidateToken
      }

      const sushiswapBlockArbitrumCache = sushiswapBlockArbitrumClientConfig.cache
        ? sushiswapBlockArbitrumClientConfig.cache
        : new InMemoryCache(sushiswapBlockArbitrumClientConfig.inMemoryCacheOptions ? sushiswapBlockArbitrumClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        sushiswapBlockArbitrumCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.sushiswapBlockArbitrum : null)
      }

      if (!sushiswapBlockArbitrumClientConfig.getAuth) {
        sushiswapBlockArbitrumClientConfig.getAuth = sushiswapBlockArbitrumGetAuth
      }

      if (process.client && sushiswapBlockArbitrumClientConfig.browserHttpEndpoint) {
        sushiswapBlockArbitrumClientConfig.httpEndpoint = sushiswapBlockArbitrumClientConfig.browserHttpEndpoint
      }

      sushiswapBlockArbitrumClientConfig.ssr = !!process.server
      sushiswapBlockArbitrumClientConfig.cache = sushiswapBlockArbitrumCache
      sushiswapBlockArbitrumClientConfig.tokenName = sushiswapBlockArbitrumTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!sushiswapBlockArbitrumClientConfig.httpLinkOptions) {
          sushiswapBlockArbitrumClientConfig.httpLinkOptions = {}
        }
        if (!sushiswapBlockArbitrumClientConfig.httpLinkOptions.headers) {
          sushiswapBlockArbitrumClientConfig.httpLinkOptions.headers = {}
        }
        sushiswapBlockArbitrumClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let sushiswapBlockArbitrumApolloCreation = createApolloClient({
        ...sushiswapBlockArbitrumClientConfig
      })
      sushiswapBlockArbitrumApolloCreation.apolloClient.wsClient = sushiswapBlockArbitrumApolloCreation.wsClient

          providerOptions.clients.sushiswapBlockArbitrum = sushiswapBlockArbitrumApolloCreation.apolloClient

      const sushiswapBlockPolygonTokenName = ''  || AUTH_TOKEN_NAME

      function sushiswapBlockPolygonGetAuth () {
        const token = cookies.get(sushiswapBlockPolygonTokenName)
        return token && sushiswapBlockPolygonClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let sushiswapBlockPolygonClientConfig

        sushiswapBlockPolygonClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/matthewlilley/polygon-blocks"
}

      const sushiswapBlockPolygonValidateToken = () => true

      if (!sushiswapBlockPolygonClientConfig.validateToken) {
        sushiswapBlockPolygonClientConfig.validateToken = sushiswapBlockPolygonValidateToken
      }

      const sushiswapBlockPolygonCache = sushiswapBlockPolygonClientConfig.cache
        ? sushiswapBlockPolygonClientConfig.cache
        : new InMemoryCache(sushiswapBlockPolygonClientConfig.inMemoryCacheOptions ? sushiswapBlockPolygonClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        sushiswapBlockPolygonCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.sushiswapBlockPolygon : null)
      }

      if (!sushiswapBlockPolygonClientConfig.getAuth) {
        sushiswapBlockPolygonClientConfig.getAuth = sushiswapBlockPolygonGetAuth
      }

      if (process.client && sushiswapBlockPolygonClientConfig.browserHttpEndpoint) {
        sushiswapBlockPolygonClientConfig.httpEndpoint = sushiswapBlockPolygonClientConfig.browserHttpEndpoint
      }

      sushiswapBlockPolygonClientConfig.ssr = !!process.server
      sushiswapBlockPolygonClientConfig.cache = sushiswapBlockPolygonCache
      sushiswapBlockPolygonClientConfig.tokenName = sushiswapBlockPolygonTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!sushiswapBlockPolygonClientConfig.httpLinkOptions) {
          sushiswapBlockPolygonClientConfig.httpLinkOptions = {}
        }
        if (!sushiswapBlockPolygonClientConfig.httpLinkOptions.headers) {
          sushiswapBlockPolygonClientConfig.httpLinkOptions.headers = {}
        }
        sushiswapBlockPolygonClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let sushiswapBlockPolygonApolloCreation = createApolloClient({
        ...sushiswapBlockPolygonClientConfig
      })
      sushiswapBlockPolygonApolloCreation.apolloClient.wsClient = sushiswapBlockPolygonApolloCreation.wsClient

          providerOptions.clients.sushiswapBlockPolygon = sushiswapBlockPolygonApolloCreation.apolloClient

      const retroBlockPolygonTokenName = ''  || AUTH_TOKEN_NAME

      function retroBlockPolygonGetAuth () {
        const token = cookies.get(retroBlockPolygonTokenName)
        return token && retroBlockPolygonClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let retroBlockPolygonClientConfig

        retroBlockPolygonClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/matthewlilley/polygon-blocks"
}

      const retroBlockPolygonValidateToken = () => true

      if (!retroBlockPolygonClientConfig.validateToken) {
        retroBlockPolygonClientConfig.validateToken = retroBlockPolygonValidateToken
      }

      const retroBlockPolygonCache = retroBlockPolygonClientConfig.cache
        ? retroBlockPolygonClientConfig.cache
        : new InMemoryCache(retroBlockPolygonClientConfig.inMemoryCacheOptions ? retroBlockPolygonClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        retroBlockPolygonCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.retroBlockPolygon : null)
      }

      if (!retroBlockPolygonClientConfig.getAuth) {
        retroBlockPolygonClientConfig.getAuth = retroBlockPolygonGetAuth
      }

      if (process.client && retroBlockPolygonClientConfig.browserHttpEndpoint) {
        retroBlockPolygonClientConfig.httpEndpoint = retroBlockPolygonClientConfig.browserHttpEndpoint
      }

      retroBlockPolygonClientConfig.ssr = !!process.server
      retroBlockPolygonClientConfig.cache = retroBlockPolygonCache
      retroBlockPolygonClientConfig.tokenName = retroBlockPolygonTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!retroBlockPolygonClientConfig.httpLinkOptions) {
          retroBlockPolygonClientConfig.httpLinkOptions = {}
        }
        if (!retroBlockPolygonClientConfig.httpLinkOptions.headers) {
          retroBlockPolygonClientConfig.httpLinkOptions.headers = {}
        }
        retroBlockPolygonClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let retroBlockPolygonApolloCreation = createApolloClient({
        ...retroBlockPolygonClientConfig
      })
      retroBlockPolygonApolloCreation.apolloClient.wsClient = retroBlockPolygonApolloCreation.wsClient

          providerOptions.clients.retroBlockPolygon = retroBlockPolygonApolloCreation.apolloClient

      const fusionXBlockMantleTokenName = ''  || AUTH_TOKEN_NAME

      function fusionXBlockMantleGetAuth () {
        const token = cookies.get(fusionXBlockMantleTokenName)
        return token && fusionXBlockMantleClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let fusionXBlockMantleClientConfig

        fusionXBlockMantleClientConfig = {
  "httpEndpoint": "https://graph.fusionx.finance/subgraphs/name/mantle/blocks"
}

      const fusionXBlockMantleValidateToken = () => true

      if (!fusionXBlockMantleClientConfig.validateToken) {
        fusionXBlockMantleClientConfig.validateToken = fusionXBlockMantleValidateToken
      }

      const fusionXBlockMantleCache = fusionXBlockMantleClientConfig.cache
        ? fusionXBlockMantleClientConfig.cache
        : new InMemoryCache(fusionXBlockMantleClientConfig.inMemoryCacheOptions ? fusionXBlockMantleClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        fusionXBlockMantleCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.fusionXBlockMantle : null)
      }

      if (!fusionXBlockMantleClientConfig.getAuth) {
        fusionXBlockMantleClientConfig.getAuth = fusionXBlockMantleGetAuth
      }

      if (process.client && fusionXBlockMantleClientConfig.browserHttpEndpoint) {
        fusionXBlockMantleClientConfig.httpEndpoint = fusionXBlockMantleClientConfig.browserHttpEndpoint
      }

      fusionXBlockMantleClientConfig.ssr = !!process.server
      fusionXBlockMantleClientConfig.cache = fusionXBlockMantleCache
      fusionXBlockMantleClientConfig.tokenName = fusionXBlockMantleTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!fusionXBlockMantleClientConfig.httpLinkOptions) {
          fusionXBlockMantleClientConfig.httpLinkOptions = {}
        }
        if (!fusionXBlockMantleClientConfig.httpLinkOptions.headers) {
          fusionXBlockMantleClientConfig.httpLinkOptions.headers = {}
        }
        fusionXBlockMantleClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let fusionXBlockMantleApolloCreation = createApolloClient({
        ...fusionXBlockMantleClientConfig
      })
      fusionXBlockMantleApolloCreation.apolloClient.wsClient = fusionXBlockMantleApolloCreation.wsClient

          providerOptions.clients.fusionXBlockMantle = fusionXBlockMantleApolloCreation.apolloClient

      const pancakeSwapBlockBaseTokenName = ''  || AUTH_TOKEN_NAME

      function pancakeSwapBlockBaseGetAuth () {
        const token = cookies.get(pancakeSwapBlockBaseTokenName)
        return token && pancakeSwapBlockBaseClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let pancakeSwapBlockBaseClientConfig

        pancakeSwapBlockBaseClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/pancakeswap/blocks"
}

      const pancakeSwapBlockBaseValidateToken = () => true

      if (!pancakeSwapBlockBaseClientConfig.validateToken) {
        pancakeSwapBlockBaseClientConfig.validateToken = pancakeSwapBlockBaseValidateToken
      }

      const pancakeSwapBlockBaseCache = pancakeSwapBlockBaseClientConfig.cache
        ? pancakeSwapBlockBaseClientConfig.cache
        : new InMemoryCache(pancakeSwapBlockBaseClientConfig.inMemoryCacheOptions ? pancakeSwapBlockBaseClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        pancakeSwapBlockBaseCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.pancakeSwapBlockBase : null)
      }

      if (!pancakeSwapBlockBaseClientConfig.getAuth) {
        pancakeSwapBlockBaseClientConfig.getAuth = pancakeSwapBlockBaseGetAuth
      }

      if (process.client && pancakeSwapBlockBaseClientConfig.browserHttpEndpoint) {
        pancakeSwapBlockBaseClientConfig.httpEndpoint = pancakeSwapBlockBaseClientConfig.browserHttpEndpoint
      }

      pancakeSwapBlockBaseClientConfig.ssr = !!process.server
      pancakeSwapBlockBaseClientConfig.cache = pancakeSwapBlockBaseCache
      pancakeSwapBlockBaseClientConfig.tokenName = pancakeSwapBlockBaseTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!pancakeSwapBlockBaseClientConfig.httpLinkOptions) {
          pancakeSwapBlockBaseClientConfig.httpLinkOptions = {}
        }
        if (!pancakeSwapBlockBaseClientConfig.httpLinkOptions.headers) {
          pancakeSwapBlockBaseClientConfig.httpLinkOptions.headers = {}
        }
        pancakeSwapBlockBaseClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let pancakeSwapBlockBaseApolloCreation = createApolloClient({
        ...pancakeSwapBlockBaseClientConfig
      })
      pancakeSwapBlockBaseApolloCreation.apolloClient.wsClient = pancakeSwapBlockBaseApolloCreation.wsClient

          providerOptions.clients.pancakeSwapBlockBase = pancakeSwapBlockBaseApolloCreation.apolloClient

      const pangolinBlockAvalancheTokenName = ''  || AUTH_TOKEN_NAME

      function pangolinBlockAvalancheGetAuth () {
        const token = cookies.get(pangolinBlockAvalancheTokenName)
        return token && pangolinBlockAvalancheClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let pangolinBlockAvalancheClientConfig

        pangolinBlockAvalancheClientConfig = {
  "httpEndpoint": "https://graph-avalanche.pangolin.network/subgraphs/name/avalanche/blocks"
}

      const pangolinBlockAvalancheValidateToken = () => true

      if (!pangolinBlockAvalancheClientConfig.validateToken) {
        pangolinBlockAvalancheClientConfig.validateToken = pangolinBlockAvalancheValidateToken
      }

      const pangolinBlockAvalancheCache = pangolinBlockAvalancheClientConfig.cache
        ? pangolinBlockAvalancheClientConfig.cache
        : new InMemoryCache(pangolinBlockAvalancheClientConfig.inMemoryCacheOptions ? pangolinBlockAvalancheClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        pangolinBlockAvalancheCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.pangolinBlockAvalanche : null)
      }

      if (!pangolinBlockAvalancheClientConfig.getAuth) {
        pangolinBlockAvalancheClientConfig.getAuth = pangolinBlockAvalancheGetAuth
      }

      if (process.client && pangolinBlockAvalancheClientConfig.browserHttpEndpoint) {
        pangolinBlockAvalancheClientConfig.httpEndpoint = pangolinBlockAvalancheClientConfig.browserHttpEndpoint
      }

      pangolinBlockAvalancheClientConfig.ssr = !!process.server
      pangolinBlockAvalancheClientConfig.cache = pangolinBlockAvalancheCache
      pangolinBlockAvalancheClientConfig.tokenName = pangolinBlockAvalancheTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!pangolinBlockAvalancheClientConfig.httpLinkOptions) {
          pangolinBlockAvalancheClientConfig.httpLinkOptions = {}
        }
        if (!pangolinBlockAvalancheClientConfig.httpLinkOptions.headers) {
          pangolinBlockAvalancheClientConfig.httpLinkOptions.headers = {}
        }
        pangolinBlockAvalancheClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let pangolinBlockAvalancheApolloCreation = createApolloClient({
        ...pangolinBlockAvalancheClientConfig
      })
      pangolinBlockAvalancheApolloCreation.apolloClient.wsClient = pangolinBlockAvalancheApolloCreation.wsClient

          providerOptions.clients.pangolinBlockAvalanche = pangolinBlockAvalancheApolloCreation.apolloClient

      const pancakeswapBlockZksyncEraTokenName = ''  || AUTH_TOKEN_NAME

      function pancakeswapBlockZksyncEraGetAuth () {
        const token = cookies.get(pancakeswapBlockZksyncEraTokenName)
        return token && pancakeswapBlockZksyncEraClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let pancakeswapBlockZksyncEraClientConfig

        pancakeswapBlockZksyncEraClientConfig = {
  "httpEndpoint": "https://api.studio.thegraph.com/query/45376/blocks-zksync/version/latest"
}

      const pancakeswapBlockZksyncEraValidateToken = () => true

      if (!pancakeswapBlockZksyncEraClientConfig.validateToken) {
        pancakeswapBlockZksyncEraClientConfig.validateToken = pancakeswapBlockZksyncEraValidateToken
      }

      const pancakeswapBlockZksyncEraCache = pancakeswapBlockZksyncEraClientConfig.cache
        ? pancakeswapBlockZksyncEraClientConfig.cache
        : new InMemoryCache(pancakeswapBlockZksyncEraClientConfig.inMemoryCacheOptions ? pancakeswapBlockZksyncEraClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        pancakeswapBlockZksyncEraCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.pancakeswapBlockZksyncEra : null)
      }

      if (!pancakeswapBlockZksyncEraClientConfig.getAuth) {
        pancakeswapBlockZksyncEraClientConfig.getAuth = pancakeswapBlockZksyncEraGetAuth
      }

      if (process.client && pancakeswapBlockZksyncEraClientConfig.browserHttpEndpoint) {
        pancakeswapBlockZksyncEraClientConfig.httpEndpoint = pancakeswapBlockZksyncEraClientConfig.browserHttpEndpoint
      }

      pancakeswapBlockZksyncEraClientConfig.ssr = !!process.server
      pancakeswapBlockZksyncEraClientConfig.cache = pancakeswapBlockZksyncEraCache
      pancakeswapBlockZksyncEraClientConfig.tokenName = pancakeswapBlockZksyncEraTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!pancakeswapBlockZksyncEraClientConfig.httpLinkOptions) {
          pancakeswapBlockZksyncEraClientConfig.httpLinkOptions = {}
        }
        if (!pancakeswapBlockZksyncEraClientConfig.httpLinkOptions.headers) {
          pancakeswapBlockZksyncEraClientConfig.httpLinkOptions.headers = {}
        }
        pancakeswapBlockZksyncEraClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let pancakeswapBlockZksyncEraApolloCreation = createApolloClient({
        ...pancakeswapBlockZksyncEraClientConfig
      })
      pancakeswapBlockZksyncEraApolloCreation.apolloClient.wsClient = pancakeswapBlockZksyncEraApolloCreation.wsClient

          providerOptions.clients.pancakeswapBlockZksyncEra = pancakeswapBlockZksyncEraApolloCreation.apolloClient

      const pokeMeTokenName = ''  || AUTH_TOKEN_NAME

      function pokeMeGetAuth () {
        const token = cookies.get(pokeMeTokenName)
        return token && pokeMeClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let pokeMeClientConfig

        pokeMeClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/gelatodigital/poke-me"
}

      const pokeMeValidateToken = () => true

      if (!pokeMeClientConfig.validateToken) {
        pokeMeClientConfig.validateToken = pokeMeValidateToken
      }

      const pokeMeCache = pokeMeClientConfig.cache
        ? pokeMeClientConfig.cache
        : new InMemoryCache(pokeMeClientConfig.inMemoryCacheOptions ? pokeMeClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        pokeMeCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.pokeMe : null)
      }

      if (!pokeMeClientConfig.getAuth) {
        pokeMeClientConfig.getAuth = pokeMeGetAuth
      }

      if (process.client && pokeMeClientConfig.browserHttpEndpoint) {
        pokeMeClientConfig.httpEndpoint = pokeMeClientConfig.browserHttpEndpoint
      }

      pokeMeClientConfig.ssr = !!process.server
      pokeMeClientConfig.cache = pokeMeCache
      pokeMeClientConfig.tokenName = pokeMeTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!pokeMeClientConfig.httpLinkOptions) {
          pokeMeClientConfig.httpLinkOptions = {}
        }
        if (!pokeMeClientConfig.httpLinkOptions.headers) {
          pokeMeClientConfig.httpLinkOptions.headers = {}
        }
        pokeMeClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let pokeMeApolloCreation = createApolloClient({
        ...pokeMeClientConfig
      })
      pokeMeApolloCreation.apolloClient.wsClient = pokeMeApolloCreation.wsClient

          providerOptions.clients.pokeMe = pokeMeApolloCreation.apolloClient

      const pokeMePolygonTokenName = ''  || AUTH_TOKEN_NAME

      function pokeMePolygonGetAuth () {
        const token = cookies.get(pokeMePolygonTokenName)
        return token && pokeMePolygonClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let pokeMePolygonClientConfig

        pokeMePolygonClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/gelatodigital/poke-me-polygon"
}

      const pokeMePolygonValidateToken = () => true

      if (!pokeMePolygonClientConfig.validateToken) {
        pokeMePolygonClientConfig.validateToken = pokeMePolygonValidateToken
      }

      const pokeMePolygonCache = pokeMePolygonClientConfig.cache
        ? pokeMePolygonClientConfig.cache
        : new InMemoryCache(pokeMePolygonClientConfig.inMemoryCacheOptions ? pokeMePolygonClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        pokeMePolygonCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.pokeMePolygon : null)
      }

      if (!pokeMePolygonClientConfig.getAuth) {
        pokeMePolygonClientConfig.getAuth = pokeMePolygonGetAuth
      }

      if (process.client && pokeMePolygonClientConfig.browserHttpEndpoint) {
        pokeMePolygonClientConfig.httpEndpoint = pokeMePolygonClientConfig.browserHttpEndpoint
      }

      pokeMePolygonClientConfig.ssr = !!process.server
      pokeMePolygonClientConfig.cache = pokeMePolygonCache
      pokeMePolygonClientConfig.tokenName = pokeMePolygonTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!pokeMePolygonClientConfig.httpLinkOptions) {
          pokeMePolygonClientConfig.httpLinkOptions = {}
        }
        if (!pokeMePolygonClientConfig.httpLinkOptions.headers) {
          pokeMePolygonClientConfig.httpLinkOptions.headers = {}
        }
        pokeMePolygonClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let pokeMePolygonApolloCreation = createApolloClient({
        ...pokeMePolygonClientConfig
      })
      pokeMePolygonApolloCreation.apolloClient.wsClient = pokeMePolygonApolloCreation.wsClient

          providerOptions.clients.pokeMePolygon = pokeMePolygonApolloCreation.apolloClient

      const pokeMeArbitrumTokenName = ''  || AUTH_TOKEN_NAME

      function pokeMeArbitrumGetAuth () {
        const token = cookies.get(pokeMeArbitrumTokenName)
        return token && pokeMeArbitrumClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let pokeMeArbitrumClientConfig

        pokeMeArbitrumClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/gelatodigital/poke-me-arbitrum"
}

      const pokeMeArbitrumValidateToken = () => true

      if (!pokeMeArbitrumClientConfig.validateToken) {
        pokeMeArbitrumClientConfig.validateToken = pokeMeArbitrumValidateToken
      }

      const pokeMeArbitrumCache = pokeMeArbitrumClientConfig.cache
        ? pokeMeArbitrumClientConfig.cache
        : new InMemoryCache(pokeMeArbitrumClientConfig.inMemoryCacheOptions ? pokeMeArbitrumClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        pokeMeArbitrumCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.pokeMeArbitrum : null)
      }

      if (!pokeMeArbitrumClientConfig.getAuth) {
        pokeMeArbitrumClientConfig.getAuth = pokeMeArbitrumGetAuth
      }

      if (process.client && pokeMeArbitrumClientConfig.browserHttpEndpoint) {
        pokeMeArbitrumClientConfig.httpEndpoint = pokeMeArbitrumClientConfig.browserHttpEndpoint
      }

      pokeMeArbitrumClientConfig.ssr = !!process.server
      pokeMeArbitrumClientConfig.cache = pokeMeArbitrumCache
      pokeMeArbitrumClientConfig.tokenName = pokeMeArbitrumTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!pokeMeArbitrumClientConfig.httpLinkOptions) {
          pokeMeArbitrumClientConfig.httpLinkOptions = {}
        }
        if (!pokeMeArbitrumClientConfig.httpLinkOptions.headers) {
          pokeMeArbitrumClientConfig.httpLinkOptions.headers = {}
        }
        pokeMeArbitrumClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let pokeMeArbitrumApolloCreation = createApolloClient({
        ...pokeMeArbitrumClientConfig
      })
      pokeMeArbitrumApolloCreation.apolloClient.wsClient = pokeMeArbitrumApolloCreation.wsClient

          providerOptions.clients.pokeMeArbitrum = pokeMeArbitrumApolloCreation.apolloClient

      const pokeMeOptimismTokenName = ''  || AUTH_TOKEN_NAME

      function pokeMeOptimismGetAuth () {
        const token = cookies.get(pokeMeOptimismTokenName)
        return token && pokeMeOptimismClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let pokeMeOptimismClientConfig

        pokeMeOptimismClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/gelatodigital/poke-me-optimism"
}

      const pokeMeOptimismValidateToken = () => true

      if (!pokeMeOptimismClientConfig.validateToken) {
        pokeMeOptimismClientConfig.validateToken = pokeMeOptimismValidateToken
      }

      const pokeMeOptimismCache = pokeMeOptimismClientConfig.cache
        ? pokeMeOptimismClientConfig.cache
        : new InMemoryCache(pokeMeOptimismClientConfig.inMemoryCacheOptions ? pokeMeOptimismClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        pokeMeOptimismCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.pokeMeOptimism : null)
      }

      if (!pokeMeOptimismClientConfig.getAuth) {
        pokeMeOptimismClientConfig.getAuth = pokeMeOptimismGetAuth
      }

      if (process.client && pokeMeOptimismClientConfig.browserHttpEndpoint) {
        pokeMeOptimismClientConfig.httpEndpoint = pokeMeOptimismClientConfig.browserHttpEndpoint
      }

      pokeMeOptimismClientConfig.ssr = !!process.server
      pokeMeOptimismClientConfig.cache = pokeMeOptimismCache
      pokeMeOptimismClientConfig.tokenName = pokeMeOptimismTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!pokeMeOptimismClientConfig.httpLinkOptions) {
          pokeMeOptimismClientConfig.httpLinkOptions = {}
        }
        if (!pokeMeOptimismClientConfig.httpLinkOptions.headers) {
          pokeMeOptimismClientConfig.httpLinkOptions.headers = {}
        }
        pokeMeOptimismClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let pokeMeOptimismApolloCreation = createApolloClient({
        ...pokeMeOptimismClientConfig
      })
      pokeMeOptimismApolloCreation.apolloClient.wsClient = pokeMeOptimismApolloCreation.wsClient

          providerOptions.clients.pokeMeOptimism = pokeMeOptimismApolloCreation.apolloClient

      const pokeMeGoerliTokenName = ''  || AUTH_TOKEN_NAME

      function pokeMeGoerliGetAuth () {
        const token = cookies.get(pokeMeGoerliTokenName)
        return token && pokeMeGoerliClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let pokeMeGoerliClientConfig

        pokeMeGoerliClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/gelatodigital/poke-me-goerli"
}

      const pokeMeGoerliValidateToken = () => true

      if (!pokeMeGoerliClientConfig.validateToken) {
        pokeMeGoerliClientConfig.validateToken = pokeMeGoerliValidateToken
      }

      const pokeMeGoerliCache = pokeMeGoerliClientConfig.cache
        ? pokeMeGoerliClientConfig.cache
        : new InMemoryCache(pokeMeGoerliClientConfig.inMemoryCacheOptions ? pokeMeGoerliClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        pokeMeGoerliCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.pokeMeGoerli : null)
      }

      if (!pokeMeGoerliClientConfig.getAuth) {
        pokeMeGoerliClientConfig.getAuth = pokeMeGoerliGetAuth
      }

      if (process.client && pokeMeGoerliClientConfig.browserHttpEndpoint) {
        pokeMeGoerliClientConfig.httpEndpoint = pokeMeGoerliClientConfig.browserHttpEndpoint
      }

      pokeMeGoerliClientConfig.ssr = !!process.server
      pokeMeGoerliClientConfig.cache = pokeMeGoerliCache
      pokeMeGoerliClientConfig.tokenName = pokeMeGoerliTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!pokeMeGoerliClientConfig.httpLinkOptions) {
          pokeMeGoerliClientConfig.httpLinkOptions = {}
        }
        if (!pokeMeGoerliClientConfig.httpLinkOptions.headers) {
          pokeMeGoerliClientConfig.httpLinkOptions.headers = {}
        }
        pokeMeGoerliClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let pokeMeGoerliApolloCreation = createApolloClient({
        ...pokeMeGoerliClientConfig
      })
      pokeMeGoerliApolloCreation.apolloClient.wsClient = pokeMeGoerliApolloCreation.wsClient

          providerOptions.clients.pokeMeGoerli = pokeMeGoerliApolloCreation.apolloClient

      const pokeMeBscTokenName = ''  || AUTH_TOKEN_NAME

      function pokeMeBscGetAuth () {
        const token = cookies.get(pokeMeBscTokenName)
        return token && pokeMeBscClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let pokeMeBscClientConfig

        pokeMeBscClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/gelatodigital/poke-me-bsc"
}

      const pokeMeBscValidateToken = () => true

      if (!pokeMeBscClientConfig.validateToken) {
        pokeMeBscClientConfig.validateToken = pokeMeBscValidateToken
      }

      const pokeMeBscCache = pokeMeBscClientConfig.cache
        ? pokeMeBscClientConfig.cache
        : new InMemoryCache(pokeMeBscClientConfig.inMemoryCacheOptions ? pokeMeBscClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        pokeMeBscCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.pokeMeBsc : null)
      }

      if (!pokeMeBscClientConfig.getAuth) {
        pokeMeBscClientConfig.getAuth = pokeMeBscGetAuth
      }

      if (process.client && pokeMeBscClientConfig.browserHttpEndpoint) {
        pokeMeBscClientConfig.httpEndpoint = pokeMeBscClientConfig.browserHttpEndpoint
      }

      pokeMeBscClientConfig.ssr = !!process.server
      pokeMeBscClientConfig.cache = pokeMeBscCache
      pokeMeBscClientConfig.tokenName = pokeMeBscTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!pokeMeBscClientConfig.httpLinkOptions) {
          pokeMeBscClientConfig.httpLinkOptions = {}
        }
        if (!pokeMeBscClientConfig.httpLinkOptions.headers) {
          pokeMeBscClientConfig.httpLinkOptions.headers = {}
        }
        pokeMeBscClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let pokeMeBscApolloCreation = createApolloClient({
        ...pokeMeBscClientConfig
      })
      pokeMeBscApolloCreation.apolloClient.wsClient = pokeMeBscApolloCreation.wsClient

          providerOptions.clients.pokeMeBsc = pokeMeBscApolloCreation.apolloClient

      const pokeMeBaseTokenName = ''  || AUTH_TOKEN_NAME

      function pokeMeBaseGetAuth () {
        const token = cookies.get(pokeMeBaseTokenName)
        return token && pokeMeBaseClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let pokeMeBaseClientConfig

        pokeMeBaseClientConfig = {
  "httpEndpoint": "https://api.thegraph.com/subgraphs/name/gelatodigital/poke-me-base"
}

      const pokeMeBaseValidateToken = () => true

      if (!pokeMeBaseClientConfig.validateToken) {
        pokeMeBaseClientConfig.validateToken = pokeMeBaseValidateToken
      }

      const pokeMeBaseCache = pokeMeBaseClientConfig.cache
        ? pokeMeBaseClientConfig.cache
        : new InMemoryCache(pokeMeBaseClientConfig.inMemoryCacheOptions ? pokeMeBaseClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        pokeMeBaseCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.pokeMeBase : null)
      }

      if (!pokeMeBaseClientConfig.getAuth) {
        pokeMeBaseClientConfig.getAuth = pokeMeBaseGetAuth
      }

      if (process.client && pokeMeBaseClientConfig.browserHttpEndpoint) {
        pokeMeBaseClientConfig.httpEndpoint = pokeMeBaseClientConfig.browserHttpEndpoint
      }

      pokeMeBaseClientConfig.ssr = !!process.server
      pokeMeBaseClientConfig.cache = pokeMeBaseCache
      pokeMeBaseClientConfig.tokenName = pokeMeBaseTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!pokeMeBaseClientConfig.httpLinkOptions) {
          pokeMeBaseClientConfig.httpLinkOptions = {}
        }
        if (!pokeMeBaseClientConfig.httpLinkOptions.headers) {
          pokeMeBaseClientConfig.httpLinkOptions.headers = {}
        }
        pokeMeBaseClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let pokeMeBaseApolloCreation = createApolloClient({
        ...pokeMeBaseClientConfig
      })
      pokeMeBaseApolloCreation.apolloClient.wsClient = pokeMeBaseApolloCreation.wsClient

          providerOptions.clients.pokeMeBase = pokeMeBaseApolloCreation.apolloClient

      const metadataTokenName = ''  || AUTH_TOKEN_NAME

      function metadataGetAuth () {
        const token = cookies.get(metadataTokenName)
        return token && metadataClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let metadataClientConfig

        metadataClientConfig = {
  "httpEndpoint": "https://defiedge-backend-v1-afcb1ce7102c.herokuapp.com/graphql"
}

      const metadataValidateToken = () => true

      if (!metadataClientConfig.validateToken) {
        metadataClientConfig.validateToken = metadataValidateToken
      }

      const metadataCache = metadataClientConfig.cache
        ? metadataClientConfig.cache
        : new InMemoryCache(metadataClientConfig.inMemoryCacheOptions ? metadataClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        metadataCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.metadata : null)
      }

      if (!metadataClientConfig.getAuth) {
        metadataClientConfig.getAuth = metadataGetAuth
      }

      if (process.client && metadataClientConfig.browserHttpEndpoint) {
        metadataClientConfig.httpEndpoint = metadataClientConfig.browserHttpEndpoint
      }

      metadataClientConfig.ssr = !!process.server
      metadataClientConfig.cache = metadataCache
      metadataClientConfig.tokenName = metadataTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!metadataClientConfig.httpLinkOptions) {
          metadataClientConfig.httpLinkOptions = {}
        }
        if (!metadataClientConfig.httpLinkOptions.headers) {
          metadataClientConfig.httpLinkOptions.headers = {}
        }
        metadataClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let metadataApolloCreation = createApolloClient({
        ...metadataClientConfig
      })
      metadataApolloCreation.apolloClient.wsClient = metadataApolloCreation.wsClient

          providerOptions.clients.metadata = metadataApolloCreation.apolloClient

  const vueApolloOptions = Object.assign(providerOptions, {
      errorHandler (error) {
          console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
      }
  })

  const apolloProvider = new VueApollo(vueApolloOptions)
  // Allow access to the provider in the context
  app.apolloProvider = apolloProvider

  if (process.server) {
    const ApolloSSR = require('vue-apollo/ssr')
    beforeNuxtRender(({ nuxtState }) => {
      nuxtState.apollo = ApolloSSR.getStates(apolloProvider)
    })
  }

  inject('apolloHelpers', {
    onLogin: async (token, apolloClient = apolloProvider.defaultClient, cookieAttributes = COOKIE_ATTRIBUTES, skipResetStore = false) => {
      // Fallback for tokenExpires param
      if (typeof cookieAttributes === 'number') cookieAttributes = { expires: cookieAttributes }

      if (typeof cookieAttributes.expires === 'number') {
        cookieAttributes.expires = new Date(Date.now()+ 86400*1000*cookieAttributes.expires)
      }

      if (token) {
        cookies.set(AUTH_TOKEN_NAME, token, cookieAttributes)
      } else {
        cookies.remove(AUTH_TOKEN_NAME, cookieAttributes)
      }
      if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
      if (!skipResetStore) {
        try {
          await apolloClient.resetStore()
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('%cError on cache reset (setToken)', 'color: orange;', e.message)
        }
      }
    },
    onLogout: async (apolloClient = apolloProvider.defaultClient, skipResetStore = false) => {
      cookies.remove(AUTH_TOKEN_NAME, COOKIE_ATTRIBUTES)
      if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
      if (!skipResetStore) {
        try {
          await apolloClient.resetStore()
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
        }
      }
    },
    getToken: (tokenName = AUTH_TOKEN_NAME) => {
      return cookies.get(tokenName)
    }
  })
}
