import { ChainId } from '~/types/web3'

const chainConfig: Record<
  ChainId | 97 | 4002 | 43113 | 80001 | 1666700000,
  {
    chainName: string
    rpcUrls: string[]
    nativeCurrency: { name: String; symbol: string; decimals: number }
    blockExplorerUrls: string[]
  }
> = {
  97: {
    chainName: 'BSC Testnet',
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    blockExplorerUrls: ['https://explorer.binance.org/smart-testnet'],
  },
  [ChainId.polygon]: {
    chainName: 'Polygon Mainnet',
    rpcUrls: [
      'https://polygon.dmm.exchange/v1/mainnet/geth?appId=prod-dmm',
      'https://speedy-nodes-nyc.moralis.io/d22f75a55d757127240f9645/polygon/mainnet',
      'https://polygon-rpc.com/',
    ],
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    blockExplorerUrls: ['https://polygonscan.com/'],
  },
  4002: {
    chainName: 'Fantom Testnet',
    rpcUrls: ['https://rpc.testnet.fantom.network/'],
    nativeCurrency: {
      name: 'FTM',
      symbol: 'FTM',
      decimals: 18,
    },
    blockExplorerUrls: [],
  },
  [ChainId.arbitrum]: {
    chainName: 'Arbitrum One',
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    nativeCurrency: {
      name: 'AETH',
      symbol: 'AETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://arbiscan.io'],
  },
  [ChainId.optimism]: {
    chainName: 'Optimistic Ethereum',
    rpcUrls: ['https://mainnet.optimism.io'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://optimistic.etherscan.io'],
  },
  43113: {
    chainName: 'Avalanche FUJI C-Chain',
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
    blockExplorerUrls: ['https://cchain.explorer.avax-test.network'],
  },
  324: {
    chainName: 'zkSync Era',
    rpcUrls: ['https://mainnet.era.zksync.io'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://explorer.zksync.io'],
  },
  80001: {
    chainName: 'Polygon Testnet Mumbai',
    rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
  },
  1666700000: {
    chainName: 'Harmony Testnet',
    rpcUrls: ['https://api.s0.b.hmny.io'],
    nativeCurrency: {
      name: 'ONE',
      symbol: 'ONE',
      decimals: 18,
    },
    blockExplorerUrls: ['https://explorer.pops.one'],
  },
  [ChainId.bsc]: {
    chainName: 'BNB Smart Chain Mainnet',
    rpcUrls: ['https://bsc-dataseed1.binance.org'],
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    blockExplorerUrls: ['https://bscscan.com'],
  },
  [ChainId.mainnet]: {
    chainName: 'Ethereum Mainnet',
    rpcUrls: ['https://mainnet.infura.io/v3/'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://etherscan.io'],
  },
  [ChainId.kovan]: {
    chainName: '',
    rpcUrls: [],
    nativeCurrency: {
      name: '',
      symbol: '',
      decimals: 0,
    },
    blockExplorerUrls: [],
  },
  [ChainId.goerli]: {
    chainName: '',
    rpcUrls: [],
    nativeCurrency: {
      name: '',
      symbol: '',
      decimals: 0,
    },
    blockExplorerUrls: [],
  },
  [ChainId.rinkeby]: {
    chainName: '',
    rpcUrls: [],
    nativeCurrency: {
      name: '',
      symbol: '',
      decimals: 0,
    },
    blockExplorerUrls: [],
  },
  [ChainId.moonbeam]: {
    chainName: 'Moonbeam',
    rpcUrls: [
      'https://moonbeam.blastapi.io/52278028-0033-4fce-b8ee-c154d22f9991',
    ],
    nativeCurrency: {
      name: 'GLMR',
      symbol: 'GLMR',
      decimals: 18,
    },
    blockExplorerUrls: ['https://moonbeam.moonscan.io'],
  },
  [ChainId.mantle]: {
    chainName: 'Mantle',
    rpcUrls: ['https://rpc.mantle.xyz'],
    nativeCurrency: {
      name: 'MNT',
      symbol: 'MNT',
      decimals: 18,
    },
    blockExplorerUrls: ['https://explorer.mantle.xyz'],
  },
  [ChainId.base]: {
    chainName: 'Base',
    rpcUrls: ['https://mainnet.base.org'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://basescan.org'],
  },
  [ChainId.baseTestnet]: {
    chainName: 'Base Testnet',
    rpcUrls: ['https://goerli.base.org'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://goerli.basescan.org'],
  },
  [ChainId.avalanche]: {
    chainName: 'Avalanche',
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
    blockExplorerUrls: ['https://snowtrace.io'],
  },
}

export const CHAIN_ICONS: Record<
  ChainId | -1 | 97 | 4002 | 43113 | 80001 | 1666700000,
  string
> = {
  '-1': 'bg-gray-500',
  [ChainId.arbitrum]: '/assets/arbitrum_logo.png',
  [ChainId.base]: '/assets/base_logo.svg',
  [ChainId.baseTestnet]: '/assets/base_logo.svg',
  [ChainId.bsc]: '/assets/bnb_logo.svg',
  [ChainId.mainnet]: '/assets/mainnet_logo.png',
  [ChainId.moonbeam]: '/assets/moonbeam.png',
  [ChainId.optimism]: '/assets/optimism_logo.png',
  [ChainId.polygon]: '/assets/polygon_logo.png',
  [ChainId.mantle]: '/assets/mantle_logo.png',
  [ChainId.avalanche]: '/assets/avalanche_logo.png',
  [ChainId.zksyncEra]: '/assets/zksync_era_logo.png',
  // 'https://cryptocdn.fra1.cdn.digitaloceanspaces.com/sites/8/arbitrum_logo.1a8b7e63.png',
  // [ChainId.mainnet]: 'https://raw.githubusercontent.com/spothq/cryptocurrency-icons/master/svg/icon/eth.svg',
  // 10: 'https://www.quicknode.com/assets/icons/optimism-b52fb9e31f95e61ddbb419bd8798e88612260334e9704be0ee41f0943a440a25.png',
  // 137: 'https://raw.githubusercontent.com/spothq/cryptocurrency-icons/master/svg/icon/matic.svg',
  1666700000: 'bg-gray-500',
  4: 'bg-yellow-500',
  4002: 'bg-blue-500',
  42: 'bg-orange-500',
  43113: 'bg-red-500',
  5: 'bg-amber-500',
  80001: 'bg-purple-500',
  97: 'bg-green-500',
}
export const CHAIN_COLORS = {
  [ChainId.arbitrum]: '#27A0EF',
  [ChainId.bsc]: '#f0b90b',
  [ChainId.base]: '#0052ff',
  [ChainId.mainnet]: '#627EEA',
  [ChainId.moonbeam]: '#52cbc7',
  [ChainId.optimism]: '#FF0420',
  [ChainId.polygon]: '#8247E5',
}

export default chainConfig
