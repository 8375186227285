// These addresses will be able to access functionalities even if production is false for a chain
const ELEVATED_ADDRESSES = [
  '0x0000ce08fa224696a819877070bf378e8b131acf',
  '0x03cdc580e7de9d805483773cd73f00e9c6b2d0a0',
  '0x03fd2634470fe726bf357bd5db35938f55015eca',
  '0x0663f73e810c8088c7de36c9c3f91facafa2efb0',
  '0x14c712fa53588087193e43b8f3fa468ac6f6b0ae',
  '0x1580ee7dfc098d633fbc32c8b4d6af18a1cc39b0',
  '0x1a6eaf4279caa31416d426a999b8a9e6d1a9686f',
  '0x1ae9fe5fe499d53dba22ffbd774a5769e8ac5b96',
  '0x1d0f888ad1754dc186bb5bf71497f7922957e022',
  '0x22cb224f9fa487dce907135b57c779f1f32251d4',
  '0x362b3ad64c7527713520a63a93bfc37ebb90a47a',
  '0x3896217ee5478884eb1768c9a692c7123cf6f886',
  '0x3b46f8020b2aaff5efcd7bc54ced111288055959',
  '0x3e13ee5fa763201e18b2cdb0f71f3fd837e5f152',
  '0x3f436e96f7fbba9dbd764294c424b38e9ff3fff8',
  '0x406e3984169cd3d1bba7f68496545b5d16b8dc98',
  '0x41ea1616dd085568a7b58e96c64ae1be4789bd7e',
  '0x4497c293b025c2e2374f346c7d52dc2caa68edf8',
  '0x47b517225f39bb1853416d1d57da9afeb1460cdd',
  '0x48a64adbb34837f3bf4d40bcc40c85bbca1a3a84',
  '0x4a3e017c87317db1461d39d996ca5523d4a2e22d',
  '0x4d75bf9f3d9d758b0a3ad7ba957cd27ecbc90990',
  '0x51e34b6b88f8d5934ee354b0aca0fda33a2b75f9',
  '0x523ada4715754c7db01dce0bc44e57405308e3d5',
  '0x542129c2de733a3f3e345345fa8d69606528d7c4',
  '0x5429376b877772e54c9e13f7ebbad7f243a185b1',
  '0x5e816a2bb35bd962bb4f6cc81b4b33cd8ce087f2',
  '0x5f392dc5d86439318e2895625ee5e436f9155075',
  '0x6127cb39ac8e6066c469ace0edcc3506feaabf94',
  '0x620f0ea1abab10f799fd3aa0221e301dc80ef734',
  '0x6ae549b3e279de338e4d79f6f897b66bd392c453',
  '0x73f98c27030623c19c033edfac22693da9478102',
  '0x822daaead66b8c2ba15a7b8224869d4f5e5e7b61',
  '0x8420e9ae88c871ea8ab74fc0d3ce4dc771499992',
  '0x84eaf08c13e86cd1603bb8de7f5f61fa115771bc',
  '0x865e996078f20aa1adf96b3aef24fe5bb290edcd',
  '0x8b18c8d10d14f1a8c8b0da6bc33da85cc7b3a14c',
  '0x8b80755c441d355405ca7571443bb9247b77ec16',
  '0x8dd18dc690514264f6b0f7d96ef73fc1392258d3',
  '0x926b052aac17c2314c54bca18971dcce36d2df65',
  '0x98029c23e4a554989c280de7fcb1c2ededf2b17f',
  '0x98edf1e01acf54d7663f7723d4d2f4b66d4e0958',
  '0x9999999e2f76ed42e6b1a4eefd0fd472790fa1a5',
  '0x9f3a4f55dace415dce62ae09d9a3f92fc79b7705',
  '0x9fe6764778db6ad5c5ed86242485d1a868a8c52a',
  '0xa344ad63e7265be83bb4af5f41ff1cdd943d9b29',
  '0xa405bb5b1cea47eb4e456cfe82923688bc4acf84',
  '0xa983540c098d74f040ad22a0214708e4ec57cdde',
  '0xad796b11d4046d2c52af8ff34997f68a638a100a',
  '0xb332ff53af11a6d1b54360879a50098a06e988e8',
  '0xb7102517d071ad7c3a0f826524f5612b76a3e509',
  '0xb7439767143691fdf106b99771e0fe4269788352',
  '0xb7996444524e51399adb8ec05b238525ce38cf6c',
  '0xbf10cb116da6f8df76bd7ac645ce9d17ad1f1272',
  '0xc1056bdfe993340326d2efadacfdfd6fab5eb13c',
  '0xc58f20d4cd28303a669826b7a03543aeac6626ba',
  '0xc9e9ed5033faf883f173cbdfe1313faeef2cb4d7',
  '0xcade480aace69f8a296fc832902b7f5a09346b90',
  '0xdeba9cd00120ea0be15955cc3fa4b2d3e66aa65d',
  '0xe50ced2561b45ccd32b67f8a6f7dea9e566e502c',
  '0xe906780daaa43656bb9abe1378d29d0e79b96f08',
  '0xec9b106e925ccbf65d1cde7232154f3788fdac4c',
  '0xecbe3854b26750b301341bad149185e55544aba1',
  '0xeebcb03994f8bb11d06be355b3ac3632577beebf',
  '0xf05bd4f159f4db9ac6b53de94fed6b9612b2c461',
  '0xf168cf3328355a5e4eaa9daeb0daf9085d62269e',
  '0xf4844a06d4f995c4c03195afcb5aa59dcbb5b4fc',
  '0xf56dd30d6ab0ebdae3a2892597ec5c8ee03df099',
  '0xf88dd1c8b3d1586f6aec8fbbf45d40d209c09be9',
  '0x8ff830dAA9b6c5F15eD2Aa904cf39CEF6b1921AC',
].map((address: string) => address.toLowerCase())

export default ELEVATED_ADDRESSES
