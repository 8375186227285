var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col h-screen relative"},[_c('ClientOnly',[(_vm.secureBanner)?_c('div',{staticClass:"flex bg-primary-1 text-white text-xs text-center py-1.5 px-7 pr-8 text-opacity-80 z-0 gap-x-4 items-center relative dark:bg-primary-4"},[_c('span',[_vm._v("\n        We encourage you to DYOR before investing in any strategy. Our\n        contracts has been audited and secured by ABDK, Watch Pug and\n        Securing.\n      ")]),_vm._v(" "),_c('button',{staticClass:"transform top-[50%] right-4 z-30 -translate-y-[50%] absolute !h-5 !w-5",on:{"click":function($event){return _vm.setSecureBanner(false)}}},[_c('XCircleIcon',{staticClass:"!h-full !w-full"})],1)]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"flex flex-1"},[_c('div',{staticClass:"flex flex-col h-screen transition-all top-0 left-0 z-20 fixed md:sticky",class:[
        { '-ml-[256px] md:ml-0': !_vm.open },
        _vm.collaspe ? 'w-[256px] md:w-[80px]' : 'w-[256px]',
        'border-r bg-[#f1f1f1] dark:bg-grey-10 border-[#CFCFCFCC] text-xs dark:border-grey-8 dark:border-opacity-80',
      ]},[_c('div',{staticClass:"border-b border-[#CFCFCFCC] flex h-[65px] px-8 items-center relative dark:border-grey-8 dark:border-opacity-80"},[_c('NuxtLink',{staticClass:"flex font-bold text-lg text-primary-600 items-center dark:text-gray-100",attrs:{"exact-active-class":"text-primary-600 dark:text-gray-100","to":"/"}},[_c('Logo'),_vm._v(" "),(!_vm.collaspe || _vm.open)?[_c('span',{staticClass:"ml-1"},[_vm._v("DefiEdge")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.chainId && _vm.chainId != 1),expression:"chainId && chainId != 1"}],staticClass:"rounded-full bg-primary-600 text-white tracking-wider py-1 px-2 transform scale-85 whitespace-normal capitalize",class:['Arbitrum One', 'BNB chain'].includes(_vm.chainName)
                  ? 'text-[6pt]'
                  : 'text-[9pt]'},[_vm._v("\n              "+_vm._s(_vm.chainName)+"\n            ")])]:_vm._e()],2),_vm._v(" "),_c('button',{staticClass:"bg-white rounded-full flex h-6 transform top-[50%] right-0 w-6 z-10 translate-x-[50%] -translate-y-[50%] absolute hidden lg:block dark:bg-grey-7",staticStyle:{"box-shadow":"0px 1px 8px rgba(0, 0, 0, 0.1)"},attrs:{"type":"button"},on:{"click":function($event){return _vm.setNavbar(!_vm.collaspe)}}},[_c('ChevronLeft',{staticClass:"m-auto h-3 transform transition-transform text-grey-6 w-3 dark:text-grey-5",class:{ 'rotate-180': !_vm.collaspe }})],1)],1),_vm._v(" "),_c('ul',{staticClass:"space-y-[6px] flex-1 p-4"},[_vm._l((_vm.links),function(link,i){return [(!link.show || link.show())?_c('li',{key:i},[_c('NuxtLink',_vm._b({attrs:{"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, ...props }){return [_c('a',{staticClass:"w-full text-[0.85rem]",attrs:{"href":href},on:{"click":function($event){navigate($event)
                  _vm.open = false}}},[_c('div',{staticClass:"rounded-lg flex space-x-4 py-3 px-4 transition-all items-center",class:[
                    props[link.activeKey || 'isExactActive']
                      ? 'text-primary-1 dark:text-primary-1 bg-white dark:bg-[#111111]'
                      : 'text-gray-500 dark:text-gray-100',
                    { 'justify-center': _vm.collaspe && !_vm.open },
                  ]},[(link.icon)?_c(link.icon,{tag:"component",staticClass:"h-4 w-4"}):(link.logo)?_c('div',[_c('SafeImage',{staticClass:"h-4 w-4",attrs:{"src":link.logo}})],1):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"leave-active-class":"opacity-0","name":"slide-reverse"}},[(!_vm.collaspe || _vm.open)?_c('span',{staticClass:"flex-1 whitespace-nowrap"},[_vm._v(_vm._s(link.name))]):_vm._e()])],1)])]}}],null,true)},'NuxtLink',link.props,false))],1):_vm._e()]})],2),_vm._v(" "),_c('div',{staticClass:"w-full p-4"},[_c('NeutralButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.showButton),expression:"showButton"}],staticClass:"w-full whitespace-nowrap",class:{ 'md:mb-[28px]': _vm.secureBanner && !_vm.scrolled },attrs:{"id":"olvy-whats-new"}},[_c('BIconGiftFill',{staticClass:"inline"}),_vm._v(" "),(!_vm.collaspe)?_c('span',[_vm._v(" What's New")]):_vm._e()],1)],1)]),_vm._v(" "),_c('div',{staticClass:"flex-1"},[_c('AppHeader',{model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}}),_vm._v(" "),_c('div',{staticClass:"flex flex-col max-w-screen bg-[#f8f8f8] w-full transition-all dark:bg-[#171717]",class:[
          _vm.secureBanner
            ? _vm.scrolled
              ? 'min-h-[calc(100vh-93px+28px)]'
              : 'min-h-[calc(100vh-93px)]'
            : _vm.scrolled
            ? 'min-h-[calc(100vh-65px+28px)]'
            : 'min-h-[calc(100vh-65px)]',
        ]},[_c('main',{staticClass:"flex-1 dark:text-gray-50"},[_c('transition',{attrs:{"mode":"out-in","name":"page"}},[_c('Nuxt')],1)],1)])],1)]),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.open)?_c('div',{staticClass:"bg-black bg-opacity-30 inset-0 z-10 fixed backdrop-filter backdrop-blur-xs md:hidden",on:{"!click":function($event){$event.preventDefault();_vm.open = false}}}):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }