export enum NetworkEnum {
  arbitrum = 'arbitrum',
  base = 'base',
  bsc = 'bsc',
  goerli = 'goerli',
  kovan = 'kovan',
  mainnet = 'mainnet',
  mantle = 'mantle',
  avalanche = 'avalanche',
  moonbeam = 'moonbeam',
  optimism = 'optimism',
  polygon = 'polygon',
  rinkeby = 'rinkeby',
  baseTestnet = 'baseTestnet',
  zksyncEra = 'zksyncEra',
}

export enum ChainId {
  arbitrum = 42161,
  base = 8453,
  bsc = 56,
  goerli = 5,
  kovan = 42,
  mainnet = 1,
  mantle = 5000,
  avalanche = 43114,
  moonbeam = 1284,
  optimism = 10,
  polygon = 137,
  rinkeby = 4,
  baseTestnet = 84531,
  zksyncEra = 324,
}

export enum Dex {
  Apeswap = 'Apeswap',
  Arbidex = 'Arbidex',
  Camelot = 'Camelot',
  Fusionx = 'Fusionx',
  Pancakeswap = 'Pancakeswap',
  Ramses = 'Ramses',
  Retro = 'Retro',
  Stellaswap = 'Stellaswap',
  Sushiswap = 'Sushiswap',
  Thena = 'Thena',
  Uniswap = 'Uniswap',
  Horiza = 'Horiza',
  Pangolin = 'Pangolin',
  Baseswap = 'Baseswap',
}

export function networkToChanId(network: NetworkEnum): ChainId {
  return ChainId[network]
}

export type Network = keyof typeof NetworkEnum
